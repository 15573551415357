import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { endAuction } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

export const AcceptBid: FC = () => {
  const { [actionTypes.END_AUCTION]: acceptBidRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { id, network } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const userNetwork = useShallowSelector(userSelector.getProp('network'));
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const isAcceptBidLoading = useMemo(() => acceptBidRequestStatus === RequestStatus.REQUEST, [acceptBidRequestStatus]);

  const handleAcceptBid = () => {
    if (network?.name && network.name === userNetwork) {
      if (id) {
        dispatch(endAuction({ id, web3Provider: walletService.Web3() }));
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  return (
    <Button variant="contained" disabled={isAcceptBidLoading} onClick={handleAcceptBid} sx={{ width: 148, height: 44 }}>
      {isAcceptBidLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'accept bid'}
    </Button>
  );
};
