import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from 'store/orders/actionTypes';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger } from 'utils';

import { confirmClaim } from '../actions';

export function* confirmClaimSaga({ type, payload: { id, web3Provider } }: ReturnType<typeof confirmClaim>) {
  yield* put(request(type));
  const address: string = yield select(userSelector.getProp('address'));

  try {
    const { data } = yield* call(baseApi.confirmClaim, +id);
    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        toast.success('You have successfully confirm claim NFT');
        yield put(success(type));
      } catch (err) {
        logger('Send confirm claim transaction', err);
        toast.error('Something went wrong');
        yield put(error(type));
      }
    } else {
      yield put(error(type));
      toast.error('No initial tx');
    }
    yield put(success(type));
  } catch (e) {
    yield put(error(type, e));
    logger('confirmClaimSaga', e);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CONFIRM_CLAIM, confirmClaimSaga);
}
