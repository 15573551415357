import { createAction } from '@reduxjs/toolkit';
import {
  ApproveNftReq,
  ApproveReq,
  BidReq,
  BurnNftReq,
  BuyNftReq,
  CreateCollectionReq,
  CreateNftReq,
  GetNftDataReq,
  LikeNftReq,
  MaxPriceReq,
  MintNftReq,
  PresearchNftsReq,
  RequestWithId,
  RequestWithPagination,
  RequestWithWeb3Provider,
  SearchNftReq,
  SelfCollectionsReq,
  SetOnAucReq,
  SetOnSaleReq,
  TransferReq,
} from 'types/requests';

import actionTypes from './actionTypes';

export const helloWorld = createAction(actionTypes.HELLO_WORLD);
export const getCategories = createAction(actionTypes.GET_CATEGORIES);
export const getEnabledOnCarousel = createAction(actionTypes.GET_ENABLED_ON_CAROUSEL);
export const getFavorites = createAction(actionTypes.GET_FAVORITES);
export const getNewestNfts = createAction(actionTypes.GET_NEWEST);
export const getPopularNfts = createAction(actionTypes.GET_POPULAR);
export const getTopCollectors = createAction<RequestWithPagination>(actionTypes.GET_TOP_COLLECTORS);
export const getSelfCollections = createAction<SelfCollectionsReq>(actionTypes.GET_SELF_COLLECTIONS);
export const createCollection = createAction<CreateCollectionReq>(actionTypes.CREATE_COLLECTION);
export const approveNft = createAction<ApproveNftReq>(actionTypes.APPROVE_NFT);
export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const mintNft = createAction<MintNftReq & RequestWithId & ApproveNftReq>(actionTypes.MINT_NFT);
export const createNft = createAction<CreateNftReq>(actionTypes.CREATE_NFT);
export const getNftData = createAction<GetNftDataReq>(actionTypes.GET_NFT_DATA);
export const searchNfts = createAction<SearchNftReq>(actionTypes.SEARCH_NFTS);
export const presearchNfts = createAction<PresearchNftsReq>(actionTypes.PRESEARCH_NFTS);
export const getMaxPrice = createAction<MaxPriceReq>(actionTypes.GET_MAX_PRICE);
export const likeNft = createAction<LikeNftReq>(actionTypes.LIKE_NFT);
export const buyNft = createAction<BuyNftReq>(actionTypes.BUY);
export const bidNft = createAction<BidReq & RequestWithWeb3Provider>(actionTypes.BID);
export const setOnSale = createAction<SetOnSaleReq & RequestWithId & ApproveNftReq>(actionTypes.SET_ON_SALE);
export const setOnAuction = createAction<SetOnAucReq & RequestWithId & ApproveNftReq>(actionTypes.SET_ON_AUCTION);
export const transferNft = createAction<TransferReq & RequestWithId & RequestWithWeb3Provider>(actionTypes.TRANSFER);
export const endAuction = createAction<RequestWithId & RequestWithWeb3Provider>(actionTypes.END_AUCTION);
export const removeFromSale = createAction<RequestWithId>(actionTypes.REMOVE_FROM_SALE);
export const burnNft = createAction<BurnNftReq & RequestWithId & RequestWithWeb3Provider>(actionTypes.BURN);
