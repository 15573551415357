import { FC, useMemo } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import { DollarCoin, Order } from 'components';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

import { NavSelect } from '../NavSelect';

const StyledLink = styled(NavLink)({
  ...flexHelper('flex-start'),
  '&:hover': {
    p: { color: COLOR_BLACK },
    svg: { color: COLOR_BLACK },
  },
});

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
});

export interface NavMenuProps {
  userId: string | number;
  profileId: string | number;
}
export const NavMenu: FC<NavMenuProps> = ({ userId, profileId }) => {
  const { search, pathname } = useLocation();

  const lastPathname = useMemo(() => {
    const lastSearch = search.split('=').pop();
    const lastPath = pathname.split('/').pop();
    return lastSearch === 'none' || lastSearch === '' ? lastPath : lastSearch;
  }, [search, pathname]);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  return (
    <Stack spacing={4} width="min-content" direction={{ xs: 'row', sm: 'column' }} pb={{ xs: 1, sm: 0 }} pr={2}>
      {!!userId && (
        <StyledLink to={routes.profile.root.getPath(userId)}>
          <PersonOutlineOutlinedIcon sx={{ color: lastPathname === userId ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }} />
          <StyledTypography color={lastPathname === userId ? COLOR_BLACK : COLOR_GRAY_150}>My Profile</StyledTypography>
        </StyledLink>
      )}
      <NavSelect
        title="Collected"
        titleIcon={
          <AllInboxOutlinedIcon
            sx={{
              mr: 1.5,
              color: type === 'owned' || type === 'on-sale' ? COLOR_BLACK : COLOR_GRAY_150,
            }}
          />
        }
        isActivePath={type === 'owned' || type === 'on-sale'}
        menuItems={[
          {
            linkIcon: (
              <DollarCoin
                sx={{
                  mr: 1.5,
                  color: type === 'on-sale' ? COLOR_BLACK : COLOR_GRAY_150,
                }}
              />
            ),
            linkTitle: 'On sale',
            isActiveLink: type === 'on-sale',
            path: routes.profile.nft.root.getPath(profileId, 'on-sale'),
          },
          {
            linkIcon: (
              <FolderOutlinedIcon
                fontSize="small"
                sx={{ width: 24, height: 24, color: type === 'owned' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }}
              />
            ),
            linkTitle: 'Owned',
            isActiveLink: type === 'owned',
            path: routes.profile.nft.root.getPath(profileId, 'owned'),
          },
        ]}
      />
      <NavSelect
        title="Activity"
        titleIcon={
          <StarBorderRoundedIcon
            fontSize="large"
            sx={{
              mr: 1.5,
              color: type === 'sold' || type === 'bidded' ? COLOR_BLACK : COLOR_GRAY_150,
              width: 24,
              height: 24,
            }}
          />
        }
        isActivePath={type === 'sold' || type === 'bidded'}
        menuItems={[
          {
            linkIcon: (
              <Inventory2OutlinedIcon
                fontSize="small"
                sx={{ color: type === 'sold' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }}
              />
            ),
            linkTitle: 'Sold',
            isActiveLink: type === 'sold',
            path: routes.profile.nft.root.getPath(profileId, 'sold'),
          },
          {
            linkIcon: (
              <GavelIcon
                sx={{
                  mr: 1.5,
                  color: type === 'bidded' ? COLOR_BLACK : COLOR_GRAY_150,
                  width: '20px',
                }}
              />
            ),
            linkTitle: 'Bidded',
            isActiveLink: type === 'bidded',
            path: routes.profile.nft.root.getPath(profileId, 'bidded'),
          },
        ]}
      />
      <StyledLink to={routes.profile.nft.root.getPath(profileId, 'favorited')}>
        {({ isActive }) => (
          <>
            <FavoriteBorderOutlinedIcon
              sx={{ color: isActive && type === 'favorited' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }}
            />
            <StyledTypography color={isActive && type === 'favorited' ? COLOR_BLACK : COLOR_GRAY_150}>
              Favorited
            </StyledTypography>
          </>
        )}
      </StyledLink>
      {userId === profileId && (
        <>
          <StyledLink to={routes.profile.ordersHistory.root.getPath(userId)}>
            {({ isActive }) => (
              <>
                <Order
                  sx={{
                    mr: 1.5,
                    width: 24,
                    color: isActive ? COLOR_BLACK : COLOR_GRAY_150,
                  }}
                />
                <StyledTypography color={isActive ? COLOR_BLACK : COLOR_GRAY_150}>Orders history</StyledTypography>
              </>
            )}
          </StyledLink>
          <StyledLink to={routes.profile.custodyRequests.root.getPath(userId)}>
            {({ isActive }) => (
              <>
                <ShoppingBagOutlinedIcon
                  sx={{
                    mr: 1.5,
                    color: isActive ? COLOR_BLACK : COLOR_GRAY_150,
                  }}
                />
                <StyledTypography color={isActive ? COLOR_BLACK : COLOR_GRAY_150}>Custody request</StyledTypography>
              </>
            )}
          </StyledLink>
        </>
      )}
    </Stack>
  );
};
