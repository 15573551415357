import { ChangeEvent, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { Close, DateTimePicker } from 'components';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'hooks';
import {
  auctionTimes,
  saleTypes,
} from 'modules/create/pages/CreateCollectible/components/CreateCollectibleForm/CreateCollectibleForm.helpers';
import { Moment } from 'moment';
import { useWalletConnectorContext } from 'services';
import networkSelector from 'store/network/selectors';
import { setOnAuction, setOnSale } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { TertiaryButton } from 'theme/Button';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { Currency } from 'types/api';

import { QuantityCounter } from '../QuantityCounter';

export const SetOnSaleNft: FC = () => {
  const [currentCurrency, setCurrentCurrency] = useState<Currency>();
  const [customStartTime, setCustomStartTime] = useState<Moment | null>(null);
  const [customEndTime, setCustomEndTime] = useState<Moment | null>(null);
  const [saleType, setSaleType] = useState('instant');
  const [endTimeIndex, setEndTimeIndex] = useState<number | null>(null);
  const [newPrice, handleChangeNewPrice] = useValidateInputField({ type: ValidationTypes.number, decimals: 4 });
  const { address, network: userNetwork } = useShallowSelector(userSelector.getUser);
  const { currencies } = useShallowSelector(networkSelector.getNetwork);
  const { id, owners, standart, collection, network, isPending } = useShallowSelector(
    nftSelector.getProp('detailedNft'),
  );
  const { [actionTypes.SET_ON_SALE]: setOnSaleRequestStatus, [actionTypes.SET_ON_AUCTION]: setOnAucRequestStatus } =
    useShallowSelector(uiSelector.getUI);
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const isSetOnSaleLoading = useMemo(() => setOnSaleRequestStatus === RequestStatus.REQUEST, [setOnSaleRequestStatus]);
  const isSetOnAucLoading = useMemo(() => setOnAucRequestStatus === RequestStatus.REQUEST, [setOnAucRequestStatus]);

  const filteredCurrencies = useMemo(
    () =>
      saleType === 'instant'
        ? currencies || []
        : currencies?.filter((currency) => currency.symbol !== 'eth' && currency.symbol !== 'matic') || [],
    [currencies, saleType],
  );

  const userOwner = useMemo(
    () => (owners && owners.find((owner) => owner?.address === address)) || undefined,
    [address, owners],
  );

  const [quantity, setQuantity] = useState('1');

  const handleChangeSaleType = (e: ChangeEvent<HTMLInputElement>) => {
    setSaleType(e.target.value);
    setCurrentCurrency({} as Currency);
  };

  const handleSetOnSale = () => {
    if (network?.name && network.name === userNetwork) {
      if (id && currentCurrency?.symbol && collection?.address) {
        let startAuctionTime;
        let endTime;

        if (saleType === 'auctionTime') {
          startAuctionTime =
            endTimeIndex !== null
              ? new BigNumber(new Date().getTime()).div(1000).toFixed(0, 1)
              : new BigNumber(customStartTime?.valueOf() || 0).div(1000).toFixed(0, 1);
        }

        if ((customEndTime || endTimeIndex) !== null) {
          endTime =
            endTimeIndex !== null
              ? new BigNumber(new Date().getTime()).div(1000).plus(+auctionTimes[endTimeIndex].value).toFixed(0, 1)
              : new BigNumber(customEndTime?.valueOf() || 0).div(1000).toFixed(0, 1);
        }

        dispatch(
          saleType === 'instant'
            ? setOnSale({
                isSingle: standart === 'ERC721',
                collectionAddress: collection.address,
                web3Provider: walletService.Web3(),
                id,
                price: newPrice,
                amount: quantity,
                currency: currentCurrency.symbol,
                endSale: standart === 'ERC1155' && endTime ? endTime : '',
              })
            : setOnAuction({
                isSingle: standart === 'ERC721',
                collectionAddress: collection.address,
                web3Provider: walletService.Web3(),
                id,
                minimal_bid: newPrice,
                currency: currentCurrency.symbol,
                start_auction: startAuctionTime,
                end_auction: endTime,
              }),
        );
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  useEffect(() => {
    if (filteredCurrencies.length) setCurrentCurrency(filteredCurrencies[0]);
  }, [filteredCurrencies]);

  useEffect(() => {
    if (customStartTime !== null || customEndTime !== null) setEndTimeIndex(null);
  }, [customStartTime, customEndTime]);

  return (
    <>
      {standart === 'ERC721' && (
        <RadioGroup
          name="saleType"
          onChange={handleChangeSaleType}
          defaultValue={saleType}
          sx={{ flexDirection: 'row', gap: 5.5 }}
        >
          {saleTypes.map((item) => (
            <FormControlLabel key={item.index} value={item.value} control={<Radio />} label={item.label} />
          ))}
        </RadioGroup>
      )}
      {saleType === 'auctionTime' && (
        <>
          <Stack direction="row" spacing={2}>
            {auctionTimes.map((item) => (
              <TertiaryButton
                key={item.index}
                className={clsx(item.index === endTimeIndex ? 'active' : 'not-active')}
                onClick={() => {
                  setEndTimeIndex(item.index);
                  setCustomStartTime(null);
                  setCustomEndTime(null);
                }}
                sx={{ textTransform: 'none' }}
              >
                {item.label}
              </TertiaryButton>
            ))}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Typography>Start time</Typography>
              <DateTimePicker
                value={customStartTime}
                setValue={setCustomStartTime}
                disabled={isSetOnAucLoading}
                disablePast
                error={
                  customStartTime?.isSameOrAfter(customEndTime) ||
                  (!customStartTime?.isValid() && customStartTime !== null)
                }
                helperText={
                  (!customStartTime?.isValid() && customStartTime !== null && 'Not valid date') ||
                  (customStartTime?.isSameOrAfter(customEndTime) && 'End time should be after the start time') ||
                  ''
                }
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>End time</Typography>
              <DateTimePicker
                value={customEndTime}
                setValue={setCustomEndTime}
                disabled={isSetOnAucLoading}
                disablePast
                error={
                  customStartTime?.isSameOrAfter(customEndTime) || (!customEndTime?.isValid() && customEndTime !== null)
                }
                helperText={(!customEndTime?.isValid() && customEndTime !== null && 'Not valid date') || ''}
              />
            </Stack>
          </Stack>
        </>
      )}
      {standart === 'ERC1155' && +(userOwner?.quantity || 1) - +(userOwner?.redeemAmount || 0) > 0 && (
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={{ xs: 3, lg: 4 }}
          alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
        >
          <QuantityCounter
            quantity={quantity}
            setQuantity={setQuantity}
            maxQuantity={
              +(userOwner?.quantity || 0) - +(userOwner?.sellingQuantity || 0) - +(userOwner?.redeemAmount || 0)
            }
          />
          <Stack spacing={2} pb={2}>
            <Typography>Price</Typography>
            <Stack direction={{ xs: 'row', sm: 'column', md: 'row' }} spacing={2}>
              <TextField
                variant="filled"
                placeholder="0.00"
                value={newPrice}
                onChange={handleChangeNewPrice}
                error={newPrice !== '' && +newPrice < 0.001}
                InputProps={{
                  endAdornment: currentCurrency?.image ? <img src={currentCurrency.image} alt="currency logo" /> : '',
                }}
                helperText={
                  newPrice !== '' &&
                  (+newPrice < 0.001
                    ? ('Price should be equal or greater then 0.001' as ReactNode)
                    : `${parseFloat(new BigNumber(+newPrice).times(currentCurrency?.rate || 0).toFixed(4, 1))}$`)
                }
                sx={{
                  width: { xs: '200px', sm: '245px', lg: '200px', xl: '245px' },
                  height: '44px',
                  img: { width: '20px', height: '20px' },
                }}
              />
              <Button
                variant="contained"
                disabled={
                  isPending ||
                  +newPrice < 0.001 ||
                  typeof currentCurrency === 'undefined' ||
                  !address ||
                  isSetOnSaleLoading ||
                  isSetOnAucLoading ||
                  +(userOwner?.quantity || 1) - +(userOwner?.redeemAmount || 0) === 0 ||
                  customStartTime?.isSameOrAfter(customEndTime) ||
                  (!customStartTime?.isValid() && customStartTime !== null) ||
                  (!customEndTime?.isValid() && customStartTime !== null) ||
                  (saleType === 'auctionTime' && (customEndTime || endTimeIndex) === null)
                }
                onClick={handleSetOnSale}
                sx={{ width: '122px', height: '44px' }}
              >
                {isSetOnSaleLoading || isSetOnAucLoading ? (
                  <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
                ) : (
                  'Sell'
                )}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
      {standart === 'ERC1155' && (
        <Stack spacing={1}>
          <Typography>Duration</Typography>
          <Stack spacing={3}>
            <Stack direction="row" spacing={{ xs: 2, xl: 4.5 }}>
              {auctionTimes.map((item) => (
                <TertiaryButton
                  key={item.index}
                  className={clsx(item.index === endTimeIndex ? 'active' : 'not-active')}
                  onClick={() => {
                    setEndTimeIndex(item.index === endTimeIndex ? null : item.index);
                    setCustomStartTime(null);
                    setCustomEndTime(null);
                  }}
                  sx={{
                    width: { xs: 95, xl: 131 },
                    height: 44,
                    textTransform: 'none',
                    color: COLOR_GRAY_150,
                    '&.active': { color: COLOR_WHITE, fontWeight: FontWeights.fontWeightSemiBold },
                  }}
                >
                  {item.label}
                </TertiaryButton>
              ))}
            </Stack>
            <Box>
              <DateTimePicker
                value={customEndTime}
                setValue={setCustomEndTime}
                disabled={isSetOnSaleLoading}
                disablePast
                error={!customEndTime?.isValid() && customEndTime !== null}
                helperText={(!customEndTime?.isValid() && customEndTime !== null && 'Not valid time') || ''}
              />
              <Button variant="text" onClick={() => setCustomEndTime(null)} sx={{ mt: '12px', ml: 1 }}>
                <Close sx={{ color: COLOR_BLACK }} />
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};
