/* eslint-disable no-console */

import { IS_PRODUCTION } from 'appConstants';

type TLogger = 'log' | 'error' | 'warn' | 'info';

/**
 * @param {string} [title] set the title of info
 * @param {string} [msg] main block of the info
 * @param {string} [type] type of log
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logger = (title?: string, msg?: string | any, type: TLogger = 'info'): void => {
  if (IS_PRODUCTION) {
    console[type](title, msg);
  }
};
