import { ContractWeb3 } from '@amfi/connect-wallet/src/interface';
import { select } from 'redux-saga/effects';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Chains, UserState } from 'types';
import { Currency } from 'types/api';
import { fromDecimals } from 'utils';
import { logger } from 'utils/logger';

import { getPolygonBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getPolygonBalanceSaga({ type, payload: { web3Provider } }: ReturnType<typeof getPolygonBalance>) {
  yield put(request(type));
  const {
    address: userAddress,
    network,
    chainType,
    balance: prevBalance,
  }: UserState = yield select(userSelector.getUser);
  const { address: usdcContractAddress, abi: usdcAbi } = contractsConfig.contracts[ContractsNames.USDC][chainType];

  try {
    const usdcContract: ContractWeb3 = yield new web3Provider.eth.Contract(
      usdcAbi[network],
      usdcContractAddress[network],
    );

    if (userAddress) {
      const usdcBalance: string = yield call(usdcContract.methods.balanceOf(userAddress).call);
      const usdcDecimals: string = yield call(usdcContract.methods.decimals().call);
      const { data: networkRates }: { data: Currency[] } = yield call(baseApi.getRates, network);

      yield put(
        updateUserState({
          balance: {
            ...prevBalance,
            [Chains.Polygon]: {
              USDC: fromDecimals(usdcBalance, +usdcDecimals),
            },
          },
          rates: networkRates,
        }),
      );
      yield put(success(type));
    }
  } catch (err) {
    logger('getTokenBalance saga', err, 'error');

    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_POLYGON_BALANCE, getPolygonBalanceSaga);
}
