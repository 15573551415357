import * as Yup from 'yup';

export type DeliveryFormProps = {
  firstName: string;
  lastName: string;
  courierInvoiceNumber: string;
  courierCompanyName: string;
  address: string;
  zipCode: string;
  mobileNumber: string;
};

export const initialValues: DeliveryFormProps = {
  firstName: '',
  lastName: '',
  courierInvoiceNumber: '',
  courierCompanyName: '',
  address: '',
  zipCode: '',
  mobileNumber: '',
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too short').max(200, 'Maximum 200 characters').nullable().required('Required'),
  lastName: Yup.string().min(2, 'Too short').max(200, 'Maximum 200 characters').nullable().required('Required'),
  courierInvoiceNumber: Yup.string()
    .min(2, 'Too short')
    .max(100, 'Maximum 100 characters')
    .nullable()
    .required('Required'),
  courierCompanyName: Yup.string()
    .min(2, 'Too short')
    .max(100, 'Maximum 100 characters')
    .nullable()
    .required('Required'),
  address: Yup.string().min(2, 'Too short').max(1000, 'Maximum 1000 characters').nullable().required('Required'),
  zipCode: Yup.string().min(2, 'Too short').max(50, 'Maximum 50 characters').nullable().required('Required'),
  mobileNumber: Yup.string()
    .min(2, 'Too short')
    .max(30, 'Maximum 30 characters')
    .matches(/^[+]?[0-9]*[(]{0,1}[0-9]*[)]{0,1}[-\s.0-9]*$/, 'Enter correct mobile number!')
    .nullable()
    .required('Required'),
});
