import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Stack, Typography } from '@mui/material';
import { routes, texts } from 'appConstants';
import { Logo, LogoLarge } from 'assets/img';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { COLOR_GREEN } from 'theme/variables';

import { CollectionsImage, ServiceImage } from './assets';

export const About: FC = () => {
  const navigate = useNavigate();

  const handleNavigateToHowItWorks = () => {
    navigate(routes.aboutUs.howItWorks.root.path);
  };
  return (
    <>
      <Stack spacing={{ xs: 3, md: 0 }}>
        <Stack spacing={1}>
          <Box sx={{ img: { width: { xs: '310px', md: '436px' } } }}>
            <LogoLarge />
          </Box>
          <Typography
            fontWeight={FontWeights.fontWeightMedium}
            fontFamily={FontFamilies.secondary}
            fontSize={{ xs: '26px', sm: '30px' }}
            lineHeight={{ xs: '42px', sm: '69px' }}
            component="h2"
          >
            NFT MARKETPLACE
          </Typography>
          <Typography width={{ xs: '100%', sm: '565px' }} variant="subtitle1">
            {texts.projectDescription}
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={{ xs: 1, md: 3, lg: 8 }}>
          <Box sx={{ img: { width: { xs: '318px', md: '600px' } } }}>
            <CollectionsImage />
          </Box>
          <Stack spacing={3} maxWidth="546px">
            <Typography variant="subtitle1">
              <Logo />
              &nbsp;&nbsp;&nbsp;collection is targeting collectible items that the MZ generations are enthusiastic
              about. It is mainly curated with limited edition sneakers and items which are MZ’s favourable and in line
              with their taste.
            </Typography>
            <Typography variant="subtitle1">
              SneakerZ collection NFTs are all tied to physical sneakers and NFT owners are entitled to redeem it.
            </Typography>
            <Typography variant="subtitle1">
              Most collectibles NFTs are also tied to physical pieces and we are busy developing Digital art series with
              our partner companies.
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems="center" spacing={{ xs: 7, md: 3, lg: 15 }}>
          <Stack spacing={3} maxWidth="516px">
            <Typography variant="subtitle1">
              <Logo />
              &nbsp;&nbsp;&nbsp; signature services are that customer can request custody of sneakers owned and receive
              NFT in return, thereafter he owns NFT with guaranteed ownership and flexes his sneakers via NFT
              on/offline.
              <Typography variant="subtitle1" sx={{ color: COLOR_GREEN }}>
                (Please note custody and delivery fees are subject to charges)
              </Typography>
            </Typography>
            <Typography variant="subtitle1">
              meta[Z]’s Offline Space, CitieZ, is now open and operational in Cheongdam, Seoul and it will soon be open
              in major cities throughout the world. They are exclusive to meta[Z] NFT holders. Booking is available via
              Instagram DM or Naver reservation.
            </Typography>

            <Typography variant="subtitle1">
              The opening show on 20th December 2021 was very much successful and our collection with limited editions
              hardly available in Korea draw attention of media and Sneakers mania as well as investors including other
              businesses. The most collectible worth sneakers including Staple NYC Pigeon, Marsyard and Off-White Lot50
              full series are now on show at the meta[Z] Space and Lounge.
            </Typography>
          </Stack>
          <Box sx={{ img: { width: { xs: '318px', md: '600px' } }, pt: { xs: 4, md: 0 } }}>
            <ServiceImage />
          </Box>
        </Stack>
      </Stack>
      <Stack alignItems="center" mt={{ xs: 8, md: 11 }}>
        <Button
          variant="outlined"
          size="large"
          onClick={handleNavigateToHowItWorks}
          sx={{ width: '210px', whiteSpace: 'nowrap' }}
        >
          How it works?
        </Button>
      </Stack>
    </>
  );
};
