import { About, HowItWorks } from 'modules/about/pages';
import { AboutUs } from 'modules/aboutus/pages';
import { Activity } from 'modules/activity/pages';
import { City, Seoul } from 'modules/citiez/pages';
import { Create, CreateCollectible, CreateCollection } from 'modules/create/pages';
import { CustodyInfo } from 'modules/custodyInfo/pages';
import { DeFi } from 'modules/defi/pages';
import { Explore } from 'modules/explore/pages';
import { Home } from 'modules/landing/pages';
import { Membershipz } from 'modules/membershipz/pages';
import { Nft, RedeemClaimNft } from 'modules/nft/pages';
import { NotFound } from 'modules/notFound/pages';
import { Privacy } from 'modules/privacy/pages';
import {
  Custody,
  CustodyRequests,
  Delivery,
  EditProfile,
  Followers,
  OrdersHistory,
  Profile,
  ProfileNftCards,
} from 'modules/profile/pages';
import { Terms } from 'modules/terms/pages';
import { Modules, RoutesProps } from 'types';

/**
@Description
key 'dirName' - should be equal to component dir name
@example MyInvestments - name of dir and dirName: MyInvestments
*/

export const routes: RoutesProps = {
  home: {
    dirName: 'Home',
    root: {
      id: 1,
      title: 'Home',
      path: '/',
      component: <Home />,
      isNavItem: true,
      module: Modules.landing,
    },
  },
  membershipz: {
    dirName: 'Membershipz',
    root: {
      id: 2,
      title: 'Membershipz',
      path: '/membershipz',
      component: <Membershipz />,
      isNavItem: true,
      module: Modules.membershipz,
    },
  },
  citiez: {
    dirName: 'Citiez',
    root: {
      id: 3,
      title: 'Citiez',
      path: '/citiez',
      component: <Home />,
      isNavItem: false,
      module: Modules.citiez,
    },
    seoul: {
      dirName: 'Seoul',
      root: {
        id: 4,
        title: 'Seoul',
        path: '/citiez/seoul',
        component: <Seoul />,
        isNavItem: true,
        module: Modules.citiez,
      },
    },
    tokyo: {
      dirName: 'City',
      root: {
        id: 5,
        title: 'Tokyo',
        path: '/citiez/tokyo',
        component: <City title="Tokyo" isWorkInProgress />,
        isNavItem: true,
        module: Modules.citiez,
      },
    },
    newYork: {
      dirName: 'City',
      root: {
        id: 6,
        title: 'New York',
        path: '/citiez/new-york',
        component: <City title="New York" isWorkInProgress />,
        isNavItem: true,
        module: Modules.citiez,
      },
    },
    london: {
      dirName: 'City',
      root: {
        id: 7,
        title: 'London',
        path: '/citiez/london',
        component: <City title="London" isWorkInProgress />,
        isNavItem: true,
        module: Modules.citiez,
      },
    },
  },
  aboutUs: {
    dirName: 'About',
    root: {
      id: 8,
      title: 'About us',
      path: '/about-us',
      component: <About />,
      isNavItem: true,
      module: Modules.about,
    },
    howItWorks: {
      dirName: 'HowItWorks',
      root: {
        id: 9,
        title: 'How it works',
        path: '/about-us/how-it-works',
        component: <HowItWorks />,
        isNavItem: true,
        module: Modules.about,
      },
    },
  },
  explore: {
    dirName: 'Explore',
    root: {
      id: 10,
      title: 'Marketplace',
      path: '/explore',
      getPath: ({
        collection,
        text,
        property,
      }: {
        categories?: string;
        collection?: string;
        text?: string;
        styleHash?: string;
        property?: Record<string, string[]>;
      }) =>
        `/explore?${collection ? `&collection=${JSON.stringify(collection)}` : ''}${
          text ? `&text=${JSON.stringify(text)}` : ''
        }${property ? `&properties=${encodeURI(JSON.stringify(property))}` : ''}`,
      component: <Explore />,
      isNavItem: true,
      module: Modules.explore,
    },
  },
  nft: {
    dirName: 'Nft',
    root: {
      id: 11,
      title: 'NFT',
      path: '/nft/:id',
      getPath: (id: number | string) => `/nft/${id}`,
      component: <Nft />,
      isNavItem: true,
      isDynamic: true,
      module: Modules.nft,
    },
    redeem: {
      dirName: 'RedeemClaimNft',
      root: {
        id: 12,
        title: 'Redeem',
        path: '/nft/:id/redeem',
        getPath: (id: number | string) => `/nft/${id}/redeem`,
        component: <RedeemClaimNft type="redeem" />,
        isNavItem: true,
        isDynamic: true,
        module: Modules.nft,
      },
    },
    claim: {
      dirName: 'RedeemClaimNft',
      root: {
        id: 13,
        title: 'Claim',
        path: '/nft/:id/claim',
        getPath: (id: number | string) => `/nft/${id}/claim`,
        component: <RedeemClaimNft type="claim" />,
        isNavItem: true,
        isDynamic: true,
        isProtected: true,
        module: Modules.nft,
      },
    },
  },
  profile: {
    dirName: 'Profile',
    root: {
      id: 14,
      title: 'Profile',
      path: '/profile/:id',
      getPath: (id: number | string) => `/profile/${id}`,
      component: <Profile />,
      isNavItem: true,
      isDynamic: true,
      module: Modules.profile,
    },
    nft: {
      dirName: 'Profile',
      root: {
        id: 15,
        title: 'Nft',
        path: '/profile/:id/nft',
        getPath: (id: number | string, type?: string) => `/profile/${id}/nft?type=${type || 'none'}`,
        component: (
          <Profile>
            <ProfileNftCards />
          </Profile>
        ),
        isNavItem: true,
        isDynamic: true,
        module: Modules.profile,
      },
    },
    ordersHistory: {
      dirName: 'Profile',
      root: {
        id: 16,
        title: 'Orders history',
        path: '/profile/:id/orders-history',
        getPath: (id: number | string) => `/profile/${id}/orders-history`,
        component: (
          <Profile>
            <OrdersHistory />
          </Profile>
        ),
        isDynamic: true,
        isNavItem: true,
        module: Modules.profile,
      },
    },
    followers: {
      dirName: 'Profile',
      root: {
        id: 17,
        title: 'Followers',
        path: `/profile/:id/followers`,
        getPath: (id: number | string) => `/profile/${id}/followers`,
        component: (
          <Profile>
            <Followers />
          </Profile>
        ),
        isDynamic: true,
        isNavItem: true,
        module: Modules.profile,
      },
    },
    following: {
      dirName: 'Profile',
      root: {
        id: 18,
        title: 'Following',
        path: `/profile/:id/following`,
        getPath: (id: number | string) => `/profile/${id}/following`,
        component: (
          <Profile>
            <Followers />
          </Profile>
        ),
        isDynamic: true,
        isNavItem: true,
        module: Modules.profile,
      },
    },
    edit: {
      dirName: 'EditProfile',
      root: {
        id: 19,
        title: 'Setting',
        path: '/profile/:id/edit',
        getPath: (id: number | string) => `/profile/${id}/edit`,
        component: <EditProfile />,
        isDynamic: true,
        isNavItem: true,
        module: Modules.profile,
      },
    },
    custody: {
      dirName: 'Custody',
      root: {
        id: 20,
        title: 'Custody',
        path: '/profile/:id/custody',
        getPath: (id: number | string) => `/profile/${id}/custody`,
        component: <Custody />,
        isDynamic: true,
        isNavItem: true,
        isProtected: true,
        module: Modules.profile,
      },
      delivery: {
        dirName: 'Delivery',
        root: {
          id: 21,
          title: 'Delivery',
          path: '/profile/:id/custody/:custodyId/delivery',
          getPath: (id: number | string, custodyId: string) => `/profile/${id}/custody/${custodyId}/delivery`,
          component: <Delivery />,
          isDynamic: true,
          isNavItem: true,
          isProtected: true,
          module: Modules.profile,
        },
      },
    },
    custodyRequests: {
      dirName: 'Profile',
      root: {
        id: 22,
        title: 'Custody requests',
        path: '/profile/:id/custody-requests',
        getPath: (id: number | string) => `/profile/${id}/custody-requests`,
        component: (
          <Profile>
            <CustodyRequests />
          </Profile>
        ),
        isDynamic: true,
        isNavItem: true,
        module: Modules.profile,
      },
    },
  },
  activity: {
    dirName: 'Activity',
    root: {
      id: 23,
      title: 'Notification',
      path: '/activity',
      component: <Activity />,
      isNavItem: true,
      isProtected: true,
      module: Modules.activity,
    },
  },
  notFound: {
    dirName: 'NotFound',
    root: {
      id: 24,
      title: 'Not found',
      path: '/*',
      component: <NotFound />,
      isNavItem: true,
      module: Modules.notFound,
    },
  },
  create: {
    dirName: 'Create',
    root: {
      id: 25,
      title: 'Create collectible',
      path: '/create',
      component: <Create />,
      isNavItem: true,
      isProtected: true,
      module: Modules.create,
    },
    singleCollectible: {
      dirName: 'CreateCollectible',
      root: {
        id: 26,
        title: 'Single',
        path: '/create/single',
        component: <CreateCollectible collectibleType="single" />,
        isNavItem: true,
        isProtected: true,
        module: Modules.create,
      },
    },
    multipleCollectible: {
      dirName: 'CreateCollectible',
      root: {
        id: 27,
        title: 'Multiple',
        path: '/create/multiple',
        component: <CreateCollectible collectibleType="multiple" />,
        isNavItem: true,
        isProtected: true,
        module: Modules.create,
      },
    },
    collection: {
      dirName: 'CreateCollection',
      root: {
        id: 28,
        title: 'Collection',
        path: '/create/collection',
        component: <CreateCollection />,
        isNavItem: true,
        isProtected: true,
        module: Modules.create,
      },
    },
  },
  privacy: {
    dirName: 'Privacy',
    root: {
      id: 29,
      title: 'Privacy Policy',
      path: '/privacy',
      component: <Privacy />,
      isNavItem: true,
      module: Modules.privacy,
    },
  },
  terms: {
    dirName: 'Terms',
    root: {
      id: 30,
      title: 'Terms of Service',
      path: '/terms',
      component: <Terms />,
      isNavItem: true,
      module: Modules.terms,
    },
  },
  aboutUs2: {
    dirName: 'AboutUs',
    root: {
      id: 31,
      title: 'About Us',
      path: '/about',
      component: <AboutUs />,
      isNavItem: true,
      module: Modules.aboutUs,
    },
  },
  defi: {
    dirName: 'DeFi',
    root: {
      id: 32,
      title: 'DeFi',
      path: '/defi',
      component: <DeFi />,
      isNavItem: true,
      module: Modules.defi,
      unsetCase: true,
    },
  },
  custodyInfo: {
    dirName: 'CustodyInfo',
    root: {
      id: 33,
      title: 'Custody',
      path: '/custody',
      component: <CustodyInfo />,
      isNavItem: true,
      module: Modules.custodyInfo,
    },
  },
};
