export * from './connect';
export * from './store';
export * from './contracts';
export * from './routes';
export * from './requests';

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
  coinbaseWallet = 'CoinbaseWallet',
}

export type Currencies = 'USDC';
export type CurrenciesForConvert = 'eth' | 'weth' | 'matic' | 'wmatic';

export type TReferrals = {
  registerTime: number;
  memberCode: string;
  memberLiquidity: number;
};

export enum RoundingModes {
  up,
  down,
}

export interface INotifyModalProps {
  currData?: {
    [k: string]: string;
  };
  closeModal: () => void;
}

export enum Modules {
  landing = 'landing',
  explore = 'explore',
  nft = 'nft',
  profile = 'profile',
  activity = 'activity',
  membershipz = 'membershipz',
  about = 'about',
  howItWorks = 'howItWorks',
  notFound = 'notFound',
  citiez = 'citiez',
  create = 'create',
  privacy = 'privacy',
  terms = 'terms',
  aboutUs = 'aboutus',
  defi = 'defi',
  custodyInfo = 'custodyInfo',
}

export enum ProjectStages {
  upcoming = 'upcoming',
  open = 'open ido',
  completed = 'past ido',
}

export type ProjectStageProps = keyof typeof ProjectStages;

export type Nullable<T> = null | T;
