import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: 28, height: 28, fill: 'none', ...sx }} viewBox="0 0 28 28">
    <path
      d="M8.80852 25.0843C19.372 25.0843 25.1513 16.3305 25.1513 8.74152C25.1513 8.49543 25.1459 8.24387 25.1349 7.99777C26.2592 7.18472 27.2294 6.17765 28 5.02386C26.9529 5.48972 25.8412 5.79396 24.7029 5.92621C25.9015 5.20774 26.799 4.07907 27.2289 2.74941C26.1013 3.41767 24.8682 3.88906 23.5823 4.1434C22.716 3.22284 21.5705 2.61332 20.323 2.40908C19.0754 2.20483 17.7954 2.41724 16.6807 3.01346C15.566 3.60968 14.6788 4.55651 14.1562 5.70755C13.6335 6.85859 13.5047 8.14973 13.7895 9.38137C11.5062 9.26679 9.27259 8.67367 7.23331 7.64047C5.19403 6.60726 3.39464 5.15703 1.9518 3.38379C1.21847 4.64813 0.994066 6.14427 1.3242 7.56812C1.65434 8.99198 2.51424 10.2367 3.72914 11.0493C2.81707 11.0204 1.92497 10.7748 1.12656 10.3329V10.404C1.12575 11.7309 1.58445 13.017 2.42469 14.0439C3.26494 15.0708 4.43488 15.775 5.73562 16.0368C4.89073 16.268 4.00398 16.3017 3.14398 16.1353C3.51103 17.2764 4.22517 18.2744 5.18673 18.9901C6.1483 19.7058 7.30931 20.1034 8.50773 20.1275C6.47316 21.7256 3.95987 22.5925 1.37266 22.5884C0.913835 22.5877 0.455466 22.5596 0 22.5042C2.62833 24.1904 5.68579 25.086 8.80852 25.0843Z"
      fill="currentColor"
    />
  </SvgIcon>
);
