import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_GRAY_200 } from '../variables';

export const getMuiSkeletonOverrides = (theme: Theme): ComponentsOverrides['MuiSkeleton'] => ({
  root: {
    background: COLOR_GRAY_200,
    transformOrigin: 0,
    ':before': { display: 'none' },
    '&.square': {
      '&:after': { content: '""', display: 'block', paddingBottom: '100%' },
    },
  },
});

export const getMuiSkeletonDefaultProps = (): ComponentsProps['MuiSkeleton'] => ({});
