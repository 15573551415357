import { fork } from 'redux-saga/effects';

import getActivities from './getActivities';
import getFollowingActivities from './getFollowingActivities';
import getNotifications from './getNotifications';
import getUserActivities from './getUserActivities';
import viewActivity from './viewActivity';

export default function* activitySagas() {
  yield fork(getNotifications);
  yield fork(getActivities);
  yield fork(getUserActivities);
  yield fork(getFollowingActivities);
  yield fork(viewActivity);
}
