import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { UsdcLogo } from 'assets/img';
import { CollectionTypeBadge, LikeButton } from 'components';
import { useWindowState } from 'hooks';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT, COLOR_WHITE } from 'theme/variables';
import { shortenPhrase } from 'utils';

export type NftCardProps = {
  id?: string | number;
  cardSize: 'medium' | 'large';
  image: string;
  animation: string;
  format: string;
  title: string;
  price: string | number;
  isSelling?: boolean;
  isLiked: boolean;
  showLike?: boolean;
  collectionType: string;
  isClaimed?: boolean;
  nftSize?: string;
  styleHash?: string;
};

type InfoTextProps = {
  customFontSize?: number;
  customMobileFontSize?: number;
};

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '20px',
  color: COLOR_GRAY_150,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const InfoText = styled(Typography)<InfoTextProps>(({ theme, customFontSize = 14, customMobileFontSize = 12 }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: COLOR_BLACK,
  fontWeight: FontWeights.fontWeightMedium,
  fontSize: customFontSize,
  lineHeight: '18px',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
  'word-wrap': 'break-word',
  [theme.breakpoints.down('sm')]: {
    fontSize: customMobileFontSize,
    '-webkit-line-clamp': '1',
  },
}));

export const NftCard: FC<NftCardProps> = ({
  id,
  cardSize,
  image,
  format,
  isLiked,
  price,
  isSelling,
  animation,
  title,
  showLike = true,
  collectionType,
  isClaimed = false,
  nftSize = '0',
  styleHash = '-',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowState();

  const handleNavigateToNft = () => {
    if (id) {
      navigate(routes.nft.root.getPath(id));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const handleNavigateToExploreStyleHash = () => {
    const key = 'Style%20%23';
    navigate(routes.explore.root.getPath({ property: { [key]: [styleHash] } }));
  };
  const handleNavigateToExploreSize = () => {
    navigate(routes.explore.root.getPath({ property: { Size: [nftSize] } }));
  };

  return (
    <Box
      padding={1}
      width={{ xs: '180px', sm: cardSize === 'medium' ? '286px' : '296px' }}
      height={{ xs: '298px', sm: cardSize === 'medium' ? '396px' : '410px' }}
      borderRadius={4}
      bgcolor={COLOR_WHITE}
      border={`1px solid ${COLOR_LIGHT}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Box overflow="hidden" position="relative" borderRadius={2}>
        {collectionType && <CollectionTypeBadge isClaimed={isClaimed} collectionType={collectionType} />}
        {showLike && (
          <Box position="absolute" zIndex={10} top={{ xs: 10, sm: 11 }} right={{ xs: 10, sm: 11 }}>
            <LikeButton isLiked={isLiked} id={id || '0'} />
          </Box>
        )}
        <Box
          overflow="hidden"
          width="100%"
          height={{ xs: '166px', sm: cardSize === 'medium' ? '270px' : '280px' }}
          onClick={handleNavigateToNft}
          sx={{
            img: { width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' },
            cursor: id ? 'pointer' : 'unset',
          }}
        >
          {format === 'image' && <img src={isHovered ? animation : image} alt="nft card" />}
          {format === 'video' &&
            (isHovered ? (
              <video controls autoPlay>
                <source src={animation} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                <track kind="captions" />
              </video>
            ) : (
              <img src={image} alt="nft card" />
            ))}
          {format === 'audio' &&
            (isHovered ? (
              <audio controls autoPlay>
                <source src={animation} />
                <track kind="captions" />
              </audio>
            ) : (
              <img src={isHovered ? animation : image} alt="nft card" />
            ))}
        </Box>
      </Box>
      <Stack mt={{ xs: 1.25, sm: 1.5 }} justifyContent="space-between">
        <Box display="grid" gridTemplateColumns={{ xs: '94px auto', sm: '150px auto' }} columnGap={1} rowGap={1.25}>
          <Box height="46px">
            <InfoText>{title}</InfoText>
          </Box>
          <Stack alignItems="flex-end">
            <Label>Style#</Label>
            <Button
              variant="text"
              disabled={styleHash === '-'}
              onClick={handleNavigateToExploreStyleHash}
              sx={{ textTransform: 'none' }}
            >
              <InfoText>{shortenPhrase(styleHash, windowWidth < 700 ? 6 : 10, 0)}</InfoText>
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box width="24px" height="24px">
              <UsdcLogo />
            </Box>
            <InfoText customFontSize={20} customMobileFontSize={16}>
              {price && isSelling !== false ? parseFloat(String(price || 0)).toLocaleString('en-US') : '-'}
            </InfoText>
          </Stack>
          <Stack alignItems="flex-end">
            <Label>Size</Label>
            <Button
              variant="text"
              disabled={styleHash === '-'}
              onClick={handleNavigateToExploreSize}
              sx={{ textTransform: 'none' }}
            >
              <InfoText>US{nftSize}</InfoText>
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
