import { FC } from 'react';
import { Button, styled } from '@mui/material';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';

interface HeaderControlsProps {
  onClick: () => void;
}

const ConnectButton = styled(Button)({
  background: `${COLOR_BLACK} !important`,
  border: '1px solid',
  borderColor: 'transparent',
  color: COLOR_WHITE,
  textTransform: 'none',
  '&:hover': {
    background: `${COLOR_WHITE} !important`,
    color: COLOR_BLACK,
    borderColor: COLOR_BLACK,
  },
});

export const ConnectWalletButton: FC<HeaderControlsProps> = ({ onClick }) => (
  <ConnectButton
    size="large"
    sx={{
      minWidth: { md: '44px', lg: '152px' },
      height: '40px',
      minHeight: '40px',
    }}
    onClick={onClick}
  >
    Connect wallet
  </ConnectButton>
);
