import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ArrowDownWhite, CopyText, Photo, TextArea, Uploader, ValidatedTextField } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector, useWindowState, ValidationTypes } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { createCollection, getCategories } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_GREEN, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

import {
  CollectionProps,
  collectionTypes,
  collectionValidationSchema,
  defaultCollectionProps,
} from './CreateCollection.helpers';

const TextButton = styled(Button)({
  color: COLOR_GRAY_100,
  fontWeight: FontWeights.fontWeightRegular,
  textTransform: 'none',
});

const FinalButton = styled(Button)({
  width: '208px',
  height: '44px',
  fontSize: '16px',
  lineHeight: '14px',
  textTransform: 'none',
});

export const CreateCollection: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const windowState = useWindowState();
  const { address, network, isSingleFabricOwner, isMultipleFabricOwner } = useShallowSelector(userSelector.getUser);
  const categories = useShallowSelector(nftSelector.getProp('categories'));
  const { [actionTypes.CREATE_COLLECTION]: createCollectionRequestStatus } = useShallowSelector(uiSelector.getUI);

  const isCreateCollectionLoading = useMemo(
    () => createCollectionRequestStatus === RequestStatus.REQUEST,
    [createCollectionRequestStatus],
  );

  const isCreateCollectionSuccess = useMemo(
    () => createCollectionRequestStatus === RequestStatus.SUCCESS,
    [createCollectionRequestStatus],
  );

  const handleSubmit = (values: CollectionProps, setSubmitting: (value: boolean) => void) => {
    const collectionFormData = new FormData();
    collectionFormData.append('name', values.name);
    collectionFormData.append('standart', values.isSingle ? 'ERC721' : 'ERC1155');
    collectionFormData.append('avatar', values.media);
    collectionFormData.append('symbol', values.symbol);
    collectionFormData.append('description', values.description);
    collectionFormData.append('royalty_address', values.payoutWalletAddress);
    collectionFormData.append('royalty_percentage', values.fee.toString());
    collectionFormData.append('category', values.category.toString());
    collectionFormData.append('collection_type', values.type === 'Digital only' ? 'Digital' : values.type);
    dispatch(
      createCollection({ network, formData: collectionFormData, web3Provider: walletService.Web3(), setSubmitting }),
    );
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isCreateCollectionSuccess) {
      navigate(-1);
    }
  }, [isCreateCollectionSuccess, navigate]);

  useEffect(() => {
    if (!isSingleFabricOwner && !isMultipleFabricOwner) navigate('/404');
  }, [address, isMultipleFabricOwner, isSingleFabricOwner, navigate]);

  return (
    <Formik
      enableReinitialize
      initialValues={defaultCollectionProps}
      validationSchema={collectionValidationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {({ touched, errors, values, handleChange, handleBlur, handleReset, setFieldValue, isValid }) => (
        <Form>
          <Typography
            mb={7}
            variant={windowState.width > 700 ? 'h1' : 'h3'}
            textTransform="uppercase"
            sx={{ color: COLOR_GREEN }}
          >
            Create Collection
          </Typography>
          <Grid
            ml={{ xs: 0, md: '20px' }}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            container
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            gap={5}
          >
            <Stack spacing={1.5} alignItems="center">
              <Uploader formikValue="media" isImgOnly>
                {!values.preview ? (
                  <Avatar
                    sx={{
                      width: '162px',
                      height: '162px',
                      background: COLOR_BLACK,
                    }}
                  >
                    <Photo sx={{ width: '30px', height: '30px', color: COLOR_WHITE }} />
                  </Avatar>
                ) : (
                  <Box
                    width="162px"
                    height="162px"
                    overflow="hidden"
                    display="flex"
                    justifyContent="center"
                    borderRadius="50%"
                    sx={{ img: { height: '162px' } }}
                  >
                    <img src={values.preview} alt="preview" />
                  </Box>
                )}
              </Uploader>
              <Typography mt={1.5} variant="h4">
                Logo image
              </Typography>
              <Typography textAlign="center" width="277px">
                We recommend an image of at least 400x400. Gift work too 🙌
              </Typography>
            </Stack>
            <Stack spacing={3} maxWidth={{ xs: '100%', sm: '432px' }}>
              <Typography variant="h4">Collection Details</Typography>
              <ToggleButtonGroup
                color="secondary"
                value={values.isSingle}
                exclusive
                onChange={() => setFieldValue('isSingle', !values.isSingle)}
              >
                <ToggleButton value={Boolean('true')}>Single</ToggleButton>
                <ToggleButton value={false}>Multiple</ToggleButton>
              </ToggleButtonGroup>
              <Stack spacing={2}>
                <Box>
                  <Typography mb={1}>Display name</Typography>
                  <Field
                    id="name"
                    name="name"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        name="name"
                        variant="filled"
                        fullWidth
                        autoComplete="off"
                        disabled={isSubmitting}
                        value={values.name}
                        placeholder="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && !!errors.name}
                        helperText={(touched.name && errors.name) || ''}
                      />
                    )}
                  />
                </Box>
                <Accordion defaultExpanded sx={{ background: COLOR_LIGHT_GRAY, borderRadius: '8px' }}>
                  <AccordionSummary
                    expandIcon={<ArrowDownWhite sx={{ color: COLOR_BLACK }} />}
                    aria-controls="types-content"
                    id="types-accordion"
                  >
                    <Typography fontWeight={FontWeights.fontWeightMedium}>Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack pl={5} pb={2} spacing={1} alignItems="flex-start">
                      {collectionTypes.map(
                        (item) =>
                          (item.label !== 'Claimable' || values.isSingle) && (
                            <TextButton
                              variant="text"
                              key={item.id}
                              className={values.type === item.label ? 'active' : ''}
                              onClick={() => setFieldValue('type', item.label)}
                              sx={{ color: values.type === item.label ? COLOR_GREEN : COLOR_GRAY_100 }}
                            >
                              {item.label}
                            </TextButton>
                          ),
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ background: COLOR_LIGHT_GRAY, borderRadius: '8px' }}>
                  <AccordionSummary
                    expandIcon={<ArrowDownWhite sx={{ color: COLOR_GREEN }} />}
                    aria-controls="categories-content"
                    id="categories-accordion"
                  >
                    <Typography fontWeight={FontWeights.fontWeightMedium}>Category</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack pl={5} pb={2} spacing={1} alignItems="flex-start">
                      {categories.map((item) => (
                        <TextButton
                          variant="text"
                          key={item.id}
                          className={values.category === item.id ? 'active' : ''}
                          onClick={() => setFieldValue('category', item.id)}
                          sx={{ color: values.category === item.id ? COLOR_GREEN : COLOR_GRAY_100 }}
                        >
                          {item.name}
                        </TextButton>
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Stack spacing={1}>
                  <Typography>Royalties</Typography>
                  <ValidatedTextField
                    item="fee"
                    validationType={ValidationTypes.int}
                    variant="filled"
                    fullWidth
                    autoComplete="off"
                    name="fee"
                    placeholder="Percentage fee"
                    value={values.fee}
                    onBlur={handleBlur}
                    error={touched.fee && !!errors.fee}
                    helperText={(touched.fee && errors.fee) || ''}
                  />
                  <Field
                    id="payoutWalletAddress"
                    name="payoutWalletAddress"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        fullWidth
                        autoComplete="off"
                        name="payoutWalletAddress"
                        placeholder="Your payout wallet address"
                        disabled={isSubmitting}
                        value={values.payoutWalletAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.payoutWalletAddress && !!errors.payoutWalletAddress}
                        helperText={(touched.payoutWalletAddress && errors.payoutWalletAddress) || ''}
                      />
                    )}
                  />
                </Stack>
                <Box>
                  <Typography mb={1}>Wallet Address</Typography>
                  <CopyText variant="contained" text={address} />
                </Box>
                <Stack spacing={1}>
                  <Typography>Symbol</Typography>
                  <Field
                    id="symbol"
                    name="symbol"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        fullWidth
                        autoComplete="off"
                        name="symbol"
                        placeholder="Input text"
                        disabled={isSubmitting}
                        value={values.symbol}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.symbol && !!errors.symbol}
                        helperText={(touched.symbol && errors.symbol) || ''}
                      />
                    )}
                  />
                </Stack>
                <Field
                  id="description"
                  name="description"
                  render={({ form: { isSubmitting } }: FieldProps) => (
                    <TextArea
                      name="description"
                      disabled={isSubmitting}
                      label="Description"
                      placeholder="Input text"
                      value={values.description}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={(touched.description && errors.description) || ''}
                    />
                  )}
                />
              </Stack>
              <Stack pt="18px" direction="row" spacing={2}>
                <FinalButton disabled={!isValid || isCreateCollectionLoading} type="submit" variant="contained">
                  {isCreateCollectionLoading ? (
                    <CircularProgress size={30} sx={{ color: COLOR_GREEN }} />
                  ) : (
                    'Create collection'
                  )}
                </FinalButton>
                <FinalButton variant="outlined" onClick={handleReset}>
                  Cancel
                </FinalButton>
              </Stack>
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
