import { FC, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useValidateInputField, ValidationTypes } from 'hooks';

export type ValidatedTextFieldProps = {
  item: string;
  validationType: ValidationTypes;
  decimals?: number;
};

export const ValidatedTextField: FC<TextFieldProps & ValidatedTextFieldProps> = ({
  item,
  validationType,
  decimals,
  ...props
}) => {
  const [input, handleChangeInput, setInput] = useValidateInputField({ type: validationType, decimals });
  const { setFieldValue: setValue } = useFormikContext();

  useEffect(() => {
    setValue(item, input);
  }, [input, item, setValue]);

  useEffect(() => {
    setInput(String(props.value));
  }, []); // only when first render

  return (
    <Field
      id={item}
      name={item}
      render={({ form: { isSubmitting } }: FieldProps) => (
        <TextField {...props} disabled={isSubmitting} onChange={handleChangeInput} />
      )}
    />
  );
};
