import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Stack } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { mintNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { TertiaryButton } from 'theme/Button';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_LARGE, COLOR_BLACK, COLOR_LIGHT, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

import { QuantityCounter } from '../QuantityCounter';

export const MintNftCounter: FC = () => {
  const [quantity, setQuantity] = useState('1');
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const {
    id,
    collection: { standart, address: collectionAddress },
  } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { [actionTypes.MINT_NFT]: mintNftRequestStatus } = useShallowSelector(uiSelector.getUI);
  const isMintNftLoading = useMemo(() => mintNftRequestStatus === RequestStatus.REQUEST, [mintNftRequestStatus]);

  const handleMintNft = () => {
    if (id && collectionAddress) {
      dispatch(
        mintNft({
          id,
          amount: Number(quantity),
          isSingle: standart === 'ERC721',
          collectionAddress,
          web3Provider: walletService.Web3(),
        }),
      );
    }
  };

  return (
    <Stack
      direction={{ xs: 'row', sm: 'column', md: 'row' }}
      mt={3}
      sx={{
        background: COLOR_LIGHT,
        border: 'none',
        borderRadius: BORDER_RADIUS_LARGE,
        p: { xs: 2, sm: '16px 40px ', md: 2 },
        width: 'min-content',
      }}
    >
      <QuantityCounter quantity={quantity} setQuantity={setQuantity} maxQuantity={5} label="Mint quantity" />
      <TertiaryButton
        onClick={handleMintNft}
        disabled={isMintNftLoading || Number(quantity) < 1}
        sx={{
          height: '44px',
          mt: { xs: 5, sm: 0, md: 5 },
          ml: { xs: 2, sm: 0, md: 2 },
          width: '140px',
          fontWeight: FontWeights.fontWeightBold,
          color: COLOR_BLACK,
        }}
      >
        {isMintNftLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'mint'}
      </TertiaryButton>
    </Stack>
  );
};
