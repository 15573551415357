import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkState } from 'types';
import { Network } from 'types/api';

const initialState: NetworkState = {} as Network;

export const networkReducer = createSlice({
  name: 'network',
  initialState,
  reducers: {
    updateNetwork: (state, action: PayloadAction<Network>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateNetwork } = networkReducer.actions;

export default networkReducer.reducer;
