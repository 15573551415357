import { FC } from 'react';
import { Facebook } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Link, Stack, styled, Typography } from '@mui/material';
import { Instagram, TwitterOutlined } from 'components';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_150, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { SocialLinksValues } from 'types/store/profile';
import { flexHelper } from 'utils/flexHelper';

const IconWrapper = styled(Box)({
  ...flexHelper(),
  width: 28,
  height: 28,
  background: COLOR_GRAY_150,
  borderRadius: '50%',
  svg: {
    width: '16px',
  },
});
interface SocialLinksBlockProps {
  socialLinks: {
    [key in SocialLinksValues]: string | undefined;
  };
}
export const SocialLinksBlock: FC<SocialLinksBlockProps> = ({ socialLinks }) => {
  const { email, site, twitter, instagram, facebook } = socialLinks;
  return (
    <Stack
      height="fit-content"
      spacing={2}
      px={3}
      py={2.5}
      display={email || site || twitter || instagram || facebook ? 'flex' : 'none'}
      sx={{
        background: COLOR_LIGHT_GRAY,
        borderRadius: BORDER_RADIUS_DEFAULT,
        width: 'fit-content',
        minWidth: '317px',
      }}
    >
      {email && (
        <Link href={`mailto:${email}`} target="_blank" rel="noreferrer" underline="none">
          <Stack direction="row" spacing={1.25}>
            <IconWrapper>
              <EmailOutlinedIcon sx={{ color: COLOR_WHITE }} />
            </IconWrapper>
            <Typography>{email}</Typography>
          </Stack>
        </Link>
      )}
      {site && (
        <Link href={site} target="_blank" rel="noreferrer" underline="none">
          <Stack direction="row" spacing={1.25}>
            <IconWrapper>
              <PublicIcon sx={{ color: COLOR_WHITE }} />
            </IconWrapper>
            <Typography>{site}</Typography>
          </Stack>
        </Link>
      )}
      {twitter && (
        <Link href={`https://twitter.com/${twitter}`} target="_blank" rel="noreferrer" underline="none">
          <Stack direction="row" spacing={1.25}>
            <IconWrapper>
              <TwitterOutlined sx={{ color: COLOR_WHITE }} />
            </IconWrapper>
            <Typography>{twitter}</Typography>
          </Stack>
        </Link>
      )}
      {instagram && (
        <Link href={`https://instagram.com/${instagram}`} target="_blank" rel="noreferrer" underline="none">
          <Stack direction="row" spacing={1.25}>
            <IconWrapper>
              <Instagram sx={{ color: COLOR_WHITE }} />
            </IconWrapper>
            <Typography>{instagram}</Typography>
          </Stack>
        </Link>
      )}
      {facebook && (
        <Link href={`https://facebook.com/${facebook}`} target="_blank" rel="noreferrer" underline="none">
          <Stack direction="row" spacing={1.25}>
            <IconWrapper>
              <Facebook sx={{ color: COLOR_WHITE }} />
            </IconWrapper>
            <Typography>{facebook}</Typography>
          </Stack>
        </Link>
      )}
    </Stack>
  );
};
