import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Link, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { backgroundImage1, backgroundImage2 } from 'assets/img/DeFi';
import clsx from 'clsx';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { Modals, State, UserState } from 'types';

import { roadMapSlides, testimonialSlides } from './DeFi.helper';

export const headingSx = {
  typography: 'h1',
  lineHeight: '60px',
  textAlign: 'center !important',
  fontFamily: FontFamilies.primary,
  fontWeight: FontWeights.fontWeightSemiBold,
};

const FooterButton = styled(Button)(({ theme }) => ({
  width: 520,
  height: 140,
  borderRadius: '16px',
  textTransform: 'capitalize',
  [theme.breakpoints.down('xl')]: {
    width: 300,
    height: 78,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const DeFi: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const { address } = useShallowSelector<State, UserState>(userSelector.getUser);

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Box>
      <Box>
        <Box
          component="img"
          src={backgroundImage1}
          sx={{ display: { md: 'none' }, width: '100%', marginBottom: { xs: 4, md: 0 } }}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: { md: 800, xl: 1014 },
            backgroundImage: { md: `url(${backgroundImage1})` },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 2,
            marginBottom: 8,
          }}
        >
          <Box sx={{ width: 900, margin: 'auto' }}>
            <Typography
              sx={{
                ...headingSx,
                typography: { xs: 'h2', md: 'h1' },
                mb: { xs: 4, md: 6 },
              }}
              className={clsx('lg', isMediumScreen && 'white')}
            >
              Leverage and Reinvest
              <br /> Be the leading Sneaker Head
            </Typography>
            <Typography sx={{ typography: 'body1', textAlign: 'center', color: { xs: COLOR_BLACK, md: COLOR_WHITE } }}>
              Entrust sneakers to meta [Z] for custody and have NFTs issued. {isMediumScreen && <br />}Collateralize
              your NFTs and borrow USDC up to 50% of your sneakers NFTs based on the current market price.
              <br /> Leverage and reinvest in sneakers, or invest in meta[Z] NFTs with the USDC.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          component="img"
          src={backgroundImage2}
          sx={{ display: { md: 'none' }, width: '100%', marginBottom: { xs: 4, md: 0 } }}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: { md: 800 },
            backgroundImage: { md: `url(${backgroundImage2})` },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '16px',
            marginBottom: 16,
          }}
        >
          <Stack sx={{ width: 750, margin: 'auto' }} alignItems="center">
            <Typography
              sx={{
                ...headingSx,
                typography: { xs: 'h2', md: 'h1' },
                mb: { xs: 4, md: 6 },
              }}
              className={clsx('lg', isMediumScreen && 'white')}
            >
              meta[Z] DeFi service is operating as a closed beta.
            </Typography>
            <Typography sx={{ typography: 'body1', textAlign: 'center', color: { xs: COLOR_BLACK, md: COLOR_WHITE } }}>
              Those who have sneakers as an investment vehicle but lack space
              <br /> Those who wish to make additional investments by maximizing leverage using their sneakers
            </Typography>
            <Button
              variant="contained"
              className="gray"
              sx={{ width: { xs: '100%', sm: '356px' }, mt: { xs: 4, md: 10 } }}
            >
              Contact meta[Z]
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ width: { xs: '100%', md: '70%' }, marginX: 'auto' }}>
        <Typography
          sx={{
            ...headingSx,
            typography: { xs: 'h2', md: 'h1' },
            mb: { xs: 6, md: 12 },
          }}
          className="lg"
        >
          How meta[Z] DeFi works
        </Typography>
        <Box
          sx={{
            marginBottom: 8,
            '.splide__arrow': {
              background: COLOR_BLACK,
              display: { xs: 'none', sm: 'flex' },
              svg: { fill: COLOR_WHITE, width: '0.6em', height: '0.6em' },
            },
            '.splide__arrow--prev': { left: isMediumScreen ? '-3em' : 0 },
            '.splide__arrow--next': { right: isMediumScreen ? '-3em' : 0 },
            '.splide__arrow:disabled': { display: 'none' },
          }}
        >
          <Splide
            options={{
              perPage: 1,
              gap: 0,
              pagination: false,
              omitEnd: true,
              focus: 0,
              fixedWidth: isMediumScreen ? 300 : 254,
            }}
          >
            {roadMapSlides.map((slide) => (
              // eslint-disable-next-line react/jsx-key
              <SplideSlide>
                <Typography
                  noWrap
                  sx={{
                    typography: 'h4',
                    pb: 4,
                    mb: 4,
                    ml: 1.25,
                    borderBottom: '1px solid',
                    borderWidth: 2,
                  }}
                >
                  {slide.title}
                  <Box
                    sx={{
                      width: '20px',
                      height: '20px',
                      position: 'absolute',
                      backgroundColor: COLOR_BLACK,
                      borderRadius: '50%',
                      left: 0,
                      top: 52,
                    }}
                  />
                </Typography>
                <Box maxWidth="250px" ml={1.25}>
                  <Typography sx={{ typography: 'body1', mr: 1 }}>{slide.text}</Typography>
                </Box>
              </SplideSlide>
            ))}
          </Splide>
        </Box>

        <Typography
          sx={{
            ...headingSx,
            marginBottom: 4,
            mt: { xs: 7.5, md: 20 },
            textAlign: 'center',
          }}
          className="lg"
        >
          Live Use Cases
        </Typography>
        <Typography sx={{ typography: 'body1', marginBottom: 6, textAlign: 'center' }}>
          The Stories of vaious meta[Z] fans with successful leveraging experiences
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: COLOR_LIGHT_GRAY,
          borderRadius: 4,
          pt: { xs: 3.75, md: 6 },
          pb: { xs: 8, md: 6 },
          marginBottom: { xs: 7.5, md: 16 },
          height: isMediumScreen ? 'unset' : '370px',
          '.splide__arrow': {
            background: COLOR_BLACK,
            width: isMediumScreen ? '2em' : '3.5em',
            height: isMediumScreen ? '2em' : '3.5em',
            svg: { fill: COLOR_WHITE, width: '0.6em', height: '0.6em' },

            '&--prev': { top: isMediumScreen ? 150 : 300, left: isMediumScreen ? '4em' : 110 },
            '&--next': { top: isMediumScreen ? 150 : 300, right: isMediumScreen ? '4em' : 110 },
          },
        }}
      >
        <Splide options={{ type: 'loop', pagination: false }}>
          {testimonialSlides.map((slide) => (
            // eslint-disable-next-line react/jsx-key
            <SplideSlide>
              <Box
                sx={{
                  width: { xs: '100%', md: 800 },
                  height: '100%',
                  marginX: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: { xs: 'flex-start', md: 'center' },
                }}
              >
                <Typography
                  sx={{
                    typography: { xs: 'h2', md: 'h1' },
                    fontSize: { xs: '26px', md: '48px' },
                    textAlign: 'center !important',
                    fontWeight: `${FontWeights.fontWeightSemiBold} !important`,
                    marginX: 1,
                    marginBottom: { xs: 3, md: 6 },
                  }}
                >
                  {slide.text}
                </Typography>
                <Typography sx={{ ...headingSx, fontSize: { xs: 22, md: 36 }, lineHeight: '26px' }}>
                  {slide.author}
                </Typography>
              </Box>
            </SplideSlide>
          ))}
        </Splide>
      </Box>

      <Box sx={{ width: { xs: '100%', md: '70%' }, marginX: 'auto' }}>
        <Typography
          sx={{
            ...headingSx,
            marginBottom: { xs: 4, md: 6 },
            textAlign: 'center',
            fontSize: { xs: '32px', md: '72px' },
          }}
        >
          Hype Your Everything!
        </Typography>
        <Typography
          sx={{
            typography: 'body1',
            fontSize: { xs: '16px', md: '36px' },
            textAlign: 'center',
            marginBottom: { xs: 4, md: 10 },
          }}
        >
          Join meta[Z]
        </Typography>

        <Box
          component={Stack}
          spacing={{ xs: 2, md: 4 }}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <FooterButton
            variant="contained"
            disabled={!!address.length}
            onClick={() => handleOpenModal(Modals.ConnectWallet)}
          >
            Connect wallet
          </FooterButton>
          <Link href="https://blog.metaz.io/" target="_blank" rel="noreferrer" underline="none">
            <FooterButton variant="contained">Keep me up to date</FooterButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
