import { createAction } from '@reduxjs/toolkit';
import {
  ApproveRedeemOrClaimReq,
  CustodyListedReq,
  CustodyUnlistedReq,
  GetAutofillReq,
  GetHistoryReq,
  HelloWorldReq,
  PayForDeliveryReq,
  RequestWithFormData,
  RequestWithId,
  RequestWithReset,
  RequestWithSubmit,
  RequestWithWeb3Provider,
  sendDeliveryInfoReq,
} from 'types/requests';

import actionTypes from './actionTypes';

export const helloWorld = createAction<HelloWorldReq>(actionTypes.HELLO_WORLD);
export const getAutofill = createAction<GetAutofillReq>(actionTypes.GET_AUTOFILL);
export const getHistory = createAction<GetHistoryReq>(actionTypes.GET_HISTORY);
export const redeemNft = createAction<RequestWithFormData & RequestWithSubmit & RequestWithWeb3Provider>(
  actionTypes.REDEEM,
);
export const claimNft = createAction<RequestWithFormData & RequestWithSubmit>(actionTypes.CLAIM);
export const confirmClaim = createAction<RequestWithId & RequestWithWeb3Provider>(actionTypes.CONFIRM_CLAIM);
export const getCustodyLists = createAction(actionTypes.GET_CUSTODY_LISTS);
export const custodyListed = createAction<CustodyListedReq & RequestWithSubmit & RequestWithReset>(
  actionTypes.CUSTODY_LISTED,
);
export const custodyUnlisted = createAction<CustodyUnlistedReq & RequestWithSubmit & RequestWithReset>(
  actionTypes.CUSTODY_UNLISTED,
);
export const sendDeliveryInfo = createAction<
  sendDeliveryInfoReq & RequestWithId & RequestWithSubmit & RequestWithReset
>(actionTypes.SEND_DELIVERY_INFO);
export const approveRedeemOrClaim = createAction<ApproveRedeemOrClaimReq>(actionTypes.APPROVE_REDEEM_OR_CLAIM);
export const payForDelivery = createAction<PayForDeliveryReq & RequestWithId & RequestWithWeb3Provider>(
  actionTypes.PAY_FOR_DELIVERY,
);
