import { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import nftSelector from 'store/nft/selectors';

export const NftDescription: FC = () => {
  const { description } = useShallowSelector(nftSelector.getProp('detailedNft'));

  return (
    <Stack spacing={2.5} mt={3}>
      <Typography variant="h4" pl={2}>
        Description
      </Typography>
      <Divider />
      <Typography pl={2}>{description || 'No description'}</Typography>
    </Stack>
  );
};
