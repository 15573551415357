import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger } from 'utils';

import { mintNft } from '../actions';
import actionTypes from '../actionTypes';

import { approveNftSaga } from './approveNft';

export function* mintNftSaga({
  type,
  payload: { collectionAddress, isSingle, id, amount, web3Provider },
}: ReturnType<typeof mintNft>) {
  yield put(request(type));

  const address: string = yield select(userSelector.getProp('address'));

  try {
    try {
      yield* call(approveNftSaga, {
        type: actionTypes.APPROVE_NFT,
        payload: { isSingle, collectionAddress, web3Provider },
      });
    } catch {
      return;
    }

    const { data } = yield* call(baseApi.mintNft, id, amount);

    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        toast.success('You have successfully minted NFT');
        yield put(success(type));
      } catch (err) {
        logger('Send transaction for mint', err);
        toast.error('Something went wrong');
        yield put(error(type));
      }
    } else {
      yield put(error(type));
      toast.error('No initial tx');
    }
  } catch (e: any) {
    yield call(baseApi.mintReject, { ids: [id], type: 'token' });

    if (typeof e === 'number') {
      toast.error(e === 4001 ? 'You have rejected confirmation' : 'Something went wrong');
    } else {
      toast.error(e.code === 4001 ? 'You have rejected confirmation' : 'Something went wrong');
    }

    logger('Mint token', e);
    yield put(error(type, e));
  }
}
export default function* listener() {
  yield takeLatest(actionTypes.MINT_NFT, mintNftSaga);
}
