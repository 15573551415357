import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Discord: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 28 28"
    sx={{
      ...sx,
      width: 28,
      height: 28,
      fill: 'none',
    }}
  >
    <path
      d="M23.7032 5.26915C21.9186 4.45029 20.0048 3.84699 18.0039 3.50145C17.9674 3.49478 17.931 3.51145 17.9123 3.54478C17.6661 3.98253 17.3935 4.55361 17.2026 5.00248C15.0505 4.68028 12.9094 4.68028 10.8014 5.00248C10.6104 4.54363 10.3279 3.98253 10.0807 3.54478C10.0619 3.51256 10.0255 3.49589 9.98906 3.50145C7.98923 3.84588 6.07549 4.44918 4.28976 5.26915C4.2743 5.27581 4.26105 5.28693 4.25225 5.30137C0.62229 10.7245 -0.372109 16.0142 0.11571 21.2385C0.117917 21.264 0.132265 21.2885 0.152131 21.304C2.54708 23.0628 4.86699 24.1305 7.14383 24.8383C7.18027 24.8494 7.21887 24.8361 7.24206 24.8061C7.78065 24.0706 8.26075 23.295 8.67239 22.4795C8.69668 22.4317 8.67349 22.3751 8.62384 22.3562C7.86232 22.0673 7.1372 21.7151 6.43968 21.3151C6.38451 21.2829 6.3801 21.204 6.43085 21.1662C6.57763 21.0562 6.72446 20.9418 6.86461 20.8262C6.88997 20.8051 6.92531 20.8007 6.95512 20.814C11.5375 22.9062 16.4985 22.9062 21.0268 20.814C21.0566 20.7996 21.0919 20.804 21.1184 20.8251C21.2586 20.9407 21.4054 21.0562 21.5533 21.1662C21.604 21.204 21.6007 21.2829 21.5455 21.3151C20.848 21.7229 20.1229 22.0673 19.3603 22.3551C19.3106 22.374 19.2885 22.4317 19.3128 22.4795C19.7333 23.2939 20.2134 24.0694 20.742 24.8049C20.7641 24.8361 20.8038 24.8494 20.8403 24.8383C23.1282 24.1305 25.4481 23.0628 27.843 21.304C27.864 21.2885 27.8772 21.2651 27.8794 21.2396C28.4633 15.1998 26.9016 9.95339 23.7396 5.30247C23.7319 5.28693 23.7186 5.27581 23.7032 5.26915ZM9.35669 18.0575C7.97708 18.0575 6.84032 16.7909 6.84032 15.2354C6.84032 13.6799 7.95504 12.4133 9.35669 12.4133C10.7694 12.4133 11.8951 13.691 11.873 15.2354C11.873 16.7909 10.7583 18.0575 9.35669 18.0575ZM18.6605 18.0575C17.281 18.0575 16.1442 16.7909 16.1442 15.2354C16.1442 13.6799 17.2589 12.4133 18.6605 12.4133C20.0732 12.4133 21.199 13.691 21.1769 15.2354C21.1769 16.7909 20.0732 18.0575 18.6605 18.0575Z"
      fill="currentColor"
    />
  </SvgIcon>
);
