import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';

export const NotFound: FC = () => {
  const navigate = useNavigate();

  const handleNavigateToHome = () => {
    navigate(routes.home.root.path);
  };
  return (
    <Stack spacing={4.5} alignItems="center">
      <Typography
        variant="h1"
        fontSize={{ xs: '92px', sm: '160px', md: '224px' }}
        lineHeight={{ xs: '92px', sm: '160px', md: '224px' }}
      >
        404
      </Typography>
      <Stack spacing={1} alignItems="center">
        <Typography textTransform="uppercase" sx={{ typography: { xs: 'h3', md: 'h2' } }}>
          Page not found.
        </Typography>
        <Typography textAlign="center" sx={{ maxWidth: '319px' }}>
          Our apologies, this is almost certainly not the page you were looking for.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        size="large"
        onClick={handleNavigateToHome}
        sx={{ width: '176px', whiteSpace: 'noWrap' }}
      >
        Go to Home page
      </Button>
    </Stack>
  );
};
