import { URL } from 'appConstants';
import { CustodyListedReq, CustodyUnlistedReq, sendDeliveryInfoReq } from 'types';
import { snakeize } from 'utils';

import ajax from './ajax';

export default {
  getAutofillClaim() {
    return ajax({
      method: 'get',
      url: URL.getAutofillClaim,
    });
  },
  getAutofillCustody() {
    return ajax({
      method: 'get',
      url: URL.getAutofillCustody,
    });
  },
  getAutofillRedeem() {
    return ajax({
      method: 'get',
      url: URL.getAutofillRedeem,
    });
  },
  getHistoryClaim(data: { page: number; itemsPerPage?: number }) {
    return ajax({
      method: 'get',
      url: URL.getHistoryClaim(data.page, data.itemsPerPage),
    });
  },
  getHistoryCustody(data: { page: number; itemsPerPage?: number }) {
    return ajax({
      method: 'get',
      url: URL.getHistoryCustody(data.page, data.itemsPerPage),
    });
  },
  getHistoryRedeem(data: { page: number; itemsPerPage?: number }) {
    return ajax({
      method: 'get',
      url: URL.getHistoryRedeem(data.page, data.itemsPerPage),
    });
  },
  redeem(data: FormData) {
    return ajax({
      method: 'post',
      url: URL.redeem,
      data,
    });
  },
  redeemReject(data: { tokenId: number | string; amount: number }) {
    return ajax({
      method: 'post',
      url: URL.redeemReject,
      data: snakeize(data),
    });
  },
  claim(data: FormData) {
    return ajax({
      method: 'post',
      url: URL.claim,
      data,
    });
  },
  confirmClaim(id: number) {
    return ajax({
      method: 'get',
      url: URL.confirmClaim(id),
    });
  },
  getCustodyLists() {
    return ajax({
      method: 'get',
      url: URL.getCustodyLists,
    });
  },
  custodyListed({ data }: CustodyListedReq) {
    return ajax({
      method: 'post',
      url: URL.custodyListed,
      data: snakeize(data),
    });
  },
  custodyUnlisted({ data }: CustodyUnlistedReq) {
    return ajax({
      method: 'post',
      url: URL.custodyUnlisted,
      data: snakeize(data),
    });
  },
  sendDeliveryInfo(id: number, { data }: sendDeliveryInfoReq) {
    return ajax({
      method: 'patch',
      url: URL.sendDeliveryInfo(id),
      data: snakeize(data),
    });
  },
  payForDeliveryCustody(orderId: string | number) {
    return ajax({
      method: 'get',
      url: URL.payForDeliveryCustody(orderId),
    });
  },
  payForDeliveryRedeem(orderId: string | number) {
    return ajax({
      method: 'get',
      url: URL.payForDeliveryRedeem(orderId),
    });
  },
  redeemPaymentReject(oderId: string | number) {
    return ajax({
      method: 'post',
      url: URL.redeemPaymentReject,
      data: { id: oderId },
    });
  },
  custodyPaymentReject(oderId: string | number) {
    return ajax({
      method: 'post',
      url: URL.custodyPaymentReject,
      data: { id: oderId },
    });
  },
};
