import { URL } from 'appConstants';

import ajax from './ajax';

export default {
  getProfileInfo(params: { id: string | number }) {
    return ajax({
      method: 'get',
      url: URL.getProfileInfo(params.id),
    });
  },
  follow(data: { id: string | number }) {
    return ajax({
      method: 'post',
      url: URL.follow,
      data,
    });
  },
  unfollow(data: { id: string | number }) {
    return ajax({
      method: 'post',
      url: URL.unfollow,
      data,
    });
  },
};
