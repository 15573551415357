import { toast } from 'react-toastify';
import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import BigNumber from 'bignumber.js/bignumber';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { ethereumWethAbi, polygonWethAbi } from 'services/WalletService/config/abi';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import networkSelector from 'store/network/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains, IChainType } from 'types';
import { logger } from 'utils';
import { AbiItem } from 'web3-utils';

import { bidNft } from '../actions';
import actionTypes from '../actionTypes';

import { approveSaga } from './approve';
import { getNftDataSaga } from './getNftData';

export function* bidSaga({ type, payload: { token_id, amount, currency, web3Provider } }: ReturnType<typeof bidNft>) {
  yield put(request(type));
  const { chainType, network }: { chainType: IChainType; network: Chains } = yield select(userSelector.getUser);
  const { exchangeAddress }: { exchangeAddress: string } = yield select(networkSelector.getNetwork);
  const tokenAddress = contractsConfig.contracts[currency.toUpperCase() as ContractsNames][chainType].address[network];

  try {
    const tokenContract: ContractWeb3 = yield new web3Provider.eth.Contract(
      (network === 'Polygon' ? polygonWethAbi : ethereumWethAbi) as AbiItem[],
      tokenAddress,
    );
    const decimals: string = yield call(tokenContract.methods.decimals().call);

    try {
      yield call(approveSaga, {
        type: actionTypes.APPROVE,
        payload: {
          web3Provider,
          amount: new BigNumber(amount).times(10 ** +decimals).toFixed(0, 1),
          spender: exchangeAddress,
          currencySymbol: currency.toUpperCase() as ContractsNames,
          isBid: true,
        },
      });
    } catch {
      return;
    }

    const { data } = yield call(baseApi.bid, { token_id, amount, currency });

    if (data?.error?.length) {
      toast.error(data.error);
      yield put(error(type, data.error));
      return;
    }

    yield call(getNftDataSaga, {
      type: actionTypes.GET_NFT_DATA,
      payload: {
        id: token_id,
      },
    });

    toast.success('You have successfully made a bid');
    yield put(success(type));
  } catch (err) {
    logger('Bid', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BID, bidSaga);
}
