import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRightGreen: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 6 11"
    sx={{
      width: '6px',
      height: '11px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 1.07121C-0.0976315 1.46174 -0.0976315 2.0949 0.292893 2.48543L3.58579 5.77832L0.292893 9.07121C-0.0976315 9.46174 -0.0976315 10.0949 0.292893 10.4854C0.683417 10.876 1.31658 10.876 1.70711 10.4854L5.70711 6.48543C6.09763 6.0949 6.09763 5.46174 5.70711 5.07121L1.70711 1.07121C1.31658 0.680689 0.683417 0.680689 0.292893 1.07121Z"
      fill="currentColor"
    />
  </SvgIcon>
);
