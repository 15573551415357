import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import nftSelector from 'store/nft/selectors';
import { COLOR_LIGHT_GRAY } from 'theme/variables';

export const NftProperties: FC = () => {
  const navigate = useNavigate();

  const { properties } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const filteredProps = useMemo(
    () => properties?.filter((prop) => (prop.traitType !== 'Collaboration' ? true : prop.traitValue !== 'None')),
    [properties],
  );

  const handleNavigate = (key: string, value: string | number) => {
    if (key === 'Style #') {
      navigate(routes.explore.root.getPath({ property: { 'Style%20%23': [value] } }));
    } else {
      navigate(routes.explore.root.getPath({ property: { [key]: [value] } }));
    }
  };
  return (
    <Stack mt={3}>
      <Typography variant="h4" pl={2}>
        Properties
      </Typography>
      <Divider />
      <Grid container spacing={3} p={3}>
        {filteredProps?.map((prop) => (
          <Grid item xs={12} sm={6} lg={4} key={prop.traitType}>
            <Box
              p={1.5}
              onClick={() => handleNavigate(prop.traitType, prop.traitValue)}
              sx={{ backgroundColor: COLOR_LIGHT_GRAY, borderRadius: 2, cursor: 'pointer' }}
              height="100%"
            >
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h4" textTransform="uppercase" fontSize={{ xs: '14px', xl: '16px' }}>
                  {prop.traitType}
                </Typography>
                <Typography
                  variant="body2"
                  textTransform="uppercase"
                  fontSize={{ xs: '16px', xl: '18px' }}
                  textAlign="center"
                  lineHeight="20px"
                >
                  {prop.traitValue}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
