import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';
import { BlockSkeleton } from 'components';
import { useShallowSelector } from 'hooks';
import { times } from 'lodash';
import { useWalletConnectorContext } from 'services';
import { confirmClaim, getHistory } from 'store/orders/actions';
import ordersActionTypes from 'store/orders/actionTypes';
import { clearHistory } from 'store/orders/reducer';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { RequestStatus } from 'types';

import { OrderBlock } from './components';

export const ClaimsHistory: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const [page, setPage] = useState(1);
  const [confirmingId, setConfirmingId] = useState(0);

  const { claims, totalClaims } = useShallowSelector(ordersSelector.getProp('history'));
  const userNetwork = useShallowSelector(userSelector.getProp('network'));
  const {
    [ordersActionTypes.GET_HISTORY]: getHistoryRequestStatus,
    [ordersActionTypes.CONFIRM_CLAIM]: confirmClaimRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const initialItemsPerPage = 4;
  const totalPages = useMemo(() => Math.ceil(totalClaims / initialItemsPerPage), [totalClaims]);

  const isGetHistoryLoading = useMemo(
    () => getHistoryRequestStatus === RequestStatus.REQUEST,
    [getHistoryRequestStatus],
  );
  const isConfirmLoading = useMemo(
    () => confirmClaimRequestStatus === RequestStatus.REQUEST,
    [confirmClaimRequestStatus],
  );

  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
    dispatch(getHistory({ type: 'claim', page: 1, itemsPerPage: (page + 1) * initialItemsPerPage }));
  };

  const handleConfirm = (id: number, nftNetwork: string) => {
    setConfirmingId(id);
    if (nftNetwork === userNetwork) {
      dispatch(confirmClaim({ id, web3Provider: walletService.Web3() }));
    } else {
      toast.error(`Wrong network, please change network to ${nftNetwork} and try again`);
    }
  };

  useEffect(() => {
    dispatch(getHistory({ type: 'claim', page: 1, itemsPerPage: initialItemsPerPage }));
    return () => {
      dispatch(clearHistory());
    };
  }, [dispatch]);

  return (
    <Stack spacing={2} width="100%">
      {!!claims.length &&
        claims.map((claim) => (
          <OrderBlock
            key={claim.id}
            item={claim}
            type="Claim"
            isStatusActionLoading={isConfirmLoading}
            onStatusAction={handleConfirm}
            actionOrderId={confirmingId}
          />
        ))}
      {!claims.length && !isGetHistoryLoading && (
        <Typography variant="h2" sx={{ pt: 3, pl: 2 }} textAlign="center">
          There is no requests yet
        </Typography>
      )}
      {isGetHistoryLoading && (
        <>
          {times(4, (index) => (
            <BlockSkeleton key={index} height={{ xs: 309, md: 309, lg: 120 }} />
          ))}
        </>
      )}
      {page < totalPages && !isGetHistoryLoading && (
        <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
          Load more
        </Button>
      )}
    </Stack>
  );
};
