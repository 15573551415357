import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { ISearchProps } from 'types';
import { camelize, logger } from 'utils';

import { getPopularNfts } from '../actions';
import actionTypes from '../actionTypes';
import { setPopular } from '../reducer';

export function* getPopularNftsSaga({ type }: ReturnType<typeof getPopularNfts>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.searchNfts, {
      props: {
        type: 'items',
        orderBy: '-likes',
        page: 1,
        itemsPerPage: 12,
      } as ISearchProps,
    });

    yield put(setPopular(camelize(data.results)));

    yield put(success(type));
  } catch (e) {
    yield put(error(type, e));
    logger('getPopularNftsSaga', e);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_POPULAR, getPopularNftsSaga);
}
