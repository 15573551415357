import { FC } from 'react';
import { Avatar, Box, Stack } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { COLOR_LIGHT } from 'theme/variables';
import { ellipsisText } from 'utils';

import { HomeSlider } from '../../../HomeSlider';
import { TopCollectorsProps } from '../../TopCollector.types';
import { RowBoldText } from '../../TopCollectors.helper';

export const TopCollectorsRow: FC<TopCollectorsProps> = ({
  navigateToToken,
  navigateToUser,
  avatar,
  name,
  items,
  tokenAmount,
}) => {
  return (
    <>
      <Stack onClick={navigateToUser} direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
        <Avatar srcSet={avatar} sx={{ width: 56, height: 56 }} />
        <RowBoldText {...ellipsisText()}>{name}</RowBoldText>
      </Stack>
      <Stack justifyContent="center">
        <RowBoldText>{tokenAmount}</RowBoldText>
      </Stack>
      <HomeSlider perPage={1} gap={12} padding={0} isLoop={false} autoWidth>
        {items.map(({ id, img }) => (
          <SplideSlide key={id}>
            <Box
              onClick={() => navigateToToken(id)}
              width={128}
              height={128}
              overflow="hidden"
              borderRadius="12px"
              sx={{ '&:hover': { border: `4px solid ${COLOR_LIGHT}`, cursor: 'pointer' } }}
            >
              <Box
                component="img"
                src={img}
                alt="top collector item"
                sx={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
              />
            </Box>
          </SplideSlide>
        ))}
      </HomeSlider>
    </>
  );
};
