import { Dispatch, FC, SetStateAction } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type NftToggleButtonProps = {
  activeToggle: 'put-on-sale' | 'transfer';
  setActiveToggle: Dispatch<SetStateAction<'put-on-sale' | 'transfer'>>;
};

export const NftToggleButton: FC<NftToggleButtonProps> = ({ activeToggle, setActiveToggle }) => (
  <ToggleButtonGroup
    color="secondary"
    value={activeToggle}
    exclusive
    onChange={() => setActiveToggle((prev) => (prev === 'put-on-sale' ? 'transfer' : 'put-on-sale'))}
    sx={{
      height: '48px',
      button: { margin: '0 !important', borderRadius: '8px !important', height: '100%', textTransform: 'none' },
    }}
  >
    <ToggleButton value="transfer">Transfer</ToggleButton>
    <ToggleButton value="put-on-sale">List for sale</ToggleButton>
  </ToggleButtonGroup>
);
