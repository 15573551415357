import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LikeActive: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      width: '25px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7896 21C12.5246 21 12.2696 20.895 12.0826 20.706L4.3146 12.926C2.2786 10.886 2.2786 7.567 4.3146 5.527C5.2986 4.543 6.6106 4 8.0096 4C9.4086 4 10.7216 4.543 11.7046 5.527L12.7896 6.614L13.8746 5.528C14.8586 4.543 16.1706 4 17.5706 4C18.9686 4 20.2816 4.543 21.2646 5.527C23.3016 7.567 23.3016 10.886 21.2656 12.926L13.4976 20.707C13.3096 20.895 13.0556 21 12.7896 21Z"
      fill="currentColor"
    />
  </SvgIcon>
);
