import { fork } from 'redux-saga/effects';

import follow from './follow';
import getProfileInfo from './getProfileInfo';
import unfollow from './unfollow';

export default function* profileSagas() {
  yield fork(getProfileInfo);
  yield fork(follow);
  yield fork(unfollow);
}
