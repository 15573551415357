import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Stack, TextField } from '@mui/material';
import { Bid } from 'components';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { reset } from 'store/api/actions';
import { bidNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

type PlaceBidProps = {
  currentBalance: number;
};

export const PlaceBid: FC<PlaceBidProps> = ({ currentBalance }) => {
  const [bid, handleChangeBid, handleSetBid] = useValidateInputField({ type: ValidationTypes.number, decimals: 4 });
  const { minimalBid, highestBid, id, currency, network } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { address, network: userNetwork } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.BID]: bidRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const isBidLoading = useMemo(() => bidRequestStatus === RequestStatus.REQUEST, [bidRequestStatus]);
  const isBidSuccess = useMemo(() => bidRequestStatus === RequestStatus.SUCCESS, [bidRequestStatus]);

  const getHelperText = useCallback((): ReactNode => {
    if (+bid <= (highestBid?.amount || 0)) {
      return 'Your bid should be greater then current highest bid';
    }
    if (+bid <= +(minimalBid || 0)) {
      return 'Your bid should be greater then minimal bid';
    }
    if (+bid > currentBalance) {
      return 'Your bid should be equal or lower then your balance';
    }
    return '';
  }, [bid, currentBalance, highestBid?.amount, minimalBid]);

  const handleBid = () => {
    if (network?.name && network.name === userNetwork) {
      if (id && bid && currency.symbol) {
        dispatch(bidNft({ token_id: id, amount: bid, currency: currency.symbol, web3Provider: walletService.Web3() }));
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  useEffect(() => {
    if (isBidSuccess) {
      handleSetBid('');
      dispatch(reset(actionTypes.BID));
    }
  }, [dispatch, handleSetBid, isBidSuccess]);

  return (
    <Stack pb={3} direction="row" spacing={2}>
      <TextField
        variant="filled"
        placeholder="0.00"
        value={bid}
        error={+bid <= +(minimalBid || 0) || +bid <= (highestBid?.amount || 0) || +bid > currentBalance}
        helperText={getHelperText()}
        onChange={handleChangeBid}
        sx={{ height: '44px', width: '182px' }}
      />
      <Button
        variant="contained"
        disabled={
          !address ||
          isBidLoading ||
          +bid <= +(minimalBid || 0) ||
          +bid <= (highestBid?.amount || 0) ||
          +bid > currentBalance
        }
        onClick={handleBid}
        sx={{ width: '144px', height: '44px', textTransform: 'none' }}
      >
        {isBidLoading ? (
          <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
        ) : (
          <>
            Place a bid
            <Bid sx={{ ml: 1 }} />
          </>
        )}
      </Button>
    </Stack>
  );
};
