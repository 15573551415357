import { FC, useRef } from 'react';
import { NavLink, To } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useModal } from 'hooks';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_100, COLOR_GRAY_400 } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

const StyledLink = styled(NavLink)({
  ...flexHelper('flex-start'),
  '&:hover': { svg: { color: COLOR_BLACK }, '& > p': { color: `${COLOR_BLACK} !important` } },
});

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
});

export type NavSelectProps = {
  title: string;
  titleIcon: JSX.Element;
  isActivePath: boolean;
  onCloseParentMenu?: () => void;
  menuItems: {
    linkIcon: JSX.Element;
    isActiveLink: boolean;
    linkTitle: string;
    path: To;
  }[];
  variant?: 'default' | 'nosqueeze'; // default causes menu to squeeze collapsable block on mobiles
};

export const NavSelect: FC<NavSelectProps> = ({
  title,
  titleIcon,
  isActivePath,
  menuItems,
  variant = 'default',
  onCloseParentMenu,
}) => {
  const [isMenuVisible, onOpenMenu, onCloseMenu] = useModal(false);
  const popoverRef = useRef(null);

  return (
    <>
      <Accordion
        disableGutters
        sx={{ display: { xs: variant === 'default' ? 'none' : 'block', sm: 'block' }, marginTop: '32px !important' }}
      >
        <AccordionSummary
          sx={{
            px: 0,
            height: 24,
            '.MuiAccordionSummary-content': { my: 0 },
            '&:hover': {
              'p, svg': { color: COLOR_BLACK },
            },
          }}
          expandIcon={
            <KeyboardArrowDownIcon
              fontSize="medium"
              sx={{
                color: isActivePath ? COLOR_BLACK : COLOR_GRAY_100,
              }}
            />
          }
        >
          {titleIcon}
          <StyledTypography color={isActivePath ? COLOR_BLACK : COLOR_GRAY_100}>{title}</StyledTypography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 2, pl: 6 }}>
          <Stack spacing={1.25}>
            {menuItems.map(({ linkIcon, linkTitle, isActiveLink, path }, index) => (
              <StyledLink key={`${index + 1}`} to={path} onClick={onCloseParentMenu}>
                <>
                  {linkIcon}
                  <StyledTypography color={isActiveLink ? COLOR_BLACK : COLOR_GRAY_100} whiteSpace="nowrap">
                    {linkTitle}
                  </StyledTypography>
                </>
              </StyledLink>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Box display={{ xs: variant === 'default' ? 'block' : 'none', sm: 'none' }}>
        <Box
          {...flexHelper()}
          onClick={onOpenMenu}
          sx={{ '&:hover': { svg: { color: COLOR_BLACK }, '& > p': { color: `${COLOR_BLACK} !important` } } }}
        >
          {titleIcon}
          <StyledTypography ref={popoverRef} color={isActivePath ? COLOR_BLACK : COLOR_GRAY_100}>
            {title}
          </StyledTypography>
          <KeyboardArrowDownIcon
            fontSize="medium"
            sx={{
              ml: 1,
              color: isActivePath ? COLOR_BLACK : COLOR_GRAY_100,
              transform: isMenuVisible ? 'rotate(180deg)' : 'auto',
            }}
          />
        </Box>
        {isMenuVisible && (
          <Menu
            anchorEl={popoverRef.current}
            open={isMenuVisible}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              sx: {
                background: COLOR_GRAY_400,
                borderRadius: BORDER_RADIUS_DEFAULT,
                top: 10,
              },
            }}
            onClose={onCloseMenu}
            disableRestoreFocus
            sx={{
              top: 15,
            }}
          >
            {menuItems.map(({ linkIcon, linkTitle, isActiveLink, path }, index) => (
              <StyledLink key={`${index + 1}`} to={path} onClick={onCloseMenu}>
                <MenuItem
                  sx={{
                    minHeight: 0,
                    '&:hover': { svg: { color: COLOR_BLACK }, '& > p': { color: `${COLOR_BLACK} !important` } },
                  }}
                >
                  {linkIcon}
                  <StyledTypography color={isActiveLink ? COLOR_BLACK : COLOR_GRAY_100} whiteSpace="nowrap">
                    {linkTitle}
                  </StyledTypography>
                </MenuItem>
              </StyledLink>
            ))}
          </Menu>
        )}
      </Box>
    </>
  );
};
