import { FC, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_10, COLOR_WHITE_OPACITY_20 } from 'theme/variables';
import { useDebounce } from 'use-debounce/lib';

type CheckboxAccordionProps = {
  label: string;
  items: string[];
  isSingle: boolean;
  activeItems: Array<string>;
  onChange: (value: Array<string>) => void;
};
export const CheckboxAccordion: FC<CheckboxAccordionProps> = ({ label, items, isSingle, activeItems, onChange }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchInputValue, 1000);
  const isWithSearch = items.length > 12;

  const searchedItems = useMemo(
    () => items.filter((item) => item.toLowerCase().includes(debouncedSearchValue.toLowerCase())),
    [items, debouncedSearchValue],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isSingle) {
      onChange(activeItems[0] === event.target.name ? [] : [event.target.name]);
    } else {
      onChange(
        checked
          ? [...activeItems, event.target.name]
          : activeItems.filter((prevItem) => prevItem !== event.target.name),
      );
    }
  };

  return (
    <Accordion disableGutters key={label} defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        expandIcon={<KeyboardArrowDownIcon fontSize="medium" sx={{ color: COLOR_BLACK }} />}
      >
        <Typography className="medium">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ background: COLOR_WHITE_OPACITY_20, mb: 1 }} />
        {isWithSearch && (
          <TextField
            variant="outlined"
            placeholder={`Search from ${label}`}
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            fullWidth
            sx={{
              '.MuiInputBase-root': {
                mb: 1,
                px: 1,
                height: 30,
              },
            }}
          />
        )}
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '154px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarColor: `${COLOR_GRAY_10} transparent`,
            '&::-webkit-scrollbar': {
              backgroundColor: 'transparent',
              width: 4,
            },
            '&::-webkit-scrollbar-thumb': {
              width: 1,
              borderRadius: BORDER_RADIUS_DEFAULT,
              background: COLOR_GRAY_10,
            },
          }}
        >
          {searchedItems.map((item) => (
            <FormControlLabel
              key={item}
              control={<Checkbox name={item} checked={activeItems.includes(item)} onChange={handleChange} />}
              label={item}
              sx={{
                mb: 1,
                ml: 0.5,
                maxWidth: '43%',
                width: '43%',

                '.MuiTypography-root': {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },
              }}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};
