import { URL } from 'appConstants';
import { ActivitiesReq, RequestWithPagination, UserActivitiesReq, ViewActivityReq } from 'types';

import ajax from './ajax';

export default {
  getNotifications() {
    return ajax({
      method: 'get',
      url: URL.notifications,
    });
  },
  viewNotification({ data }: ViewActivityReq) {
    return ajax({
      method: 'post',
      url: URL.notifications,
      data,
    });
  },
  getActivities(props: ActivitiesReq) {
    return ajax({
      method: 'get',
      url: URL.activities(props),
    });
  },
  getUserActivities(props: UserActivitiesReq) {
    return ajax({
      method: 'get',
      url: URL.userActivities(props),
    });
  },
  getFollowingActivities(props: UserActivitiesReq) {
    return ajax({
      method: 'get',
      url: URL.followingActivities(props),
    });
  },
  getTopCollectors(props: RequestWithPagination) {
    return ajax({
      method: 'get',
      url: URL.topCollectors(props),
    });
  },
};
