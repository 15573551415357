import { Chains, Currencies } from 'types';

export type FilterItemProps = { id: number; label: string; icon?: JSX.Element };
export type FilterPriceRangeProps = FilterItemProps & { label: Currencies | '' };
export type PriceRangeProps = { currency: Currencies | ''; minValue: number | ''; maxValue: number | '' };
export type FilterProps = {
  categories: string;
  collaboration: Array<string>;
  location: Array<string>;
  collectionType: string;
  collection: string;
  redeemStatus: string;
  text: string;
  network: Chains | '';
  priceRange: PriceRangeProps;
  saleType: string;
};
export type CustomFilterProps = Record<string, string[]>;

export const initialFilterState: FilterProps = {
  categories: 'SneakerZ',
  collaboration: [],
  location: [],
  collectionType: '',
  collection: '',
  redeemStatus: '',
  text: '',
  network: '',
  priceRange: { currency: 'USDC', minValue: 0, maxValue: 0 },

  saleType: '',
};

export const initialCustomFilterState: CustomFilterProps = {};
export type ChipData = string | number;

export const saleTypeMock: FilterItemProps[] = [
  { id: 1, label: 'Auction' },
  { id: 2, label: 'On sale' },
];

export type PropertyData = Record<string, { type: string; values: string[] }>;
