import { FC, useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';

import { CheckboxAccordion, RangeAccordion } from './components';
import { CustomFilterProps, FilterProps, PriceRangeProps, PropertyData } from './FilterMenu.helpers';

type FilterMenuProps = {
  filterItems: FilterProps;
  customFilterItems: CustomFilterProps;
  onFilterChange: (key: keyof FilterProps, item: string | number | Array<string | number> | PriceRangeProps) => void;
  onCustomFilterChange: (key: string, item: string | string[]) => void;
  onReset: () => void;
  filterProperties: PropertyData;
};

export const FilterMenu: FC<FilterMenuProps> = ({
  filterItems,
  customFilterItems,
  onFilterChange,
  onCustomFilterChange,
  onReset,
  filterProperties,
}) => {
  const filterSizeByType = (value: string) => {
    switch (customFilterItems[encodeURIComponent('Size Type')][0]) {
      case 'Women':
        return value.includes('W');
      case 'Men':
        return value.includes('M');
      case 'GS':
        return value.includes('Y');
      case 'Toddler/Infant':
        return ['K', 'C'].some((letter) => value.includes(letter));
      default:
        return true;
    }
  };

  const sortSizeValues = (value1: string, value2: string) => +value1.slice(0, -1) - +value2.slice(0, -1);

  const getSizeValues = useCallback(
    (sizeValues: string[]) => {
      if (customFilterItems[encodeURIComponent('Size Type')]?.length) {
        return sizeValues.filter(filterSizeByType).sort(sortSizeValues);
      }
      return sizeValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customFilterItems[encodeURIComponent('Size Type')], filterSizeByType],
  );

  const filteredSizes = useMemo(
    () => getSizeValues(filterProperties?.Size?.values || []),
    [filterProperties?.Size?.values, getSizeValues],
  );
  return (
    <Stack spacing={2} maxWidth={{ xs: '100%', sm: '340px' }} minWidth={{ xs: '100%', sm: '340px' }}>
      <Button
        variant="outlined"
        sx={{ width: '50%', height: '44px', border: '1px solid', whiteSpace: 'nowrap', mb: 2 }}
        onClick={onReset}
      >
        Reset filter
      </Button>
      {!!Object.keys(filterProperties) &&
        Object.keys(filterProperties).map((label) =>
          filterProperties[label].type === 'single' ? (
            // encodeURIComponent - save custom admin properties with chars from using as special symbols in URL
            <CheckboxAccordion
              key={label}
              label={label}
              items={label === 'Size' ? filteredSizes : filterProperties[label].values}
              activeItems={customFilterItems[encodeURIComponent(label)] || []}
              isSingle
              onChange={(value) => onCustomFilterChange(encodeURIComponent(label), value)}
            />
          ) : (
            <CheckboxAccordion
              key={label}
              label={label}
              items={label === 'Size' ? filteredSizes : filterProperties[label].values}
              activeItems={customFilterItems[encodeURIComponent(label)] || []}
              isSingle={false}
              onChange={(value) => onCustomFilterChange(encodeURIComponent(label), value)}
            />
          ),
        )}

      <RangeAccordion
        label="Price range"
        activeItem={filterItems.priceRange}
        onChange={(value) => onFilterChange('priceRange', value)}
      />
    </Stack>
  );
};
