import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Link: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: '19px', height: '18px', fill: 'none', ...sx }} viewBox="0 0 19 18">
    <path
      d="M7.5 3H3.5C2.39543 3 1.5 3.89543 1.5 5V15C1.5 16.1046 2.39543 17 3.5 17H13.5C14.6046 17 15.5 16.1046 15.5 15V11M11.5 1H17.5M17.5 1V7M17.5 1L7.5 11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
