import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileState } from 'types';

const initialState: ProfileState = {
  address: '',
  avatar: '',
  bio: '',
  cover: '',
  createdAt: '',
  customUrl: '',
  displayName: '',
  facebook: '',
  followers: [],
  followersCount: 0,
  follows: [],
  followsCount: 0,
  id: 0,
  instagram: '',
  isVerificated: false,
  site: '',
  twitter: '',
  email: '',
};

export const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<Partial<ProfileState>>) => ({
      ...state,
      ...action.payload,
    }),
    clearProfile: () => ({
      ...initialState,
    }),
  },
});

export const { updateProfile, clearProfile } = profileReducer.actions;
export default profileReducer.reducer;
