import AdidasImage from './assets/adidas.png';
import GolfImage from './assets/golf.png';
import NewBalanceImage from './assets/new_balance.png';
import NikeImage from './assets/nike.png';
import ResaleImage from './assets/resale.png';
import SuperchargeImage from './assets/supercharge.png';
import TicketImage from './assets/ticket.png';
import TicketImage2 from './assets/ticket2.png';
import TicketImage3 from './assets/ticket3.png';
import TiffanyImage from './assets/tiffany.png';
import Yeezy1Image from './assets/yeezy1.png';

export type PreviewData = {
  key: string | number;
  title1?: string;
  maxTitleWidth?: number;
  title2?: string;
  subtitle?: string;
  bgImage: string;
  linkValue?: string;
  linkType?: 'explore' | 'nft' | 'style' | 'external';
  withExploreButton: boolean;
};

export const previewDataMock: PreviewData[] = [
  {
    key: 11,
    bgImage: TicketImage3,
    linkValue: '2666',
    linkType: 'nft',
    withExploreButton: false,
  },
  {
    key: 10,
    bgImage: TicketImage2,
    linkValue: '2664',
    linkType: 'nft',
    withExploreButton: false,
  },
  {
    key: 9,
    bgImage: TicketImage,
    linkValue: '2647',
    linkType: 'nft',
    withExploreButton: false,
  },
  {
    key: 8,
    bgImage: SuperchargeImage,
    linkValue: 'https://blog.metaz.io/how-meta-z-can-supercharge-your-sneaker-collection/',
    linkType: 'external',
    withExploreButton: true,
  },
  {
    key: 7,
    title1: 'New balance',
    title2: 'New nft design just dropped',
    bgImage: NewBalanceImage,
    linkValue: 'New Balance',
    linkType: 'style',
    withExploreButton: true,
  },
  {
    key: 6,
    title1: 'Adidas',
    title2: 'New nft design just dropped',
    bgImage: AdidasImage,
    linkValue: 'adidas',
    linkType: 'style',
    withExploreButton: true,
  },
  {
    key: 5,
    title1: 'JORDAN Golf',
    title2: 'New nft design just dropped',
    bgImage: GolfImage,
    linkValue: 'Golf',
    linkType: 'style',
    withExploreButton: true,
  },
  {
    key: 4,
    title1: 'nike style',
    title2: 'New nft design just dropped',
    bgImage: NikeImage,
    linkValue: 'Nike',
    linkType: 'style',
    withExploreButton: true,
  },
  {
    key: 3,
    title1: 'yeezy style',
    title2: 'New nft design just dropped',
    bgImage: Yeezy1Image,
    linkValue: 'Yeezy',
    linkType: 'style',
    withExploreButton: true,
  },
  {
    key: 2,
    title1: 'Missed the draw event? It’s here for you. ',
    maxTitleWidth: 1100,
    title2: 'Nike Air Force 1 Low Tiffany & Co. 1837',
    bgImage: TiffanyImage,
    linkValue: '867',
    linkType: 'nft',
    withExploreButton: true,
  },
  {
    key: 1,
    bgImage: ResaleImage,
    linkValue: 'explore',
    linkType: 'explore',
    withExploreButton: false,
  },
];
