import { FC } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { CheckCircleOutline } from 'components';
import { theme } from 'theme';
import {
  BORDER,
  BORDER_BUTTON_GREEN,
  BORDER_RADIUS_DEFAULT,
  COLOR_GRAY_200,
  COLOR_GRAY_400_OPACITY,
  COLOR_GREEN,
} from 'theme/variables';

import { AuthenticateImage, BitcoinImage, CustodyImage, RedeemImage } from './assets';
import { redeemStepsMock } from './HowItWorks.helpers';

const StyledStack = styled(Stack)(() => ({
  backgroundColor: COLOR_GRAY_400_OPACITY,
  border: BORDER,
  borderColor: COLOR_GRAY_200,
  borderRadius: BORDER_RADIUS_DEFAULT,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6, 7),
  },
  [theme.breakpoints.only('md')]: {
    padding: theme.spacing(6, 4),
  },
  [theme.breakpoints.only('sm')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.only('xs')]: {
    padding: theme.spacing(2.25),
  },
}));

export const HowItWorks: FC = () => {
  return (
    <Stack spacing={{ xs: 6, md: 11.5 }}>
      <Typography
        textTransform="uppercase"
        sx={{
          typography: { xs: 'h2', sm: 'h1' },
          color: `${COLOR_GREEN} !important`,
          fontSize: { xs: '24 px', md: '48px' },
        }}
      >
        HOW IT WORKS?
      </Typography>
      <StyledStack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, md: 3, lg: 3 }}
        position="relative"
      >
        <Typography
          textTransform="uppercase"
          variant="h2"
          sx={{ color: COLOR_GREEN, display: { xs: 'unset', sm: 'none' }, width: '100%' }}
        >
          Buy / sell
        </Typography>
        <Box
          sx={{
            position: { xs: 'static', sm: 'absolute' },
            top: { sm: 20, md: -90 },
            left: { sm: 20, md: 40, lg: 93 },
            img: { width: { xs: '264px', md: '393px' } },
          }}
        >
          <BitcoinImage />
        </Box>
        <Stack spacing={3} maxWidth={{ xs: '400px', sm: '50%', md: '400px', lg: '556px' }}>
          <Typography
            textTransform="uppercase"
            variant="h1"
            sx={{ color: COLOR_GREEN, display: { xs: 'none', sm: 'unset' } }}
          >
            Buy / sell
          </Typography>
          <Typography>
            Buy and sell physical valuables and art pieces without a risk of damaging them. We got rid of bothersome
            process of trading physical goods when trading valuable physical assets by allowing customers to trade
            physical products in a form of NFTs. You are able to buy or sell valuable products with meta[Z] NFTs on
            metaz.io without actually transporting physical valuables.
          </Typography>
          <Typography>
            Owners sell their valuables while those are securely stored in our safe warehouse, buyers become an owner of
            valuables instantly and have the valuable safely stored in our safe warehouse.
          </Typography>
        </Stack>
      </StyledStack>
      <StyledStack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="flex-start"
        spacing={{ xs: 1, md: 3, lg: 3 }}
        position="relative"
      >
        <Typography
          textTransform="uppercase"
          variant="h2"
          sx={{ color: COLOR_GREEN, display: { xs: 'unset', sm: 'none' }, width: '100%' }}
        >
          Custody
        </Typography>
        <Box
          sx={{
            position: { xs: 'static', sm: 'absolute' },
            top: { sm: 50, md: 20 },
            right: { sm: 30, md: 40, lg: 93 },
            img: { width: { xs: '264px', md: '393px' } },
          }}
        >
          <CustodyImage />
        </Box>
        <Stack spacing={3} maxWidth={{ xs: '400px', sm: '50%', md: '400px', lg: '556px' }}>
          <Typography
            textTransform="uppercase"
            variant="h1"
            sx={{ color: COLOR_GREEN, display: { xs: 'none', sm: 'unset' } }}
          >
            Custody
          </Typography>
          <Typography>
            There are countless sneakers resell platforms all around world, insisting that they can help customers trade
            sneakers just like trading stocks. However, the fact is that their commission rates range up to 30% and it
            normally takes 5 days or more.
          </Typography>
          <Typography>
            We offer custody service of collectible sneakers. Customers will receive an NFT for the pair sent to us ,
            which they want to be in the custody of Meta[Z]. Customers(you) holding NFTs can conveniently trade sneakers
            in a fleeting moment with minimum fees. Every NFT on SneakerZ collection is tied physical sneakers, which
            means we safely store all sneakers in our secure warehouse.
          </Typography>
        </Stack>
      </StyledStack>
      <StyledStack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, md: 3, lg: 3 }}
        position="relative"
      >
        <Typography
          textTransform="uppercase"
          variant="h2"
          sx={{ color: COLOR_GREEN, display: { xs: 'unset', sm: 'none' }, width: '100%' }}
        >
          Redeem
        </Typography>
        <Box
          sx={{
            position: { xs: 'static', sm: 'absolute' },
            top: { sm: 100, md: -20, lg: -80 },
            left: { sm: 0, md: -30, lg: -30 },
            img: { width: { xs: '360px', sm: '55%', md: '580px', lg: '670px' } },
          }}
        >
          <RedeemImage />
        </Box>
        <Stack spacing={3} maxWidth={{ xs: '400px', sm: '50%', md: '450px', lg: '610px' }}>
          <Typography
            textTransform="uppercase"
            variant="h1"
            sx={{ color: COLOR_GREEN, display: { xs: 'none', sm: 'unset' } }}
          >
            Redeem
          </Typography>
          <Typography>
            Any NFTs marked as redeemable NFT can be redeemed. Meta[Z] NFT holders can ask us to redeem the NFT any time
            after a noted date to receive physical goods. The process will be as follows.
          </Typography>
          <Stack spacing={{ xs: 1, md: 2 }} direction={{ xs: 'column', md: 'row' }}>
            {redeemStepsMock.map(({ id, label }) => (
              <Stack
                key={id}
                p={1.5}
                spacing={2}
                border={BORDER_BUTTON_GREEN}
                borderRadius={BORDER_RADIUS_DEFAULT}
                width="100%"
                alignItems="center"
              >
                <CheckCircleOutline />
                <Typography textAlign="center" fontSize="12px" lineHeight="16px">
                  {label}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </StyledStack>
      <StyledStack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="flex-start"
        spacing={{ xs: 1, md: 3, lg: 3 }}
        position="relative"
        minHeight="416px"
      >
        <Typography
          textTransform="uppercase"
          variant="h2"
          sx={{ color: COLOR_GREEN, display: { xs: 'unset', sm: 'none' }, width: '100%' }}
        >
          Authenticate
        </Typography>
        <Box
          sx={{
            position: { xs: 'static', sm: 'absolute' },
            top: { sm: 95, md: -20, lg: -40 },
            right: { sm: 20, md: 20, lg: 73 },
            img: { width: { xs: '314px', md: '490px', lg: '516px' } },
            [theme.breakpoints.between('sm', 780)]: {
              position: 'unset',
              marginTop: '71px !important',
              img: { width: '100%' },
              order: 1,
            },
          }}
        >
          <AuthenticateImage />
        </Box>
        <Stack
          spacing={3}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          sx={{
            [theme.breakpoints.between('sm', 780)]: {
              maxWidth: 360,
            },
          }}
        >
          <Typography
            textTransform="uppercase"
            variant="h1"
            sx={{ color: COLOR_GREEN, display: { xs: 'none', sm: 'unset' } }}
          >
            Authenticate
          </Typography>
          <Typography maxWidth={{ xs: '400px', sm: '300px', md: '400px', lg: '574px' }}>
            All physical goods those are underlying redeemable NFTs are authentic. We verify all physical goods before
            minting redeemable NFTs and authenticities are guaranteed. If any physical goods turned to be not-authentic,
            we reimburse 3-times of its value.
          </Typography>
        </Stack>
      </StyledStack>
      <Stack alignItems="center" mt={{ xs: 8, md: 11 }}>
        <Button
          variant="outlined"
          size="large"
          onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
          sx={{ width: '210px', whiteSpace: 'nowrap' }}
        >
          Back in the top ↑
        </Button>
      </Stack>
    </Stack>
  );
};
