import { FC } from 'react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { Avatar, Stack, styled, Typography } from '@mui/material';
import { useActivity } from 'modules/activity/hooks';
import moment from 'moment';
import { BORDER, BORDER_GREEN, BORDER_RADIUS_DEFAULT, COLOR_GRAY_100, COLOR_GREEN } from 'theme/variables';
import { Activity } from 'types/api';
import { shortenPhrase } from 'utils';

const ActivityWrapper = styled(Stack)({
  border: BORDER,
  borderColor: 'transparent',
  cursor: 'pointer',
  ' .arrowIcon': {
    display: 'none',
  },
  '&:hover': {
    border: BORDER_GREEN,
    borderRadius: BORDER_RADIUS_DEFAULT,
    ' .arrowIcon': {
      display: 'unset',
    },
    ' .notificationIcon': {
      display: 'none',
    },
  },
});
export interface ActivityBlockProps {
  item: Activity;
  activityType: string;
}
export const ActivityBlock: FC<ActivityBlockProps> = ({ item, activityType }) => {
  const { handleActivityClick } = useActivity();
  const {
    id,
    fromId,
    fromAddress,
    fromName,
    fromImage,
    toId,
    toAddress,
    toName,
    toImage,
    method,
    date,
    tokenImage,
    tokenId,
  } = item;
  return (
    <ActivityWrapper
      key={id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2.125}
      maxWidth="654px"
      onClick={() => handleActivityClick(tokenId, activityType === 'My Activity' ? fromId : toId)}
    >
      <Stack direction="row" spacing={3} width="100%">
        <Avatar
          sx={{ width: '64px', height: '64px' }}
          src={tokenImage || (activityType === 'My Activity' ? fromImage : toImage)}
        />
        <Stack width="100%" overflow="hidden">
          <Typography textTransform="capitalize">{method === 'Auctionwin' ? 'Auction win' : method}</Typography>
          <Stack>
            {method !== 'Auctionwin' && (
              <Typography sx={{ color: COLOR_GRAY_100, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {method !== 'Buy' && fromName && 'by '}
                {`${fromName !== fromAddress ? fromName || '' : shortenPhrase(fromName || '')} `}
                {activityType !== 'My Activity' && toName && fromName && (method !== 'Buy' ? 'to ' : 'from ')}
                {activityType !== 'My Activity' &&
                  toName &&
                  (toName !== toAddress ? toName : shortenPhrase(toName || ''))}
              </Typography>
            )}
            {method === 'Auctionwin' && (
              <Typography sx={{ color: COLOR_GRAY_100, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                You won the auction!
              </Typography>
            )}
          </Stack>
          <Typography sx={{ color: COLOR_GREEN }} variant="subtitle1" lineHeight="24px">
            {moment(date).fromNow()}
          </Typography>
        </Stack>
      </Stack>
      <NavigateNextRoundedIcon className="arrowIcon" sx={{ fill: COLOR_GREEN, fontSize: '20px' }} />
    </ActivityWrapper>
  );
};
