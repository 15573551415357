import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import { MultipleCollectible, SingleCollectible, Warning } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import { getNetwork } from 'store/network/actions';
import userSelector from 'store/user/selectors';
import { TertiaryButton } from 'theme/Button';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_GRAY_150, COLOR_GRAY_200, COLOR_GREEN, COLOR_WHITE } from 'theme/variables';

const GreenText = styled(Box)({
  color: COLOR_GREEN,
});

const CollectibleButton = styled(TertiaryButton)(({ theme }) => ({
  width: '432px',
  height: '220px',
  borderRadius: '8px',
  '&:hover': {
    background: COLOR_GRAY_150,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const CollectibleButtonContent = styled(Grid)({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
});

const IconBackground = styled(Box)({
  display: 'flex',
  width: '54px',
  height: '54px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: COLOR_GRAY_200,
});

export const Create: FC = () => {
  const windowState = useWindowState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const network = useShallowSelector(userSelector.getProp('network'));

  useEffect(() => {
    dispatch(getNetwork({ network }));
  });

  return (
    <Box mt="36px">
      <Typography variant={windowState.width > 700 ? 'h1' : 'h2'} textTransform="uppercase" sx={{ color: COLOR_GREEN }}>
        create collectible
      </Typography>
      <Typography mt={1} variant="h4" fontWeight={FontWeights.fontWeightRegular}>
        Choose <GreenText component="span">“Single”</GreenText> if you want your collectible to be one of a kind
        or&nbsp;
        <GreenText component="span">“Multiple”</GreenText> if you want to sell one collectible multiple times.
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} mt={7} justifyContent="center" spacing={2}>
        <CollectibleButton onClick={() => navigate(routes.create.singleCollectible.root.path)}>
          <CollectibleButtonContent container>
            <IconBackground>
              <SingleCollectible sx={{ margin: 'auto', color: COLOR_WHITE }} />
            </IconBackground>
            <Typography variant="h4" textTransform="none" fontWeight={FontWeights.fontWeightRegular}>
              Create Single
            </Typography>
          </CollectibleButtonContent>
        </CollectibleButton>
        <CollectibleButton onClick={() => navigate(routes.create.multipleCollectible.root.path)}>
          <CollectibleButtonContent container>
            <IconBackground>
              <MultipleCollectible sx={{ color: COLOR_WHITE }} />
            </IconBackground>
            <Typography variant="h4" textTransform="none" fontWeight={FontWeights.fontWeightRegular}>
              Create Multiple
            </Typography>
          </CollectibleButtonContent>
        </CollectibleButton>
      </Stack>
      <Stack
        mt="88px"
        direction="row"
        justifyContent="center"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        spacing={3}
      >
        <Warning sx={{ mt: { xs: '8px', sm: 0 }, color: COLOR_BLACK }} />
        <Typography variant="h4" fontWeight={FontWeights.fontWeightRegular} sx={{ color: COLOR_GRAY_100 }}>
          We do not own your private keys and cannot access your funds without your confirmation.
        </Typography>
      </Stack>
    </Box>
  );
};
