import activity from './activity/reducer';
import modals from './modals/reducer';
import network from './network/reducer';
import nft from './nft/reducer';
import orders from './orders/reducer';
import profile from './profile/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  ui,
  user,
  profile,
  modals,
  nft,
  network,
  activity,
  orders,
};
