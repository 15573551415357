import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_BLACK } from '../variables';

export const getMuiTabs = (theme: Theme): ComponentsOverrides['MuiTabs'] => ({
  root: {
    '& .MuiTabs-indicator': {
      height: '4px',
      background: COLOR_BLACK,
      borderRadius: '4px 4px 0 0',
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
});

export const getMuiTabsDefaultProps = (): ComponentsProps['MuiTabs'] => ({});
