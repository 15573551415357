import * as Yup from 'yup';

export type ListSneakersFormProps = {
  listId: number;
  customListId: string;
  sizeType: string;
  size: string;
  location: string;
  email: string;
  name: string;
};

export const initialValues: ListSneakersFormProps = {
  listId: -1,
  customListId: '',
  sizeType: '',
  size: '',
  email: '',
  location: '',
  name: '',
};

export const setInitialValues: (obj: Partial<ListSneakersFormProps>) => ListSneakersFormProps = (
  customInitialValues,
) => {
  return {
    ...initialValues,
    ...customInitialValues,
  };
};

export const validationSchema = Yup.object().shape({
  listId: Yup.number().required('Required'),
  customListId: Yup.mixed().when('listId', {
    is: (listId: number) => listId > 0,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().min(2, 'Too short').max(1000, 'Maximum 1000 characters').required('Required'),
  }),
  name: Yup.mixed().when('listId', {
    is: (listId: number) => listId > 0,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().min(2, 'Too short').max(100, 'Maximum 1000 characters').required('Required'),
  }),
  sizeType: Yup.string().required('Required'),
  size: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      'Enter correct email!',
    )
    .max(30, 'Maximum 30 characters')
    .required('Required'),
});

export enum SizeTypes {
  Men = 'Men',
  Women = 'Women',
  GS = 'GS',
  ToddlerInfant = 'Toddler/Infant',
}

export const sizesMock: Record<SizeTypes, string[]> = {
  [SizeTypes.Men]: [
    '3.0M',
    '3.5M',
    '4.0M',
    '4.5M',
    '5.0M',
    '5.5M',
    '6.0M',
    '6.5M',
    '7.0M',
    '7.5M',
    '8.0M',
    '8.5M',
    '9.0M',
    '9.5M',
    '10.0M',
    '10.5M',
    '11.0M',
    '11.5M',
    '12.0M',
    '12.5M',
    '13.0M',
    '14.0M',
    '15.0M',
    '16.0M',
  ],
  [SizeTypes.Women]: [
    '5.0W',
    '5.5W',
    '6.0W',
    '6.5W',
    '7.0W',
    '7.5W',
    '8.0W',
    '8.5W',
    '9.0W',
    '9.5W',
    '10.0W',
    '10.5W',
    '11.0W',
    '11.5W',
    '12.0W',
    '12.5W',
  ],
  [SizeTypes.GS]: ['3.5Y', '4.0Y', '4.5Y', '5.0Y', '5.5Y', '6.0Y', '6.5Y', '7.0Y'],
  [SizeTypes.ToddlerInfant]: [
    '3K',
    '4K',
    '5K',
    '6K',
    '7K',
    '1C',
    '2C',
    '3C',
    '4C',
    '5C',
    '6C',
    '7C',
    '8C',
    '9C',
    '10C',
  ],
};
