import type {} from '@mui/lab/themeAugmentation';
import { createTheme, ThemeOptions } from '@mui/material';
import { getMuiButtonDefaultProps, getMuiButtonOverrides } from 'theme/Button';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from 'theme/Container';
import { breakpointOptions, getMuiGridDefaultProps, getMuiGridOverrides } from 'theme/Grid';
import { getTypographyOptions } from 'theme/Typography';
import { COLOR_TEXT_BLACK } from 'theme/variables';

import { getMuiLoadingButtonDefaultProps, getMuiLoadingButtonOverrides } from './Button/LoadingButton.theme';
import { getMuiSlider } from './Slider/Slider.theme';
import {
  getMuiAccordion,
  getMuiAccordionDetails,
  getMuiAccordionPropsDefaultProps,
  getMuiAccordionSummary,
} from './Accordion';
import { getMuiBackdropDefaultProps, getMuiBackdropOverrides } from './Backdrop';
import { getMuiBreadcrumbsDefaultProps, getMuiBreadcrumbsOverrides } from './Breadcrumbs';
import { getMuiCheckboxDefaultProps, getMuiCheckboxOverrides } from './Checkbox';
import { getMuiChip, getMuiChipDefaultProps } from './Chip';
import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './CssBaseLine';
import { getMuiDialogDefaultProps, getMuiDialogOverrides } from './Dialog';
import { getMuiIconButtonDefaultProps, getMuiIconButtonOverrides } from './IconButton';
import { getMuiMenu, getMuiMenuDefaultProps, getMuiMenuItem } from './Menu';
import { getMuiPaginationDefaultProps, getMuiPaginationOverrides } from './Pagination';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './Popover';
import { getMuiRadioDefaultProps, getMuiRadioOverrides } from './Radio';
import { getMuiSelectDefaultProps, getMuiSelectOverrides } from './Select';
import { getMuiSkeletonDefaultProps, getMuiSkeletonOverrides } from './Skeleton';
import { getMuiTab, getMuiTabDefaultProps } from './Tab';
import { getMuiTableCell, getMuiTableContainer, getMuiTableRow } from './Table';
import { getMuiTabs, getMuiTabsDefaultProps } from './Tabs';
import { getMuiInputBaseOverrides, getMuiTextFieldDefaultProps, getMuiTextFieldOverrides } from './TextField';
import {
  getMuiToggleButtonDefaultProps,
  getMuiToggleButtonGroupOverrides,
  getMuiToggleButtonOverrides,
} from './ToggleButton';

const themeBase = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_TEXT_BLACK,
    },
  },
  typography: getTypographyOptions(),
  breakpoints: breakpointOptions,
});

export const theme = createTheme(themeBase, {
  components: {
    MuiGrid: {
      defaultProps: getMuiGridDefaultProps(),
      styleOverrides: getMuiGridOverrides(),
    },
    MuiContainer: {
      defaultProps: getMuiContainerDefaultProps(),
      styleOverrides: getMuiContainerOverrides(),
    },
    MuiBackdrop: {
      defaultProps: getMuiBackdropDefaultProps(),
      styleOverrides: getMuiBackdropOverrides(themeBase),
    },
    MuiButton: {
      defaultProps: getMuiButtonDefaultProps(),
      styleOverrides: getMuiButtonOverrides(themeBase),
    },
    MuiIconButton: {
      defaultProps: getMuiIconButtonDefaultProps(),
      styleOverrides: getMuiIconButtonOverrides(),
    },
    MuiToggleButton: {
      defaultProps: getMuiToggleButtonDefaultProps(),
      styleOverrides: getMuiToggleButtonOverrides(themeBase),
    },
    MuiToggleButtonGroup: {
      styleOverrides: getMuiToggleButtonGroupOverrides(themeBase),
    },
    MuiLoadingButton: {
      defaultProps: getMuiLoadingButtonDefaultProps(),
      styleOverrides: getMuiLoadingButtonOverrides(),
    },
    MuiDialog: {
      defaultProps: getMuiDialogDefaultProps(),
      styleOverrides: getMuiDialogOverrides(),
    },
    MuiCssBaseline: {
      defaultProps: getMuiCssBaselineDefaultProps(),
      styleOverrides: getMuiCssBaselineOverrides(themeBase),
    },
    MuiPopover: {
      defaultProps: getMuiPopoverDefaultProps(),
      styleOverrides: getMuiPopoverOverrides(themeBase),
    },
    MuiBreadcrumbs: {
      defaultProps: getMuiBreadcrumbsDefaultProps(),
      styleOverrides: getMuiBreadcrumbsOverrides(),
    },
    MuiSelect: {
      defaultProps: getMuiSelectDefaultProps(),
      styleOverrides: getMuiSelectOverrides(themeBase),
    },
    MuiSlider: {
      styleOverrides: getMuiSlider(themeBase),
    },
    MuiTableContainer: {
      styleOverrides: getMuiTableContainer(themeBase),
    },
    MuiTableCell: {
      styleOverrides: getMuiTableCell(themeBase),
    },
    MuiTableRow: {
      styleOverrides: getMuiTableRow(themeBase),
    },
    MuiChip: {
      defaultProps: getMuiChipDefaultProps(),
      styleOverrides: getMuiChip(themeBase),
    },
    MuiMenu: {
      defaultProps: getMuiMenuDefaultProps(),
      styleOverrides: getMuiMenu(themeBase),
    },
    MuiMenuItem: {
      styleOverrides: getMuiMenuItem(),
    },
    MuiInputBase: {
      styleOverrides: getMuiInputBaseOverrides(),
    },
    MuiTextField: {
      defaultProps: getMuiTextFieldDefaultProps(),
      styleOverrides: getMuiTextFieldOverrides(themeBase),
    },
    MuiCheckbox: {
      defaultProps: getMuiCheckboxDefaultProps(),
      styleOverrides: getMuiCheckboxOverrides(themeBase),
    },
    MuiAccordion: {
      defaultProps: getMuiAccordionPropsDefaultProps(),
      styleOverrides: getMuiAccordion(themeBase),
    },
    MuiAccordionDetails: {
      styleOverrides: getMuiAccordionDetails(themeBase),
    },
    MuiAccordionSummary: {
      styleOverrides: getMuiAccordionSummary(themeBase),
    },
    MuiTab: {
      defaultProps: getMuiTabDefaultProps(),
      styleOverrides: getMuiTab(themeBase),
    },
    MuiTabs: {
      defaultProps: getMuiTabsDefaultProps(),
      styleOverrides: getMuiTabs(themeBase),
    },
    MuiRadio: {
      defaultProps: getMuiRadioDefaultProps(),
      styleOverrides: getMuiRadioOverrides(themeBase),
    },
    MuiSkeleton: {
      defaultProps: getMuiSkeletonDefaultProps(),
      styleOverrides: getMuiSkeletonOverrides(themeBase),
    },
    MuiPagination: {
      defaultProps: getMuiPaginationDefaultProps(),
      styleOverrides: getMuiPaginationOverrides(themeBase),
    },
  },
} as ThemeOptions);
