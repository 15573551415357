import { ChangeEvent, FC } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import {
  BORDER_GRAY,
  BORDER_RADIUS_DEFAULT,
  COLOR_GRAY_400,
  COLOR_GRAY_700,
  COLOR_GREEN,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
} from 'theme/variables';

type FilterBlockProps = {
  items: string[];
  activeItems: string[];
  onChange: (items: string[]) => void;
};
export const FilterBlock: FC<FilterBlockProps> = ({ items, activeItems, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(
      checked ? [...activeItems, event.target.name] : activeItems.filter((prevItem) => prevItem !== event.target.name),
    );
  };

  return (
    <Box
      sx={{
        background: COLOR_LIGHT_GRAY,
        borderRadius: BORDER_RADIUS_DEFAULT,
        p: { xs: 3, sm: 1.5, md: 3 },
        height: 'fit-content',
      }}
    >
      <FormGroup>
        {items.map((item) => (
          <FormControlLabel
            key={item}
            control={<Checkbox name={item} onChange={handleChange} checked={activeItems.includes(item)} />}
            label={item}
            sx={{ mb: 2, ml: 0.5, width: '50%', textTransform: 'capitalize' }}
          />
        ))}
      </FormGroup>
      <Stack direction="row" spacing={2} pt={3.5}>
        <Button
          variant="contained"
          disabled={JSON.stringify(items) === JSON.stringify(activeItems)}
          onClick={() => onChange(items)}
          sx={{
            minWidth: '120x',
            width: '136px',
            height: '44px',
            whiteSpace: 'nowrap',
            '&:active': {
              background: COLOR_GRAY_700,
              color: COLOR_GREEN,
            },
            '&:disabled': {
              border: BORDER_GRAY,
            },
          }}
        >
          Select all
        </Button>
        <Button
          variant="outlined"
          onClick={() => onChange([])}
          disabled={activeItems.length === 0}
          sx={{
            minWidth: '120px',
            width: '136px',
            height: '44px',
            whiteSpace: 'nowrap',
            backgroundColor: COLOR_GRAY_700,
            color: COLOR_WHITE,
            '&:hover': {
              backgroundColor: COLOR_GRAY_400,
            },
            '&:active': {
              borderColor: COLOR_GREEN,
            },
          }}
        >
          Clear all
        </Button>
      </Stack>
    </Box>
  );
};
