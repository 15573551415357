import { FC } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { COLOR_LIGHT } from 'theme/variables';

export type NotableNewsCardProps = {
  img: string;
  title: string;
  date: string;
  link: string;
};

export const NotableNewsCard: FC<NotableNewsCardProps> = ({ img, date, title, link }) => {
  return (
    <Box
      mx="auto"
      overflow="hidden"
      height={350}
      maxWidth={400}
      borderRadius="16px"
      border={`1px solid ${COLOR_LIGHT}`}
      sx={{ '&:hover': { border: `4px solid ${COLOR_LIGHT}` } }}
    >
      <Box height={230} width="100%" sx={{ cursor: 'pointer' }}>
        <Link href={link} target="_blank" rel="noreferrer">
          <Box
            component="img"
            src={img}
            alt={`notable news ${title}`}
            sx={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }}
          />
        </Link>
      </Box>
      <Stack px={2} py={1} spacing={1} justifyContent="space-between" height={120}>
        <Typography fontSize={16} lineHeight="24px" fontWeight={FontWeights.fontWeightSemiBold}>
          {title}
        </Typography>
        <Typography fontSize={14} lineHeight="24px">
          {date}
        </Typography>
      </Stack>
    </Box>
  );
};
