import { FC, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Copy } from 'components/Icon';
import { BORDER_GRAY, BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY } from 'theme/variables';

export interface CopyTextProps {
  text: string;
  variant?: 'contained' | 'outlined';
  size?: 'small' | 'large';
}
export const CopyText: FC<CopyTextProps> = ({ text, variant, size }) => {
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText('');
    }, 1500);
    return () => clearTimeout(timer);
  }, [helperText]);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setHelperText('Coppied!');
    } catch (err) {
      setHelperText('Not coppied!');
    }
  };
  return (
    <Box
      px={2}
      sx={{
        width: '100%',
        borderRadius: BORDER_RADIUS_DEFAULT,
        border: variant === 'contained' ? 'none' : BORDER_GRAY,
        background: `${COLOR_LIGHT_GRAY} !important`,
      }}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        height={size === 'small' ? '36px' : 'auto'}
        xs={12}
        sx={{
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="body1"
          color={COLOR_GRAY_150}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            py: 1.25,
          }}
        >
          {helperText || text}
        </Typography>
        <IconButton onClick={handleCopyAddress}>
          <Copy
            sx={{
              path: {
                fill: 'none',
                stroke: COLOR_BLACK,
              },
            }}
          />
        </IconButton>
      </Grid>
    </Box>
  );
};
