import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompareArrows: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 19"
    sx={{
      width: '21',
      height: '19',
      ...sx,
    }}
  >
    <path
      d="M5.43261 13.5V1.5M5.43261 1.5L1.271 5.5M5.43261 1.5L9.59423 5.5M15.8367 5.5V17.5M15.8367 17.5L19.9983 13.5M15.8367 17.5L11.675 13.5"
      stroke="#7FFB50"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
