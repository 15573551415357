import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import {
  BG_MODAL_GRAY,
  BORDER_RADIUS_DEFAULT,
  COLOR_LIGHT,
  COLOR_LIGHT_GRAY,
  SHADOW_MODAL_DEFAULT,
} from 'theme/variables';

export const getMuiDialogOverrides = (): ComponentsOverrides['MuiDialog'] => ({
  root: {
    background: BG_MODAL_GRAY,
  },

  paper: {
    width: '342px',
    background: COLOR_LIGHT,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: BORDER_RADIUS_DEFAULT,
    boxShadow: SHADOW_MODAL_DEFAULT,
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
