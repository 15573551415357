import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { setActiveModal } from 'store/modals/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Modals } from 'types';
import { logger } from 'utils';

import { custodyListed } from '../actions';
import actionTypes from '../actionTypes';
import { setOrderId } from '../reducer';

export function* custodyListedSaga({
  type,
  payload: { data: formData, setSubmitting, resetForm },
}: ReturnType<typeof custodyListed>) {
  yield* put(request(type));
  try {
    const { data } = yield* call(baseApi.custodyListed, { data: formData });

    if (typeof data === 'number' || 'Order created') {
      yield* put(setOrderId(data));
      toast.success('You successfully send custody request. Please fill delivery info');
      yield* put(success(type));
    } else {
      throw Error();
    }
  } catch (err) {
    yield* put(error(type, err));
    toast.error('Custody request has not been sent');
    logger('custodyListed', err);
  } finally {
    yield* put(setActiveModal({ activeModal: Modals.ApproveCustody, open: true }));
    setSubmitting(false);
    resetForm();
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CUSTODY_LISTED, custodyListedSaga);
}
