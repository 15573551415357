import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Stack, styled, Typography } from '@mui/material';
import { ActivitySkeleton, FilterButton } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import { times } from 'lodash';
import { getActivities, getFollowingActivities, getUserActivities, viewActivity } from 'store/activity/actions';
import actionTypes from 'store/activity/actionTypes';
import { clearActivities, clearTotalActivities } from 'store/activity/reducer';
import activitySelector from 'store/activity/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_GREEN, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

import { ActivityBlock, FilterBlock } from './components/FilterBlock';
import { activityTypesMock, filterItemsMock, partialFilterItemsMock } from './Activity.helpers';

const ActivityButton = styled(Button)({
  color: COLOR_GREEN,
  backgroundColor: COLOR_LIGHT_GRAY,
  fontSize: '16px',
  height: '44px',
  fontWeight: FontWeights.fontWeightBold,
  whiteSpace: 'nowrap',
  '&.active': {
    color: COLOR_WHITE,
    backgroundColor: COLOR_GREEN,
    '&:active': {
      color: COLOR_WHITE,
    },
  },
});

export const Activity: FC = () => {
  const dispatch = useDispatch();
  const [isFilterChecked, setFilterChecked] = useState(false);
  const [activityType, setActivityType] = useState<string>('My Activity');
  const [activeFilterItems, setActiveFilterItems] = useState<string[]>([]);
  const [filterItems, setFilterItems] = useState<string[]>(filterItemsMock);
  const { activities, totalActivities } = useShallowSelector(activitySelector.getActivity);

  const itemsPerPage = 8;
  const totalPages = Math.ceil(totalActivities / itemsPerPage);
  const [page, setPage] = useState(1);
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);
  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
  };

  useEffect(() => {
    setVisibleCount(page * itemsPerPage);
  }, [page]);

  const address = useShallowSelector(userSelector.getProp('address'));
  const { width } = useWindowState();
  const handleTypeChange = (type: string) => {
    dispatch(clearActivities());
    dispatch(clearTotalActivities());
    setActivityType(type);
    setPage(1);
    setVisibleCount(itemsPerPage);
    setFilterItems(type === 'All Activity' ? partialFilterItemsMock : filterItemsMock);
    if (type === 'All Activity')
      setActiveFilterItems(activeFilterItems.filter((prevItem) => prevItem !== 'Likes' && prevItem !== 'Followings'));
  };
  const handleFilterChange = (items: string[]) => {
    dispatch(clearActivities());
    dispatch(clearTotalActivities());
    setActiveFilterItems(items);
    setPage(1);
    setVisibleCount(itemsPerPage);
  };

  useEffect(() => {
    dispatch(viewActivity({ data: { method: 'all' } }));
    const filter = activeFilterItems.join(', ');
    if (activityType === 'All Activity') {
      dispatch(
        getActivities(
          filter.length
            ? {
                type: filter,
                itemsPerPage: visibleCount,
              }
            : { type: partialFilterItemsMock.join(', '), itemsPerPage: visibleCount },
        ),
      );
    }
    if (activityType === 'Following') {
      dispatch(
        getFollowingActivities(
          filter.length
            ? {
                type: filter,
                address,
                itemsPerPage: visibleCount,
              }
            : { address, itemsPerPage: visibleCount },
        ),
      );
    }
    if (activityType === 'My Activity') {
      dispatch(
        getUserActivities(
          filter.length
            ? {
                type: filter,
                address,
                itemsPerPage: visibleCount,
              }
            : { address, itemsPerPage: visibleCount },
        ),
      );
    }
  }, [activeFilterItems, activityType, address, visibleCount, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearActivities());
      dispatch(clearTotalActivities());
    };
  }, [dispatch]);
  const {
    [actionTypes.GET_ACTIVITIES]: getActivitiesRequestStatus,
    [actionTypes.GET_USER_ACTIVITIES]: getUserActivitiesRequestStatus,
    [actionTypes.GET_FOLLOWING_ACTIVITIES]: getFollowingActivitiesRequestStatus,
  } = useShallowSelector(uiSelector.getUI);
  const isGetActivitiesLoading = useMemo(
    () =>
      getActivitiesRequestStatus === RequestStatus.REQUEST ||
      getUserActivitiesRequestStatus === RequestStatus.REQUEST ||
      getFollowingActivitiesRequestStatus === RequestStatus.REQUEST,
    [getActivitiesRequestStatus, getUserActivitiesRequestStatus, getFollowingActivitiesRequestStatus],
  );
  return (
    <Stack spacing={3} mx={{ xs: 0, md: 5, lg: 12 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{ typography: { xs: 'h3', md: 'h1' }, color: `${COLOR_GREEN} !important` }}
          className="uppercase"
        >
          Notifications
        </Typography>
        {width < 700 && <FilterButton isFilterChecked={isFilterChecked} setFilterChecked={setFilterChecked} />}
      </Stack>
      {width < 700 && isFilterChecked && (
        <FilterBlock items={filterItems} activeItems={activeFilterItems} onChange={handleFilterChange} />
      )}
      <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" spacing={2}>
        <Stack direction="row" spacing={2} justifyContent={{ xs: 'space-between', md: 'flex-start' }}>
          {activityTypesMock.map((typeName) => (
            <ActivityButton
              className={activityType === typeName ? 'active' : ''}
              variant="contained"
              size="small"
              key={typeName}
              onClick={() => handleTypeChange(typeName)}
            >
              {typeName}
            </ActivityButton>
          ))}
        </Stack>
        {width >= 700 && <FilterButton isFilterChecked={isFilterChecked} setFilterChecked={setFilterChecked} />}
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={{ xs: 2, md: 5, lg: 14 }}>
        <Stack width="100%" spacing={1}>
          {!!activities.length &&
            activities.map((activity, index) => (
              <ActivityBlock key={`${index + 1}`} item={activity} activityType={activityType} />
            ))}
          {!isGetActivitiesLoading && !activities.length && <Typography>There is no new notifications</Typography>}
          {isGetActivitiesLoading && (
            <>
              {times(4, (index) => (
                <ActivitySkeleton key={index} />
              ))}
            </>
          )}
          {page < totalPages && (
            <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
              Load more
            </Button>
          )}
        </Stack>
        {width >= 700 && isFilterChecked && (
          <FilterBlock items={filterItems} activeItems={activeFilterItems} onChange={handleFilterChange} />
        )}
      </Stack>
    </Stack>
  );
};
