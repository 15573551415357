import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IconButton, Typography } from '@mui/material';
import { Like, LikeActive } from 'components/Icon';
import { useShallowSelector } from 'hooks';
import { likeNft } from 'store/nft/actions';
import userSelector from 'store/user/selectors';
import { COLOR_BLACK, COLOR_GRAY_100 } from 'theme/variables';

export type LikeButtonOnChangeProp = (data: { id: number | string; likesCount?: number; isLiked: boolean }) => void;

export type LikeButtonProps = {
  isLiked: boolean;
  likesCount?: number;
  id: number | string;
  onChange?: LikeButtonOnChangeProp;
};

export const LikeButton: FC<LikeButtonProps> = ({ id, isLiked, likesCount = 0, onChange }) => {
  const [isLike, setIsLike] = useState(isLiked);
  const [count, setCount] = useState(likesCount || (isLiked ? 1 : 0));
  const address = useShallowSelector(userSelector.getProp('address'));
  const dispatch = useDispatch();

  const successCallback = useCallback(() => {
    const newChanges: Partial<Pick<LikeButtonProps, 'isLiked' | 'likesCount'>> = {};
    if (isLike) {
      if (typeof likesCount !== 'undefined') {
        newChanges.likesCount = isLiked ? likesCount - 1 : likesCount;
      }
      newChanges.isLiked = false;
    } else {
      if (typeof likesCount !== 'undefined') {
        newChanges.likesCount = isLiked ? likesCount : likesCount + 1;
      }
      newChanges.isLiked = true;
    }

    if (newChanges.likesCount !== undefined) {
      setCount(newChanges.likesCount);
    }
    if (newChanges.isLiked !== undefined) {
      setIsLike(newChanges.isLiked);
    }

    if (onChange) {
      onChange({
        id,
        isLiked: newChanges.isLiked,
        likesCount: newChanges.likesCount,
      });
    }
  }, [id, isLike, isLiked, likesCount, onChange]);

  const errorCallback = useCallback(() => {
    if (isLike) {
      toast.error('Dislike error');
    } else {
      toast.error('Like error');
    }
  }, [isLike]);

  const handleLike = useCallback(
    (tokenId: number | string) => {
      if (address) {
        dispatch(
          likeNft({
            id: +tokenId,
            successCallback,
            errorCallback,
          }),
        );
      }
    },
    [address, dispatch, errorCallback, successCallback],
  );

  useEffect(() => {
    setCount(likesCount || (isLiked ? 1 : 0));
  }, [likesCount]);

  useEffect(() => {
    setIsLike(isLiked);
  }, [isLiked]);

  return (
    <IconButton disabled={!address} onClick={() => handleLike(id)}>
      {isLike ? <LikeActive sx={{ color: COLOR_BLACK }} /> : <Like sx={{ color: COLOR_BLACK }} />}
      {likesCount ? (
        <Typography color={COLOR_GRAY_100} ml={1}>
          {count < 1000 ? count : '999+'}
        </Typography>
      ) : (
        ''
      )}
    </IconButton>
  );
};
