import { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { useWindowState } from 'hooks';
import { COLOR_LIGHT } from 'theme/variables';
import { ellipsisText } from 'utils';

import { HomeSlider } from '../../../HomeSlider';
import { TopCollectorsProps } from '../../TopCollector.types';
import { RowBoldText } from '../../TopCollectors.helper';

export const TopCollectorCard: FC<TopCollectorsProps> = ({
  navigateToToken,
  navigateToUser,
  avatar,
  name,
  items,
  tokenAmount,
}) => {
  const [perPage, setPerPage] = useState(4);
  const { width } = useWindowState();

  useEffect(() => {
    if (width < 450) setPerPage(2);
    else if (width < 580) setPerPage(3);
    else setPerPage(4);
  }, [width]);

  return (
    <Stack p={1.25} spacing={2.5} width="100%" borderRadius="12px" border={`1px solid ${COLOR_LIGHT}`}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          onClick={navigateToUser}
          direction="row"
          alignItems="center"
          spacing={2}
          maxWidth={224}
          sx={{ cursor: 'pointer' }}
        >
          <Box
            component="img"
            src={avatar}
            alt={`avatar-${name}`}
            sx={{ width: 56, height: 56, borderRadius: '50%' }}
          />
          <RowBoldText {...ellipsisText()}>{name}</RowBoldText>
        </Stack>
        <Stack justifyContent="center" pr={1.25}>
          <RowBoldText>{tokenAmount}</RowBoldText>
        </Stack>
      </Stack>
      <HomeSlider perPage={perPage} gap={12} padding={0} isLoop={false} autoWidth>
        {items.map(({ id, img }) => (
          <SplideSlide key={id}>
            <Box
              onClick={() => navigateToToken(id)}
              width={128}
              height={128}
              overflow="hidden"
              borderRadius="12px"
              sx={{ '&:hover': { border: `4px solid ${COLOR_LIGHT}`, cursor: 'pointer' } }}
            >
              <Box
                component="img"
                src={img}
                alt="top collector item"
                sx={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
              />
            </Box>
          </SplideSlide>
        ))}
      </HomeSlider>
    </Stack>
  );
};
