import { FC } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';

import { CustodyForm } from './components';

export const Custody: FC = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box sx={{ maxWidth: 800, marginX: 'auto' }}>
      <Typography
        className={clsx('bold', isMediumScreen && 'lg')}
        sx={{ typography: 'h1', marginBottom: { xs: 4, md: 8 } }}
      >
        Custody Request Form
      </Typography>
      <Typography className="semiBold" sx={{ typography: 'h1', marginBottom: { xs: 4, md: 8 } }}>
        Sneakers Detail
      </Typography>
      <CustodyForm />
    </Box>
  );
};
