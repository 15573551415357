import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Pagination, Stack, styled, Typography } from '@mui/material';
import { FilterButton, NftCard } from 'components';
import NftCardSkeleton from 'components/NftCardSkeleton';
import { useShallowSelector, useWindowState } from 'hooks';
import { isEmpty, times } from 'lodash';
import { SearchNfts } from 'modules/layout/containers/Header/components/SearchNfts';
import { getCategories, searchNfts } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import { clearNfts } from 'store/nft/reducer';
import nftsSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { theme } from 'theme';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_10, COLOR_GRAY_150, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

import {
  CustomFilterProps,
  FilterMenu,
  FilterProps,
  initialCustomFilterState,
  initialFilterState,
  PriceRangeProps,
  PropertyData,
  SortSelect,
} from './components';

const BrandButton = styled(Button)({
  padding: theme.spacing(1.75, 2.5),
  height: 52,
  textTransform: 'none',
  minWidth: 'unset',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 1.5),
    height: 40,
    fontSize: 14,
  },
  '&.active': {
    background: COLOR_BLACK,
    borderColor: COLOR_BLACK,
    color: COLOR_WHITE,
  },
});

export const Explore: FC = () => {
  const [isFilterMenuVisible, setFilterMenuVisible] = useState<boolean>(false);
  const [sortType, setSortType] = useState<string>('');
  const [filterItems, setFilterItems] = useState<FilterProps>(initialFilterState);
  const [customFilterItems, setCustomFilterItems] = useState<CustomFilterProps>(initialCustomFilterState);
  const [page, setPage] = useState<number>(1);
  const { width: windowWidth } = useWindowState();
  const [searchParams] = useSearchParams();
  const { nfts, totalNfts, categories } = useShallowSelector(nftsSelector.getNft);
  const { [actionTypes.SEARCH_NFTS]: searchNftsRequestStatus } = useShallowSelector(uiSelector.getUI);
  const startNftRef = useRef<any>(null);
  const dispatch = useDispatch();
  const initialItemsPerPage = 60;
  const totalPages = Math.ceil(totalNfts / initialItemsPerPage);

  const isSearchNftsLoading = useMemo(
    () => searchNftsRequestStatus === RequestStatus.REQUEST,
    [searchNftsRequestStatus],
  );

  const isMobile = useMemo(() => windowWidth < 700, [windowWidth]);

  const filterProperties: PropertyData = useMemo(
    () => categories.find((item) => item.name === filterItems.categories)?.propertyData || {},
    [categories, filterItems.categories],
  );

  const fetchNfts = useCallback(
    (filter: FilterProps, customFilter: CustomFilterProps, pageIndex: number, sortBy: string) => {
      dispatch(
        searchNfts({
          props: {
            type: 'items',
            itemsPerPage: initialItemsPerPage,
            page: pageIndex,
            categories: filter.categories,
            orderBy: sortBy,
            properties: customFilter,
            collections: filter.collection,
            text: filter.text,
            collectionType: filter.collectionType,
            redeemStatus: filter.redeemStatus,
            // currency: 'USDC',
            minPrice: filter.priceRange.currency ? filter.priceRange.minValue : '',
            maxPrice: filter.priceRange.currency ? filter.priceRange.maxValue : '',
            network: filter.network ? filter.network : undefined,
            onAucSale: filter.saleType === 'Auction' ? true : '',
            onAnySale: filter.saleType === 'On sale' ? true : '',
          },
        }),
      );
    },
    [dispatch],
  );

  const handleSortBy = (newSortType: string) => {
    setSortType(newSortType);
    dispatch(clearNfts());
    setPage(1);
    searchParams.set('sortType', newSortType);
  };

  const handleResetFilter = () => {
    Object.keys(filterItems).forEach((key) => searchParams.delete(key));
    searchParams.delete('sortType');
    searchParams.delete('properties');
    setFilterItems({ ...initialFilterState });
    setCustomFilterItems({ ...initialCustomFilterState });
    setSortType('');
    dispatch(clearNfts());
    setPage(1);
  };

  const handleFilterChange = (
    key: keyof FilterProps,
    item: string | number | Array<string | number> | PriceRangeProps,
  ) => {
    setFilterItems({ ...filterItems, [key]: item });

    if (item) {
      searchParams.set(key, JSON.stringify(item));
    } else {
      searchParams.delete(key);
    }
  };

  const handleCustomFilterChange = (key: string, item: string | string[]) => {
    const newCustomPropertiesState = item?.length
      ? { ...customFilterItems, [key]: item instanceof Array ? item : [item] }
      : Object.fromEntries(Object.entries(customFilterItems).filter(([propKey]) => propKey !== key));

    if (!isEmpty(newCustomPropertiesState)) {
      searchParams.set('properties', JSON.stringify(newCustomPropertiesState));
    } else {
      searchParams.delete('properties');
    }
    setCustomFilterItems(newCustomPropertiesState);
  };

  const handleSearchTextChange = (searchText: string) => {
    handleFilterChange('text', searchText);
  };

  const changePage = (e: ChangeEvent<unknown>, index: number) => {
    setPage(index);
    startNftRef.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(clearNfts());
    fetchNfts(filterItems, customFilterItems, index, sortType);
  };

  useEffect(() => {
    const newFilterItems = initialFilterState;
    searchParams.forEach((value, key) => {
      const parsedKey = key as keyof FilterProps;
      if (Object.hasOwn(newFilterItems, parsedKey)) {
        newFilterItems[parsedKey] = JSON.parse(value);
      }
      if (key === 'sortType') {
        setSortType(value);
      }
      if (key === 'properties') {
        setCustomFilterItems(JSON.parse(value));
      }
    });
    setFilterItems(newFilterItems);
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(clearNfts());
    setPage(1);
    fetchNfts(filterItems, customFilterItems, 1, sortType);
  }, [dispatch, fetchNfts, customFilterItems, filterItems, sortType]);

  useEffect(() => {
    const newUrl = `${window.location.origin}${window.location.pathname}${
      searchParams.toString().length ? '?' : ''
    }${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }, [searchParams, filterItems, customFilterItems, sortType]);

  useEffect(() => {
    dispatch(getCategories());
    return () => {
      dispatch(clearNfts());
    };
  }, [dispatch]);

  useEffect(() => {
    setFilterMenuVisible(!isMobile);
  }, [isMobile]);

  return (
    <>
      <Typography
        sx={{
          typography: { xs: 'h2', md: 'h1' },
          fontFamily: `${FontFamilies.quaternary} !important`,
          fontWeight: `${FontWeights.fontWeightBold} !important`,
        }}
      >
        Explore Marketplace
      </Typography>
      <Stack mt={3} spacing={2}>
        <Stack direction="row" spacing={2}>
          <FilterButton isFilterChecked={isFilterMenuVisible} setFilterChecked={setFilterMenuVisible} />
          {!isMobile ? <SearchNfts onEnterKeyDown={handleSearchTextChange} isFullWidth /> : ''}
          <SortSelect isMobile onChange={handleSortBy} activeSortItem={sortType} />
        </Stack>
        <Stack
          display={{ xs: 'none', sm: 'flex' }}
          direction="row"
          spacing={2}
          pb={1}
          sx={{
            overflowX: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarColor: `${COLOR_GRAY_10} transparent`,
            '&::-webkit-scrollbar': {
              backgroundColor: 'transparent',
              height: 4,
            },
            '&::-webkit-scrollbar-thumb': {
              height: 1,
              borderRadius: BORDER_RADIUS_DEFAULT,
              background: COLOR_GRAY_10,
            },
          }}
        >
          <BrandButton
            variant="outlined"
            onClick={() => handleCustomFilterChange('Brand', '')}
            className={customFilterItems?.Brand?.length ? '' : 'active'}
          >
            All MetaZ
          </BrandButton>
          {filterProperties?.Brand?.values?.map((item, index) => (
            <BrandButton
              key={`${index + 1}`}
              // onClick={() => handleChooseBrand(item)}
              onClick={() => handleCustomFilterChange('Brand', item)}
              className={customFilterItems?.Brand?.includes(item) ? 'active' : ''}
              variant="outlined"
            >
              {item}
            </BrandButton>
          )) || ''}
        </Stack>
      </Stack>

      <Stack
        mt={{ xs: 3, sm: 4, md: 6 }}
        mb={{ xs: 10, sm: 6 }}
        direction={{ xs: 'column', sm: 'row' }}
        gap={3}
        ref={startNftRef}
      >
        {isFilterMenuVisible && (
          <FilterMenu
            filterItems={filterItems}
            customFilterItems={customFilterItems}
            onFilterChange={handleFilterChange}
            onCustomFilterChange={handleCustomFilterChange}
            onReset={handleResetFilter}
            filterProperties={filterProperties}
          />
        )}

        <Stack width="100%" spacing={3} display={{ xs: isFilterMenuVisible ? 'none' : 'flex', sm: 'flex' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography color={COLOR_GRAY_150} fontWeight={FontWeights.fontWeightSemiBold}>
              {!!totalNfts && `${totalNfts} Results`}
            </Typography>
            <SortSelect onChange={handleSortBy} activeSortItem={sortType} />
          </Stack>

          <Box
            display="grid"
            gap={{ xs: 1.25, sm: 2 }}
            justifyItems="center"
            gridTemplateColumns={`repeat(${isFilterMenuVisible ? 5 : 6}, 1fr)`}
            sx={{
              [theme.breakpoints.down(1900)]: {
                gridTemplateColumns: `repeat(${isFilterMenuVisible ? 4 : 5}, 1fr)`,
              },

              [theme.breakpoints.down(1620)]: isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }
                : {},
              [theme.breakpoints.down(1570)]: !isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(4, 1fr)',
                  }
                : {},
              [theme.breakpoints.down(1330)]: isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }
                : {},
              [theme.breakpoints.down(1270)]: !isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }
                : {},
              [theme.breakpoints.down(1000)]: isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(1, 1fr)',
                  }
                : {},
              [theme.breakpoints.down(930)]: !isFilterMenuVisible
                ? {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }
                : {},

              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
              [theme.breakpoints.down(590)]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
            }}
          >
            {!!nfts.length &&
              nfts.map((item, index) => (
                <NftCard
                  key={`${index + 1}`}
                  id={item?.id || 0}
                  cardSize="medium"
                  image={item?.image || ''}
                  animation={item?.animationFile || ''}
                  format={item?.format || 'image'}
                  title={item.name}
                  price={item?.price || 0}
                  isSelling={item?.isSelling}
                  isLiked={item?.isLiked || false}
                  isClaimed={item?.isClaimed}
                  nftSize={
                    item?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Size')?.traitValue ||
                    ''
                  }
                  styleHash={
                    item?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Style #')
                      ?.traitValue || ''
                  }
                  collectionType={
                    item?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Location')
                      ?.traitValue || ''
                  }
                />
              ))}
            {!nfts.length && !isSearchNftsLoading && (
              <Typography
                sx={{
                  typography: { xs: 'h3', md: 'h2' },
                  pt: 3,
                  pl: 2,
                  width: '100%',
                  textAlign: 'center',
                  display: { xs: isFilterMenuVisible ? 'none' : 'unset', sm: 'unset' },
                }}
              >
                There is no NFTs matching your request
              </Typography>
            )}
            {isSearchNftsLoading && (
              <>
                {times(3, (index) => (
                  <NftCardSkeleton key={`${index + 1}`} size="medium" />
                ))}
              </>
            )}
          </Box>
          {totalPages > 1 && (
            <Pagination count={totalPages} variant="outlined" shape="rounded" page={page} onChange={changePage} />
          )}
        </Stack>
      </Stack>
    </>
  );
};
