import { URL } from 'appConstants';
import {
  BidReq,
  BuyReq,
  Chains,
  MaxPriceReq,
  PresearchNftsReq,
  SearchNftReq,
  SelfCollectionsReq,
  SetOnAucReq,
  SetOnSaleReq,
  TrackTxReq,
  TransferReq,
} from 'types';
import { snakeize } from 'utils';

import ajax from './ajax';

export default {
  searchNfts({ props }: SearchNftReq) {
    return ajax({
      method: 'get',
      url: URL.searchNfts(props),
    });
  },
  presearchNfts(data: PresearchNftsReq) {
    return ajax({
      method: 'get',
      url: URL.presearchNfts(data),
    });
  },
  getMaxPrice(data: MaxPriceReq) {
    return ajax({
      method: 'get',
      url: URL.maxPrice(data),
    });
  },
  getCategories() {
    return ajax({
      method: 'get',
      url: URL.getCategories,
    });
  },
  getEnabledOnCarousel(network?: Chains) {
    return ajax({
      method: 'get',
      url: URL.getEnabledOnCarousel(network),
    });
  },
  getFavorites(network?: Chains) {
    return ajax({
      method: 'get',
      url: URL.getFavorites(network),
    });
  },
  getSelfCollections(props: SelfCollectionsReq) {
    return ajax({
      method: 'get',
      url: URL.getCollections(props),
    });
  },
  getNftData(id: number | string) {
    return ajax({
      method: 'get',
      url: URL.getTokenInfo(id),
    });
  },
  createCollection(data: { network: string; formData: FormData }) {
    return ajax({
      method: 'post',
      url: URL.createCollection(data.network),
      data: data.formData,
    });
  },
  createNft(formData: FormData) {
    return ajax({
      method: 'post',
      url: URL.createToken,
      data: formData,
    });
  },
  mintNft(id: number | string, amount: number) {
    return ajax({
      method: 'post',
      url: URL.mintToken(id),
      data: { amount },
    });
  },
  mintReject(data: { ids: Array<number | string>; type: string }) {
    return ajax({
      method: 'post',
      url: URL.mintReject,
      data,
    });
  },
  trackTransaction(data: TrackTxReq) {
    return ajax({
      method: 'post',
      url: URL.trackTransaction,
      data,
    });
  },
  likeNft(id: number) {
    return ajax({
      method: 'post',
      url: URL.like,
      data: { id },
    });
  },
  buyNft(data: BuyReq) {
    return ajax({
      method: 'post',
      url: URL.buy,
      data,
    });
  },
  bid(data: BidReq) {
    return ajax({
      method: 'post',
      url: URL.bid,
      data,
    });
  },
  buyReject(data: { id: number | string; owner: string }) {
    return ajax({
      method: 'post',
      url: URL.buyReject,
      data,
    });
  },
  setOnSale(id: number | string, data: SetOnSaleReq) {
    return ajax({
      method: 'post',
      url: URL.setOnSale(id),
      data: snakeize(data),
    });
  },
  transfer(id: number | string, data: TransferReq) {
    return ajax({
      method: 'post',
      url: URL.transferToken(id),
      data,
    });
  },
  setOnAuction(id: number | string, data: SetOnAucReq) {
    return ajax({
      method: 'post',
      url: URL.setOnAuction(id),
      data,
    });
  },
  endAuction(id: number | string) {
    return ajax({
      method: 'post',
      url: URL.endAuction(id),
    });
  },
  removeFromSale(id: number | string) {
    return ajax({
      method: 'post',
      url: URL.removeFromSale(id),
    });
  },
  burn(id: number | string, data: { amount: number | string }) {
    return ajax({
      method: 'post',
      url: URL.burnToken(id),
      data,
    });
  },
};
