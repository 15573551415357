import { FC } from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { theme as muiTheme } from 'theme';
import { FontWeights } from 'theme/Typography';

import { partners } from './Partners.mocked';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 64,
  fontWeight: FontWeights.fontWeightBlack,
  lineHeight: '66px',
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
    lineHeight: '36px',
  },
}));

export const Partners: FC = () => {
  const isDownMdLayout = useMediaQuery(muiTheme.breakpoints.down('md'));
  return (
    <Stack>
      <Title
        textAlign="center"
        mt={{ xs: '30px', md: '70px', lg: '105px' }}
        mb={{ xs: '30px', sm: '60px', md: '100px', lg: '177px' }}
      >
        Trusted partners worldwide
      </Title>

      <Box
        overflow="hidden"
        position="relative"
        height={{ xs: 75, md: 150 }}
        mb={{ xs: '30px', sm: '40px', md: '80px', lg: '218px' }}
      >
        <Splide
          options={{
            gap: 43,
            pagination: false,
            omitEnd: true,
            type: 'loop',
            arrows: false,
            drag: false,
            focus: 'center',
            height: isDownMdLayout ? 75 : 150,
            autoScroll: {
              speed: 1,
            },
            autoWidth: true,
          }}
          extensions={{ AutoScroll }}
        >
          {partners.map(({ img, name, height, width, containerWidth }) => (
            <SplideSlide key={name}>
              <Stack
                height={{ xs: 75, md: 150 }}
                width={{ xs: '100%', md: containerWidth }}
                minWidth={{ xs: 'unset', md: containerWidth }}
                maxWidth={{ xs: 175, md: 'unset' }}
                key={name}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  src={img}
                  sx={{ maxHeight: height, maxWidth: width, width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </Stack>
            </SplideSlide>
          ))}
        </Splide>
        <Box
          sx={{
            background: 'linear-gradient(90deg, #FFF 0.56%, transparent 10.97%, transparent 90.97%, #FFF 99.44%)',
            position: 'absolute',
            width: '100%',
            height: { xs: 75, md: 150 },
            top: 0,
            zIndex: 2,
          }}
        />
      </Box>
    </Stack>
  );
};
