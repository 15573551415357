import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { BlockSkeleton } from 'components';
import { useShallowSelector } from 'hooks';
import { times } from 'lodash';
import { getHistory } from 'store/orders/actions';
import ordersActionTypes from 'store/orders/actionTypes';
import { clearHistory } from 'store/orders/reducer';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { RequestStatus, State, UserState } from 'types';

import { CustodyRequestBlock } from './components';

export const CustodyRequests: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();

  const [page, setPage] = useState(1);
  const [activeId, setActiveId] = useState(0);

  const { custody, totalCustody } = useShallowSelector(ordersSelector.getProp('history'));
  const { [ordersActionTypes.GET_HISTORY]: getHistoryRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { id: currentUserId, customUrl: userCustomUrl } = useShallowSelector<State, UserState>(userSelector.getUser);

  const initialItemsPerPage = 3;
  const totalPages = Math.ceil(totalCustody / initialItemsPerPage);
  const isCurrentUser = userId === currentUserId?.toString() || userId === userCustomUrl;

  const isGetHistoryLoading = useMemo(
    () => getHistoryRequestStatus === RequestStatus.REQUEST,
    [getHistoryRequestStatus],
  );

  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
    dispatch(getHistory({ type: 'custody', page: 1, itemsPerPage: (page + 1) * initialItemsPerPage }));
  };

  useEffect(() => {
    if (isCurrentUser) {
      dispatch(getHistory({ type: 'custody', page: 1, itemsPerPage: initialItemsPerPage }));
    } else {
      navigate(routes.profile.root.getPath(userId));
    }
    return () => {
      dispatch(clearHistory());
    };
  }, [dispatch, isCurrentUser, navigate, userId]);

  return (
    <Grid item container xs={12} sm={8.5} md={9} lg={10} display="block">
      <Stack spacing={2} width="100%">
        {!!custody.length &&
          custody.map((request) => (
            <CustodyRequestBlock
              activeId={activeId}
              setActiveId={() => setActiveId(request.id || 0)}
              key={request.id}
              custodyRequest={request}
              itemsPerPage={page * initialItemsPerPage}
            />
          ))}
        {!custody.length && !isGetHistoryLoading && (
          <Typography variant="h2" textAlign="center" sx={{ pt: 3, pl: 2 }}>
            There is no requests yet
          </Typography>
        )}
        {isGetHistoryLoading && (
          <>
            {times(3, (index) => (
              <BlockSkeleton key={index} height={{ xs: 320, md: 164, lg: 164 }} />
            ))}
          </>
        )}
        {page < totalPages && !isGetHistoryLoading && (
          <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
            Load more
          </Button>
        )}
      </Stack>
    </Grid>
  );
};
