import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { camelize, logger } from 'utils';

import { getTopCollectors } from '../actions';
import actionTypes from '../actionTypes';
import { setTopCollectors } from '../reducer';

export function* getTopCollectorsSaga({ type, payload }: ReturnType<typeof getTopCollectors>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.getTopCollectors, payload);

    yield put(setTopCollectors(camelize(data)));

    yield put(success(type));
  } catch (e) {
    yield put(error(type, e));
    logger('getTopCollectorsSaga', e);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOP_COLLECTORS, getTopCollectorsSaga);
}
