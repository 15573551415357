import * as Yup from 'yup';

export type RedeemClaimFormFields = {
  amount: string;
  firstName: string;
  lastName: string;
  address: string;
  zipCode: string;
  mobileNumber: string;
  email: string;
};

export const initialValues: RedeemClaimFormFields = {
  amount: '1',
  firstName: '',
  lastName: '',
  address: '',
  zipCode: '',
  mobileNumber: '',
  email: '',
};

export const setInitialValues: (obj: Partial<RedeemClaimFormFields>) => RedeemClaimFormFields = (
  customInitialValues,
) => {
  return {
    ...initialValues,
    ...customInitialValues,
  };
};
export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too short')
    .matches(/(?!^\d+$)^.+$/, `First name can't contain only numbers!`)
    .max(100, 'Maximum 100 characters')
    .nullable()
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Too short')
    .matches(/(?!^\d+$)^.+$/, `Last name can't contain only numbers!`)
    .max(100, 'Maximum 100 characters')
    .nullable()
    .required('Last name is required'),
  address: Yup.string()
    .min(5, 'Too short')
    .max(500, 'Maximum 500 characters')
    .nullable()
    .required('Address is required'),
  zipCode: Yup.string()
    .min(2, 'Too short')
    .max(50, 'Maximum 50 characters')
    .nullable()
    .required('Zip code is required'),
  mobileNumber: Yup.string()
    .min(3, 'Too short')
    .max(30, 'Maximum 30 characters')
    .matches(/^[+]?[0-9]*[(]{0,1}[0-9]*[)]{0,1}[-\s.0-9]*$/, 'Enter correct mobile number!')
    .nullable()
    .required('Mobile number is required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      'Enter correct email!',
    )
    .max(50, 'Maximum 50 characters')
    .nullable()
    .required('Email is required'),
});

type fieldProps = {
  id: keyof RedeemClaimFormFields;
  name: keyof RedeemClaimFormFields;
  label: string;
  placeholder: string;
  isMultiline?: boolean;
  helperText?: (count: number) => string;
};

export const inputFields: fieldProps[] = [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    placeholder: '',
    isMultiline: false,
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last name',
    placeholder: '',
    isMultiline: false,
  },
  {
    id: 'zipCode',
    name: 'zipCode',
    label: 'Zip code',
    placeholder: 'Input zip code',
    isMultiline: false,
    helperText: (count) => `Single line text ${count}/50`,
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    placeholder: '',
    isMultiline: false,
    // helperText: (count) => `Single line text ${count}/50`,
  },
  {
    id: 'mobileNumber',
    name: 'mobileNumber',
    label: 'Mobile number',
    placeholder: '',
    isMultiline: false,
    // helperText: (count) => `Single line text ${count}/30`,
  },
  {
    id: 'address',
    name: 'address',
    label: 'Address',
    placeholder: '',
    isMultiline: false,
    // helperText: (count) => `Multiline text ${count}/500`,
  },
];
