import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import { BlockSkeleton } from 'components';
import { useShallowSelector } from 'hooks';
import { times } from 'lodash';
import { useWalletConnectorContext } from 'services';
import { getHistory, payForDelivery } from 'store/orders/actions';
import ordersActionTypes from 'store/orders/actionTypes';
import { clearHistory } from 'store/orders/reducer';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import { RequestStatus } from 'types';

import { OrderBlock } from './components';

export const RedeemsHistory: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const { redeems, totalRedeems } = useShallowSelector(ordersSelector.getProp('history'));
  const [paidId, setPaidId] = useState(0);

  const initialItemsPerPage = 4;
  const totalPages = Math.ceil(totalRedeems / initialItemsPerPage);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getHistory({ type: 'redeem', page: 1, itemsPerPage: initialItemsPerPage }));
    return () => {
      dispatch(clearHistory());
    };
  }, [dispatch]);

  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
    dispatch(getHistory({ type: 'redeem', page: 1, itemsPerPage: (page + 1) * initialItemsPerPage }));
  };

  const {
    [ordersActionTypes.GET_HISTORY]: getHistoryRequestStatus,
    [ordersActionTypes.PAY_FOR_DELIVERY]: payForDeliveryRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const isGetHistoryLoading = useMemo(
    () => getHistoryRequestStatus === RequestStatus.REQUEST,
    [getHistoryRequestStatus],
  );
  const isPayForDeliveryLoading = useMemo(
    () => payForDeliveryRequestStatus === RequestStatus.REQUEST,
    [payForDeliveryRequestStatus],
  );

  const handlePayForDelivery = (id: number) => {
    setPaidId(id);
    dispatch(
      payForDelivery({
        type: 'redeem',
        id,
        web3Provider: walletService.Web3(),
        itemsPerPage: page * initialItemsPerPage,
      }),
    );
  };

  return (
    <Stack spacing={2} width="100%">
      {!!redeems.length &&
        redeems.map((redeem) => (
          <OrderBlock
            key={redeem.id}
            item={redeem}
            type="Redeem"
            actionOrderId={paidId}
            onStatusAction={handlePayForDelivery}
            isStatusActionLoading={isPayForDeliveryLoading}
          />
        ))}
      {!redeems.length && !isGetHistoryLoading && (
        <Typography variant="h2" sx={{ pt: 3, pl: 2 }} textAlign="center">
          There is no requests yet
        </Typography>
      )}
      {isGetHistoryLoading && (
        <>
          {times(4, (index) => (
            <BlockSkeleton key={index} height={{ xs: 309, md: 309, lg: 120 }} />
          ))}
        </>
      )}
      {page < totalPages && !isGetHistoryLoading && (
        <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
          Load more
        </Button>
      )}
    </Stack>
  );
};
