import { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { COLOR_BLACK, COLOR_LIGHT } from 'theme/variables';

import { FilterItemProps } from '../FilterMenu.helpers';

type ButtonAccordionProps = {
  label: string;
  items: FilterItemProps[];
  activeItem: string;
  onChange: (value: string) => void;
};
export const ButtonAccordion: FC<ButtonAccordionProps> = ({ label, items, activeItem, onChange }) => {
  const handleChange = (item: string) => {
    onChange(activeItem === item ? '' : item);
  };

  return (
    <Accordion disableGutters key={label} defaultExpanded>
      <AccordionSummary
        sx={{ zIndex: 1, px: 0 }}
        expandIcon={<KeyboardArrowDownIcon fontSize="medium" sx={{ color: COLOR_BLACK }} />}
      >
        <Typography className="semiBold">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ background: COLOR_LIGHT, mb: 1 }} />
        <Grid container flexDirection="column" rowGap={1} columnGap={1}>
          {items.map((item) => (
            <FormControlLabel
              key={item.id}
              control={<Checkbox onClick={() => handleChange(item.label)} checked={activeItem === item.label} />}
              label={item.label}
              sx={{ ml: 0, span: { textDecoration: activeItem === item.label ? 'underline' : 'none' } }}
            />
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
