import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_150 } from 'theme/variables';

export type NftSkeletonProps = {
  size: 'medium' | 'large';
};

const NftCardSkeleton: FC<NftSkeletonProps> = ({ size }) => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          borderRadius: BORDER_RADIUS_DEFAULT,
          bgcolor: COLOR_GRAY_150,
          width: { xs: '180px', sm: size === 'medium' ? '286px' : '296px' },
          height: { xs: '298px', sm: size === 'medium' ? '396px' : '410px' },
        }}
      />
    </Box>
  );
};

export default NftCardSkeleton;
