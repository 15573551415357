import { AbiItem } from 'web3-utils';

import ethereumWeth from './erc20.abi.json';
import ethereumErc721 from './erc721.abi.json';
import ethereumErc721Fabric from './erc721Fabric.abi.json';
import ethereumErc1155 from './erc1155.abi.json';
import ethereumErc1155Fabric from './erc1155Fabric.abi.json';
import ethereumExchange from './exchange.abi.json';
import ethereumRedeem from './redeem.abi.json';

export const ethereumWethAbi = ethereumWeth as AbiItem[];
export const ethereumErc721Abi = ethereumErc721 as AbiItem[];
export const ethereumErc1155Abi = ethereumErc1155 as AbiItem[];
export const ethereumErc721FabricAbi = ethereumErc721Fabric as AbiItem[];
export const ethereumErc1155FabricAbi = ethereumErc1155Fabric as AbiItem[];
export const ethereumExchangeAbi = ethereumExchange as AbiItem[];
export const ethereumRedeemAbi = ethereumRedeem as AbiItem[];
