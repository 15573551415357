import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { FontWeights } from '../Typography';
import { COLOR_BLACK } from '../variables';

export const getMuiTab = (theme: Theme): ComponentsOverrides['MuiTab'] => ({
  root: {
    color: COLOR_BLACK,
    textTransform: 'none',

    '&.Mui-selected': {
      color: COLOR_BLACK,
      fontWeight: FontWeights.fontWeightSemiBold,
    },
  },
});

export const getMuiTabDefaultProps = (): ComponentsProps['MuiTab'] => ({});
