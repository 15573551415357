import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CircleIcon from '@mui/icons-material/Circle';
import { Avatar, Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import { useActivity } from 'modules/activity/hooks';
import moment from 'moment';
import { viewActivity } from 'store/activity/actions';
import activitySelector from 'store/activity/selectors';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_100,
  COLOR_GRAY_400,
  COLOR_LIGHT,
  COLOR_WHITE,
} from 'theme/variables';
import { INotifyModalProps } from 'types';
import { shortenPhrase } from 'utils';
import { flexHelper } from 'utils/flexHelper';

type ActivityModalProps = INotifyModalProps;

const StyledButton = styled(Button)({
  '&:hover': {
    background: `${COLOR_GRAY_400} !important`,
    border: 'none',
  },
});
const Wrapper = styled(Box)({
  backgroundColor: COLOR_LIGHT,
  borderRadius: BORDER_RADIUS_DEFAULT,
  width: '100%',
  ...flexHelper(),
  justifyContent: 'start',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const ActivityModal: FC<ActivityModalProps> = ({ closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useShallowSelector(activitySelector.getProp('notifications'));
  const { handleActivityClick } = useActivity();

  const handleNavigateToActivity = () => {
    closeModal();
    navigate(routes.activity.root.path);
  };

  const onActivityClick = (tokenId: string | undefined, fromId: string | undefined) => {
    closeModal();
    handleActivityClick(tokenId, fromId);
  };

  useEffect(() => {
    return () => {
      dispatch(viewActivity({ data: { method: 'all' } }));
    };
  }, [dispatch]);

  return (
    <Grid container rowGap={3} pt={1}>
      <Stack spacing={1} width="100%">
        {notifications.length ? (
          notifications.map(
            ({
              id,
              method,
              fromId,
              fromName,
              fromAddress,
              date,
              tokenId,
              tokenImage,
              fromImage,
              isViewed,
              toName,
              toAddress,
            }) => (
              <Wrapper key={id} py={1} px={2} width="100%" onClick={() => onActivityClick(tokenId, fromId)}>
                <Avatar sx={{ width: '65px', height: '65px', mr: 3 }} src={tokenImage || fromImage} />
                <Stack width="100%" overflow="hidden">
                  <Typography textTransform="capitalize">{method}</Typography>
                  <Stack>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                      <Typography sx={{ color: COLOR_BLACK, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {fromName && 'by '}
                        {method !== 'Buy'
                          ? `${fromName !== fromAddress ? fromName || '' : shortenPhrase(fromName || '')} `
                          : `${toName !== toAddress ? toName || '' : shortenPhrase(toName || '')} `}
                      </Typography>
                      {!isViewed && <CircleIcon sx={{ color: COLOR_BLACK, fontSize: '12px' }} />}
                    </Stack>
                  </Stack>
                  <Typography sx={{ color: COLOR_BLACK }} variant="subtitle1" lineHeight="24px">
                    {moment(date).fromNow()}
                  </Typography>
                </Stack>
              </Wrapper>
            ),
          )
        ) : (
          <Typography sx={{ color: COLOR_GRAY_100 }}>There is no new notifications</Typography>
        )}
      </Stack>
      <StyledButton size="small" variant="contained" fullWidth onClick={handleNavigateToActivity}>
        <Typography variant="body2" sx={{ color: COLOR_WHITE }}>
          See all
        </Typography>
      </StyledButton>
    </Grid>
  );
};
