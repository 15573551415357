import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Activity } from 'types/api';
import { camelize, logger } from 'utils';

import { viewActivity } from '../actions';
import actionTypes from '../actionTypes';
import { setIsNewNotifications, setNotifications } from '../reducer';

export function* viewActivitySaga({ type, payload }: ReturnType<typeof viewActivity>) {
  yield* put(request(type));
  try {
    yield* call(baseApi.viewNotification, payload);
    const { data } = yield* call(baseApi.getNotifications);
    const result = camelize(data);
    const isNewNotifications = result.find((activity: Activity) => activity.isViewed === false);
    yield* put(setNotifications(result));
    yield put(setIsNewNotifications(isNewNotifications));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('viewActivity', err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.VIEW_ACTIVITY, viewActivitySaga);
}
