import { ComponentsOverrides, ComponentsProps } from '@mui/material';

export const getMuiContainerOverrides = (): ComponentsOverrides['MuiContainer'] => ({});

export const getMuiContainerDefaultProps = (): ComponentsProps['MuiContainer'] => ({
  maxWidth: 'xl',
  sx: {
    px: {
      xs: 1.25,
      sm: 1.25,
      md: 4,
      lg: 4,
      xl: 0,
    },
    mx: 'auto',
  },
});
