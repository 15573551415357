import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from 'store/nft/actionTypes';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getCategories } from '../actions';
import { setCategories } from '../reducer';

export function* getCategoriesSaga({ type }: ReturnType<typeof getCategories>) {
  yield* put(request(type));

  try {
    const { data } = yield* call(baseApi.getCategories);

    // Don't use camelCase for propertyData to save custom admin properties case
    const categoriesData = data.map((category: any) =>
      category.property_data ? { ...camelize(category), propertyData: category.property_data } : camelize(category),
    );

    yield* put(setCategories(categoriesData));

    yield* put(success(type));
  } catch (e) {
    yield* put(error(type, e));
    logger('getCategoriesSaga', e);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CATEGORIES, getCategoriesSaga);
}
