import { Currency } from 'types/api';
import * as Yup from 'yup';

type Item = {
  index: number;
  value: string | number;
  label: string;
};

type DetailsItem = {
  id: keyof CreateFormProps;
  name: keyof CreateFormProps;
  label: string;
  placeholder: string;
  type?: string;
  textHelper?: string;
};

export type CreateFormProps = {
  isUnlockable: boolean;
  name: string;
  totalSupply: number;
  currency: Currency;
  text: string;
  description: string;
  priceOrBid: number | string;
  endAuction: number;
  creatorRoyalty: number;
  collection: number;
  onSale: boolean;
  media: string;
  format: 'image' | 'video' | 'audio';
  preview: string;
  cover: string;
  coverPreview: string;
  saleType: 'instant' | 'auction' | 'auctionTime';
  details: Record<string, string>;
};

export const props: CreateFormProps = {
  isUnlockable: true,
  name: '',
  totalSupply: 1,
  currency: { image: '', name: '', symbol: '' },
  text: '',
  description: '',
  priceOrBid: '',
  endAuction: 0,
  creatorRoyalty: 0,
  collection: -1,
  onSale: true,
  media: '',
  format: 'image',
  preview: '',
  cover: '',
  coverPreview: '',
  saleType: 'instant',
  details: {},
};

export const validationSchema = Yup.object().shape({
  media: Yup.mixed().required('Media is required'),
  cover: Yup.mixed().required('Cover is required'),
  name: Yup.string().min(2, 'Too short').max(200, 'Too long').required('Name is required'),
  collection: Yup.number().min(0, 'Collection is required').required(),
  details: Yup.mixed().when('collection', {
    is: (collection: number) => collection >= 0,
    then: Yup.object().shape({
      Size: Yup.string().required('Size is required'),
      'Size Type': Yup.string().required('Size type is required'),
      Brand: Yup.string().required('Brand is required'),
      Style: Yup.string().required('Style is required'),
      'Style #': Yup.string().required('Style # is required'),
      Collaboration: Yup.string().required('Collaboration is required'),
    }),
    otherwise: Yup.object().required('Properties are required'),
  }),
  totalSupply: Yup.number()
    .min(1, 'Minimal amount equals to 1')
    .max(100, 'Too much')
    .required('Number of copies is required'),
  description: Yup.string().min(5, 'Too short').max(200, 'Too long').notRequired(),
  text: Yup.string().min(5, 'Too short').max(500, 'Too long').notRequired(),
  priceOrBid: Yup.number().min(0.001, 'Minimal value equals to 0.001').notRequired(),
  currency: Yup.object().shape({
    symbol: Yup.string().notRequired(),
  }),
});

export const saleTypes: Item[] = [
  {
    index: 1,
    value: 'instant',
    label: 'Instant Sale',
  },
  {
    index: 2,
    value: 'auction',
    label: 'Auction',
  },
  {
    index: 3,
    value: 'auctionTime',
    label: 'Time auction',
  },
];

export const auctionTimes: Item[] = [
  {
    index: 0,
    value: 60 * 60 * 24 * 7,
    label: '1 week',
  },
  {
    index: 1,
    value: 60 * 60 * 24 * 14,
    label: '2 weeks',
  },
  {
    index: 2,
    value: 60 * 60 * 24 * 30,
    label: '1 month',
  },
];

export const details: DetailsItem[] = [
  {
    id: 'name',
    name: 'name',
    label: 'Item name',
    placeholder: 'Name',
  },
  {
    id: 'description',
    name: 'description',
    label: 'Description',
    placeholder: 'Text',
  },
  {
    id: 'creatorRoyalty',
    name: 'creatorRoyalty',
    label: 'Royalties',
    placeholder: 'Text',
  },
  {
    id: 'totalSupply',
    name: 'totalSupply',
    type: 'integer',
    label: 'Supply',
    placeholder: 'Number',
    textHelper: 'The number of items that can be minted',
  },
];
