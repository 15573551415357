import { ChangeEventHandler, FC, FocusEventHandler } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { COLOR_GRAY_100 } from 'theme/variables';

export type TextAreaProps = {
  name: string;
  disabled: boolean;
  label: string;
  placeholder: string;
  value: string;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error: string;
};

export const TextArea: FC<TextAreaProps> = ({
  name,
  disabled,
  label,
  error,
  handleChange,
  handleBlur,
  value,
  placeholder,
}) => (
  <Stack spacing={1}>
    <Typography>{label}</Typography>
    <TextField
      name={name}
      variant="filled"
      disabled={disabled}
      multiline
      fullWidth
      rows={4}
      error={!!error}
      helperText={error}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
    <Typography variant="subtitle1" sx={{ color: COLOR_GRAY_100 }}>{`${value.length}/500`}</Typography>
  </Stack>
);
