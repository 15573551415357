import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersAutofill, OrdersHistory, OrdersState } from 'types';
import { CustodyList } from 'types/api';

export const initialHistoryItemState = { total: 0, totalPages: 0, resultsPerPage: 0, results: [] };
const initialState: OrdersState = {
  autofills: {
    claim: {},
    custody: {},
    redeem: {},
  },
  history: {
    claims: [],
    totalClaims: 0,
    custody: [],
    totalCustody: 0,
    redeems: [],
    totalRedeems: 0,
  },
  custodyLists: [],
  locations: [],
  orderId: -1,
};

export const ordersReducer = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateOrdersState: (state, action: PayloadAction<Partial<OrdersState>>) => ({
      ...state,
      ...action.payload,
    }),
    setAutofills: (state, action: PayloadAction<OrdersAutofill>) => ({
      ...state,
      autofills: action.payload,
    }),
    setHistory: (state, action: PayloadAction<OrdersHistory>) => ({
      ...state,
      history: action.payload,
    }),
    clearHistory: (state) => ({
      ...state,
      history: initialState.history,
    }),
    setCustodyLists: (state, action: PayloadAction<CustodyList[]>) => ({
      ...state,
      custodyLists: action.payload,
    }),
    setLocations: (state, action: PayloadAction<string[]>) => ({
      ...state,
      locations: action.payload,
    }),
    setOrderId: (state, action: PayloadAction<number>) => ({
      ...state,
      orderId: action.payload,
    }),
    clearOrderId: (state) => ({
      ...state,
      orderId: -1,
    }),
  },
});

export const {
  updateOrdersState,
  setAutofills,
  setHistory,
  setCustodyLists,
  setLocations,
  setOrderId,
  clearOrderId,
  clearHistory,
} = ordersReducer.actions;

export default ordersReducer.reducer;
