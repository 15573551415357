import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';

export const getMuiToggleButtonOverrides = (theme: Theme): ComponentsOverrides['MuiToggleButton'] => ({
  root: {
    margin: `${theme.spacing(0.5)} !important`,
    height: '34px',
    minWidth: '155px',
    background: COLOR_LIGHT_GRAY,
    border: 'none',
    borderRadius: BORDER_RADIUS_DEFAULT,
    color: COLOR_GRAY_150,
    fontSize: '16px',
    fontWeight: FontWeights.fontWeightSemiBold,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0.75,
      minWidth: 'min-content',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: FontWeights.fontWeightMedium,
      textTransform: 'none',
    },
    '&:hover': {
      background: COLOR_LIGHT_GRAY,
    },
    '&.MuiToggleButton-root.Mui-selected': {
      background: COLOR_BLACK,
      border: 'none',
      borderRadius: BORDER_RADIUS_DEFAULT,
      color: COLOR_WHITE,
    },
  },
});
export const getMuiToggleButtonGroupOverrides = (theme: Theme): ComponentsOverrides['MuiToggleButtonGroup'] => ({
  root: {
    width: '100%',
    height: '44px',
    backgroundColor: COLOR_LIGHT_GRAY,
    borderRadius: BORDER_RADIUS_DEFAULT,
  },
});

export const getMuiToggleButtonDefaultProps = (): ComponentsProps['MuiToggleButton'] => ({});
