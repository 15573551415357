export const notifyText = {
  disconnet: {
    info: 'Disconnect from wallet ',
  },
  approve: {
    success: 'Successful approve',
    error: 'Error approve',
  },
  stake: {
    success: 'Successful stake',
    error: 'Error stake',
  },
  harvest: {
    success: 'Successful harvest',
    error: 'Error harvest',
  },
  withdraw: {
    success: 'Successful withdraw',
    error: 'Error withdraw',
  },
};
