import { ComponentsOverrides, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { BORDER, COLOR_WHITE, COLOR_WHITE_OPACITY_20 } from 'theme/variables';

// TABLE CONTAINER
export const getMuiTableContainer = (theme: Theme): ComponentsOverrides['MuiTableContainer'] => ({
  root: {
    backgroundColor: 'transparent',
  },
});

// TABLE CELL
export const getMuiTableCell = (theme: Theme): ComponentsOverrides['MuiTableCell'] => ({
  root: {
    fontSize: '18px',
    padding: theme.spacing(3, 0),
    border: 'none',
    '&.MuiTableCell-head': {
      fontWeight: FontWeights.fontWeightRegular,
    },
    color: COLOR_WHITE,
  },
  sizeSmall: {
    padding: theme.spacing(0.5, 0),
    fontSize: '16px',
  },
});

// TABLE ROW
export const getMuiTableRow = (theme: Theme): ComponentsOverrides['MuiTableRow'] => ({
  root: {
    borderBottom: BORDER,
    borderColor: COLOR_WHITE_OPACITY_20,
    borderTop: 'none',
  },
});
