import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { useWindowState } from 'hooks';

import { TitleText } from '../../Home';
import { HomeSlider } from '../HomeSlider';

import { NotableNewsCard } from './components';
import { newsItems } from './NotableNews.mocked';

export const NotableNews: FC = () => {
  const [perPage, setPerPage] = useState(4);
  const { width } = useWindowState();

  useEffect(() => {
    if (width < 650) setPerPage(1);
    else if (width < 1050) setPerPage(2);
    else if (width < 1350) setPerPage(3);
    else setPerPage(4);
  }, [width]);

  return (
    <Stack spacing={3}>
      <TitleText>Notable News</TitleText>
      <HomeSlider perPage={perPage} gap={16} padding={{ left: width < 700 ? 0 : 15, right: width < 700 ? 0 : 15 }}>
        {newsItems.map((item, index) => (
          <SplideSlide key={`${index + 1}`}>
            <NotableNewsCard {...item} />
          </SplideSlide>
        ))}
      </HomeSlider>
    </Stack>
  );
};
