import { Discord, Instagram, Mail, Telegram, Twitter } from 'components/Icon';

export const socialsItems = [
  {
    id: 1,
    Icon: Telegram,
    link: 'https://t.me/metaz_io',
  },
  {
    id: 2,
    Icon: Twitter,
    link: 'https://twitter.com/metaz_io',
  },
  {
    id: 3,
    Icon: Discord,
    link: 'https://discord.gg/RGxZGpwTWQ',
  },
  {
    id: 4,
    Icon: Instagram,
    link: 'https://www.instagram.com/metaz_io/',
  },
  {
    id: 5,
    Icon: Mail,
    link: 'mailto:metaz@metaz.io',
  },
];
