import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Stack } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { ContractsNames } from 'services/WalletService/config';
import { setActiveModal } from 'store/modals/reducer';
import { buyNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_WHITE } from 'theme/variables';
import { Modals, RequestStatus } from 'types';

type BuyNftButtonProps = {
  currentBalance: number;
};

export const BuyNftButton: FC<BuyNftButtonProps> = ({ currentBalance }) => {
  const { standart, id, price, owners, currency, network } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { address, network: userNetwork } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.BUY]: buyRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const isBuyLoading = useMemo(() => buyRequestStatus === RequestStatus.REQUEST, [buyRequestStatus]);

  const handleBuy = () => {
    if (network?.name && network.name === userNetwork) {
      if (
        (standart === 'ERC721' || (owners?.length === 1 && Number(owners[0].sellingQuantity) === 1)) &&
        id &&
        price &&
        currency?.symbol &&
        owners
      ) {
        dispatch(
          buyNft({
            tokenAmount: standart === 'ERC721' ? 0 : 1,
            price,
            sellerId: owners[0]?.url || '',
            currencySymbol: (currency.symbol !== 'eth' && currency.symbol !== 'matic'
              ? currency.symbol.toUpperCase()
              : '') as ContractsNames,
            id,
            web3Provider: walletService.Web3(),
          }),
        );
        return;
      }
      if (standart === 'ERC1155') {
        dispatch(setActiveModal({ activeModal: Modals.BuyMultiple, open: true }));
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  return (
    <Stack direction="row" spacing={{ xs: 1, md: 3, xl: 7 }}>
      <Button
        variant="contained"
        onClick={handleBuy}
        disabled={!address || !id || isBuyLoading || (price ? +price : 0) > currentBalance}
        sx={{
          fontWeight: FontWeights.fontWeightBold,
          minHeight: '48px',
          height: '48px',
          width: '100%',
          textTransform: 'capitalize',
        }}
      >
        {isBuyLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'buy now'}
      </Button>
      <Button
        variant="contained"
        disabled
        sx={{
          width: '100%',
          minHeight: '48px',
          height: '48px',
          textTransform: 'capitalize',
        }}
      >
        make offer
      </Button>
    </Stack>
  );
};
