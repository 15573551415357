import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SuccessBadge: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: '56px', height: '56px', fill: 'none', ...sx }} viewBox="0 0 56 56">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0013 4.6665C15.1143 4.6665 4.66797 15.1128 4.66797 27.9998C4.66797 40.8845 15.1143 51.3332 28.0013 51.3332C40.8883 51.3332 51.3346 40.8845 51.3346 27.9998C51.3346 15.1128 40.8883 4.6665 28.0013 4.6665Z"
      fill="currentColor"
    />
    <mask id="mask0_876_25271" maskUnits="userSpaceOnUse" x="4" y="4" width="48" height="48">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0013 4.6665C15.1143 4.6665 4.66797 15.1128 4.66797 27.9998C4.66797 40.8845 15.1143 51.3332 28.0013 51.3332C40.8883 51.3332 51.3346 40.8845 51.3346 27.9998C51.3346 15.1128 40.8883 4.6665 28.0013 4.6665Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_876_25271)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1888 22.4125L28.5301 36.4125C28.0915 36.9888 27.4125 37.3295 26.6891 37.3342H26.6728C25.9565 37.3342 25.2798 37.0028 24.8365 36.4382L19.1618 29.1885C18.3685 28.1758 18.5458 26.7082 19.5608 25.9148C20.5735 25.1192 22.0435 25.2965 22.8368 26.3138L26.6471 31.1812L35.4765 19.5868C36.2558 18.5625 37.7188 18.3618 38.7478 19.1435C39.7721 19.9252 39.9705 21.3882 39.1888 22.4125"
        fill="#FFFFFF"
      />
    </g>
  </SvgIcon>
);
