import { FC } from 'react';
import { Box, IconButton, Menu, Stack } from '@mui/material';
import { LogoSmall } from 'assets/img';
import { Burger, MenuClose } from 'components';
import { Footer } from 'modules/layout/containers/Footer';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';
import { Modals } from 'types';

import { ConnectWalletButton } from '../ConnectWalletButton';
import { Nav } from '../Nav';
import { Profile } from '../Profile';
import { SearchNfts } from '../SearchNfts';

type MobileMenuProps = {
  address?: string;
  isMenuOpen: boolean;
  handleIsOpenMenu: () => void;
  handleOpenModal: (type: Modals) => void;
  handleNavigateToHome: () => void;
};

export const MobileMenu: FC<MobileMenuProps> = ({
  isMenuOpen,
  handleIsOpenMenu,
  address,
  handleOpenModal,
  handleNavigateToHome,
}) => {
  return (
    <>
      <IconButton onClick={handleIsOpenMenu}>
        {isMenuOpen ? <MenuClose sx={{ color: COLOR_BLACK }} /> : <Burger sx={{ color: COLOR_BLACK }} />}
      </IconButton>
      {isMenuOpen && (
        <Menu
          open={isMenuOpen}
          id="menu"
          sx={{
            '.MuiPaper-root': {
              top: '0 !important',
              left: '0 !important',
              px: 3,
              maxWidth: '100vw',
              width: '100%',
              maxHeight: '100vh',
              height: '100%',
              background: `${COLOR_WHITE} !important`,
              boxShadow: 'none',

              '.MuiList-root': { height: '100%' },
            },
          }}
        >
          <IconButton onClick={handleIsOpenMenu} sx={{ position: 'absolute', left: -10, top: 22 }}>
            {isMenuOpen ? <MenuClose sx={{ color: COLOR_BLACK }} /> : <Burger sx={{ color: COLOR_BLACK }} />}
          </IconButton>
          <Stack mb={3} height={44} alignItems="center" justifyContent="center" spacing={3}>
            <IconButton sx={{ cursor: 'pointer' }} onClick={handleNavigateToHome}>
              <LogoSmall />
            </IconButton>
          </Stack>
          <Stack height="92%" justifyContent="space-between">
            <Stack spacing={2.5}>
              <SearchNfts isFullWidth onItemClick={handleIsOpenMenu} />
              {!address?.length ? (
                <ConnectWalletButton onClick={() => handleOpenModal(Modals.ConnectWallet)} />
              ) : (
                <Profile onOpenModal={handleOpenModal} handleIsOpenMobileMenu={handleIsOpenMenu} />
              )}
              <Nav closeMenu={handleIsOpenMenu} />
            </Stack>
            <Box mx={-3}>
              <Footer />
            </Box>
          </Stack>
        </Menu>
      )}
    </>
  );
};
