import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Notice } from 'components/Notice';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector, useWindowState } from 'hooks';
import apiActions from 'store/api/actions';
import { custodyListed, custodyUnlisted, getCustodyLists } from 'store/orders/actions';
import ordersActionTypes from 'store/orders/actionTypes';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_10, COLOR_GRAY_150, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { CustodySize } from 'types/api';

import { ListSneakersFormProps, setInitialValues, sizesMock, SizeTypes, validationSchema } from './CustodyForm.helpers';

const sizeOptions = [
  {
    label: 'Men',
    value: SizeTypes.Men,
  },
  {
    label: 'Women',
    value: SizeTypes.Women,
  },
  {
    label: 'GS',
    value: SizeTypes.GS,
  },
  {
    label: 'Toddler/Infant',
    value: SizeTypes.ToddlerInfant,
  },
];

export const CustodyForm: FC = () => {
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowState();

  const [searchInput, setSearchInput] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [sizeTypes, setSizeTypes] = useState<CustodySize[]>([]);
  const [sizes, setSizes] = useState<CustodySize['sizes']>([]);
  const [locations, setLocations] = useState<{ name: string }[]>([]);

  const { email } = useShallowSelector(userSelector.getUser);
  const { custodyLists, locations: allLocationsStrings } = useShallowSelector(ordersSelector.getOrders);
  const {
    [ordersActionTypes.CUSTODY_LISTED]: custodyListedRequestStatus,
    [ordersActionTypes.CUSTODY_UNLISTED]: custodyUnlistedRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const availableSizes = useMemo(() => sizeTypes.map(({ name }) => name), [sizeTypes]);

  const allLocations = useMemo(
    () =>
      allLocationsStrings
        ?.map((location) => {
          return { name: location };
        })
        .reverse() || [],
    [allLocationsStrings],
  );
  const getAllSizes = useCallback(
    (sizeTypeValue: SizeTypes) =>
      sizesMock[sizeTypeValue].map((sizeName) => {
        return { name: sizeName, locations: allLocations };
      }),
    [allLocations],
  );

  const searchedStyles = useMemo(
    () => custodyLists.filter((item) => item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())),
    [custodyLists, searchInput],
  );

  const isCustodyListedLoading = useMemo(
    () => custodyListedRequestStatus === RequestStatus.REQUEST,
    [custodyListedRequestStatus],
  );
  const isCustodyUnlistedLoading = useMemo(
    () => custodyUnlistedRequestStatus === RequestStatus.REQUEST,
    [custodyUnlistedRequestStatus],
  );

  const handleSubmit = (
    values: ListSneakersFormProps,
    resetForm: () => void,
    setSubmitting: (value: boolean) => void,
  ) => {
    const isLocationExist = locations.find(({ name }) => name === values.location);
    const custodyList = custodyLists.find(({ id }) => id === values.listId);
    if (values.customListId) {
      dispatch(
        custodyUnlisted({
          data: {
            style: values.customListId,
            name: values.name,
            sizeType: values.sizeType,
            size: values.size,
            email: values.email,
            location: values.location,
          },
          setSubmitting,
          resetForm,
        }),
      );
    } else
      dispatch(
        custodyListed({
          data: {
            listId: +values.listId,
            email: values.email,
            sizeType: values.sizeType,
            size: values.size,
            location: values.location,
          },
          setSubmitting,
          resetForm,
        }),
      );
  };

  useEffect(() => {
    dispatch(getCustodyLists());
    return () => {
      dispatch(apiActions.reset(ordersActionTypes.CUSTODY_LISTED));
      dispatch(apiActions.reset(ordersActionTypes.CUSTODY_UNLISTED));
    };
  }, [dispatch]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      initialValues={setInitialValues({ email: email || '' })}
      onSubmit={(values, { resetForm, setSubmitting }) => handleSubmit(values, resetForm, setSubmitting)}
    >
      {({ touched, errors, handleChange, handleBlur, values, setFieldValue, isValid }) => (
        <Form>
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h4" className="lg" mb={2}>
              Style number
            </Typography>
            <Box
              maxHeight="320px"
              pt={2}
              sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                scrollbarColor: `${COLOR_GRAY_150} transparent`,
                '&::-webkit-scrollbar': {
                  backgroundColor: 'transparent',
                  width: 4,
                },
                '&::-webkit-scrollbar-thumb': {
                  width: 1,
                  borderRadius: BORDER_RADIUS_DEFAULT,
                  background: COLOR_GRAY_150,
                },
              }}
            >
              <Select
                value={values.listId}
                name="listId"
                placeholder="Choose from the list or Type style number"
                MenuProps={{ autoFocus: false }}
                onChange={(e) => {
                  setFieldValue('listId', e.target.value);
                  setFieldValue('sizeType', '');
                  setFieldValue('size', '');
                  setFieldValue('location', '');

                  setSizeTypes(custodyLists.find(({ id }) => id === +e.target.value)?.sizeTypes || []);
                  setSizes([]);
                  setLocations([]);
                }}
                onClose={() => setSearchInput('')}
                disabled={!!values.customListId}
                sx={{ width: '100%', maxWidth: 600, marginBottom: 2 }}
              >
                <ListSubheader>
                  <TextField
                    autoFocus
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Search the style number of your sneakers"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== 'Escape') {
                        e.stopPropagation();
                      }
                    }}
                    fullWidth
                    sx={{
                      '.MuiInputBase-root': {
                        mt: 0.5,
                        px: 1,
                        height: 40,
                        fontSize: { xs: 14, sm: 16 },
                      },
                    }}
                  />
                </ListSubheader>
                <MenuItem key="not-found" value={0} sx={{ marginBottom: 1 }}>
                  There is no style number I want to select
                </MenuItem>
                {searchedStyles.map(({ name, id }) => (
                  <MenuItem key={id} value={id} sx={{ marginBottom: 1 }}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Field
                name="customListId"
                render={({ form: { isSubmitting } }: FieldProps) => (
                  <TextField
                    variant="filled"
                    placeholder="Type the style number of your sneakers, if not found on the list above."
                    autoComplete="off"
                    inputProps={{
                      maxLength: 100,
                    }}
                    name="customListId"
                    value={values.customListId}
                    disabled={isSubmitting || values.listId > 0}
                    onChange={(e) => {
                      setFieldValue('listId', 0);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={touched.customListId && !!errors.customListId && values.listId === 0}
                    helperText={touched.customListId && errors.customListId}
                    multiline={windowWidth < 650}
                    sx={{ width: '100%', maxWidth: 600, input: { p: 0 } }}
                  />
                )}
              />
            </Box>
          </Box>
          {values.customListId && (
            <Stack spacing={3} mb={3}>
              <Typography variant="h4" className="lg">
                Sneakers Name
              </Typography>
              <Field
                name="name"
                render={({ form: { isSubmitting } }: FieldProps) => (
                  <TextField
                    variant="filled"
                    placeholder="Name"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 100,
                    }}
                    name="name"
                    value={values.name}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ width: '100%', maxWidth: 600, input: { p: 0 } }}
                  />
                )}
              />
            </Stack>
          )}
          <Stack spacing={2} sx={{ marginBottom: 4 }}>
            <Typography variant="h4" className="lg">
              Size Type
            </Typography>
            <Stack direction="row">
              {sizeTypes.length
                ? sizeTypes.map((sizeType) => (
                    <FormControlLabel
                      key={sizeType.name}
                      control={
                        <Checkbox
                          name="sizeType"
                          onClick={() => {
                            setFieldValue('sizeType', sizeType.name);
                            // setSizes(sizeType.sizes || []);
                            setSizes(getAllSizes(sizeType.name as unknown as SizeTypes) || []);
                            setLocations([]);
                          }}
                          disabled={!availableSizes.includes(sizeType.name)}
                          checked={values.sizeType === sizeType.name}
                        />
                      }
                      label={sizeType.name}
                      sx={{ pt: 2, ml: 0.5, fontWeight: FontWeights.fontWeightSemiBold }}
                    />
                  ))
                : sizeOptions.map((sizeOption) => (
                    <FormControlLabel
                      key={sizeOption.label}
                      control={
                        <Checkbox
                          name="sizeType"
                          onClick={() => {
                            setFieldValue('sizeType', sizeOption.value);
                            setSizes(getAllSizes(sizeOption.value) || []);
                          }}
                          checked={values.sizeType === sizeOption.value}
                        />
                      }
                      label={sizeOption.label}
                      sx={{ pt: 2, ml: 0.5, fontWeight: FontWeights.fontWeightSemiBold }}
                    />
                  ))}
            </Stack>
          </Stack>
          <Stack spacing={3} sx={{ marginBottom: 4 }}>
            <Typography variant="h4" className="lg">
              Size
            </Typography>
            <Select
              value={values.size}
              placeholder="Choose size type"
              onChange={(e) => {
                setFieldValue('size', e.target.value);
                setLocations(sizes?.find(({ name }) => name === e.target.value)?.locations || []);
              }}
              sx={{ width: '100%', maxWidth: 600 }}
            >
              {!!sizes?.length &&
                sizes.map((size) => (
                  <MenuItem key={size.name} value={size.name} sx={{ marginBottom: 1 }}>
                    {size.name}
                  </MenuItem>
                ))}
            </Select>
            <Typography sx={{ color: COLOR_GRAY_150 }}>
              If you can&apos;t find your size, please select &quot;There is no style number I want to select&quot; in
              Style number selector
            </Typography>
          </Stack>
          {!!values.size && (
            <Stack spacing={2} sx={{ marginBottom: 4 }}>
              <Typography variant="h4" className="lg">
                Locations
              </Typography>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  maxHeight: '154px',
                  maxWidth: '600px',
                  overflowY: 'scroll',
                  scrollbarWidth: 'thin',
                  scrollbarColor: `${COLOR_GRAY_10} transparent`,
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: 4,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    width: 1,
                    borderRadius: BORDER_RADIUS_DEFAULT,
                    background: COLOR_GRAY_10,
                  },
                }}
              >
                {allLocations.map(({ name: locationName }) => (
                  <FormControlLabel
                    key={locationName}
                    control={
                      <Checkbox
                        name="location"
                        onClick={() => {
                          setFieldValue('location', locationName);
                        }}
                        checked={values.location === locationName}
                      />
                    }
                    label={locationName}
                    sx={{ pt: 2, ml: 0.5, fontWeight: FontWeights.fontWeightSemiBold }}
                  />
                ))}
              </FormGroup>
            </Stack>
          )}
          <Stack spacing={3} mb={3}>
            <Typography variant="h4" className="lg">
              Email address
            </Typography>
            <Field
              name="email"
              render={({ form: { isSubmitting } }: FieldProps) => (
                <TextField
                  variant="filled"
                  placeholder="Email"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 100,
                  }}
                  name="email"
                  value={values.email}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ width: '100%', maxWidth: 600, input: { p: 0 } }}
                />
              )}
            />
          </Stack>
          <Notice
            checked={isAgree}
            onClick={() => setIsAgree((prev) => !prev)}
            containerProps={{ sx: { marginBottom: { xs: 4, md: 8 } } }}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={!isValid || isCustodyListedLoading || !isAgree}
            sx={{
              display: 'block',
              marginX: 'auto',
              width: { xs: '100%', sm: 356 },
              maxHeight: '64px',
              height: 'fit-content',
              minHeight: '56px',
              fontWeight: FontWeights.fontWeightBold,
              py: 1.25,
              textTransform: 'capitalize',
            }}
          >
            {isCustodyListedLoading || isCustodyUnlistedLoading ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
              'Submit request'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
