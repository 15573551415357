import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { FontFamilies } from 'theme/Typography';
import { SocialLinksValues } from 'types';

import { SocialLinksBlock } from './components/SocialLinksBlock';

interface ProfileProps {
  bio: string;
  socialLinks: {
    [key in SocialLinksValues]: string | undefined;
  };
}

export const ProfileInfo: FC<ProfileProps> = ({ bio, socialLinks }) => {
  return (
    <Grid item container xs={12} sm={8.5} md={9} lg={10} mt={0} display="block">
      <Grid container justifyContent="space-between" direction={{ xs: 'column', md: 'row' }} spacing={4} width="100%">
        <Grid item xs={12} lg={7}>
          <Stack spacing={1.5} minWidth="300px" maxWidth="607px">
            <Typography variant="h3" fontFamily={FontFamilies.primary}>
              Profile Information
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{bio}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Stack alignItems={{ xs: 'flex-start', lg: 'flex-end' }}>
            <Stack spacing={1.5}>
              <SocialLinksBlock socialLinks={socialLinks} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
