import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getSelfCollections } from '../actions';
import actionTypes from '../actionTypes';
import { setCollections } from '../reducer';

export function* getSelfCollectionsSaga({ type, payload }: ReturnType<typeof getSelfCollections>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.getSelfCollections, payload);

    // Don't use camelCase for propertyData to save custom admin properties case
    const collectionsData = data.results.map((collection: any) =>
      collection.property_data
        ? { ...camelize(collection), propertyData: collection.property_data }
        : camelize(collection),
    );
    yield put(setCollections(collectionsData));
    yield put(success(type));
  } catch (err) {
    logger('getSelfCollections', err, 'error');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_SELF_COLLECTIONS, getSelfCollectionsSaga);
}
