import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getHistory } from '../actions';
import actionTypes from '../actionTypes';
import { setHistory } from '../reducer';

export function* getHistorySaga({
  type,
  payload: { type: orderType, page, itemsPerPage },
}: ReturnType<typeof getHistory>) {
  yield put(request(type));

  try {
    if (orderType === 'redeem') {
      const { data } = yield call(baseApi.getHistoryRedeem, { page: page || 1, itemsPerPage });
      yield put(
        setHistory({
          claims: [],
          totalClaims: 0,
          custody: [],
          totalCustody: 0,
          redeems: camelize(data.results),
          totalRedeems: data.total,
        }),
      );
    }
    if (orderType === 'claim') {
      const { data } = yield call(baseApi.getHistoryClaim, { page: page || 1, itemsPerPage });
      yield put(
        setHistory({
          claims: camelize(data.results),
          totalClaims: data.total,
          custody: [],
          totalCustody: 0,
          redeems: [],
          totalRedeems: 0,
        }),
      );
    }
    if (orderType === 'custody') {
      const { data } = yield call(baseApi.getHistoryCustody, { page: page || 1, itemsPerPage });
      yield put(
        setHistory({
          claims: [],
          totalClaims: 0,
          custody: camelize(data.results),
          totalCustody: data.total,
          redeems: [],
          totalRedeems: 0,
        }),
      );
    }
    yield put(success(type));
  } catch (err) {
    logger('Get history', err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_HISTORY, getHistorySaga);
}
