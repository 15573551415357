import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { transferNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

import { QuantityCounter } from '../QuantityCounter';

export const TransferNft: FC = () => {
  const [sendToAddress, setSendToAddress] = useState('');
  const [quantity, setQuantity] = useState('1');
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const { id, standart, network, owners, isPending } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { address, network: userNetwork } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.TRANSFER]: transferRequestStatus } = useShallowSelector(uiSelector.getUI);

  const isTransferLoading = useMemo(() => transferRequestStatus === RequestStatus.REQUEST, [transferRequestStatus]);

  const userOwner = useMemo(
    () => (owners && owners.find((owner) => owner?.address === address)) || undefined,
    [address, owners],
  );

  const handleChangeSendToAddress = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSendToAddress(e.target.value);
  };

  const handleTransfer = () => {
    if (network?.name && network.name === userNetwork) {
      if (id && sendToAddress) {
        dispatch(
          transferNft({
            id,
            address: sendToAddress,
            amount: quantity,
            web3Provider: walletService.Web3(),
          }),
        );
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Typography>Send to address</Typography>
        <TextField
          variant="filled"
          placeholder="Input address"
          value={sendToAddress}
          error={sendToAddress !== '' && sendToAddress.length < 10}
          onChange={handleChangeSendToAddress}
          sx={{ maxWidth: '363px' }}
        />
      </Stack>
      <Stack direction="row" spacing={4} alignItems="flex-end">
        {standart === 'ERC1155' && (
          <QuantityCounter
            quantity={quantity}
            setQuantity={setQuantity}
            maxQuantity={+(userOwner?.quantity || 1) - +(userOwner?.redeemAmount || 0)}
          />
        )}
        <Box>
          <Button
            variant="contained"
            disabled={
              isPending ||
              sendToAddress.length < 10 ||
              !address ||
              isTransferLoading ||
              +(userOwner?.quantity || 1) - +(userOwner?.sellingQuantity || 0) - +(userOwner?.redeemAmount || 0) === 0
            }
            onClick={handleTransfer}
            sx={{ width: '108px', height: '44px', marginBottom: 2 }}
          >
            {isTransferLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'Send'}
          </Button>
        </Box>
      </Stack>
    </>
  );
};
