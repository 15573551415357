import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MenuClose: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: 24, height: 24, fill: 'none', ...sx }} viewBox="0 0 24 24">
    <path d="M6 18L18.7279 5.27208" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M10.5898 9.58936L18.7286 17.7282" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 5L8.11425 7.11425" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>
);
