import { BreakpointsOptions, ComponentsOverrides, ComponentsProps } from '@mui/material';

export const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 700,
    md: 1100,
    lg: 1440,
    xl: 1850, // 1920
    xxl: 2560,
    xxxl: 3840,
  },
};

export const getMuiGridOverrides = (): ComponentsOverrides['MuiGrid'] => ({});

export const getMuiGridDefaultProps = (): ComponentsProps['MuiGrid'] => ({
  xs: 'auto',
  lineHeight: 0,
});
