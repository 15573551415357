// ===== Colors
export const COLOR_GREEN = '#010101';
export const COLOR_GREEN_OPACITY = 'rgba(0, 0, 0, 0.25)';
export const COLOR_PURPLE = '#A660FF';
export const COLOR_LIGHT_GRAY = '#F4F4F4';
export const COLOR_LIGHT = '#E4E4E4';
export const COLOR_GRAY_10 = '#C8C8C8';
export const COLOR_GRAY_50 = '#A1A1A9';
export const COLOR_GRAY_100 = '#828282';
export const COLOR_GRAY_150 = '#777777';
export const COLOR_GRAY_200 = '#363636';
export const COLOR_GRAY_300 = '#272727';
export const COLOR_GRAY_400 = '#232323';
export const COLOR_GRAY_400_OPACITY = 'rgba(35, 35, 35, 0.6)';
export const COLOR_GRAY_700 = '#151515';
export const COLOR_BLACK = '#010101';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_RED_100 = '#FB5050';
export const COLOR_RED_200 = '#FF2121';
export const COLOR_RED_300 = '#C30808';
export const COLOR_YELLOW = '#FFEC40';
export const COLOR_WHITE_OPACITY_20 = 'rgba(255, 255, 255, 0.2)';
export const COLOR_WHITE_OPACITY_70 = 'rgba(255, 255, 255, 0.7)';
export const COLOR_BLUE_OPACITY_40 = 'rgba(151, 192, 220, 0.4)';

// ===== Borders
export const BORDER = ' 1px solid';
export const BORDER_GREEN = ' 1px solid #010101';
export const BORDER_GRAY = '1px solid rgba(255, 255, 255, 0.2)';
export const BORDER_WHITE = '1px solid #FFF';
export const BORDER_RED = '1px solid #FB5050';

// ===== Border-Radius
export const BORDER_RADIUS_SMALL = '4px';
export const BORDER_RADIUS_DEFAULT = '8px';
export const BORDER_RADIUS_LARGE = '16px';

// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.3s ease-in-out';

// ===== Button
export const BORDER_BUTTON = ' 2px solid';
export const BORDER_BUTTON_GREEN = ' 2px solid #010101';

// ===== Backgrounds
export const BG_MAIN = '#ffffff';
export const BG_MODAL = '#232323';
export const BG_MODAL_GRAY = 'rgba(76, 76, 76, 0.6)';
export const BG_GREEN = 'rgba(0, 0, 0, 0.15)';

// ===== Colors
export const COLOR_TEXT_BLACK = 'rgba(0, 0, 0, 1)';
export const COLOR_TEXT_WHITE = 'rgba(255, 255, 255, 1)';

// ===== Box-Shadow
export const SHADOW_MODAL_DEFAULT = '5px 8px 18px 0px rgba(0, 0, 0, 0.1)';

export const COLOR_ANTI_FLASH_WHITE = '#F3F3F3';
export const COLOR_BLUEBONNET = '#001EF4';
export const COLOR_LIGHT_SILVER = '#D9D9D9';
