import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger } from 'utils';

import { burnNft } from '../actions';
import actionTypes from '../actionTypes';

import { getNftDataSaga } from './getNftData';

export function* burnSaga({ type, payload: { id, amount, web3Provider } }: ReturnType<typeof burnNft>) {
  yield put(request(type));

  const address: string = yield select(userSelector.getProp('address'));

  try {
    const { data } = yield call(baseApi.burn, id, { amount });
    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    yield call(web3Provider.eth.sendTransaction, {
      ...data.initial_tx,
      from: address,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });
    yield call(getNftDataSaga, {
      type: actionTypes.GET_NFT_DATA,
      payload: { id },
    });
    toast.success('You have successfully burnt NFT');
    yield put(success(type));
  } catch (err) {
    logger('Burn NFT', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BURN, burnSaga);
}
