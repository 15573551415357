import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Bid } from 'components';
import { useShallowSelector } from 'hooks';
import moment from 'moment';
import { getNetwork } from 'store/network/actions';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';
import { Currencies } from 'types';

import {
  AcceptBid,
  BuyNftButton,
  NftPriceOrBid,
  NftToggleButton,
  PlaceBid,
  RemoveFromSaleButton,
  SetOnSaleNft,
  TransferNft,
  UpdateSale,
} from '..';

export const NftActionMenu: FC = () => {
  const [activeToggle, setActiveToggle] = useState<'put-on-sale' | 'transfer'>('put-on-sale');
  const {
    isSelling,
    isAucSelling,
    isTimedAucSelling,
    owners,
    sellers,
    currency,
    startAuction,
    endAuction,
    bids,
    standart,
  } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { address, network: chainNetwork, balance } = useShallowSelector(userSelector.getUser);
  const dispatch = useDispatch();

  const currentBalance = useMemo(
    () => balance[chainNetwork][currency?.symbol?.toUpperCase() as Currencies] || 0,
    [balance, chainNetwork, currency],
  );

  const userOwner = useMemo(() => owners && owners.find((owner) => owner?.address === address), [address, owners]);
  const userSeller = useMemo(
    () => sellers && sellers.find((seller) => seller?.address === address),
    [address, sellers],
  );
  const otherSellers = useMemo(
    () => (sellers && sellers.filter((seller) => seller?.address !== address)) || [],
    [address, sellers],
  );
  const isWaitingAucStart = startAuction > +new BigNumber(new Date().getTime()).div(1000).toFixed(0, 1);
  useEffect(() => {
    dispatch(getNetwork({ network: chainNetwork }));
  }, [chainNetwork, dispatch]);

  return (
    <Stack mt={3} spacing={3} pb={4}>
      {!!userOwner?.quantity &&
      ((!isSelling && !isAucSelling && !isTimedAucSelling && !isWaitingAucStart) ||
        (!userSeller && standart === 'ERC1155')) ? (
        <>
          <NftToggleButton activeToggle={activeToggle} setActiveToggle={setActiveToggle} />
          {activeToggle === 'transfer' ? <TransferNft /> : <SetOnSaleNft />}
        </>
      ) : (
        ''
      )}
      {isWaitingAucStart ? (
        <Stack direction="row" spacing={1.25}>
          <Bid sx={{ color: COLOR_BLACK }} />
          <Typography color={COLOR_GRAY_150}>{`Sale starts ${moment(+(startAuction || 0) * 1000).format(
            'MMMM Do, YYYY',
          )} at ${moment(+(startAuction || 0) * 1000).format('h:mm A')}`}</Typography>
        </Stack>
      ) : (
        ''
      )}
      {endAuction && isTimedAucSelling ? (
        <Stack direction="row" spacing={1.25}>
          <Bid sx={{ color: COLOR_BLACK }} />
          <Typography color={COLOR_GRAY_150}>{`Sale ends ${moment(+(endAuction || 0) * 1000).format(
            'MMMM Do, YYYY',
          )} at ${moment(+(endAuction || 0) * 1000).format('h:mm A')}`}</Typography>
        </Stack>
      ) : (
        ''
      )}
      {isSelling && <NftPriceOrBid />}
      {(!userOwner && isSelling) || (userOwner && otherSellers.length) ? (
        <BuyNftButton currentBalance={+currentBalance} />
      ) : (
        ''
      )}
      {userOwner && isSelling && userSeller ? <UpdateSale userOwner={userOwner} /> : ''}
      {!userOwner && (isAucSelling || isTimedAucSelling) ? <PlaceBid currentBalance={+currentBalance} /> : ''}
      {userOwner && (isAucSelling || isTimedAucSelling) && bids?.length ? <AcceptBid /> : ''}
      {userOwner &&
      ((isSelling && userSeller) || isAucSelling || isTimedAucSelling || isWaitingAucStart) &&
      !bids?.length ? (
        <RemoveFromSaleButton />
      ) : (
        ''
      )}
    </Stack>
  );
};
