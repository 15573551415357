import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const NotifyBell: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: 24, height: 24, fill: 'none', ...sx }} viewBox="0 0 24 24">
    <path
      d="M11.75 2.5C15.2018 2.5 18 5.29821 18 8.75V18H5.5V8.75C5.5 5.29821 8.29821 2.5 11.75 2.5ZM19.5 18V8.75C19.5 4.46979 16.0302 1 11.75 1C7.46979 1 4 4.46979 4 8.75V18H1.75C1.33579 18 1 18.3358 1 18.75C1 19.1642 1.33579 19.5 1.75 19.5H21.75C22.1642 19.5 22.5 19.1642 22.5 18.75C22.5 18.3358 22.1642 18 21.75 18H19.5Z"
      fill="currentColor"
    />
    <path
      d="M8.5 18.75C8.5 18.3358 8.83579 18 9.25 18H14.25C14.6642 18 15 18.3358 15 18.75V19.25C15 21.0449 13.5449 22.5 11.75 22.5C9.95509 22.5 8.5 21.0449 8.5 19.25V18.75ZM10.0177 19.5C10.139 20.3481 10.8684 21 11.75 21C12.6316 21 13.361 20.3481 13.4823 19.5H10.0177Z"
      fill="currentColor"
    />
  </SvgIcon>
);
