import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Activity } from 'types/api';
import { camelize, logger } from 'utils';

import { getNotifications } from '../actions';
import actionTypes from '../actionTypes';
import { setIsNewNotifications, setNotifications } from '../reducer';

export function* getNotificationsSaga({ type }: ReturnType<typeof getNotifications>) {
  yield* put(request(type));
  try {
    const { data } = yield* call(baseApi.getNotifications);
    const result = camelize(data);
    yield* put(setNotifications(result));
    const isNewNotifications = result.find((activity: Activity) => activity.isViewed === false);
    yield put(setIsNewNotifications(!!isNewNotifications));
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getNotifications', err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_NOTIFICATIONS, getNotificationsSaga);
}
