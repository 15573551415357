import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import { call, put, takeLatest } from 'redux-saga/effects';
import { contractsConfig } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import networkActionTypes from 'store/network/actionTypes';
import { getNetworkSaga } from 'store/network/sagas/getNetwork';
import networkSelector from 'store/network/selectors';
import userSelector from 'store/user/selectors';
import { select } from 'typed-redux-saga';
import { Chains, IChainType } from 'types';
import { camelize } from 'utils/camelize';
import { logger } from 'utils/logger';

import { getPolygonBalance, updateUserInfo } from '../actions';
import actionTypes from '../actionTypes';
import { disconnectWalletState, updateUserState } from '../reducer';

export function* updateUserInfoSaga({ type, payload: { web3Provider, network } }: ReturnType<typeof updateUserInfo>) {
  yield put(request(type));
  const { contracts } = contractsConfig;

  const chainType: IChainType = yield select(userSelector.getProp('chainType'));

  try {
    const { data } = yield call(baseApi.getSelfInfo);
    yield put(updateUserState({ ...camelize(data), network }));

    if (network === Chains.Polygon) yield put(getPolygonBalance({ web3Provider }));

    yield call(getNetworkSaga, {
      type: networkActionTypes.GET_NETWORK,
      payload: { network },
    });

    const { fabric721Address, fabric1155Address }: { fabric721Address: string; fabric1155Address: string } =
      yield select(networkSelector.getNetwork);

    const fabric721Abi = contracts.ERC721FABRIC[chainType].abi[network];
    const fabric1155Abi = contracts.ERC1155FABRIC[chainType].abi[network];

    const erc721FabricContract: ContractWeb3 = yield new web3Provider.eth.Contract(fabric721Abi, fabric721Address);
    const erc1155FabricContract: ContractWeb3 = yield new web3Provider.eth.Contract(fabric1155Abi, fabric1155Address);

    const erc721FabricOwnerAddress: string = yield call(erc721FabricContract.methods.owner().call);
    const erc1155FabricOwnerAddress: string = yield call(erc1155FabricContract.methods.owner().call);

    yield put(
      updateUserState({
        isSingleFabricOwner: erc721FabricOwnerAddress.toLowerCase() === data?.address?.toLowerCase(),
        isMultipleFabricOwner: erc1155FabricOwnerAddress.toLowerCase() === data?.address?.toLowerCase(),
      }),
    );

    yield put(success(type));
  } catch (err) {
    logger('Update user info saga', err, 'error');
    yield put(error(type, err));
    yield put(disconnectWalletState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_USER_INFO, updateUserInfoSaga);
}
