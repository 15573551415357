import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { Notice } from 'components/Notice';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector } from 'hooks';
import { getAutofill, getHistory, sendDeliveryInfo } from 'store/orders/actions';
import actionTypes from 'store/orders/actionTypes';
import { clearHistory, clearOrderId } from 'store/orders/reducer';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { Custody, State } from 'types/api';

import { DeliveryFormProps, initialValues, validationSchema } from './Delivery.helpers';

const WhiteTitle = styled(Typography)({
  color: COLOR_BLACK,
  display: 'inline',
  textAlign: 'center',
});

export const Delivery: FC = () => {
  const [isAgree, setIsAgree] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { custodyId } = useParams();
  const ordersHistory = useShallowSelector(ordersSelector.getProp('history'));
  const { custody: custodyAutofill } = useShallowSelector(ordersSelector.getProp('autofills'));
  const { address: walletAddress, email } = useShallowSelector(userSelector.getUser);
  const [custodyInfo, setCustodyInfo] = useState<Custody | undefined>({ state: State.Approved });

  const { [actionTypes.SEND_DELIVERY_INFO]: sendDeliveryInfoSRequestStatus } = useShallowSelector(uiSelector.getUI);

  const isSendDeliveryInfoLoading = useMemo(
    () => sendDeliveryInfoSRequestStatus === RequestStatus.REQUEST,
    [sendDeliveryInfoSRequestStatus],
  );

  const handleSubmit = (values: DeliveryFormProps, resetForm: () => void, setSubmitting: (value: boolean) => void) => {
    dispatch(sendDeliveryInfo({ id: +(custodyId || 0), data: values, setSubmitting, resetForm }));
  };

  const handleCheckboxClick = (
    values: DeliveryFormProps,
    setFieldValue: (item: string, value: string | number | undefined) => void,
  ) => {
    if (!isChecked) {
      Object.keys(values).map((item) => setFieldValue(item, custodyAutofill[item as keyof Custody] || ''));
    }
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    if (ordersHistory.custody.length) {
      setCustodyInfo(ordersHistory.custody.find(({ id }) => id === +(custodyId || 0)));
    }
  }, [custodyId, ordersHistory.custody]);

  useEffect(() => {
    dispatch(getHistory({ type: 'custody' }));
    dispatch(getAutofill({ type: 'custody' }));

    return () => {
      dispatch(clearOrderId());
      dispatch(clearHistory());
    };
  }, [dispatch]);

  useEffect(() => {
    if (custodyInfo?.state !== State.Approved) navigate('/404');
  }, [navigate, custodyInfo?.state]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 900, marginX: 'auto' }}>
      <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, marginBottom: 4 }} className="lg bold">
        Custody Request Form
      </Typography>
      <Stack
        direction="column"
        padding={2}
        spacing={1}
        sx={{
          borderRadius: BORDER_RADIUS_DEFAULT,
          border: COLOR_BLACK,
          borderStyle: 'dotted',
          background: COLOR_LIGHT_GRAY,
          marginBottom: 6,
        }}
      >
        <WhiteTitle>
          Your request has been approved. Please, type in the shipment detail of your sneakers sent for safe custody.
        </WhiteTitle>
        <WhiteTitle sx={{ fontWeight: FontWeights.fontWeightBold }}>
          *Please, note that upon arrival of your sneakers, verification and authentication will take place.
        </WhiteTitle>
      </Stack>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setSubmitting }) => handleSubmit(values, resetForm, setSubmitting)}
      >
        {({ touched, errors, handleChange, handleBlur, values, isValid, setFieldValue }) => (
          <Form>
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{ marginBottom: { xs: 2, md: 8 } }} spacing={8}>
              <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <Typography
                  sx={{ typography: { xs: 'h2', md: 'h1' }, marginBottom: { xs: 2, md: 4 } }}
                  className={clsx('semiBold', !isMediumScreen && 'lg')}
                >
                  Sneakers Details
                </Typography>

                <Stack width="100%" spacing={{ xs: 2, md: 4 }}>
                  <Stack spacing={2}>
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      ID
                    </Typography>
                    <TextField disabled value={custodyInfo?.id || '-'} variant="filled" autoComplete="off" />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Sneaker Name
                    </Typography>
                    <TextField disabled value={custodyInfo?.name || '-'} variant="filled" autoComplete="off" />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Style Number
                    </Typography>
                    <TextField disabled value={custodyInfo?.style || '-'} variant="filled" autoComplete="off" />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Size Type
                    </Typography>
                    <TextField disabled value={custodyInfo?.sizeType || '-'} variant="filled" autoComplete="off" />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Size
                    </Typography>
                    <TextField disabled value={custodyInfo?.size || '-'} variant="filled" autoComplete="off" />
                  </Stack>
                </Stack>
              </Box>
              <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <Typography
                  sx={{ typography: { xs: 'h2', md: 'h1' }, marginBottom: { xs: 2, md: 4 } }}
                  className={clsx('semiBold', !isMediumScreen && 'lg')}
                >
                  Personal Details
                </Typography>

                <Stack spacing={{ xs: 2, md: 4 }} width="100%" marginBottom={{ xs: 2, md: 16 }}>
                  <Stack spacing={2} width="100%">
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Typography sx={{ typography: 'h4' }} className="semiBold">
                        First Name
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="autofill"
                            onClick={() => handleCheckboxClick(values, setFieldValue)}
                            disabled={!Object.values(custodyAutofill).length}
                          />
                        }
                        label="Autofill"
                        sx={{
                          marginLeft: 0,
                          '.MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            fontWeight: FontWeights.fontWeightSemiBold,
                          },
                        }}
                      />
                    </Box>

                    <Field
                      id="firstName"
                      name="firstName"
                      render={({ form: { isSubmitting } }: FieldProps) => (
                        <TextField
                          variant="filled"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                          }}
                          name="firstName"
                          value={values.firstName}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && !!errors.firstName}
                          helperText={touched.firstName && errors.firstName}
                          sx={{ input: { p: 0 } }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={2} width="100%">
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Last Name
                    </Typography>
                    <Field
                      id="lastName"
                      name="lastName"
                      render={({ form: { isSubmitting } }: FieldProps) => (
                        <TextField
                          variant="filled"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                          }}
                          name="lastName"
                          value={values.lastName}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastName && !!errors.lastName}
                          helperText={touched.lastName && errors.lastName}
                          sx={{ input: { p: 0 } }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={2} width="100%">
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Wallet Address
                    </Typography>
                    <Field
                      id="walletAddress"
                      name="walletAddress"
                      render={() => (
                        <TextField
                          variant="filled"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                          }}
                          name="walletAddress"
                          value={walletAddress}
                          disabled
                          sx={{ input: { p: 0 } }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={2} width="100%">
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Email
                    </Typography>
                    <Field
                      id="email"
                      name="email"
                      render={() => (
                        <TextField
                          variant="filled"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                          }}
                          name="email"
                          value={custodyInfo?.email || email}
                          disabled
                          sx={{ input: { p: 0 } }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack spacing={2} width="100%">
                    <Typography sx={{ typography: 'h4' }} className="semiBold">
                      Mobile number
                    </Typography>
                    <Field
                      id="mobileNumber"
                      name="mobileNumber"
                      render={({ form: { isSubmitting } }: FieldProps) => (
                        <TextField
                          variant="filled"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 200,
                          }}
                          name="mobileNumber"
                          value={values.mobileNumber}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.mobileNumber && !!errors.mobileNumber}
                          helperText={touched.mobileNumber && errors.mobileNumber}
                          sx={{ input: { p: 0 } }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Stack>

            <Typography sx={{ typography: 'h1', marginBottom: 1 }} className="semiBold">
              Shipment Details
            </Typography>
            <Typography sx={{ typography: 'body1', marginBottom: { xs: 2, md: 4 } }}>
              Please give the details of shipment used to send the sneakers.
            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ marginBottom: { xs: 4, md: 8 } }}
              spacing={{ xs: 0, md: 8 }}
            >
              <Stack sx={{ width: { xs: '100%', md: '50%' } }} spacing={{ xs: 2, md: 4 }}>
                <Stack spacing={2}>
                  <Typography sx={{ typography: 'h4' }} className="semiBold">
                    Courier service name
                  </Typography>
                  <Field
                    id="courierCompanyName"
                    name="courierCompanyName"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 200,
                        }}
                        name="courierCompanyName"
                        value={values.courierCompanyName}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.courierCompanyName && !!errors.courierCompanyName}
                        helperText={touched.courierCompanyName && errors.courierCompanyName}
                        sx={{ input: { p: 0 } }}
                      />
                    )}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography sx={{ typography: 'h4' }} className="semiBold">
                    Zip Code
                  </Typography>
                  <Field
                    id="zipCode"
                    name="zipCode"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 200,
                        }}
                        name="zipCode"
                        value={custodyInfo?.zipCode}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.zipCode && !!errors.zipCode}
                        helperText={touched.zipCode && errors.zipCode}
                        sx={{ input: { p: 0 } }}
                      />
                    )}
                  />
                </Stack>
              </Stack>

              <Stack sx={{ width: { xs: '100%', md: '50%' } }} spacing={{ xs: 2, md: 4 }}>
                <Stack spacing={2}>
                  <Typography sx={{ typography: 'h4' }} className="semiBold">
                    Tracking number
                  </Typography>
                  <Field
                    id="courierInvoiceNumber"
                    name="courierInvoiceNumber"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 200,
                        }}
                        name="courierInvoiceNumber"
                        value={values.courierInvoiceNumber}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.courierInvoiceNumber && !!errors.courierInvoiceNumber}
                        helperText={touched.courierInvoiceNumber && errors.courierInvoiceNumber}
                        sx={{ input: { p: 0 } }}
                      />
                    )}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography sx={{ typography: 'h4' }} className="semiBold">
                    Sender&apos;s address
                  </Typography>
                  <Field
                    id="address"
                    name="address"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        autoComplete="off"
                        multiline
                        rows={3}
                        inputProps={{
                          maxLength: 200,
                        }}
                        name="address"
                        value={values.address}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.address && !!errors.address}
                        helperText={touched.address && errors.address}
                        sx={{ input: { p: 0 } }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Notice
              checked={isAgree}
              onClick={() => setIsAgree((prev) => !prev)}
              containerProps={{ sx: { marginBottom: { xs: 4, md: 8 } } }}
            />
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={!isValid || isSendDeliveryInfoLoading || !isAgree}
                sx={{ fontWeight: FontWeights.fontWeightBold, whiteSpace: 'nowrap', textTransform: 'capitalize' }}
              >
                {isSendDeliveryInfoLoading ? (
                  <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
                ) : (
                  'Submit Request'
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
