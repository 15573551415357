import { URL } from 'appConstants';

import activityApiCalls from './activityApiCalls';
import ajax from './ajax';
import nftApiCalls from './nftApiCalls';
import ordersApiCalls from './ordersApiCalls';
import profileApiCalls from './profileApiCalls';
import userApiCalls from './userApiCalls';

export const baseApi = {
  getRates(network = 'Ethereum') {
    return ajax({
      method: 'get',
      url: URL.getRates(network),
    });
  },
  getNetwork(network = 'Ethereum') {
    return ajax({
      method: 'get',
      url: URL.getNetwork(network),
    });
  },
  ...nftApiCalls,
  ...userApiCalls,
  ...profileApiCalls,
  ...activityApiCalls,
  ...ordersApiCalls,
};
