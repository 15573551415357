import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { COLOR_GREEN } from 'theme/variables';

export const Membershipz: FC = () => {
  return (
    <Stack spacing={7} alignItems={{ xs: 'center', sm: 'start' }}>
      <Stack spacing={2}>
        <Typography
          textTransform="uppercase"
          sx={{ typography: { xs: 'h2', sm: 'h1' }, color: `${COLOR_GREEN} !important` }}
        >
          Membershipz
        </Typography>
      </Stack>

      <Box width="100%" display="flex" justifyContent="center">
        <Button variant="outlined" size="large" sx={{ width: 288 }}>
          To Be Added
        </Button>
      </Box>
    </Stack>
  );
};
