import { toast } from 'react-toastify';
import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import BigNumber from 'bignumber.js';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { closeModal } from 'store/modals/reducer';
import networkSelector from 'store/network/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains, IChainType, TokenContractData } from 'types';
import { getMaxGas, logger } from 'utils';

import { buyNft } from '../actions';
import actionTypes from '../actionTypes';

import { approveSaga } from './approve';
import { getNftDataSaga } from './getNftData';

export function* buySaga({
  type,
  payload: { id, tokenAmount, price, sellerId, currencySymbol, web3Provider },
}: ReturnType<typeof buyNft>) {
  yield put(request(type));

  const { address, chainType, network }: { address: string; chainType: IChainType; network: Chains } = yield select(
    userSelector.getUser,
  );

  const { exchangeAddress } = yield select(networkSelector.getNetwork);

  try {
    if (currencySymbol) {
      try {
        const tokenData = currencySymbol
          ? contractsConfig.contracts[currencySymbol as ContractsNames][chainType]
          : ({} as TokenContractData);

        const tokenContract: ContractWeb3 = yield new web3Provider.eth.Contract(
          tokenData.abi[network],
          tokenData.address[network],
        );

        const decimals: string = yield call(tokenContract.methods.decimals().call);

        yield call(approveSaga, {
          type: actionTypes.APPROVE,
          payload: {
            web3Provider,
            amount: new BigNumber(price)
              .times(+tokenAmount || 1)
              .times(10 ** +decimals)
              .toFixed(0, 1),
            spender: exchangeAddress,
            currencySymbol,
            isBid: false,
          },
        });
      } catch {
        return;
      }
    }

    const { data } = yield call(baseApi.buyNft, { id, tokenAmount, sellerId });

    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield call(getMaxGas);
        const { transactionHash } = yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxPriorityFeePerGas,
          maxFeePerGas,
        });
        toast.success('You have successfully bought NFT. It takes some time to complete the transaction');

        try {
          yield call(baseApi.trackTransaction, {
            tx_hash: String(transactionHash),
            token: id,
            ownership: sellerId,
            amount: tokenAmount,
          });
        } catch (err) {
          logger('Track transaction', err);
          yield put(error(type));
        }

        yield call(getNftDataSaga, {
          type: actionTypes.GET_NFT_DATA,
          payload: {
            id,
          },
        });

        yield put(closeModal());
        yield put(success(type));
      } catch (err) {
        logger('Send transaction for buy', err);
        toast.error('Something went wrong');
        yield put(error(type));
        yield call(baseApi.buyReject, { id, owner: sellerId });
      }
    } else {
      yield put(error(type));
      toast.error('No initial tx');
    }
  } catch (e: any) {
    yield call(baseApi.buyReject, { id, owner: sellerId });
    if (typeof e === 'number') {
      toast.error(e === 4001 ? 'You have rejected confirmation' : 'Something went wrong');
    } else {
      toast.error(e.code === 4001 ? 'You have rejected confirmation' : 'Something went wrong');
    }
    logger('Buy token', e);
    yield put(error(type, e));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BUY, buySaga);
}
