import { FC, ReactNode, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { setOnSale } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { Ownership } from 'types/api';

import { QuantityCounter } from '../QuantityCounter';

type UpdateSaleProps = {
  userOwner: Ownership | undefined;
};

export const UpdateSale: FC<UpdateSaleProps> = ({ userOwner }) => {
  const [quantity, setQuantity] = useState(userOwner?.sellingQuantity || '1');
  const [updatedPrice, handleChangeUpdatedPrice] = useValidateInputField({ type: ValidationTypes.number, decimals: 4 });
  const { standart, currency, id, collection, network, sellers } = useShallowSelector(
    nftSelector.getProp('detailedNft'),
  );
  const { address, network: userNetwork } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.SET_ON_SALE]: setOnSaleRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const isSetOnSaleLoading = useMemo(() => setOnSaleRequestStatus === RequestStatus.REQUEST, [setOnSaleRequestStatus]);

  const handleUpdatePrice = () => {
    if (network?.name && network.name === userNetwork) {
      if (id && currency?.symbol && collection?.address) {
        dispatch(
          setOnSale({
            price: updatedPrice,
            id,
            currency: currency.symbol,
            web3Provider: walletService.Web3(),
            collectionAddress: collection.address,
            isSingle: standart === 'ERC721',
            amount: standart === 'ERC1155' ? quantity : undefined,
            endSale: sellers?.find((seller) => seller?.address === address)?.endSale,
          }),
        );
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  return (
    <Stack spacing={6}>
      <Stack spacing={1}>
        {standart === 'ERC1155' && (
          <QuantityCounter
            quantity={quantity}
            setQuantity={setQuantity}
            maxQuantity={+(userOwner?.quantity || 1) - +(userOwner?.redeemAmount || 0)}
          />
        )}
        <Typography>Price</Typography>
        <Stack pb={3} direction="row" spacing={2}>
          <TextField
            variant="filled"
            placeholder="0.00"
            value={updatedPrice}
            error={updatedPrice !== '' && +updatedPrice < 0.001}
            onChange={handleChangeUpdatedPrice}
            sx={{ height: '44px', width: '182px' }}
            helperText={
              updatedPrice !== '' &&
              (+updatedPrice < 0.001
                ? ('Price should be equal or greater then 0.001' as ReactNode)
                : `$ ${parseFloat(new BigNumber(updatedPrice || 0).times(currency?.rate || 0).toString(10))}`)
            }
          />
          <Button
            variant="contained"
            disabled={
              !address ||
              isSetOnSaleLoading ||
              +updatedPrice < 0.001 ||
              +(userOwner?.quantity || 0) - +(userOwner?.redeemAmount || 0) === 0
            }
            onClick={handleUpdatePrice}
            sx={{ width: '132px', height: '44px' }}
          >
            {isSetOnSaleLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'Update'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
