export type SortItem = {
  title: string;
  value: string;
};

export type SortItems = {
  label: string;
  items: SortItem[];
  activeItem: string;
};

export const sortItemsMock = [
  {
    label: 'Price',
    items: [
      { title: 'Low to High ↑', value: 'price' },
      { title: 'High to Low ↓', value: '-price' },
    ],
  },
  {
    label: 'Date',
    items: [
      { title: 'Recently listed', value: '-listed_at' },
      { title: 'Recently created', value: '-created_at' },
      { title: 'Last sale', value: 'last_sale' },
    ],
  },
  {
    label: 'Likes',
    items: [
      { title: 'Most liked', value: '-likes' },
      { title: 'Less liked', value: 'likes' },
    ],
  },
];
