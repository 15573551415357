import { fork } from 'redux-saga/effects';

import approveRedeemOrClaimSaga from './approveRedeemOrClaim';
import claimNftSaga from './claimNft';
import confirmClaimSaga from './confirmClaim';
import custodyListed from './custodyListed';
import custodyUnlisted from './custodyUnlisted';
import getAutoFillSaga from './getAutofill';
import getCustodyLists from './getCustodyLists';
import getHistorySaga from './getHistory';
import payForReturnSaga from './payForDelivery';
import redeemNftSaga from './redeemNft';
import sendDeliveryInfo from './sendDeliveryInfo';

export default function* ordersSagas() {
  yield fork(getAutoFillSaga);
  yield fork(getHistorySaga);
  yield fork(redeemNftSaga);
  yield fork(claimNftSaga);
  yield fork(confirmClaimSaga);
  yield fork(getCustodyLists);
  yield fork(custodyListed);
  yield fork(custodyUnlisted);
  yield fork(sendDeliveryInfo);
  yield fork(approveRedeemOrClaimSaga);
  yield fork(payForReturnSaga);
}
