import { useNavigate } from 'react-router-dom';
import { Box, Button, styled, Typography, useMediaQuery } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { routes } from 'appConstants';
import { theme } from 'theme';
import { FontWeights } from 'theme/Typography';
import { BORDER, BORDER_RADIUS_DEFAULT, COLOR_GRAY_200, COLOR_WHITE, TRANSITION_DEFAULT_TIME } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

import { HomeSlider } from '../HomeSlider';

import { PreviewData, previewDataMock } from './Preview.mock';

const PreviewCardText = styled(Typography)({
  fontSize: 48,
  lineHeight: '72px',
  letterSpacing: '-0.04em',
  fontWeight: FontWeights.fontWeightSemiBold,
  textTransform: 'uppercase',
  color: COLOR_WHITE,
  marginRight: 16,
  [theme.breakpoints.down('md')]: {
    fontSize: 22,
    lineHeight: '26px',
  },
});

export const Preview = () => {
  const navigate = useNavigate();
  const isDownSmLayout = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigateToExplore = (type: PreviewData['linkType'], value: PreviewData['linkValue']) => {
    if (type === 'explore') {
      navigate(routes.explore.root.path);
    }
    if (type === 'nft') {
      navigate(routes.nft.root.getPath(value));
    }
    if (type === 'style') {
      const key = 'Style';
      navigate(routes.explore.root.getPath({ property: { [key]: [value] } }));
    }
    if (type === 'external') {
      window.location.assign(value || '');
    }
  };

  return (
    <HomeSlider
      perPage={1}
      gap={19}
      padding={{ left: isDownSmLayout ? 0 : 15, right: isDownSmLayout ? 0 : 15 }}
      fixedWidth={undefined}
      wrapperStyle={{
        marginX: isDownSmLayout ? '-10px !important' : 'inherit',
      }}
    >
      {previewDataMock.map(
        ({ key, title1, maxTitleWidth, title2, subtitle, bgImage, linkValue, linkType, withExploreButton }) => (
          <SplideSlide key={key}>
            <Button
              variant="text"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={linkType && linkValue ? () => handleNavigateToExplore(linkType, linkValue) : () => {}}
              disabled={!(linkType && linkValue)}
              sx={{
                position: 'relative',
                maxHeight: 730,
                overflow: 'hidden',
                borderRadius: { xs: 4, md: 7.5 },
                '&:hover .button': {
                  borderColor: COLOR_GRAY_200,
                  background: COLOR_GRAY_200,
                },
              }}
            >
              <Box
                component="img"
                src={bgImage}
                alt="preview bg"
                sx={{ width: '100%', borderRadius: { xs: 4, md: 7.5 } }}
              />
              <Box
                position="absolute"
                zIndex={2}
                width="100%"
                height="100%"
                sx={{
                  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 44.79%, rgba(0, 0, 0, 0.5) 100%)',
                }}
              />
              <Box position="absolute" zIndex={3} bottom={{ xs: 10, md: 45 }} left={{ xs: 10, md: 57 }}>
                <PreviewCardText maxWidth={maxTitleWidth || '100%'}>{title1}</PreviewCardText>
                <PreviewCardText>{title2}</PreviewCardText>
                <Typography
                  className="white unset-transform"
                  fontSize={{ xs: 10, sm: 16 }}
                  lineHeight={{
                    xs: '18px',
                    sm: '24px',
                  }}
                >
                  {subtitle}
                </Typography>
                {withExploreButton && (
                  <Box
                    mt={{ xs: 1, md: 5 }}
                    width={{ xs: 100, sm: 166 }}
                    height={{ xs: 36, sm: 44 }}
                    className="button"
                    {...flexHelper()}
                    display={{ xs: 'none', sm: 'flex' }}
                    sx={{
                      background: 'transparent',
                      border: BORDER,
                      borderColor: COLOR_WHITE,
                      borderRadius: BORDER_RADIUS_DEFAULT,
                      transition: TRANSITION_DEFAULT_TIME,
                      fontWeight: FontWeights.fontWeightSemiBold,
                      padding: theme.spacing(2, 2),
                      color: COLOR_WHITE,
                      textTransform: 'Capitalize',
                    }}
                  >
                    Explore
                  </Box>
                )}
              </Box>
            </Button>
          </SplideSlide>
        ),
      )}
    </HomeSlider>
  );
};
