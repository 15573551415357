import { DetailedHTMLProps, FC, ImgHTMLAttributes, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, Button, Stack, Typography } from '@mui/material';
import { COLOR_GRAY_100, COLOR_GRAY_400, COLOR_GREEN } from 'theme/variables';

import { CheongdamImages, SinsaImages } from '../assets';

const disabledButtonStyle = {
  background: COLOR_GRAY_400,
  borderColor: COLOR_GRAY_100,
  color: COLOR_GRAY_100,
};

const ImageBox: FC<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({
  src,
  alt = 'lounge',
}) => (
  <Box sx={{ height: '100%', img: { width: '100%', height: '100%', objectFit: 'cover' } }}>
    <LazyLoadImage src={src} alt={alt} />
  </Box>
);

export const Seoul: FC = () => {
  const [currentTab, setCurrentTab] = useState<'cheongdam' | 'sinsa'>('cheongdam');
  return (
    <Stack spacing={4.5} alignItems="center">
      <Stack spacing={2} width="100%">
        <Typography
          textTransform="uppercase"
          className="lg"
          sx={{ typography: { xs: 'h2', sm: 'h1' }, color: `${COLOR_GREEN} !important` }}
        >
          Seoul
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
          <Typography fontSize={{ md: '18px' }} lineHeight={{ md: '30px' }} sx={{ maxWidth: '644px' }}>
            It will provide a space around the world where customers can actually experience products, and these spaces
            are called METAZ spaces.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="contained"
              size="large"
              onClick={() => setCurrentTab('cheongdam')}
              sx={currentTab === 'sinsa' ? disabledButtonStyle : {}}
            >
              Cheongdam
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => setCurrentTab('sinsa')}
              sx={currentTab === 'cheongdam' ? disabledButtonStyle : {}}
            >
              Sinsa
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {currentTab === 'cheongdam' && (
        <>
          <Box
            sx={{
              img: {
                width: '100%',
                height: { xs: '459px', sm: 'auto' },
                objectFit: { xs: 'cover', sm: 'unset' },
              },
              height: { xs: '459px', sm: 'auto' },
              width: '100%',
              '& > iframe': {
                height: { xs: '459px', sm: '100vw' },
                maxHeight: 1026,
              },
            }}
          >
            <iframe
              title="Matterport Showroom"
              width="100%"
              height="459"
              src="https://my.matterport.com/show/?m=TGQEJCfe8Wb"
              frameBorder="0"
              allowFullScreen
            />
          </Box>

          <Stack spacing={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <ImageBox src={CheongdamImages.image1} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1">
                  <ImageBox src={CheongdamImages.image2} />
                </Stack>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={CheongdamImages.image3} />
                  <ImageBox src={CheongdamImages.image4} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image5} />
                    <ImageBox src={CheongdamImages.image6} />
                  </Stack>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image7} />
                  </Stack>
                </Stack>

                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image8} />
                  </Stack>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image9} />
                    <ImageBox src={CheongdamImages.image10} />
                  </Stack>
                </Stack>
              </Stack>

              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1" spacing={2}>
                  <ImageBox src={CheongdamImages.image11} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <ImageBox src={CheongdamImages.image12} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1">
                  <ImageBox src={CheongdamImages.image13} />
                </Stack>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={CheongdamImages.image14} />
                  <ImageBox src={CheongdamImages.image15} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image16} />
                    <ImageBox src={CheongdamImages.image17} />
                  </Stack>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image18} />
                  </Stack>
                </Stack>

                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image19} />
                  </Stack>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image20} />
                    <ImageBox src={CheongdamImages.image21} />
                  </Stack>
                </Stack>
              </Stack>

              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1" spacing={2}>
                  <ImageBox src={CheongdamImages.image22} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <ImageBox src={CheongdamImages.image23} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1">
                  <ImageBox src={CheongdamImages.image24} />
                </Stack>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={CheongdamImages.image25} />
                  <ImageBox src={CheongdamImages.image26} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image27} />
                    <ImageBox src={CheongdamImages.image28} />
                  </Stack>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image29} />
                  </Stack>
                </Stack>

                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image30} />
                  </Stack>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image31} />
                    <ImageBox src={CheongdamImages.image32} />
                  </Stack>
                </Stack>
              </Stack>

              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1" spacing={2}>
                  <ImageBox src={CheongdamImages.image33} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <ImageBox src={CheongdamImages.image34} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1">
                  <ImageBox src={CheongdamImages.image35} />
                </Stack>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={CheongdamImages.image36} />
                  <ImageBox src={CheongdamImages.image37} />
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <ImageBox src={CheongdamImages.image38} />
                  </Stack>
                  <Stack flex="1">
                    <ImageBox src={CheongdamImages.image39} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
      {currentTab === 'sinsa' && (
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack flex="1" spacing={2}>
              <ImageBox src={SinsaImages.image1} />
            </Stack>
            <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1">
                <ImageBox src={SinsaImages.image2} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                <ImageBox src={SinsaImages.image3} />
                <ImageBox src={SinsaImages.image4} />
              </Stack>
            </Stack>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack flex="1" spacing={2}>
              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={SinsaImages.image5} />
                  <ImageBox src={SinsaImages.image6} />
                </Stack>
                <Stack flex="1">
                  <ImageBox src={SinsaImages.image7} />
                </Stack>
              </Stack>

              <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack flex="1">
                  <ImageBox src={SinsaImages.image8} />
                </Stack>
                <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                  <ImageBox src={SinsaImages.image9} />
                  <ImageBox src={SinsaImages.image10} />
                </Stack>
              </Stack>
            </Stack>

            <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1" spacing={2}>
                <ImageBox src={SinsaImages.image11} />
              </Stack>
            </Stack>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack flex="1" spacing={2}>
              <ImageBox src={SinsaImages.image12} />
            </Stack>
            <Stack flex="1" direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Stack flex="1">
                <ImageBox src={SinsaImages.image13} />
              </Stack>
              <Stack flex="1" direction={{ xs: 'column', sm: 'column' }} spacing={2} />{' '}
              {/* placeholder to hold grid layout */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
