import { FC, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, BoxProps, Button, ButtonProps, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Chevron } from 'assets/img';
import {
  backgroundNetImage,
  borderlessImage,
  cheaperImage,
  custodyImage,
  fasterImage,
  leverageImage,
  marketplaceImage,
  maximiseSneakersImage,
  metazImage,
  nightSkyImage,
  redeemImage,
  saferImage,
  safeSneakersImage,
  sneakersAuthenticateImage,
  sneakersNftImage,
  sneakersSendImage,
  sneakersVaultImage,
  tradeImage,
} from 'assets/img/AboutUs';
import { AspectRatio, FullFixedScreen, UndoContainer } from 'components';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import {
  COLOR_ANTI_FLASH_WHITE,
  COLOR_BLACK,
  COLOR_BLUEBONNET,
  COLOR_GRAY_150,
  COLOR_LIGHT_SILVER,
  COLOR_WHITE,
} from 'theme/variables';
import { Modals } from 'types';

import { HowItWorksCard } from './components/HowItWorksCard/HowItWorksCard';

export const headingSx = {
  typography: 'h1',
  lineHeight: '60px',
  textAlign: 'center !important',
  fontFamily: FontFamilies.primary,
  fontWeight: FontWeights.fontWeightSemiBold,
};

const Br = ({ ...props }: BoxProps) => <Box component="br" display={{ xs: 'none', sm: 'inline' }} {...props} />;

const metazFeatures = [
  {
    name: 'Cheaper',
    description: () => (
      <>
        We have cut down unnecessary process of physical and human involvement by applying NFT as means of ownership.
        Processing fee charge is <strong>3%(max)</strong> or less. The rate is subject to change as per the service
        growth.
      </>
    ),
    image: cheaperImage,
  },
  {
    name: 'Faster',
    description: () =>
      'Trade sneakers with NFTs instead of physical pairs, minimizing transaction delays and costs. Transactions occur instantly, typically taking less than a couple of minutes.',
    image: fasterImage,
  },
  {
    name: 'Borderless',
    description: () =>
      'Trading NFTs is borderless. Connect with the global market and tap into a wider audience of potential buyers.',
    image: borderlessImage,
  },
  {
    name: 'Safer',
    description: () =>
      'All NFTs are linked to their physical sneaker counterparts, securely stored in the temperature/humidity controlled METAZ vault. We offer free custody to METAZ to maintain the optimal condition of their sneakers.',
    image: saferImage,
  },
];

const metazFeatures2 = [
  {
    name: 'Custody',
    description:
      'Secure your sneakers and enjoy peace of mind with our real-time updated custody process. Ownership is 100% guaranteed with NFT.',
    image: custodyImage,
  },
  {
    name: 'Leverage',
    description:
      'Utilize your sneaker assets to their maximum potential through the NFT (Sneakers ownership) as a means to access various DeFi services.',
    image: leverageImage,
  },
  {
    name: 'Trade',
    description:
      'Trading sneakers on METAZ is simple and fast—just a few clicks. The marketplace eliminates the hassles of transporting sneakers and transaction delays.',
    image: tradeImage,
  },
  {
    name: 'Redeem',
    description:
      'All the NFT on the platform are tied physical sneakers. They are redeemable for actual physical pair of sneakers with just one click!',
    image: redeemImage,
  },
];

const buttonSx: ButtonProps['sx'] = {
  backgroundColor: { xs: COLOR_BLACK, sm: COLOR_BLUEBONNET },
  borderRadius: '16px',
  width: { xs: '100%', sm: 520 },
  paddingY: { xs: 3, md: 7 },
  textTransform: 'initial',
  fontSize: { xs: 18, md: 28 },
  lineHeight: '30px',
  fontWeight: FontWeights.fontWeightSemiBold,
};

export const AboutUs: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const address = useShallowSelector(userSelector.getProp('address'));
  const dispatch = useDispatch();

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <UndoContainer
      sx={{
        width: { xs: '100vw', xl: '100%' },
      }}
    >
      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_BLACK,
          backgroundImage: `url(${nightSkyImage})`,
          backgroundSize: { xs: 'contain', md: 'auto' },
          backgroundRepeat: 'no-repeat',
          paddingTop: { xs: 24, sm: 32, md: 32 },
          paddingX: { xs: 3, md: 8, lg: 10.25, xl: 12 },
          paddingBottom: { xs: 4, md: 12 },
        }}
      >
        {/* <Box component="img" src={nightSkyImage} sx={{ maxWidth: '50vw' }} /> */}
        <Typography
          sx={{ color: COLOR_WHITE, fontSize: { xs: 18, md: 28 }, lineHeight: '19.5px', textAlign: 'center' }}
        >
          The Ultimate Sneakers Trading platform
        </Typography>
        <Box sx={{ paddingBottom: 4 }} />
        <Typography
          sx={{
            color: COLOR_WHITE,
            fontSize: { xs: 30, md: 48, lg: 60, xl: 70 },
            lineHeight: { xs: '38px', md: '60px' },
            fontWeight: FontWeights.fontWeightSemiBold,
            textAlign: 'center',
          }}
        >
          Maximise the value of your Sneakers
        </Typography>
        <Box sx={{ paddingBottom: { xs: 3.5, md: 2.5, lg: 4.5 } }} />
        <Box sx={{ textAlign: 'center' }}>
          <Box component="img" src={metazImage} alt="metaz" sx={{ width: { xs: 214, md: 325 } }} />
        </Box>

        <Box sx={{ paddingBottom: { xs: 4, md: 16, xl: 32 } }} />
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_BLACK,
          marginTop: '-1px',
        }}
      >
        <Box
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
            paddingTop: { xs: 16, md: 24 },
            paddingBottom: 12,
            paddingX: { xs: 3, sm: 6, md: 12, xl: 22.25 },
          }}
        >
          <Box sx={{ paddingX: { xs: 0, xl: 4 } }}>
            <Typography
              sx={{
                color: COLOR_WHITE,
                fontSize: { xs: 28, md: 32, lg: 48 },
                lineHeight: { xs: '39px', md: 'normal', lg: '60px' },
                fontWeight: FontWeights.fontWeightSemiBold,
              }}
            >
              Trade Your Sneakers effortlessly
            </Typography>
            <Box sx={{ paddingBottom: 14.5 }} />

            <Grid container spacing={{ xs: 4, xl: 12 }} sx={{ px: { xs: 3.5, lg: 0 } }}>
              {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={12} sx={{ paddingX: 2 }}> */}
              {metazFeatures.map((feature) => (
                <Grid key={feature.name} item xs={12} sm={6} md={3}>
                  <Box sx={{ paddingBottom: { xs: 8, lg: 0 }, textAlign: 'center' }}>
                    <Box component="img" src={feature.image} height={180} />
                    <Box sx={{ paddingBottom: { xs: 4, lg: 8 } }} />
                    <Typography
                      sx={{
                        color: COLOR_WHITE,
                        fontSize: { xs: 26, md: 36 },
                        lineHeight: '60px',
                        fontWeight: FontWeights.fontWeightSemiBold,
                        textAlign: 'center',
                      }}
                    >
                      {feature.name}
                    </Typography>
                    <Box sx={{ paddingBottom: 4 }} />
                    <Typography sx={{ color: COLOR_WHITE, fontSize: 18, lineHeight: '27px', textAlign: 'center' }}>
                      {feature.description()}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_ANTI_FLASH_WHITE,
        }}
      >
        <Box
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
            paddingX: { xs: 2.5, sm: 6, md: 12, xl: 22.25 },
          }}
        >
          <Box sx={{ paddingBottom: { xs: 5, lg: 13.25 } }} />

          <Typography
            sx={{
              fontSize: { xs: 30, md: 48 },
              lineHeight: { xs: '40px', md: '60px' },
              fontWeight: FontWeights.fontWeightSemiBold,
              px: { xs: 0.5, lg: 0 },
            }}
          >
            How METAZ Works
          </Typography>

          <Box sx={{ paddingBottom: { xs: 9, md: 8.25, lg: 13.5 } }} />

          <Grid container columnSpacing={{ xs: 0, sm: 2 }} rowSpacing={{ xs: 2.5, md: 3.5 }}>
            <Grid item xs={12} sm={6} lg={5.5}>
              <Box component={isMediumScreen ? AspectRatio : Fragment} aspectRatio={1}>
                <HowItWorksCard
                  title="Send sneakers"
                  description="Connect your wallet and complete a custody form to request custody for your sneakers with METAZ."
                  image={sneakersSendImage}
                />
              </Box>
            </Grid>
            {isLargeScreen && (
              <Grid item md={1}>
                <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Chevron />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={5.5}>
              <Box component={isMediumScreen ? AspectRatio : Fragment} aspectRatio={1}>
                <HowItWorksCard
                  title="Authentication"
                  description="The sneakers will undergo inspection to assess their eligibility and authenticity."
                  image={sneakersAuthenticateImage}
                />
              </Box>
            </Grid>
            {isLargeScreen && <Grid item xs={6.5} />}
            {isLargeScreen && (
              <Grid item xs={12} md={5.5}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Chevron sx={{ transform: 'rotate(90deg)' }} />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={5.5} sx={{ order: { xs: 4, lg: 4 } }}>
              <Box component={isMediumScreen ? AspectRatio : Fragment} aspectRatio={1}>
                <HowItWorksCard
                  title="NFT minting"
                  description="NFT is created for the pair of sneakers. At this point, the sneakers are ready to trade while securely stored in the METAZ vaults."
                  image={sneakersNftImage}
                />
              </Box>
            </Grid>
            {isLargeScreen && (
              <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' }, order: 5 }}>
                <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Chevron sx={{ transform: 'rotate(180deg)' }} />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={5.5} sx={{ order: { xs: 3, lg: 6 } }}>
              <Box component={isMediumScreen ? AspectRatio : Fragment} aspectRatio={1}>
                <HowItWorksCard
                  title="Sneakers checked into METAZ Vault"
                  description="Upon inspection completion, the sneakers are sent to the METAZ Vault for safekeeping to maintain their optimal condition."
                  image={sneakersVaultImage}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ paddingBottom: { xs: 7.5, md: 18 } }} />

          <Box sx={{ width: { xs: '100%' } }}>
            <Typography
              sx={{
                display: { xs: 'grid', sm: 'inline-block' },
                fontSize: { xs: 28, sm: 40, md: 59, lg: 70 },
                lineHeight: { xs: '39px', sm: '64px', md: '89px' },
                fontWeight: FontWeights.fontWeightSemiBold,
              }}
            >
              Efficient Marketplace with <Br />
              Optimized Transaction Speed <Br />
              and Instant Payouts
              <Typography
                // variant={{ xs: 'subtitle1', md: 'body1' }}
                component="span"
                variant={isSmallScreen ? 'body1' : 'subtitle1'}
                sx={{
                  display: 'inline-block',
                  fontWeight: FontWeights.fontWeightLight,
                  color: COLOR_GRAY_150,
                  paddingLeft: { xs: 0, sm: 8 },
                }}
              >
                Built on Polygon
              </Typography>
            </Typography>

            <Box sx={{ paddingBottom: 8 }} />

            <Typography sx={{ fontSize: 19, lineHeight: { xs: '25px', md: '32px' } }}>
              On METAZ, users can effortlessly trade sneakers using NFTs, while their physical assets are securely
              stored in the METAZ Vault. <Br />
              Built on Polygon, METAZ ensures a cost-effective and time-efficient user experience, featuring quick
              transactions and instant payouts with minimal gas fees. <Br />
              By leveraging NFTs, we`ve substantially reduced transaction fees and associated costs, eliminating
              physical involvement in the trading process.
            </Typography>
          </Box>

          <Box sx={{ paddingBottom: { xs: 4, md: 8 } }} />

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box component="img" src={marketplaceImage} alt="marketplace" sx={{ width: 726, maxWidth: '100%' }} />
          </Box>

          <Box sx={{ paddingBottom: { xs: 8, md: 16 } }} />
        </Box>
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_BLUEBONNET,
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 0, sm: 10, md: 8 }}
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
            paddingX: { xs: 2.5, sm: 14, md: 12, xl: 22.25 },
            paddingTop: { xs: 7.5, md: 14 },
            paddingBottom: { xs: 4.75, md: 12 },
            display: 'flex',
          }}
          alignItems="center"
        >
          <Box
            component="img"
            src={safeSneakersImage}
            alt="safe sneakers"
            sx={{ width: { xs: '100%', md: '52%' }, maxWidth: { sm: 626, md: '100%' }, order: { xs: 3, sm: 1, md: 1 } }}
          />
          <Box
            sx={{
              display: { xs: 'contents', sm: 'flex' },
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: { xs: '100%', md: '40%' },
              order: 2,
            }}
          >
            <div>
              <Typography
                sx={{
                  fontSize: { xs: 28, md: 46 },
                  lineHeight: { xs: '39px', md: '50px' },
                  fontWeight: FontWeights.fontWeightSemiBold,
                  color: COLOR_WHITE,
                }}
              >
                Safeguard your sneakers <Br display="inline" /> in the best condition
              </Typography>
              <Box sx={{ paddingBottom: { xs: 5, md: 8 } }} />
              <Typography
                sx={{ fontSize: { xs: 17, md: 20 }, lineHeight: { xs: '25px', md: '32px' }, color: COLOR_WHITE }}
              >
                {`METAZ offers custody services for NFT holders. All sneakers held in custody with METAZ are stored securely and in a controlled environment. We've established an official partnership with a global logistics and warehousing company with over two decades of experience.`}
              </Typography>
            </div>

            <Box sx={{ paddingBottom: { xs: 5, md: 0 } }} />

            <Button
              variant="contained"
              href="/custody"
              sx={{
                marginTop: { xs: `${8.25 * 8}px !important`, sm: 'auto !important', md: `${6.25 * 8}px !important` },
                borderRadius: '16px',
                width: { xs: '100%', sm: 350, md: 'fit-content' },
                paddingX: { xs: 0, md: 7.5 },
                paddingY: { xs: 3, md: 2.5 },
                textTransform: 'initial',
                fontSize: { xs: 18, md: 28 },
                lineHeight: '30px',
                fontWeight: FontWeights.fontWeightSemiBold,
                fontFamily: FontFamilies.primary,
                order: 4,
              }}
            >
              More on Custody
            </Button>
          </Box>
        </Stack>
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_ANTI_FLASH_WHITE,
        }}
      >
        <Box
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
            paddingTop: { xs: 10, md: 13 },
            paddingX: { xs: 3, sm: 6, md: 12, xl: 22.25 },
            paddingBottom: { xs: 4.5, md: 10 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 30, md: 48 },
              lineHeight: { xs: '40px', md: '60px' },
              fontWeight: FontWeights.fontWeightSemiBold,
            }}
          >
            METAZ, <Br sx={{ display: { xs: 'inline', sm: 'none' } }} /> a comprehensive platform <Br />
            maximising the value of sneakers on the blockchain
          </Typography>

          <Box sx={{ paddingBottom: { xs: 3.25, lg: 5.25 } }} />

          <Typography
            sx={{
              fontSize: { xs: 17, md: 20 },
              lineHeight: { xs: '25px', md: '32px' },
              fontWeight: { xs: FontWeights.fontWeightRegular, md: FontWeights.fontWeightSemiBold },
            }}
          >
            Our fully automated METAZ services, from Custody to Redeem, is accessible online, built on blockchain
            technologies.
          </Typography>

          <Box sx={{ paddingBottom: { xs: 6, md: 8 } }} />

          <Grid container columnSpacing={7.5} rowSpacing={14.5} justifyContent="space-around">
            {/* <Grid item xs={5.5}></Grid> */}
            {metazFeatures2.map((feature) => (
              <Grid key={feature.name} item xs={12} sm={5.5} md={6} lg={3}>
                <Box
                  sx={{
                    maxWidth: { xs: 320, sm: 290, lg: 350 },
                    backgroundColor: COLOR_WHITE,
                    borderRadius: '50%',
                    aspectRatio: '1 / 1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: { xs: 'auto', sm: 'initial' },
                  }}
                >
                  <Box
                    component="img"
                    src={feature.image}
                    alt={feature.name}
                    sx={{ transform: { sm: 'scale(0.9)', md: 'scale(0.85)', xl: 'scale(1)' } }}
                  />
                </Box>
                <Box sx={{ paddingBottom: { xs: 3, md: 7.5 } }} />
                <Typography
                  sx={{
                    fontSize: { xs: 30, md: 36 },
                    lineHeight: '50px',
                    fontWeight: FontWeights.fontWeightSemiBold,
                    textAlign: { xs: 'center', sm: 'start' },
                  }}
                >
                  {feature.name}
                </Typography>
                <Box sx={{ paddingBottom: { xs: 3, sm: 2.5, lg: 3 } }} />
                <Typography
                  sx={{
                    fontSize: { xs: 18, md: 20 },
                    lineHeight: { xs: '27px', md: '32px' },
                    fontWeight: FontWeights.fontWeightMedium,
                    textAlign: { xs: 'center', sm: 'start' },
                  }}
                >
                  {feature.description}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ paddingBottom: { xs: 22.5, sm: 32 } }} />

          <Typography
            sx={{
              fontSize: { xs: 30, sm: 46 },
              lineHeight: { xs: '40px', sm: '60px' },
              fontWeight: FontWeights.fontWeightSemiBold,
            }}
          >
            You are all set to maximise the value of your valuable sneakers. Take your valuable assets beyond its max
            level.
          </Typography>
        </Box>
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_ANTI_FLASH_WHITE,
        }}
      >
        <Grid
          container
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
          }}
        >
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: COLOR_LIGHT_SILVER,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingX: { xs: 4.75, md: 0 },
                paddingY: { xs: 6, md: 0 },
                ...(isMediumScreen && {
                  aspectRatio: '1 / 1',
                }),
                height: '100%',
              }}
            >
              <Box
                component="img"
                src={maximiseSneakersImage}
                alt="maximise sneakers value"
                sx={{ maxWidth: { xs: '100%', md: '90%', lg: '100%' } }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: COLOR_BLACK,
                paddingX: { xs: 2.5, sm: 6, lg: 7.5, xl: 14.25 },
                paddingY: { xs: 5, lg: 7.5, xl: 17.5 },
                ...(isMediumScreen && {
                  aspectRatio: '1 / 1',
                  overflowY: 'hidden',
                }),
                height: '100%',
              }}
            >
              <Typography
                sx={{
                  display: { xs: 'grid', md: 'inline-block' },
                  position: 'relative',
                  fontSize: { xs: 28, lg: 48, xl: 70 },
                  lineHeight: { xs: '40px', lg: 'normal', xl: '80px' },
                  fontWeight: FontWeights.fontWeightSemiBold,
                  color: COLOR_WHITE,
                  width: '100%',
                }}
              >
                Maximise the value <Br /> of your sneakers on <Br display="inline" /> METAZ
                <Typography
                  sx={{
                    position: { xs: 'initial', md: 'absolute' },
                    right: 0,
                    bottom: 0,
                    fontSize: { xs: 14, md: 16 },
                    lineHeight: '20px',
                    color: COLOR_GRAY_150,
                    fontWeight: FontWeights.fontWeightLight,
                    paddingLeft: { xs: 0, md: 2 },
                  }}
                >
                  (To be announced.)
                </Typography>
              </Typography>

              <Box sx={{ paddingBottom: { xs: 3, lg: 4, xl: 7.25 } }} />

              <Typography
                sx={{
                  fontSize: { xs: 17, lg: 20 },
                  lineHeight: { xs: '25px', md: '28px', lg: '32px' },
                  fontFamily: FontFamilies.primary,
                  fontWeight: { xs: FontWeights.fontWeightRegular, md: FontWeights.fontWeightMedium },
                  color: COLOR_WHITE,

                  [theme.breakpoints.between(1100, 1200)]: {
                    fontSize: 16,
                    lineHeight: '23px',
                  },
                }}
              >
                Sneakers have evolved into a cultural phenomenon over the past decades, with the resale market
                experiencing significant growth. The potential for further expansion remains positive, given the current
                market growth rate. Today, sneakers are unequivocally recognized as valuable assets. <br /> <br />{' '}
                However, the market is still in its early stages, primarily centered around buy/sell commerce. <br />{' '}
                <br /> METAZ stands out as the sole comprehensive on-chain platform designed to maximize the leverage of
                sneaker assets. Borrow against NFTs and reinvest in other sneakers or other preferred choices.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: COLOR_WHITE,
                paddingX: { xs: 2.5, sm: 6, lg: 10, xl: 16.25 },
                paddingY: { xs: 5, lg: 10, xl: 17.5 },
                ...(isMediumScreen && {
                  aspectRatio: '1 / 1',
                  overflowY: 'hidden',
                }),
                height: '100%',
              }}
            >
              <Stack direction={{ lg: 'row' }} spacing={{ xs: 0.5, lg: 1 }} position="relative">
                <Typography
                  sx={{
                    display: 'inline-grid',
                    fontSize: { xs: 28, lg: 48, xl: 70 },
                    lineHeight: { xs: '40px', lg: 'normal', xl: '80px' },
                    fontWeight: FontWeights.fontWeightSemiBold,
                  }}
                >
                  METAZ Token
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    height: 'max-content',
                    lineHeight: '44px',
                    alignSelf: { xs: 'flex-start', lg: 'flex-end' },
                    color: COLOR_GRAY_150,
                    fontWeight: FontWeights.fontWeightLight,
                  }}
                >
                  (To be announced.)
                </Typography>
              </Stack>

              <Box sx={{ paddingBottom: { xs: 3, lg: 4, xl: 7.5 } }} />

              <Typography
                sx={{
                  fontSize: { xs: 17, lg: 20 },
                  lineHeight: '32px',
                  fontFamily: FontFamilies.primary,
                  fontWeight: { xs: FontWeights.fontWeightRegular, md: FontWeights.fontWeightMedium },
                  maxWidth: 600,
                }}
              >
                To provide a comprehensive leverage and financing ecosystem using sneaker assets, METAZ plans to launch
                an official token in the near future. We anticipate that the token will reduce barriers for sneaker
                enthusiasts and crypto investors, enabling them to enhance their sneakers trading experience and
                returns.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              component="img"
              src={backgroundNetImage}
              alt="background net"
              sx={{ backgroundColor: COLOR_WHITE, width: '100%', height: '100%' }}
            />
          </Grid>
        </Grid>
      </FullFixedScreen>

      <FullFixedScreen
        sx={{
          backgroundColor: COLOR_BLACK,
        }}
      >
        <Box
          sx={{
            width: { xl: theme.breakpoints.values.xl },
            margin: 'auto',
            backgroundColor: { xs: COLOR_WHITE, sm: COLOR_BLACK },
            paddingX: 2.5,
            paddingY: 13.25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 32, md: 48 },
              lineHeight: { xs: 'normal', sm: '90px' },
              fontWeight: FontWeights.fontWeightSemiBold,
              color: { xs: COLOR_BLACK, sm: COLOR_WHITE },
              textAlign: 'center',
            }}
          >
            Maximise the value of sneakers!
          </Typography>
          <Box sx={{ paddingBottom: 2 }} />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 20 },
              lineHeight: { xs: '24px', md: '32px' },
              color: { xs: COLOR_BLACK, sm: COLOR_WHITE },
              textAlign: 'center',
            }}
          >
            Join meta[Z] today!
          </Typography>
          <Box sx={{ paddingBottom: { xs: 4, md: 9.25 } }} />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2.5, md: 5.5, lg: 7.5 }}
            sx={{ width: { xs: '100%', md: 'initial' } }}
          >
            <Button
              variant="contained"
              disabled={!!address.length}
              onClick={() => handleOpenModal(Modals.ConnectWallet)}
              sx={buttonSx}
            >
              Connect wallet and start
            </Button>
            <Button
              variant="contained"
              href="https://metaz.gitbook.io/how-it-works/about-us/meta-z"
              target="_blank"
              rel="noreferrer"
              sx={buttonSx}
            >
              Tell me more
            </Button>
          </Stack>
        </Box>
      </FullFixedScreen>
    </UndoContainer>
  );
};
