import { URL } from 'appConstants';
import { MetamaskLoginReq } from 'types';

import ajax from './ajax';

export default {
  getMetamaskMessage(data: string) {
    return ajax({
      method: 'get',
      url: URL.getMetamaskMessage(data),
    });
  },
  metamaskLogin(data: MetamaskLoginReq) {
    return ajax({
      method: 'post',
      url: URL.metamaskLogin,
      data,
    });
  },
  getSelfInfo() {
    return ajax({
      method: 'get',
      url: URL.selfInfo,
    });
  },
  patchSelfInfo(data: FormData) {
    return ajax({
      method: 'patch',
      url: URL.selfInfo,
      data,
    });
  },
};
