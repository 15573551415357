import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Avatar, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import { EmptyImage } from 'assets/img';
import { CopyText, Uploader } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector } from 'hooks';
import uiSelector from 'store/ui/selectors';
import { patchUserInfo } from 'store/user/actions';
import actionTypes from 'store/user/actionTypes';
import userSelector from 'store/user/selectors';
import { FontFamilies } from 'theme/Typography';
import { COLOR_GRAY_100, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { getToastMessage, logger } from 'utils';

import { EditProfileFormProps, getProps, validationSchema } from './EditProfile.helpers';

export const EditProfile: FC = () => {
  const user = useShallowSelector(userSelector.getUser);
  const { [actionTypes.PATCH_USER_INFO]: patchUserInfoRequestStatus } = useShallowSelector(uiSelector.getUI);
  const dispatch = useDispatch();

  const isPatchUserInfoLoading = useMemo(
    () => patchUserInfoRequestStatus === RequestStatus.REQUEST,
    [patchUserInfoRequestStatus],
  );

  const handleUploadAvatar = (value: Blob | string, setSubmitting: (value: boolean) => void) => {
    try {
      const formData = new FormData();
      formData.append('avatar', value);
      dispatch(patchUserInfo({ formData, setSubmitting }));
    } catch (e) {
      logger('uploadAvatar', e);
      getToastMessage('error', 'Something went wrong');
    }
  };

  const navigate = useNavigate();

  const handleNavigateToProfile = () => {
    navigate(routes.profile.root.getPath(user.customUrl || user.id));
  };
  const handleSubmit = (values: EditProfileFormProps, setSubmitting: (value: boolean) => void) => {
    const formData = new FormData();
    formData.append('display_name', values.displayName?.trim() || '');
    if (values.customUrl) formData.append('custom_url', values.customUrl?.trim().replaceAll(' ', '-') || '');
    formData.append('bio', values.bio || '');
    formData.append('site', values.site?.trim() || '');
    formData.append('email', values.email?.trim() || '');
    formData.append('twitter', values.twitter || '');
    formData.append('instagram', values.instagram || '');
    formData.append('facebook', values.facebook || '');
    dispatch(patchUserInfo({ formData, setSubmitting }));
    if (values.customUrl) navigate(routes.profile.edit.root.getPath(values.customUrl || user.id));
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      initialValues={getProps(user)}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {({ touched, errors, handleChange, handleBlur, values, isValid, setFieldValue, setSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Stack maxWidth="629px" spacing={1}>
              <Typography sx={{ typography: { xs: 'h2', md: 'h1' } }} className="uppercase">
                Edit profile
              </Typography>
              <Typography variant="h4" fontFamily={FontFamilies.primary} className="regular">
                You can set preferred display name, create your profile URL and manage other personal settings.
              </Typography>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={3}>
              <Uploader
                formikValue="avatar"
                isLoading={isPatchUserInfoLoading}
                isImgOnly
                maxSizeInMb={30}
                setFormat={(value) => setFieldValue('avatar', value)}
                handleUpload={(avatar) => handleUploadAvatar(avatar, setSubmitting)}
              >
                <Avatar
                  srcSet={values.avatar || EmptyImage}
                  sx={{
                    width: '112px',
                    height: '112px',
                  }}
                />
              </Uploader>
              <Stack spacing={1} maxWidth="313px" alignItems={{ xs: 'center', sm: 'flex-start' }}>
                <Typography variant="body1" className="s" textAlign={{ xs: 'center', sm: 'left' }}>
                  Acceptable file format: PNG, JPG, GIF maximum file size: 30 MB
                </Typography>
                <Typography variant="body1" className="s" textAlign={{ xs: 'center', sm: 'left' }}>
                  We recommend an image of at least 400x400. Gifs work too 🙌
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={3} maxWidth="992px">
              <Stack maxWidth="432px" width={{ xs: '100%', sm: '50%' }} spacing={2}>
                <Stack spacing={1}>
                  <Typography>Name</Typography>
                  <Field
                    id="displayName"
                    name="displayName"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Name"
                        autoComplete="off"
                        name="displayName"
                        value={values.displayName}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.displayName && !!errors.displayName}
                        helperText={touched.displayName && errors.displayName}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Custom URL</Typography>
                  <Field
                    id="customUrl"
                    name="customUrl"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Enter your username"
                        autoComplete="off"
                        name="customUrl"
                        value={values.customUrl || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.customUrl && !!errors.customUrl}
                        helperText={touched.customUrl && errors.customUrl}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Wallet Address</Typography>
                  <CopyText variant="contained" text={values.address || ''} />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Bio</Typography>
                  <Field
                    id="bio"
                    name="bio"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Input Text"
                        name="bio"
                        value={values.bio || ''}
                        autoComplete="off"
                        multiline
                        rows={5}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 500,
                        }}
                        onBlur={handleBlur}
                        helperText={`${values.bio?.length || 0}/500`}
                        error={touched.bio && !!errors.bio}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack maxWidth="432px" width={{ xs: '100%', sm: '50%' }} spacing={2}>
                <Stack spacing={1}>
                  <Typography>Contact email</Typography>
                  <Field
                    id="email"
                    name="email"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="@gmail.com"
                        autoComplete="off"
                        name="email"
                        value={values.email || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Website</Typography>
                  <Field
                    id="site"
                    name="site"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Input Text"
                        autoComplete="off"
                        name="site"
                        value={values.site || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.site && !!errors.site}
                        helperText={touched.site && errors.site}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Twitter</Typography>
                  <Field
                    id="twitter"
                    name="twitter"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Enter your username"
                        autoComplete="off"
                        name="twitter"
                        value={values.twitter || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.twitter && !!errors.twitter}
                        helperText={touched.twitter && errors.twitter}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Instagram</Typography>
                  <Field
                    id="instagram"
                    name="instagram"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Enter your username"
                        autoComplete="off"
                        name="instagram"
                        value={values.instagram || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.instagram && !!errors.instagram}
                        helperText={touched.instagram && errors.instagram}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography>Facebook</Typography>
                  <Field
                    id="facebook"
                    name="facebook"
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <TextField
                        variant="filled"
                        placeholder="Enter your username"
                        autoComplete="off"
                        name="facebook"
                        value={values.facebook || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.facebook && !!errors.facebook}
                        helperText={touched.facebook && errors.facebook}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Typography color={COLOR_GRAY_100} maxWidth="541px">
                To update your settings you should sign message through your wallet. Click Update profile then sign the
                message
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={!isValid || isPatchUserInfoLoading}
                  sx={{ minWidth: '140px', width: { xs: '100%', sm: '182px' }, whiteSpace: 'nowrap' }}
                >
                  {isPatchUserInfoLoading ? (
                    <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
                  ) : (
                    'Update profile'
                  )}
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ minWidth: '140px', width: { xs: '100%', sm: '182px' } }}
                  onClick={handleNavigateToProfile}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
