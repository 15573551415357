import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { RoundMinus, RoundPlus } from 'components';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_RED_100 } from 'theme/variables';

type QuantityCounterProps = {
  quantity: string;
  setQuantity: Dispatch<SetStateAction<string>>;
  maxQuantity: number;
  alignItems?: 'flex-start' | 'center';
  spacing?: number;
  label?: string;
};

export const QuantityCounter: FC<QuantityCounterProps> = ({
  quantity,
  setQuantity,
  maxQuantity,
  alignItems = 'flex-start',
  spacing = 2,
  label = 'Quantity',
}) => {
  const [error, setError] = useState('');

  const handleChangeQuantity = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (!Number.isNaN(+value) && +value >= 0) {
      setQuantity(value === '' || value === '-' ? '' : (+value).toFixed(0).toString());
    }
  };

  useEffect(() => {
    if (+quantity < 1) setError('Quantity should be equal or greater then 1');
    else if (+quantity > maxQuantity) setError(`Quantity should be equal or lower then ${maxQuantity}`);
    else setError('');
  }, [maxQuantity, quantity]);

  return (
    <Stack spacing={spacing} alignItems={alignItems}>
      <Typography>{label}</Typography>
      <Stack direction="row" spacing={1}>
        <IconButton disabled={+quantity <= 1} onClick={() => setQuantity((prev) => (+prev - 1).toString())}>
          <RoundMinus sx={{ color: +quantity <= 1 ? COLOR_GRAY_100 : COLOR_BLACK }} />
        </IconButton>
        <TextField
          variant="filled"
          value={quantity}
          error={!!error}
          onChange={handleChangeQuantity}
          sx={{
            '.MuiFilledInput-root': {
              padding: '7px 11px !important',
              width: '87px',
              height: '44px',
            },
            input: { textAlign: 'center', fontSize: '18px', lineHeight: '30px' },
          }}
        />
        <IconButton disabled={+quantity >= maxQuantity} onClick={() => setQuantity((prev) => (+prev + 1).toString())}>
          <RoundPlus sx={{ color: +quantity >= maxQuantity ? COLOR_GRAY_100 : COLOR_BLACK }} />
        </IconButton>
      </Stack>
      <Typography variant="subtitle1" textAlign="center" sx={{ color: COLOR_RED_100 }}>
        {error}
      </Typography>
    </Stack>
  );
};
