import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDownWhite: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: '12px', height: '8px', fill: 'none', ...sx }} viewBox="0 0 12 8">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.351472 0.751374C0.820101 0.282745 1.5799 0.282745 2.04853 0.751374L6 4.70285L9.95147 0.751374C10.4201 0.282745 11.1799 0.282745 11.6485 0.751374C12.1172 1.22 12.1172 1.9798 11.6485 2.44843L6.84853 7.24843C6.3799 7.71706 5.6201 7.71706 5.15147 7.24843L0.351472 2.44843C-0.117157 1.9798 -0.117157 1.22 0.351472 0.751374Z"
      fill="currentColor"
    />
  </SvgIcon>
);
