import { FC, useCallback, useEffect, useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { ArrowLeftGreen, ArrowRightGreen, Close } from 'components';
import { useShallowSelector } from 'hooks';
import { closeModal } from 'store/modals/reducer';
import modalSelector from 'store/modals/selectors';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';

import { SellerCard } from './components';

export const BuyMultipleModal: FC = () => {
  const { activeModal } = useShallowSelector(modalSelector.getProp('modalState'));
  const { sellers } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const address = useShallowSelector(userSelector.getProp('address'));
  const dispatch = useDispatch();

  const otherSellers = useMemo(
    () => (sellers && sellers.filter((seller) => seller?.address !== address)) || [],
    [address, sellers],
  );

  const handleCloseModal = () => dispatch(closeModal());

  const getNavButton = useCallback(
    (onClick: () => void, next: boolean, prev: boolean) => (
      <Button
        variant="outlined"
        onClick={() => onClick()}
        sx={{
          position: 'absolute',
          bottom: '0px',
          width: '44px',
          minWidth: '44px',
          height: '44px',
          minHeight: '44px',
          borderRadius: '50%',
          left: prev ? '80px' : 'none',
          right: next ? '80px' : 'none',
        }}
      >
        {prev && <ArrowLeftGreen />}
        {next && <ArrowRightGreen />}
      </Button>
    ),
    [],
  );

  useEffect(
    () => () => {
      dispatch(closeModal());
    },
    [dispatch],
  );

  return (
    <Dialog
      open={activeModal === 'BuyMultiple'}
      onClose={handleCloseModal}
      sx={{
        '.MuiPaper-root': {
          overflow: 'hidden',
          padding: 2,
          width: '391px',
          // minHeight: '419px',
          borderRadius: '8px',
        },
      }}
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
          <Stack spacing={1}>
            <Typography variant="h3" textTransform="uppercase" sx={{ color: COLOR_BLACK }}>
              buy multiple nft
            </Typography>
            <Typography variant="subtitle1" sx={{ color: COLOR_GRAY_150 }}>
              Select the owner to purchase the NFT
            </Typography>
          </Stack>
          <Button variant="text" onClick={handleCloseModal}>
            <Close sx={{ color: COLOR_BLACK }} />
          </Button>
        </Stack>
        <Carousel
          animation="slide"
          autoPlay={false}
          indicators={false}
          cycleNavigation={otherSellers.length > 1}
          navButtonsAlwaysVisible
          NavButton={({ onClick, prev, next }) => getNavButton(onClick as () => void, next, prev)}
        >
          {otherSellers.map((seller, index) => (
            <SellerCard key={`${index + 1}`} seller={seller} />
          ))}
        </Carousel>
      </Stack>
    </Dialog>
  );
};
