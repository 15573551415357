import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { setActiveModal } from 'store/modals/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Modals } from 'types';
import { logger } from 'utils';

import { custodyUnlisted } from '../actions';
import actionTypes from '../actionTypes';

export function* custodyUnlistedSaga({
  type,
  payload: { data, setSubmitting, resetForm },
}: ReturnType<typeof custodyUnlisted>) {
  yield* put(request(type));
  try {
    yield* call(baseApi.custodyUnlisted, { data });

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('custodyUnlisted', err);
  } finally {
    yield put(setActiveModal({ activeModal: Modals.ApproveCustody, open: true }));
    setSubmitting(false);
    resetForm();
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CUSTODY_UNLISTED, custodyUnlistedSaga);
}
