import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Person: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      color: 'transparent',
      width: '20px',
      height: '20px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.359 7.53187C5.359 4.96871 7.43684 2.89087 10 2.89087C12.5631 2.89087 14.641 4.96871 14.641 7.53187C14.641 10.0731 12.5985 12.1374 10.0655 12.1724C10.0437 12.1723 10.0218 12.1722 10 12.1722C9.97815 12.1722 9.95631 12.1723 9.93448 12.1724C7.40153 12.1374 5.359 10.0731 5.359 7.53187ZM6.08935 12.8999C4.43433 11.6922 3.359 9.73759 3.359 7.53187C3.359 3.86414 6.33227 0.890869 10 0.890869C13.6677 0.890869 16.641 3.86414 16.641 7.53187C16.641 9.73759 15.5657 11.6921 13.9106 12.8999C14.4355 13.1022 14.9457 13.3457 15.4363 13.629C17.0891 14.5834 18.4616 15.956 19.4157 17.6089C19.6918 18.0873 19.5279 18.6988 19.0496 18.9749C18.5712 19.251 17.9597 19.0871 17.6836 18.6088C16.905 17.2599 15.785 16.1398 14.4362 15.361C13.1078 14.594 11.6037 14.1847 10.0706 14.1725C10.0471 14.1727 10.0236 14.1729 10 14.1729C9.97644 14.1729 9.9529 14.1727 9.9294 14.1725C8.39634 14.1847 6.89215 14.594 5.56377 15.361C4.21501 16.1398 3.09504 17.2599 2.31643 18.6088C2.04034 19.0871 1.42876 19.251 0.950442 18.9749C0.472124 18.6988 0.308193 18.0873 0.584291 17.6089C1.53842 15.956 2.91088 14.5834 4.56369 13.629C5.05432 13.3457 5.56452 13.1022 6.08935 12.8999Z"
      fill="currentColor"
    />
  </SvgIcon>
);
