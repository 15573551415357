import { Box, BoxProps } from '@mui/material';

export const Chevron = (boxProps: BoxProps) => (
  <Box
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="44"
    viewBox="0 0 30 44"
    fill="none"
    {...boxProps}
    sx={{ width: 30, height: 44, ...boxProps?.sx }}
  >
    <path d="M30 22L1.77553e-06 43.6506L3.66829e-06 0.349364L30 22Z" fill="white" />
  </Box>
);
