import { LibraryAddOutlined } from '@mui/icons-material';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { Box, styled } from '@mui/material';
import { routes } from 'appConstants/routes';
import { PolygonLogo } from 'assets/img';
import { DollarCoin, Order, Person, PlusGreen, Setting } from 'components';
import { NavSelectProps } from 'modules/profile/pages/Profile/components/NavSelect';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';
import { Chains } from 'types';
import { flexHelper } from 'utils/flexHelper';

const IconBox = styled(Box)({
  width: '24px',
  ...flexHelper(),
});

export const navButtonsMock = [
  {
    id: 1,
    title: 'My Profile',
    icon: <Person sx={{ color: COLOR_BLACK }} />,
    getPath: (id: number | string) => routes.profile.root.getPath(id),
  },
  {
    id: 2,
    title: 'Collected',
    componentType: 'accordion',
    // reason: args could be anything depends on componentType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getComponentProps: (...args: any[]): NavSelectProps => {
      const [type, profileId] = args;
      return {
        title: 'Collected',
        titleIcon: (
          <Box width="36px" {...flexHelper()}>
            <AllInboxOutlinedIcon
              sx={{
                mr: 1.5,
                color: COLOR_BLACK,
              }}
            />
          </Box>
        ),
        isActivePath: true,
        menuItems: [
          {
            linkIcon: (
              <Box width="36px" {...flexHelper()}>
                <DollarCoin
                  sx={{
                    mr: 1.5,
                    color: type === 'on-sale' ? COLOR_BLACK : COLOR_GRAY_150,
                  }}
                />
              </Box>
            ),
            linkTitle: 'On sale',
            isActiveLink: type === 'on-sale',
            path: routes.profile.nft.root.getPath(profileId, 'on-sale'),
          },
          {
            linkIcon: (
              <Box width="36px" {...flexHelper()}>
                <FolderOutlinedIcon
                  fontSize="small"
                  sx={{ color: type === 'owned' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }}
                />
              </Box>
            ),
            linkTitle: 'Owned',
            isActiveLink: type === 'owned',
            path: routes.profile.nft.root.getPath(profileId, 'owned'),
          },
        ],
        variant: 'nosqueeze',
      };
    },
  },
  {
    id: 3,
    title: 'Activity',
    componentType: 'accordion',
    // reason: args could be anything depends on componentType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getComponentProps: (...args: any[]): NavSelectProps => {
      const [type, profileId] = args;
      return {
        title: 'Activity',
        titleIcon: (
          <Box width="36px" {...flexHelper()}>
            <StarBorderRoundedIcon
              fontSize="large"
              sx={{
                mr: 1.5,
                color: COLOR_BLACK,
                width: '28px',
                height: '28px',
              }}
            />
          </Box>
        ),
        isActivePath: true,
        menuItems: [
          {
            linkIcon: (
              <Box width="36px" {...flexHelper()}>
                <Inventory2OutlinedIcon
                  fontSize="small"
                  sx={{ color: type === 'sold' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5 }}
                />
              </Box>
            ),
            linkTitle: 'Sold',
            isActiveLink: type === 'sold',
            path: routes.profile.nft.root.getPath(profileId, 'sold'),
          },
          {
            linkIcon: (
              <Box width="36px" {...flexHelper()}>
                <GavelIcon sx={{ color: type === 'bidded' ? COLOR_BLACK : COLOR_GRAY_150, mr: 1.5, width: '20px' }} />
              </Box>
            ),
            linkTitle: 'Bidded',
            isActiveLink: type === 'bidded',
            path: routes.profile.nft.root.getPath(profileId, 'bidded'),
          },
        ],
        variant: 'nosqueeze',
      };
    },
  },
  {
    id: 4,
    title: 'Favorited',
    icon: (
      <IconBox>
        <FavoriteBorderOutlinedIcon sx={{ color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: (id: number | string) => routes.profile.nft.root.getPath(id, 'favorited'),
  },
  {
    id: 5,
    title: 'Orders history',
    icon: (
      <IconBox>
        <Order sx={{ color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: (id: number | string) => routes.profile.ordersHistory.root.getPath(id),
  },
  {
    id: 6,
    title: 'Custody request',
    icon: (
      <IconBox>
        <ShoppingBagOutlinedIcon sx={{ color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: (id: number | string) => routes.profile.custodyRequests.root.getPath(id),
  },
  {
    id: 7,
    title: 'Create NFT',
    icon: (
      <IconBox>
        <PlusGreen sx={{ width: '24px', height: '24px', color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: () => routes.create.root.path,
  },
  {
    id: 8,
    title: 'Create collection',
    icon: (
      <IconBox>
        <LibraryAddOutlined sx={{ width: '24px', height: '24px', color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: () => routes.create.collection.root.path,
  },
  {
    id: 9,
    title: 'Setting',
    icon: (
      <IconBox>
        <Setting sx={{ color: COLOR_BLACK }} />
      </IconBox>
    ),
    getPath: (id: number | string) => routes.profile.edit.root.getPath(id),
  },
];

export const networkMock = {
  // [Chains.Ethereum]: { name: 'Ethereum', icon: <EthereumLogo /> },
  [Chains.Polygon]: { name: 'Polygon', icon: <PolygonLogo /> },
};
