import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Bid: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 22"
    sx={{
      width: '20px',
      height: '22px',
      fill: 'none',
      path: {
        strokeWidth: '1.33333px',
      },
      ...sx,
    }}
  >
    <path
      d="M6.1665 10.3332L6.83317 10.9998M10.4998 14.6665L9.83317 13.9998M6.83317 10.9998L11.4998 6.33317L14.4998 9.33317M6.83317 10.9998L9.83317 13.9998M14.4998 9.33317L15.1665 9.99984L10.8332 5.6665M14.4998 9.33317L12.1665 11.6665M9.83317 13.9998L12.1665 11.6665M12.1665 11.6665L15.4998 14.9998"
      stroke="currentColor"
    />
    <path d="M4.5 16.333H10.8333" stroke="currentColor" />
  </SvgIcon>
);
