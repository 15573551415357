import { Box } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK } from 'theme/variables';

export const roadMapSlides = [
  {
    title: 'Request Custody Service',
    text: (
      <>
        Request Custody of your limited edition sneakers
        <br />
        <br />
        <Box component="a" href="/custody" sx={{ color: COLOR_BLACK, fontWeight: FontWeights.fontWeightSemiBold }}>
          Go and request! ›
        </Box>
      </>
    ),
  },
  {
    title: 'Authentication',
    text: (
      <>
        Once sneakers arrive in meta[Z] following custody service request, meta[Z] proceeds with inspection of the
        sneakers.
      </>
    ),
  },
  {
    title: ' NFT Airdrop',
    text: (
      <>
        When the custody process is successfully completed, the NFT of the sneakers will be airdropped to the
        collector&apos;s wallet.
      </>
    ),
  },
  {
    title: 'Apply for De-Fi Service',
    text: (
      <>
        Get a loan on your NFT and borrow USDC up to 50% of your sneakers NFTs based on the current market price of
        sneakers
      </>
    ),
  },
];

export const testimonialSlides = [
  {
    text: '“I added one more Jordan1 High Fragment to my collection, leveraging my NFTs through meta[Z] De-Fi!”',
    author: 'Afsider',
  },
  {
    text: '“All my sneakers are in cool place of meta[Z]. I’m turning the sneakers room into my studio now.”',
    author: 'Peter',
  },
  {
    text: '“I’m flexing my sneakers like no other. All in my wallet, but cooler than ever”',
    author: 'Tobby',
  },
  {
    text: '“My sneakers are safe from my beloved cats and dogs. I’m relieved and happy for my sneakers in safe custody.”',
    author: 'Kuda',
  },
  {
    text: '“I sold my sneakers with very little commision and I didn’t even have to send my sneakers. Absolutely, no hassle or wait. Just right and convenient.”',
    author: 'Brian',
  },
];
