import { Box, BoxProps, useTheme } from '@mui/material';

export const FullFixedScreen = (props: BoxProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={{
        width: { xl: '100vw' },
        marginLeft: { xl: `calc(${theme.breakpoints.values.xl}px/2 - 100vw/2)` },
        ...props?.sx,
      }}
    />
  );
};
