import { AbiItem } from 'web3-utils';

import polygonErc721 from './erc721.abi.json';
import polygonErc721Fabric from './erc721Fabric.abi.json';
import polygonErc1155 from './erc1155.abi.json';
import polygonErc1155Fabric from './erc1155Fabric.abi.json';
import polygonExchange from './exchange.abi.json';
import polygonRedeem from './redeem.abi.json';
import polygonUsdc from './usdc.abi.json';
import polygonWeth from './weth.abi.json';
import polygonWmatic from './wmatic.abi.json';

export const polygonErc721Abi = polygonErc721 as AbiItem[];
export const polygonErc1155Abi = polygonErc1155 as AbiItem[];
export const polygonErc721FabricAbi = polygonErc721Fabric as AbiItem[];
export const polygonErc1155FabricAbi = polygonErc1155Fabric as AbiItem[];
export const polygonExchangeAbi = polygonExchange as AbiItem[];
export const polygonRedeemAbi = polygonRedeem as AbiItem[];
export const polygonUsdcAbi = polygonUsdc as AbiItem[];
export const polygonWethAbi = polygonWeth as AbiItem[];
export const polygonWmaticAbi = polygonWmatic as AbiItem[];
