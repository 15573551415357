import { FC, PropsWithChildren } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Dialog, IconButton, styled, Typography } from '@mui/material';
import { COLOR_BLACK } from 'theme/variables';

export interface ModalProps {
  // size?: 'sm' | 'md' | 'lg';
  open: boolean;
  onClose: () => void;
  closable?: boolean;
  title?: string;
}

const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  top: '18px',
  right: '19px',
  color: COLOR_BLACK,
});

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ open, onClose, closable = true, title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transitionDuration={{
        enter: 250,
        exit: 100,
      }}
    >
      {title && (
        <Box
          sx={(theme) => ({
            position: 'relative',
            padding: theme.spacing(2),
          })}
        >
          <Typography
            variant="h4"
            color={COLOR_BLACK}
            className="uppercase"
            sx={(theme) => ({ paddingRight: theme.spacing(3) })}
          >
            {title}
          </Typography>
          {closable && (
            <CloseIconButton onClick={onClose}>
              <CloseRoundedIcon sx={{ width: '18px', height: '18px' }} />
            </CloseIconButton>
          )}
        </Box>
      )}

      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 2, 3),
        })}
      >
        {children}
      </Box>
    </Dialog>
  );
};
