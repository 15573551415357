import { FC, useCallback, useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useShallowSelector } from 'hooks';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_10, COLOR_LIGHT, COLOR_LIGHT_GRAY } from 'theme/variables';
import { Chains } from 'types';
import { constructExplorerUrl } from 'utils';

import { NftInfoBlock } from '../NftInfoBlock';

export const NftInfoTabs: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { owners, history, bids, network, standart } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { chainType } = useShallowSelector(userSelector.getUser);

  const isAdmin = useCallback(
    (userAddress: string) => history?.find((action) => action?.method === 'Mint')?.toAddress === userAddress,
    [history],
  );

  return (
    <Box border={`1px solid ${COLOR_LIGHT}`} borderRadius={2} p={3} pt={0} mt={3.5}>
      <Tabs
        value={activeTab}
        onChange={(event, value) => setActiveTab(value)}
        aria-label="nft info tabs"
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab label="Owner" value={0} />
        <Tab label="History" value={1} />
        {bids?.length ? <Tab label="Bids" value={2} /> : ''}
      </Tabs>
      <Box
        mt="18px"
        maxHeight="150px"
        sx={{
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
          scrollbarColor: `${COLOR_GRAY_10} ${COLOR_LIGHT_GRAY}`,
          '&::-webkit-scrollbar': {
            backgroundColor: COLOR_LIGHT_GRAY,
            width: 4,
          },
          '&::-webkit-scrollbar-thumb': {
            width: 1,
            borderRadius: BORDER_RADIUS_DEFAULT,
            background: COLOR_GRAY_10,
          },
        }}
      >
        {activeTab === 0 ? (
          <Stack spacing={2}>
            {owners && owners?.length
              ? owners.map((owner, index) => (
                  <NftInfoBlock
                    key={`${index + 1}`}
                    tab="owner"
                    userLink={`/profile/${owner?.url || '1'}`}
                    isAdmin={isAdmin(owner?.address || '')}
                    avatar={owner?.avatar || ''}
                    name={owner?.name || 'Noname'}
                    address={owner?.address ? owner.address : 'no address'}
                    quantity={owner?.quantity || 0}
                    isMultiple={standart === 'ERC1155'}
                  />
                ))
              : ''}
          </Stack>
        ) : (
          ''
        )}
        {activeTab === 1 ? (
          <Stack spacing={2}>
            {history &&
              history?.length &&
              history.map((action) => (
                <NftInfoBlock
                  key={action?.id}
                  tab="history"
                  operation={action?.method || ''}
                  userLink={`/profile/${
                    (action?.method === 'Buy' || action?.method === 'Auctionwin' ? action?.toId : action?.fromId) ||
                    action?.toId ||
                    1
                  }`}
                  txLink={constructExplorerUrl(action.txHash || '', (network?.name || '') as Chains, chainType, 'tx')}
                  isAdmin={isAdmin(action?.fromAddress || action?.toAddress || '')}
                  avatar={
                    (action?.method === 'Buy' || action?.method === 'Auctionwin'
                      ? action?.toImage
                      : action?.fromImage) ||
                    action?.toImage ||
                    ''
                  }
                  name={
                    (action?.method === 'Buy' || action?.method === 'Auctionwin' ? action?.toName : action?.fromName) ||
                    action?.toName ||
                    ''
                  }
                  address={
                    (action?.method === 'Buy' || action?.method === 'Auctionwin'
                      ? action?.toAddress
                      : action?.fromAddress) ||
                    action?.toAddress ||
                    ''
                  }
                  price={action?.price || 0}
                  currency={action?.currency?.toUpperCase() || 'ETH'}
                  data={action?.date || ''}
                />
              ))}
          </Stack>
        ) : (
          ''
        )}
        {activeTab === 2 ? (
          <Stack spacing={2}>
            {bids && bids.length
              ? bids.map((bid) => (
                  <NftInfoBlock
                    key={bid?.id}
                    tab="bid"
                    userLink={`/profile/${bid?.user?.id || ''}`}
                    isAdmin={isAdmin(bid?.user?.address || '')}
                    avatar={bid?.user?.avatar || ''}
                    name={bid?.user?.name || ''}
                    address={bid?.user?.address || ''}
                    price={bid?.amount || 0}
                    currency={bid?.currency?.symbol?.toUpperCase() || 'ETH'}
                  />
                ))
              : ''}
          </Stack>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};
