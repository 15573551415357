import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from 'store/orders/actionTypes';
import { call, put, takeLatest } from 'typed-redux-saga';
import { FullCategory } from 'types/api';
import { camelize, logger } from 'utils';

import { getCustodyLists } from '../actions';
import { setCustodyLists, setLocations } from '../reducer';

export function* getCustodyListsSaga({ type }: ReturnType<typeof getCustodyLists>) {
  yield* put(request(type));

  try {
    const { data } = yield* call(baseApi.getCustodyLists);
    const result = camelize(data).find((item: FullCategory) => item.name === 'SneakerZ');

    yield* put(setCustodyLists(result.listedItems));
    yield* put(setLocations(result?.propertyData?.location?.values || []));

    yield* put(success(type));
  } catch (e) {
    yield* put(error(type, e));
    logger('getCustodyListsSaga', e);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CUSTODY_LISTS, getCustodyListsSaga);
}
