import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/Modal';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services/WalletConnect';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import actionTypes from 'store/orders/actionTypes';
import uiSelector from 'store/ui/selectors';
import { Modals, ModalsInitialState, RequestStatus, State } from 'types/store';

import { ActivityModal } from './ActivityModal';
import { ConnectWalletModal, ConvertModal, CustodyModal, DisconnectModal, modalData } from './index';
import { NetworkModal } from './NetworkModal';

export const AppModal: FC = () => {
  const dispatch = useDispatch();
  const { modalState } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const { connect, disconnect } = useWalletConnectorContext();
  const currData = modalData[modalState.activeModal];
  const {
    [actionTypes.CUSTODY_UNLISTED]: custodyUnlistedRequestStatus,
    [actionTypes.CUSTODY_LISTED]: custodyListedRequestStatus,
    [actionTypes.SEND_DELIVERY_INFO]: sendDeliveryInfoRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const isCustodyUnlistedSuccess = useMemo(
    () => custodyUnlistedRequestStatus === RequestStatus.SUCCESS,
    [custodyUnlistedRequestStatus],
  );
  const isCustodyListedSuccess = useMemo(
    () => custodyListedRequestStatus === RequestStatus.SUCCESS,
    [custodyListedRequestStatus],
  );
  const isSendDeliveryInfoSuccess = useMemo(
    () => sendDeliveryInfoRequestStatus === RequestStatus.SUCCESS,
    [sendDeliveryInfoRequestStatus],
  );
  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  if (modalState.activeModal === Modals.init) return null;

  return (
    <Modal open={modalState.open} onClose={closeModal} title={currData.title} closable>
      {modalState.activeModal === Modals.ConnectWallet && (
        <ConnectWalletModal closeModal={closeModal} onConnectWallet={connect} currData={currData} />
      )}
      {modalState.activeModal === Modals.Disconnect && (
        <DisconnectModal closeModal={closeModal} disconnect={disconnect} />
      )}
      {modalState.activeModal === Modals.Network && <NetworkModal onConnectNetwork={connect} closeModal={closeModal} />}
      {modalState.activeModal === Modals.Activity && <ActivityModal closeModal={closeModal} />}
      {modalState.activeModal === Modals.Custody && (
        <CustodyModal
          subtitle={isSendDeliveryInfoSuccess ? 'Delivery info successfully send' : 'Failed to send delivery info'}
          closeModal={closeModal}
          isSuccess={isSendDeliveryInfoSuccess}
        />
      )}
      {modalState.activeModal === Modals.ApproveCustody && (
        <CustodyModal
          subtitle={
            isCustodyUnlistedSuccess || isCustodyListedSuccess
              ? 'Order successfully placed. Expect approval.'
              : 'Failed to place order'
          }
          closeModal={closeModal}
          isSuccess={isCustodyUnlistedSuccess || isCustodyListedSuccess}
        />
      )}
      {modalState.activeModal === Modals.Convert && <ConvertModal closeModal={closeModal} />}
    </Modal>
  );
};
