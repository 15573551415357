import { FC, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useValidateInputField, ValidationTypes } from 'hooks';

export const PriceOrBidTextField: FC<TextFieldProps> = (props) => {
  const [priceOrBid, handleChangePriceOrBid] = useValidateInputField({ type: ValidationTypes.number });
  const { setFieldValue: setValue } = useFormikContext();

  useEffect(() => {
    setValue('priceOrBid', priceOrBid);
  }, [priceOrBid, setValue]);

  return (
    <Field
      id="priceOrBid"
      name="priceOrBid"
      render={({ form: { isSubmitting } }: FieldProps) => (
        <TextField {...props} disabled={isSubmitting} onChange={handleChangePriceOrBid} />
      )}
    />
  );
};
