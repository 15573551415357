import { FC } from 'react';
import { Box, Divider, Link, Stack, styled, Typography } from '@mui/material';
import { Link as LinkIcon } from 'components';
import { useShallowSelector } from 'hooks';
import moment from 'moment';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { constructExplorerUrl, shortenPhrase } from 'utils';

const TextRow = styled(Stack)({
  flexDirection: 'row',
  flexWrap: 'wrap',
});
export const NftDetail: FC = () => {
  const { network: chain, chainType } = useShallowSelector(userSelector.getUser);
  const {
    standart,
    id: tokenId,
    network,
    createdAt,
    collection,
    totalSupply,
  } = useShallowSelector(nftSelector.getProp('detailedNft'));

  return (
    <Stack spacing={2.5} mt={3}>
      <Typography variant="h4" pl={2}>
        Item Detail
      </Typography>
      <Divider />
      <Box pl={2}>
        <TextRow>
          <Typography>Contract Address:&nbsp;</Typography>
          {collection?.address ? (
            <Box display="flex" flexWrap="nowrap">
              <Typography>{shortenPhrase(collection.address)}</Typography>
              <Link
                href={constructExplorerUrl(collection.address, chain, chainType, 'address')}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon sx={{ ml: '12px' }} />
              </Link>
            </Box>
          ) : (
            <Typography>no contract address</Typography>
          )}
        </TextRow>
        <TextRow>
          <Typography>Token ID:&nbsp;</Typography>
          <Typography>{tokenId || 'no token ID'}</Typography>
        </TextRow>
        <TextRow>
          <Typography>Token Standard:&nbsp;</Typography>
          <Typography>{standart || 'no token standard'}</Typography>
        </TextRow>
        <TextRow>
          <Typography>Blockchain:&nbsp;</Typography>
          <Typography>{network?.name || 'no blockchain'}</Typography>
        </TextRow>
        <TextRow>
          <Typography>Created at:&nbsp;</Typography>
          <Typography>{moment(createdAt).format('DD.MM.YYYY') || 'no data'}</Typography>
        </TextRow>
        <TextRow>
          <Typography>Transaction fee:&nbsp;</Typography>
          <Typography>{parseFloat(collection?.royaltyPercentage || '0')}%</Typography>
        </TextRow>
        <TextRow>
          <Typography>Total supply:&nbsp;</Typography>
          <Typography>{totalSupply}</Typography>
        </TextRow>
      </Box>
    </Stack>
  );
};
