import { toast } from 'react-toastify';
import { ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import networkSelector from 'store/network/selectors';
import nftActionTypes from 'store/nft/actionTypes';
import { approveSaga } from 'store/nft/sagas/approve';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger } from 'utils';

import { payForDelivery } from '../actions';
import actionTypes from '../actionTypes';

import { getHistorySaga } from './getHistory';

export function* payForDeliverySaga({
  type,
  payload: { type: orderType, id, web3Provider, itemsPerPage },
}: ReturnType<typeof payForDelivery>) {
  yield put(request(type));

  const address: string = yield select(userSelector.getProp('address'));
  const { paymentGetterAddress } = yield select(networkSelector.getNetwork);

  try {
    const { data } = yield call(
      orderType === 'redeem' ? baseApi.payForDeliveryRedeem : baseApi.payForDeliveryCustody,
      id,
    );

    if (data.initial_tx) {
      try {
        if (data.approve_amount) {
          try {
            yield call(approveSaga, {
              type: nftActionTypes.APPROVE,
              payload: {
                web3Provider,
                amount: data.approve_amount,
                spender: paymentGetterAddress,
                currencySymbol: ContractsNames.USDC,
                isBid: false,
              },
            });
          } catch {
            return;
          }
        }

        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        yield call(getHistorySaga, {
          type: actionTypes.GET_HISTORY,
          payload: {
            type: orderType,
            page: 1,
            itemsPerPage,
          },
        });

        toast.success('You have successfully paid for delivery');
        yield put(success(type));
      } catch (err) {
        logger('Send transaction for pay for delivery', err);

        yield call(orderType === 'redeem' ? baseApi.redeemPaymentReject : baseApi.custodyPaymentReject, id);

        toast.error('Something went wrong');
        yield put(error(type, err));
      }
    } else {
      toast.error('No initial tx');
      yield put(error(type));
    }

    yield put(success(type));
  } catch (err) {
    logger('Pay for delivery', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.PAY_FOR_DELIVERY, payForDeliverySaga);
}
