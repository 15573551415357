import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { COLOR_GREEN } from 'theme/variables';

export const Copy: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      color: 'transparent',
      width: '25px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      d="M8.62036 16H6.62036C5.51579 16 4.62036 15.1046 4.62036 14V6C4.62036 4.89543 5.51579 4 6.62036 4H14.6204C15.7249 4 16.6204 4.89543 16.6204 6V8M10.6204 20H18.6204C19.7249 20 20.6204 19.1046 20.6204 18V10C20.6204 8.89543 19.7249 8 18.6204 8H10.6204C9.51579 8 8.62036 8.89543 8.62036 10V18C8.62036 19.1046 9.51579 20 10.6204 20Z"
      stroke={COLOR_GREEN}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
