import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Button, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import apiActions from 'store/api/actions';
import ordersActionTypes from 'store/orders/actionTypes';
import userSelector from 'store/user/selectors';
import { COLOR_BLACK, COLOR_GRAY_200, COLOR_RED_300 } from 'theme/variables';
import { INotifyModalProps } from 'types';

interface CustodyModalProps extends INotifyModalProps {
  subtitle: string;
  isSuccess: boolean;
}

export const CustodyModal: FC<CustodyModalProps> = ({ subtitle, closeModal, isSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId, customUrl } = useShallowSelector(userSelector.getUser);
  const handleCreateNewOrder = () => {
    closeModal();
    navigate(routes.profile.custody.root.getPath(customUrl || userId));
  };
  const handleNavigateToHistory = () => {
    closeModal();
    navigate(routes.profile.custodyRequests.root.getPath(customUrl || userId));
  };
  useEffect(() => {
    return () => {
      dispatch(apiActions.reset(ordersActionTypes.CUSTODY_UNLISTED));
      dispatch(apiActions.reset(ordersActionTypes.SEND_DELIVERY_INFO));
    };
  }, [dispatch]);
  return (
    <Stack spacing={2} alignItems="center" mt={3}>
      {isSuccess ? (
        <CheckCircleRoundedIcon sx={{ width: '103px', height: '103px', color: COLOR_BLACK, mb: 1 }} />
      ) : (
        <CancelRoundedIcon sx={{ width: '103px', height: '103px', color: COLOR_RED_300, mb: 1 }} />
      )}
      <Typography textAlign="center" sx={{ display: 'block', width: '250px' }}>
        {subtitle}
      </Typography>
      <Stack spacing={1}>
        <Button variant="contained" size="large" onClick={handleCreateNewOrder}>
          Create new order
        </Button>
        <Button
          variant="outlined"
          size="large"
          onClick={handleNavigateToHistory}
          sx={{
            '&:hover': {
              backgroundColor: COLOR_GRAY_200,
            },
          }}
        >
          View custody requests
        </Button>
      </Stack>
    </Stack>
  );
};
