import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { CheckedRounded } from 'components';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_LIGHT, COLOR_LIGHT_GRAY, COLOR_WHITE, COLOR_WHITE_OPACITY_20 } from 'theme/variables';

export const getMuiCheckboxOverrides = (theme: Theme): ComponentsOverrides['MuiCheckbox'] => ({
  root: {
    background: COLOR_WHITE,
    border: '1px solid',
    borderColor: COLOR_LIGHT,
    borderRadius: '4px',
    width: '22px',
    height: '22px',
    '&:hover': {
      background: COLOR_LIGHT_GRAY,
      borderColor: COLOR_BLACK,
    },
    '+ span': {
      paddingLeft: theme.spacing(1),
      color: COLOR_BLACK,
      fontWeight: FontWeights.fontWeightRegular,
    },
    svg: {
      fill: 'none',
    },
    '&.Mui-checked': {
      background: COLOR_BLACK,
      border: COLOR_BLACK,
      svg: {
        fill: COLOR_WHITE,
      },
      '+ span': {
        paddingLeft: theme.spacing(1),
        color: COLOR_BLACK,
      },
    },
    '&.Mui-disabled': {
      color: `${COLOR_BLACK} !important`,
      borderColor: COLOR_LIGHT_GRAY,
      background: COLOR_LIGHT,
      cursor: 'not-allowed',
    },
  },
});

export const getMuiCheckboxDefaultProps = (): ComponentsProps['MuiCheckbox'] => ({
  checkedIcon: <CheckedRounded />,
});
