import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { logger } from 'utils';

import { likeNft } from '../actions';
import actionTypes from '../actionTypes';

export function* likeNftSaga({ type, payload: { id, successCallback, errorCallback } }: ReturnType<typeof likeNft>) {
  yield put(request(type));
  try {
    yield call(baseApi.likeNft, +id);
    successCallback();
    yield put(success(type));
  } catch (err) {
    logger('like nft', err);
    errorCallback();
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LIKE_NFT, likeNftSaga);
}
