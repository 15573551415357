import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { TitleText } from 'modules/landing/pages/Home/Home';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY, COLOR_RED_300 } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

export type HotStylesBlockProps = {
  img: string;
  title: string;
  hashtags: string[];
  description: string;
  isLastRow?: boolean;
};

export const HotStylesBlock: FC<HotStylesBlockProps> = ({ img, description, hashtags, title, isLastRow = false }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(routes.explore.root.getPath({ property: { Style: [title] } }));
  };

  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      p={{ xs: 0, sm: 4 }}
      spacing={2}
      justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      sx={{
        borderBottom: { xs: 'none', sm: !isLastRow ? `1px dashed ${COLOR_GRAY_150}` : 'none' },
        '&:hover': { background: COLOR_LIGHT_GRAY },
      }}
    >
      <Box
        component="img"
        onClick={handleNavigate}
        src={img}
        alt={`hot-style-${title}`}
        sx={{ width: { xs: '100%', lg: 300 }, borderRadius: '8px', cursor: 'pointer', mx: { xs: 1.25, sm: 0 } }}
      />
      <Stack height="100%" width="100%" justifyContent="space-between" spacing={2}>
        <Stack spacing={{ xs: 2, lg: 0 }}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <TitleText>{title}</TitleText>
            <Box {...flexHelper()} width={72} height={28} border={`1px solid ${COLOR_RED_300}`} borderRadius="4px">
              <Typography
                fontFamily={FontFamilies.quaternary}
                fontWeight={FontWeights.fontWeightBold}
                color={COLOR_RED_300}
              >
                HOT
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            {hashtags &&
              hashtags.map((item, index) => (
                <Typography key={`${index + 1}`} fontSize={18} fontWeight={FontWeights.fontWeightSemiBold}>
                  #{item}
                </Typography>
              ))}
          </Stack>
        </Stack>
        <Box border={`1px solid ${COLOR_BLACK}`} borderRadius="8px">
          <Box padding={1} borderBottom={`1px solid ${COLOR_BLACK}`}>
            <Typography>Description</Typography>
          </Box>
          <Box padding={1}>{description}</Box>
        </Box>
      </Stack>
    </Stack>
  );
};
