import { Button, buttonClasses, styled } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import {
  COLOR_BLACK,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GRAY_200,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export const TertiaryButton = styled(Button)({
  minWidth: '76px',
  height: '36px',
  fontSize: '16px',
  whiteSpace: 'nowrap',
  fontWeight: FontWeights.fontWeightRegular,
  background: COLOR_LIGHT_GRAY,
  color: COLOR_BLACK,
  border: 'none',
  '&.outlined': {
    border: `1px solid ${COLOR_GRAY_100}`,
  },
  p: { color: COLOR_BLACK },

  '&:hover': {
    background: COLOR_BLACK,
    color: COLOR_WHITE,
    border: 'none',
    p: { color: COLOR_WHITE },
    svg: { color: COLOR_WHITE, transition: TRANSITION_DEFAULT_TIME },
  },

  '&.light-grey': {
    background: COLOR_GRAY_200,
  },

  '&.active, &:active': {
    background: COLOR_BLACK,
    p: {
      color: COLOR_WHITE,
      fontWeight: FontWeights.fontWeightSemiBold,
    },
    '&:hover': {
      background: COLOR_BLACK,
      color: COLOR_WHITE,
      p: { color: COLOR_WHITE },
    },
  },

  [`&.${buttonClasses.disabled}`]: {
    background: COLOR_GRAY_150,
  },
});
