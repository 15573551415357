import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getAutofill } from '../actions';
import actionTypes from '../actionTypes';
import { setAutofills } from '../reducer';

export function* getAutoFillSaga({ type, payload: { type: orderType } }: ReturnType<typeof getAutofill>) {
  yield put(request(type));

  try {
    if (orderType === 'redeem') {
      const { data } = yield call(baseApi.getAutofillRedeem);
      yield put(setAutofills({ claim: {}, custody: {}, redeem: camelize(data) }));
    }
    if (orderType === 'claim') {
      const { data } = yield call(baseApi.getAutofillClaim);
      yield put(setAutofills({ claim: camelize(data), custody: {}, redeem: {} }));
    }
    if (orderType === 'custody') {
      const { data } = yield call(baseApi.getAutofillCustody);
      yield put(setAutofills({ claim: {}, custody: camelize(data), redeem: {} }));
    }
    yield put(success(type));
  } catch (err) {
    logger('Get autofill', err);
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_AUTOFILL, getAutoFillSaga);
}
