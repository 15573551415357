import { FC, useMemo } from 'react';
import { Box, Divider, Grid, Link, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_150,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  COLOR_WHITE_OPACITY_20,
} from 'theme/variables';
import { Chains } from 'types';
import { Claim, Redeem, State } from 'types/api';
import { constructExplorerUrl, ellipsisText } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { OrderButtonTextVariants, OrderStatusColors } from '../OrdersHistory.helpers';

import { StatusActionButton } from './StatusActionButton';

const StyledDivider = styled(Divider)(({ theme }) => ({
  background: COLOR_WHITE_OPACITY_20,
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(2),
  },
}));

const Label = styled(Typography)({
  color: COLOR_GRAY_150,
  whiteSpace: 'nowrap',
});

export interface OrderBlockProps {
  item: Claim | Redeem;
  type: string;
  isStatusActionLoading: boolean;
  actionOrderId: number;
  onStatusAction: (id: number, networkName: string) => void;
}

export const OrderBlock: FC<OrderBlockProps> = ({
  item,
  type,
  isStatusActionLoading,
  actionOrderId,
  onStatusAction,
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { network: chainNetwork, balance, chainType } = useShallowSelector(userSelector.getUser);

  const { id, name, firstName, lastName, state, zipCode, address, mobileNumber, email, size, paymentAmount } = item;

  const currentBalance = useMemo(() => balance[chainNetwork].USDC || 0, [balance, chainNetwork]);
  const isPayActionNeeded = useMemo(
    () =>
      state &&
      (OrderButtonTextVariants[state] === OrderButtonTextVariants.Approved ||
        OrderButtonTextVariants[state] === OrderButtonTextVariants['Pay for shipping']),
    [state],
  );
  return (
    <Grid
      container
      key={id}
      flexWrap="nowrap"
      flexDirection={{ xs: 'column', lg: 'row' }}
      rowSpacing={1}
      alignItems="center"
      sx={{ backgroundColor: COLOR_LIGHT_GRAY, p: 2, borderRadius: BORDER_RADIUS_DEFAULT }}
    >
      <Grid item md={1.4} lg={1.2} {...flexHelper()}>
        <Typography variant="body1" className="bold" textTransform="uppercase">
          {type}
        </Typography>
      </Grid>
      <StyledDivider orientation={downLg ? 'horizontal' : 'vertical'} flexItem />
      <Grid item md={2.3} lg={2.4} minWidth={{ xs: '100%', lg: 'auto' }}>
        <Stack spacing={1} direction={{ sm: 'row', lg: 'column' }} justifyContent="flex-start">
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Order number:&nbsp;</Label>
            <Typography className="semiBold">№{id}</Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Nft name:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText(false)}>
              {name}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Size:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText(false)}>
              {size}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <StyledDivider orientation={downLg ? 'horizontal' : 'vertical'} flexItem />
      <Grid item md={1.9} lg={2} minWidth={{ xs: '100%', lg: 'auto' }}>
        <Stack spacing={1} direction={{ sm: 'row', lg: 'column' }}>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>First name:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText(false)}>
              {firstName}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Last name:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText(false)}>
              {lastName}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <StyledDivider orientation={downLg ? 'horizontal' : 'vertical'} flexItem />
      <Grid item md={2} minWidth={{ xs: '100%', lg: 'auto' }}>
        <Stack spacing={1} direction={{ sm: 'row', lg: 'column' }}>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Address:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText(false)}>
              {address}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Zip code:&nbsp;</Label>
            <Typography className="semiBold">{zipCode}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <StyledDivider orientation={downLg ? 'horizontal' : 'vertical'} flexItem />
      <Grid item md={2} lg={2.2} minWidth={{ xs: '100%', lg: 'auto' }}>
        <Stack spacing={1} direction={{ sm: 'row', lg: 'column' }}>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Mobile number:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText()}>
              {mobileNumber}
            </Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Label>Email:&nbsp;</Label>
            <Typography className="semiBold" {...ellipsisText()}>
              {email}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <StyledDivider flexItem />
      <Grid
        item
        md={1.4}
        lg={1.2}
        gap={2}
        {...flexHelper('flex-start')}
        alignItems={{ xs: 'center', lg: 'flex-start' }}
        flexDirection="column"
      >
        {state === State.ClaimTxCommitted && (
          <Link
            href={constructExplorerUrl(
              'txHash' in item ? item.txHash || '' : '',
              'network' in item ? ((item.network?.name || '') as Chains) : ('' as Chains),
              chainType,
              'tx',
            )}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              sx={{
                color: OrderStatusColors[state || 'Waiting for approval'],
                whiteSpace: { xs: 'nowrap', lg: 'normal' },
              }}
            >
              {state}
            </Typography>
          </Link>
        )}
        {state !== State.ClaimTxCommitted && state !== State.PayForShipping && (
          <Box px={2} py={1} {...flexHelper()} bgcolor={COLOR_BLACK} borderRadius="12px">
            <Typography
              sx={{
                color: OrderStatusColors[state || 'Waiting for approval'] || COLOR_WHITE,
                whiteSpace: { xs: 'nowrap', lg: 'normal' },
                fontSize: '14px',
              }}
            >
              {state}
            </Typography>
          </Box>
        )}
        {isPayActionNeeded && id && (
          <Stack>
            <StatusActionButton
              actionLabel={OrderButtonTextVariants[state || 'Waiting for approval'] || ''}
              isLoading={isStatusActionLoading && actionOrderId === id}
              disabled={(paymentAmount || 0) > currentBalance}
              onClick={() => onStatusAction(id || 0, 'network' in item ? item.network?.name || '' : '')}
            />
            {(paymentAmount || 0) > currentBalance && (
              <Box mt={0.5}>
                <Typography variant="subtitle1" lineHeight="20px">
                  Not enough balance
                </Typography>
                <Typography variant="subtitle1" lineHeight="20px">
                  You need {parseFloat((Math.round(+(paymentAmount || 0) * 100) / 100).toFixed(2))}
                  &nbsp;USDC
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
