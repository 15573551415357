/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCase, isArray, isObject, snakeCase, transform } from 'lodash';

export const camelize = (obj: any, ignoreHash?: boolean) =>
  transform(obj, (acc: any, value, key, target) => {
    let camelKey;
    if (ignoreHash) {
      camelKey = isArray(target) ? key : key.toString().replace(/[^#]*/g, camelCase);
    } else {
      camelKey = isArray(target) ? key : camelCase(key.toString());
    }
    acc[camelKey] = isObject(value) ? camelize(value, ignoreHash) : value;
  });

export const snakeize = (obj: any) =>
  transform(obj, (acc: any, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key.toString());

    acc[camelKey] = isObject(value) ? snakeize(value) : value;
  });
