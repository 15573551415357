import { Box, BoxProps } from '@mui/material';

type AspectRatioProps = { aspectRatio: number } & BoxProps;

export const AspectRatio = ({ aspectRatio, ...props }: AspectRatioProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '&:before': {
          content: '""',
          display: 'block',
          /* (9 / 16) * 100 = 56.25 */
          paddingTop: `${aspectRatio * 100}%`,
        },
      }}
    >
      <Box
        {...props}
        sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, height: '100%', width: '100%', ...props?.sx }}
      >
        {props?.children}
      </Box>
    </Box>
  );
};
