import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { BORDER_BUTTON_GREEN, COLOR_BLACK, COLOR_GREEN } from 'theme/variables';

export const getMuiIconButtonOverrides = (): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
    color: COLOR_GREEN,
  },

  colorPrimary: {
    border: BORDER_BUTTON_GREEN,
    '& > *': {
      '& > *': {
        fill: COLOR_GREEN,
      },
    },
    '&:hover': {
      backgroundColor: COLOR_GREEN,
      '& > *': {
        '& > *': {
          fill: COLOR_BLACK,
        },
      },
    },
  },

  colorSecondary: {
    backgroundColor: COLOR_GREEN,
    '& > *': {
      '& > *': {
        fill: COLOR_BLACK,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      border: BORDER_BUTTON_GREEN,
      '& > *': {
        '& > *': {
          fill: COLOR_GREEN,
        },
      },
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
});
