import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button, Grid, styled } from '@mui/material';
import { routes } from 'appConstants';
import { INotifyModalProps } from 'types';

interface IDisconnectModalProps extends INotifyModalProps {
  disconnect: () => void;
}

const StyledButton = styled(Button)({
  width: '150px',
  height: '44px',
});

export const DisconnectModal: FC<IDisconnectModalProps> = ({ disconnect, closeModal }) => {
  const navigate = useNavigate();
  const handleDisconnect = () => {
    disconnect();
    closeModal();
    navigate(routes.home.root.path);
  };
  return (
    <Grid container direction="row" flexWrap="nowrap" columnGap={1} pt={1}>
      <StyledButton variant="contained" onClick={handleDisconnect}>
        Disconnect
      </StyledButton>
      <StyledButton variant="outlined" onClick={closeModal}>
        Cancel
      </StyledButton>
    </Grid>
  );
};
