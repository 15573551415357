import { FC, PropsWithChildren } from 'react';
import { Box, SxProps } from '@mui/material';
import { Splide } from '@splidejs/react-splide';
import { Options as SplideOptions } from '@splidejs/splide';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';

type HomeSliderProps = {
  perPage: number;
  pagination?: boolean;
  gap: number;
  padding: string | number | { left: string | number; right: string | number };
  width?: number | string;
  isLoop?: boolean;
  isArrows?: boolean;
  hideArrowsOnMobile?: boolean;
  wrapperStyle?: SxProps;
};

export const HomeSlider: FC<PropsWithChildren<HomeSliderProps> & SplideOptions> = ({
  children,
  padding,
  perPage,
  pagination = false,
  gap,
  width,
  isLoop = true,
  isArrows = true,
  hideArrowsOnMobile = true,
  wrapperStyle,
  ...splideOptions
}) => (
  <Box
    sx={{
      ...wrapperStyle,
      '.splide__arrow': {
        background: COLOR_BLACK,
        width: 40,
        height: 40,
        display: hideArrowsOnMobile ? { xs: 'none', sm: 'flex' } : 'flex',
        svg: { fill: COLOR_WHITE, width: '0.6rem', height: '0.6rem' },
        '&--prev': {
          left: 0,
        },
        '&--next': {
          right: 0,
        },
      },
    }}
  >
    <Splide
      options={{
        arrows: isArrows,
        type: isLoop ? 'loop' : 'slide',
        perPage,
        pagination,
        gap,
        padding,
        width,
        omitEnd: true,
        focus: 0,
        ...splideOptions,
      }}
    >
      {children}
    </Splide>
  </Box>
);
