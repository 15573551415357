import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NftState, PaginateTopCollectors } from 'types';
import { Category, CompositeCollection, Token, TokenFastSearch, TokenFull } from 'types/api';

const initialState: NftState = {
  nfts: [],
  totalNfts: 0,
  maxPrice: 0,
  categories: [],
  collections: [],
  detailedNft: {} as TokenFull,
  presearchedNfts: [],
  enabledOnCarousel: [],
  favorites: [],
  newest: [],
  popular: [],
  topCollectors: {
    total: 0,
    resultsPerPage: 0,
    totalPages: 0,
    results: [],
  },
};

export const nftReducer = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    updateNftState: (state, action: PayloadAction<Partial<NftState>>) => ({
      ...state,
      ...action.payload,
    }),
    setNfts: (state, action: PayloadAction<Token[]>) => ({
      ...state,
      nfts: action.payload,
    }),
    setMaxPrice: (state, action: PayloadAction<number>) => ({
      ...state,
      maxPrice: action.payload,
    }),
    setDetailedNft: (state, action: PayloadAction<TokenFull>) => ({
      ...state,
      detailedNft: action.payload,
    }),
    setTotalNfts: (state, action: PayloadAction<number>) => ({
      ...state,
      totalNfts: action.payload,
    }),
    setPresearchedNfts: (state, action: PayloadAction<TokenFastSearch[]>) => ({
      ...state,
      presearchedNfts: action.payload,
    }),
    clearNfts: (state) => ({
      ...state,
      nfts: [],
      totalNfts: 0,
    }),
    clearPresearchedNfts: (state) => ({
      ...state,
      presearchedNfts: [],
    }),
    clearDetailedNft: (state) => ({
      ...state,
      detailedNft: initialState.detailedNft,
    }),
    setCategories: (state, action: PayloadAction<Category[]>) => ({
      ...state,
      categories: action.payload,
    }),
    setCollections: (state, action: PayloadAction<CompositeCollection[]>) => ({
      ...state,
      collections: action.payload,
    }),
    setEnabledOnCarousel: (state, action: PayloadAction<TokenFull[]>) => ({
      ...state,
      enabledOnCarousel: action.payload,
    }),
    setFavorites: (state, action: PayloadAction<TokenFull[]>) => ({
      ...state,
      favorites: action.payload,
    }),
    setNewest: (state, action: PayloadAction<TokenFull[]>) => ({
      ...state,
      newest: action.payload,
    }),
    setPopular: (state, action: PayloadAction<TokenFull[]>) => ({
      ...state,
      popular: action.payload,
    }),
    setTopCollectors: (state, action: PayloadAction<PaginateTopCollectors>) => ({
      ...state,
      topCollectors: action.payload,
    }),
  },
});

export const {
  updateNftState,
  setNfts,
  setPresearchedNfts,
  setDetailedNft,
  setTotalNfts,
  setCollections,
  setCategories,
  setEnabledOnCarousel,
  setFavorites,
  clearDetailedNft,
  clearPresearchedNfts,
  clearNfts,
  setMaxPrice,
  setNewest,
  setPopular,
  setTopCollectors,
} = nftReducer.actions;

export default nftReducer.reducer;
