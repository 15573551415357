import { FC, useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, CircularProgress } from '@mui/material';
import { flexHelper } from 'utils/flexHelper';

export type ProgressCloseButtonProps = {
  closeToast?: () => void;
  delay?: number;
};
export const ProgressCloseButton: FC<ProgressCloseButtonProps> = ({ delay = 450, closeToast }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, delay);

    return () => {
      clearInterval(timer);
    };
  }, [delay]);
  return (
    <Box {...flexHelper()}>
      <Box position="relative" minWidth="32px" minHeight="32px">
        <CircularProgress
          variant="determinate"
          thickness={2}
          value={progress}
          size={29}
          sx={{ position: 'absolute', left: 0, width: '22px' }}
        />
        <CloseRoundedIcon onClick={closeToast} sx={{ position: 'absolute', left: 3, top: 2, width: '22px' }} />
      </Box>
    </Box>
  );
};
