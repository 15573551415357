import { User } from 'types/api';
import * as Yup from 'yup';

export type EditProfileFormProps = User & {
  isLoading: boolean;
  isUploadLoading: boolean;
  isDeleteLoading: boolean;
  avatar: Blob | string;
};
export const getProps = (user: User): EditProfileFormProps => {
  return {
    ...user,
    avatar: user.avatar || '',
    isLoading: false,
    isUploadLoading: false,
    isDeleteLoading: false,
  };
};

export const validationSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(2, 'Too short')
    .matches(/(?!^\d+$)^.+$/, `Name can't contain only numbers!`)
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
  customUrl: Yup.string()
    .matches(/^[a-z0-9 _\-.]+$/, 'Enter correct username!')
    .matches(/(?!^\d+$)^.+$/, `Username can't contain only numbers!`)
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
  bio: Yup.string().max(500, 'Too long').nullable().notRequired(),
  email: Yup.string()
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      'Enter correct email!',
    )
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
  site: Yup.string()
    // eslint-disable-next-line no-useless-escape
    .matches(/^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/i, 'Enter correct url!')
    .max(50, 'Maximum 50 characters')
    .nullable()
    .notRequired(),
  twitter: Yup.string()
    .matches(/^\S*$/, 'Spaces are not allowed!')
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
  instagram: Yup.string()
    .matches(/^\S*$/, 'Spaces are not allowed!')
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
  facebook: Yup.string()
    .matches(/^\S*$/, 'Spaces are not allowed!')
    .max(30, 'Maximum 30 characters')
    .nullable()
    .notRequired(),
});
