import { Box, BoxProps } from '@mui/material';

export const UndoContainer = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        marginLeft: { xs: -1.25, md: -4, xl: 0 },
        marginTop: { xs: -5, md: -10 },
        ...props?.sx,
      }}
    />
  );
};
