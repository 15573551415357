import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { CollectionTypeBadge } from 'components';
import { useShallowSelector } from 'hooks';
import apiActions from 'store/api/actions';
import { getNftData, searchNfts } from 'store/nft/actions';
import nftActionTypes from 'store/nft/actionTypes';
import { clearDetailedNft } from 'store/nft/reducer';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_GRAY_10,
  COLOR_GRAY_100,
  COLOR_GRAY_400,
  COLOR_LIGHT_GRAY,
} from 'theme/variables';
import { RequestStatus } from 'types';

import {
  BuyMultipleModal,
  CollectionsScrollBar,
  MintNftCounter,
  NftActionMenu,
  NftDescription,
  NftDetail,
  NftHeader,
  NftInfoTabs,
  NftProperties,
} from './components';

export const Nft: FC = () => {
  const { id = 0 } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    format,
    animationFile,

    standart,

    collection,
    owners,
    isSelling,
    isAucSelling,
    isTimedAucSelling,
    startAuction,
    properties,
    isClaimed,

    isPending,
  } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const nfts = useShallowSelector(nftSelector.getProp('nfts'));
  const { address } = useShallowSelector(userSelector.getUser);

  const userOwner = useMemo(
    () => (owners && owners.find((owner) => owner.address === address)) || undefined,
    [address, owners],
  );

  const location = useMemo(
    () => properties?.find((prop) => prop.traitType === 'Location')?.traitValue || undefined,
    [properties],
  );

  const isCollectionOwner = useMemo(
    () => collection?.owners && collection?.owners?.includes(address),
    [address, collection?.owners],
  );

  const handleNavigate = () => {
    if (collection?.collectionType && id) {
      if (collection.collectionType === 'Redeemable') {
        navigate(routes.nft.redeem.root.getPath(id));
      }
      if (collection.collectionType === 'Claimable') {
        navigate(routes.nft.claim.root.getPath(id));
      }
    }
  };

  useEffect(() => {
    dispatch(getNftData({ id }));

    return () => {
      dispatch(clearDetailedNft());
    };
  }, [dispatch, id]);

  const { [nftActionTypes.GET_NFT_DATA]: getNftDataRequestStatus } = useShallowSelector(uiSelector.getUI);

  useEffect(() => {
    if (getNftDataRequestStatus === RequestStatus.ERROR) {
      dispatch(apiActions.reset(nftActionTypes.GET_NFT_DATA));
      navigate('/404');
    }
  }, [getNftDataRequestStatus, navigate, dispatch]);

  useEffect(() => {
    if (collection?.id) {
      dispatch(searchNfts({ props: { type: 'items', collections: collection.id.toString(), onAnySale: true } }));
    }
  }, [collection?.id, dispatch]);

  useEffect(() => {
    const infoElement = document.getElementById('Nft-info');

    if (infoElement) infoElement.scrollTop = 0;
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, [id]);

  return (
    <>
      <Box position="relative" zIndex={1} px={{ md: '0', lg: '100px' }}>
        <NftHeader isMobile />
        <Grid container gap={{ xs: '20px', md: '70px' }} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
          <Box
            maxWidth={{ xs: '100%', sm: '50%', md: '491px', xl: '820px' }}
            width="100%"
            mt={{ xs: 2, sm: 5, lg: 7, xl: 11.5 }}
          >
            {animationFile ? (
              <Box
                position="relative"
                borderRadius={4}
                sx={{
                  overflow: 'hidden',
                  img: {
                    width: '100%',
                    height: 'auto',
                  },
                }}
              >
                {location && <CollectionTypeBadge isClaimed={isClaimed} collectionType={location} />}
                {format === 'image' && <img src={animationFile} alt="NFT animation file" />}
                {format === 'video' && (
                  <video controls autoPlay>
                    <source src={animationFile} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    <track kind="captions" />
                  </video>
                )}
                {format === 'audio' && (
                  <audio controls autoPlay>
                    <source src={animationFile} />
                    <track kind="captions" />
                  </audio>
                )}
              </Box>
            ) : (
              <Skeleton
                animation="wave"
                width="100%"
                color={COLOR_GRAY_400}
                variant="rectangular"
                sx={{
                  borderRadius: 4,
                  height: { xs: '100%', sm: '50%', md: '491px', xl: '820px' },
                }}
              />
            )}
          </Box>

          <Divider flexItem orientation="vertical" />

          <Box
            id="Nft-info"
            width="100%"
            height={{ xs: '100%', sm: '500px', md: '700px', lg: '770px', xl: '920px' }}
            pr={{ xs: 0, sm: 1, md: 2, lg: 4, xl: 8 }}
            pb={{ xs: 4, md: 6, xl: 10 }}
            sx={{
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              scrollbarColor: `${COLOR_GRAY_10} ${COLOR_LIGHT_GRAY}`,
              '&::-webkit-scrollbar': {
                backgroundColor: COLOR_LIGHT_GRAY,
                width: 4,
              },
              '&::-webkit-scrollbar-thumb': {
                width: 1,
                borderRadius: BORDER_RADIUS_DEFAULT,
                background: COLOR_GRAY_10,
              },
            }}
          >
            <NftHeader isMobile={false} />
            {(isSelling || isAucSelling || isTimedAucSelling || userOwner) && <NftActionMenu />}
            {!!userOwner?.quantity && collection?.collectionType && collection?.collectionType !== 'Digital' && (
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Typography color={COLOR_GRAY_100}>
                  or click to {collection?.collectionType === 'Redeemable' ? 'redeem' : 'claim'}
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleNavigate}
                  disabled={
                    isClaimed ||
                    isPending ||
                    userOwner?.redeemAmount > 0 ||
                    (standart === 'ERC721' && (isAucSelling || isTimedAucSelling || isSelling || !!startAuction)) ||
                    (standart === 'ERC1155' && +(userOwner?.sellingQuantity || 0) === +(userOwner?.quantity || 0))
                  }
                  sx={{
                    marginTop: 3,
                    width: 178,
                    height: 44,
                  }}
                >
                  {(isPending || userOwner?.redeemAmount > 0) && 'Pending'}
                  {!isPending &&
                    userOwner?.redeemAmount === null &&
                    !isClaimed &&
                    (collection?.collectionType === 'Redeemable' ? 'redeem' : 'claim')}
                  {!isPending && userOwner?.redeemAmount === null && isClaimed && 'claimed'}
                </Button>
              </Stack>
            )}
            {userOwner && !userOwner?.quantity && <Typography color={COLOR_GRAY_100}>Transaction pending</Typography>}
            {standart === 'ERC1155' && isCollectionOwner && <MintNftCounter />}
            <Stack spacing={{ xs: 3, md: 6, xl: 14 }}>
              <NftInfoTabs />
              <NftDescription />
              <NftProperties />
              <NftDetail />
            </Stack>
          </Box>
        </Grid>
      </Box>
      {collection?.id && nfts.filter((nft) => +(nft.id || 0) !== +id).length > 0 ? (
        <CollectionsScrollBar collectionId={collection.id} />
      ) : (
        ''
      )}
      <BuyMultipleModal />
    </>
  );
};
