import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, ButtonBase, CircularProgress, Grid, Stack, styled, Typography } from '@mui/material';
import BigNumber from 'bignumber.js/bignumber';
import { ArrowRightGreen, NftCard, PlusGreen, Warning } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { createNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { TertiaryButton } from 'theme/Button';
import { FontWeights } from 'theme/Typography';
import { COLOR_GRAY_100, COLOR_GREEN } from 'theme/variables';
import { RequestStatus } from 'types';
import { CompositeCollection } from 'types/api';

import { auctionTimes, CreateFormProps } from './components/CreateCollectibleForm/CreateCollectibleForm.helpers';
import { CreateCollectibleForm, SuccessCreateCollectibleModal } from './components';

export type SavedCollectibleForms = {
  single: CreateFormProps[];
  multiple: CreateFormProps[];
};

const ItemButton = styled(Button)(({ theme }) => ({
  width: '156px',
  height: '44px',
  [theme.breakpoints.down('sm')]: {
    width: '78px',
    height: '36px',
  },
}));

const FinalButton = styled(Button)({
  maxWidth: '320px',
  maxHeight: '44px',
  width: '100%',
});

export type CreateCollectibleProps = {
  collectibleType: 'single' | 'multiple';
};

export const CreateCollectible: FC<CreateCollectibleProps> = ({ collectibleType }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [savedCollectibleForms, setSavedCollectibleForms] = useState<SavedCollectibleForms>({
    single: [],
    multiple: [],
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const windowState = useWindowState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const { address } = useShallowSelector(userSelector.getUser);
  const collections = useShallowSelector(nftSelector.getProp('collections'));
  const { [actionTypes.CREATE_NFT]: createNftRequestStatus } = useShallowSelector(uiSelector.getUI);
  const isSingle = collectibleType === 'single';

  const isCreateNftLoading = useMemo(() => createNftRequestStatus === RequestStatus.REQUEST, [createNftRequestStatus]);
  const isCreateNftSuccess = useMemo(() => createNftRequestStatus === RequestStatus.SUCCESS, [createNftRequestStatus]);

  const chosenCollection = useMemo(
    () =>
      collections[savedCollectibleForms[isSingle ? 'single' : 'multiple'][0]?.collection] ||
      ({} as CompositeCollection),
    [collections, isSingle, savedCollectibleForms],
  );

  const handleSwitch = () => {
    navigate(`/create/${collectibleType === 'single' ? 'multiple' : 'single'}`);
    setIsFormOpen(false);
  };

  const handleOpenForm = (index: number) => {
    setCurrentFormIndex(index);
    setIsFormOpen(true);
  };

  const handleCloseForm = () => setIsFormOpen(false);

  const handleDeleteForm = (index: number) => {
    setSavedCollectibleForms((prev) => ({
      ...prev,
      [isSingle ? 'single' : 'multiple']: prev[isSingle ? 'single' : 'multiple'].filter((item, idx) => index !== idx),
    }));
  };

  const handleCreate = () => {
    const formsData = new FormData();
    formsData.append('collection', String(chosenCollection?.id || 0));
    savedCollectibleForms[isSingle ? 'single' : 'multiple'].forEach((item) => {
      formsData.append('media', item.media);
    });
    savedCollectibleForms[isSingle ? 'single' : 'multiple'].forEach((item) => {
      formsData.append('cover', item.cover);
    });
    formsData.append(
      'data',
      JSON.stringify(
        savedCollectibleForms[isSingle ? 'single' : 'multiple'].map((item) => ({
          name: item.name,
          total_supply: item.totalSupply,
          currency: item.currency.symbol,
          description: item.description,
          price: item.saleType === 'instant' ? item.priceOrBid : null,
          minimal_bid: item.saleType === 'instant' ? null : item.priceOrBid,
          mint_to: address,
          selling_quantity: item.totalSupply,
          details: item.details,
          selling: item.onSale,
          start_auction:
            item.saleType === 'auctionTime' ? new BigNumber(new Date().getTime()).div(1000).toFixed(0, 1) : null,
          end_auction:
            item.saleType === 'auctionTime'
              ? new BigNumber(new Date().getTime()).div(1000).plus(auctionTimes[item.endAuction].value).toFixed(0, 1)
              : null,
          digital_key: item.isUnlockable ? item.text : null,
          format: item.format,
        })),
      ),
    );
    if (chosenCollection?.address) {
      dispatch(
        createNft({
          isSingle,
          formData: formsData,
          web3Provider: walletService.Web3(),
          collectionAddress: chosenCollection.address,
        }),
      );
    }
  };

  const handleClearAll = useCallback(() => {
    setSavedCollectibleForms((prev) => ({ ...prev, [isSingle ? 'single' : 'multiple']: [] }));
  }, [isSingle]);

  useEffect(() => {
    if (isCreateNftSuccess) handleClearAll();
  }, [handleClearAll, isCreateNftSuccess]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" gap={2}>
        <Typography
          variant={windowState.width > 700 ? 'h1' : 'h2'}
          textTransform="uppercase"
          sx={{ color: COLOR_GREEN }}
        >
          Create {collectibleType} collectible
        </Typography>
        <TertiaryButton
          onClick={handleSwitch}
          sx={{
            justifyContent: 'space-between',
            width: '208px',
            textTransform: 'none',
          }}
        >
          Switch to {collectibleType === 'single' ? 'Multiple' : 'Single'}
          <ArrowRightGreen sx={{ width: '7.2px', height: '12px', color: COLOR_GREEN }} />
        </TertiaryButton>
      </Grid>
      {isFormOpen ? (
        <CreateCollectibleForm
          savedCollectibleForms={savedCollectibleForms[isSingle ? 'single' : 'multiple']}
          setSavedCollectibleForms={setSavedCollectibleForms}
          isSingle={collectibleType === 'single'}
          currentFormIndex={currentFormIndex}
          closeForm={handleCloseForm}
        />
      ) : (
        <>
          <Grid container mt="56px" justifyContent={{ xs: 'center', sm: 'flex-start' }} gap={2}>
            {savedCollectibleForms[isSingle ? 'single' : 'multiple'].map((item, index) => (
              <Stack key={`${index + 1}`} spacing={1.5} width={{ xs: '164px', sm: '320px' }}>
                <ButtonBase
                  onClick={() => handleOpenForm(index)}
                  sx={{
                    borderRadius: { xs: '4px', sm: '16px' },
                  }}
                >
                  <Box
                    sx={{
                      pointerEvents: 'none',
                    }}
                  >
                    <NftCard
                      id={1}
                      cardSize="large"
                      image={item.coverPreview}
                      animation={item.preview}
                      nftSize={item.details?.Size || '0'}
                      format={item.format}
                      title={item.name}
                      price={item.priceOrBid}
                      isLiked={false}
                      showLike={false}
                      isClaimed={false}
                      collectionType={item.details?.Location || ''}
                      styleHash={item.details?.['Style #'] || '-'}
                    />
                  </Box>
                </ButtonBase>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <ItemButton variant="contained" onClick={() => handleOpenForm(index)}>
                    edit
                  </ItemButton>
                  <ItemButton variant="outlined" onClick={() => handleDeleteForm(index)}>
                    delete
                  </ItemButton>
                </Stack>
              </Stack>
            ))}
            {savedCollectibleForms[isSingle ? 'single' : 'multiple'].length < 5 && (
              <TertiaryButton
                onClick={() => handleOpenForm(savedCollectibleForms[isSingle ? 'single' : 'multiple'].length)}
                sx={{
                  width: { xs: '163px', sm: '320px' },
                  minWidth: { xs: '163px', sm: '320px' },
                  height: { xs: '228px', sm: '430px' },
                  color: COLOR_GREEN,
                  flexDirection: 'column',
                  gap: { xs: '22px', sm: '45px' },
                  textTransform: { xs: 'none', sm: 'uppercase' },
                  fontWeight: FontWeights.fontWeightSemiBold,
                }}
              >
                <PlusGreen sx={{ width: { xs: '36px', sm: '56px', color: COLOR_GREEN } }} />
                Create NFT
              </TertiaryButton>
            )}
          </Grid>
          <Grid
            mt={{ xs: '71px', sm: '89px' }}
            container
            justifyContent="center"
            alignItems="center"
            gap={{ xs: 2, sm: '28px' }}
          >
            <Warning />
            <Typography
              variant="h4"
              fontWeight={FontWeights.fontWeightRegular}
              textAlign="center"
              sx={{ color: COLOR_GRAY_100 }}
            >
              Minting no more than 5 NFT in a single transaction
            </Typography>
          </Grid>
          {savedCollectibleForms[isSingle ? 'single' : 'multiple'].length ? (
            <Stack mt={3.25} direction="row" justifyContent="center" spacing={2}>
              <FinalButton disabled={isCreateNftLoading} variant="contained" onClick={handleCreate}>
                {isCreateNftLoading ? <CircularProgress size={30} sx={{ color: COLOR_GREEN }} /> : 'create item'}
              </FinalButton>
              <FinalButton variant="outlined" onClick={handleClearAll}>
                clear all
              </FinalButton>
            </Stack>
          ) : (
            Fragment
          )}
        </>
      )}
      <SuccessCreateCollectibleModal clearAllForm={handleClearAll} />
    </>
  );
};
