import { Chains, IChainType, IConnectWallet, IContracts, TChains } from 'types';

import {
  polygonErc721Abi,
  polygonErc721FabricAbi,
  polygonErc1155Abi,
  polygonErc1155FabricAbi,
  polygonExchangeAbi,
  polygonRedeemAbi,
  polygonUsdcAbi,
  polygonWethAbi,
  polygonWmaticAbi,
} from './abi';

export const chains: TChains = {
  Polygon: {
    mainnet: {
      name: 'Polygon Mainnet',
      chainId: 137,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpc: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
      blockExplorerUrl: 'https://polygonscan.com/',
      provider: {
        MetaMask: { name: 'MetaMask' },
        CoinbaseWallet: {
          name: 'CoinbaseWallet',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                137: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
              },
              chainId: 137,
            },
          },
        },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                137: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
              },
              chainId: 137,
              wcConfig: {
                chains: [137],
                projectId: process.env.REACT_APP_WC_PROJECT_ID,
                showQrModal: true,
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                qrModalOptions: {
                  themeVariables: {
                    '--wcm-z-index': '9999',
                  },
                },
              },
            },
          },
        },
      },
    },
    testnet: {
      name: 'Polygon Mainnet',
      chainId: 137,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpc: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
      blockExplorerUrl: 'https://polygonscan.com/',
      provider: {
        MetaMask: { name: 'MetaMask' },
        CoinbaseWallet: {
          name: 'CoinbaseWallet',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                137: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
              },
              chainId: 137,
            },
          },
        },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                137: 'https://endpoints.omniatech.io/v1/matic/mainnet/public',
              },
              chainId: 137,
              wcConfig: {
                chains: [137],
                projectId: process.env.REACT_APP_WC_PROJECT_ID,
                showQrModal: true,
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                qrModalOptions: {
                  themeVariables: {
                    '--wcm-z-index': '9999',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: Chains, type: IChainType): IConnectWallet => {
  const chain = chains[newChainName][type];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
      nativeCurrency: chain.nativeCurrency,
      rpc: chain.rpc,
      blockExplorerUrl: chain.blockExplorerUrl,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  WETH = 'WETH',
  WMATIC = 'WMATIC',
  USDC = 'USDC',
  exchange = 'EXCHANGE',
  redeem = 'REDEEM',
  erc721 = 'ERC721',
  erc1155 = 'ERC1155',
  erc721Fabric = 'ERC721FABRIC',
  erc1155Fabric = 'ERC1155FABRIC',
}

export type IContractsNames = keyof typeof ContractsNames;

// NOTE: both development and production uses polygon mainnet contracts
export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.WETH]: {
      testnet: {
        address: {
          [Chains.Polygon]: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        },
        abi: {
          [Chains.Polygon]: polygonWethAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        },
        abi: {
          [Chains.Polygon]: polygonWethAbi,
        },
      },
    },
    [ContractsNames.WMATIC]: {
      testnet: {
        address: {
          [Chains.Polygon]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        abi: {
          [Chains.Polygon]: polygonWmaticAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        abi: {
          [Chains.Polygon]: polygonWmaticAbi,
        },
      },
    },
    [ContractsNames.USDC]: {
      testnet: {
        address: {
          [Chains.Polygon]: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        },
        abi: {
          [Chains.Polygon]: polygonUsdcAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        },
        abi: {
          [Chains.Polygon]: polygonWethAbi,
        },
      },
    },
    [ContractsNames.exchange]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonExchangeAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonExchangeAbi,
        },
      },
    },
    [ContractsNames.redeem]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonRedeemAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonRedeemAbi,
        },
      },
    },
    [ContractsNames.erc721]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc721Abi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc721Abi,
        },
      },
    },
    [ContractsNames.erc1155]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc1155Abi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc1155Abi,
        },
      },
    },
    [ContractsNames.erc721Fabric]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc721FabricAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc721FabricAbi,
        },
      },
    },
    [ContractsNames.erc1155Fabric]: {
      testnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc1155FabricAbi,
        },
      },
      mainnet: {
        address: {
          [Chains.Polygon]: '',
        },
        abi: {
          [Chains.Polygon]: polygonErc1155FabricAbi,
        },
      },
    },
  },
};
