import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useSmoothTopScroll } from 'hooks';

import { AppModal } from '../AppModal';
import { Footer, Header } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);

  return (
    <Box
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Header />
      <AppModal />
      <Container
        sx={{
          my: {
            xs: 5,
            md: 10,
          },
          px: {
            xs: 1.25,
            sm: 1.25,
            md: 4,
            lg: 4,
            xl: 0,
          },
          position: 'relative',
          zIndex: 1,
          minHeight: '50vh',
        }}
      >
        {children}
      </Container>
      <Footer />
    </Box>
  );
};
