import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { routes } from 'appConstants';
import { SearchField } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { presearchNfts } from 'store/nft/actions';
import { clearPresearchedNfts } from 'store/nft/reducer';
import nftSelector from 'store/nft/selectors';

import SearchPopover from './SearchPopover';

type SearchNftsProps = {
  isFullWidth?: boolean;
  onItemClick?: () => void;
  onEnterKeyDown?: (text: string) => void;
};

export const SearchNfts: FC<SearchNftsProps> = ({ isFullWidth = false, onItemClick, onEnterKeyDown }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopperVisible, onOpenPopper, onClosePopper] = useModal(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [textInput, setTextInput] = useState('');
  const presearchedNfts = useShallowSelector(nftSelector.getProp('presearchedNfts'));

  const handleOpenSearch = () => setIsSearchActive(true);

  const handleCloseSearch = () => setIsSearchActive(false);

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
    onOpenPopper();
  };

  const handleClose = () => {
    onClosePopper();
    setTextInput('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (onEnterKeyDown) {
        onEnterKeyDown(textInput);
      } else {
        navigate(routes.explore.root.getPath({ text: textInput }));
      }
      handleClose();
    }
  };

  const handleClickItem = () => {
    if (onItemClick) onItemClick();
    handleClose();
    handleCloseSearch();
  };

  useEffect(() => {
    dispatch(presearchNfts({ presearch: textInput }));
  }, [dispatch, textInput]);

  useEffect(
    () => () => {
      dispatch(clearPresearchedNfts());
    },
    [dispatch],
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(target as Node) &&
        (popoverRef.current === null || !popoverRef.current.contains(target as Node))
      ) {
        handleCloseSearch();
        onClosePopper();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [searchRef, popoverRef, onClosePopper]);

  return (
    <>
      {isSearchActive ? (
        <SearchField
          aria-describedby="search"
          popoverRef={searchRef}
          placeholder=""
          onChange={handleTextInputChange}
          onFocus={onOpenPopper}
          autoFocus
          onKeyDown={handleKeyDown}
          value={textInput}
          fullWidth={isFullWidth}
        />
      ) : (
        <Button
          variant="contained"
          className="light-gray"
          sx={{
            width: isFullWidth ? '100%' : 140,
            height: isFullWidth ? 48 : 40,
          }}
          onClick={handleOpenSearch}
        >
          Search
        </Button>
      )}
      {isPopperVisible && textInput && (
        <SearchPopover
          items={presearchedNfts}
          searchedText={textInput}
          anchorEl={searchRef.current}
          ref={popoverRef}
          open={isPopperVisible}
          onItemClick={handleClickItem}
        />
      )}
    </>
  );
};
