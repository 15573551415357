import { FC, forwardRef, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Popper, PopperProps, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY } from 'theme/variables';
import { TokenFastSearch } from 'types/api';

export type SearchPopover = {
  items: TokenFastSearch[];
  searchedText: string;
  onItemClick: () => void;
};

const SearchPopover: FC<SearchPopover & PopperProps> = forwardRef(
  ({ items, searchedText, onItemClick, ...popperProps }, ref) => {
    const colorizeSearchedText = (text: string): ReactNode => {
      const searchedTextStartIndex = text.toLowerCase().indexOf(searchedText.toLowerCase());

      return (
        <Typography
          variant="subtitle1"
          color={COLOR_GRAY_150}
          lineHeight="22px"
          sx={{ '&:hover': { color: COLOR_BLACK } }}
        >
          {text.slice(0, searchedTextStartIndex)}
          <Box component="span" sx={{ color: COLOR_BLACK }}>
            {text.slice(searchedTextStartIndex, searchedTextStartIndex + searchedText.length)}
          </Box>
          {text.slice(searchedTextStartIndex + searchedText.length)}
        </Typography>
      );
    };
    return (
      <Popper
        ref={ref}
        disablePortal
        placement="bottom-start"
        sx={{
          zIndex: 1,
          background: COLOR_LIGHT_GRAY,
          borderRadius: BORDER_RADIUS_DEFAULT,
          maxWidth: { xs: 300, lg: 400 },
          p: 1,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        {...popperProps}
      >
        <Box
          sx={{
            p: 1,
            px: 0.5,
            maxHeight: '300px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarColor: `${COLOR_GRAY_150} transparent`,
            '&::-webkit-scrollbar': {
              backgroundColor: 'transparent',
              width: 4,
            },
            '&::-webkit-scrollbar-thumb': {
              width: 1,
              borderRadius: BORDER_RADIUS_DEFAULT,
              background: COLOR_GRAY_150,
            },
          }}
        >
          <Stack spacing={1.5}>
            {items.map(({ id, name }) => (
              <NavLink to={routes.nft.root.getPath(id)} key={id} onClick={onItemClick}>
                {colorizeSearchedText(name || '')}
              </NavLink>
            ))}
            {!items.length && (
              <Typography variant="subtitle1" color={COLOR_GRAY_150}>
                There is no nfts for your request
              </Typography>
            )}
          </Stack>
        </Box>
      </Popper>
    );
  },
);
SearchPopover.displayName = 'SearchPopover';
export default SearchPopover;
