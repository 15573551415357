import { FC } from 'react';
import { Button, Grid, styled, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { BORDER_GREEN, BORDER_RADIUS_SMALL, COLOR_GRAY_100, COLOR_GRAY_200, COLOR_WHITE } from 'theme/variables';
import { Chains, INotifyModalProps, WalletProviders } from 'types';

import { networkMock } from '../../Header/components/Profile';

interface INetworkModalProps extends INotifyModalProps {
  onConnectNetwork: (provider: WalletProviders, chain: Chains) => void;
}

const StyledButton = styled(Button)({
  width: '100%',
  height: '44px',
  background: `${COLOR_GRAY_200} !important`,
  border: '1px solid',
  borderColor: 'transparent',
  borderRadius: BORDER_RADIUS_SMALL,
  textTransform: 'capitalize',
  p: { color: COLOR_WHITE },
  '&:hover': {
    background: `${COLOR_GRAY_200} !important`,
    border: BORDER_GREEN,
    p: { color: COLOR_WHITE },
  },
  '&:disabled': {
    p: { color: COLOR_GRAY_100 },
  },
});

export const NetworkModal: FC<INetworkModalProps> = ({ onConnectNetwork, closeModal }) => {
  const { provider, network } = useShallowSelector(userSelector.getUser);

  const handleConnect = (chain: Chains) => {
    onConnectNetwork(provider as WalletProviders, chain);
    closeModal();
  };

  return (
    <Grid container direction="row" flexWrap="nowrap" columnGap={1} pt={1}>
      <StyledButton
        variant="contained"
        size="small"
        onClick={() => handleConnect(Chains.Polygon)}
        disabled={network === Chains.Polygon}
      >
        {networkMock.Polygon.icon}
        <Typography variant="body1" className="capitalize" sx={{ ml: 1 }}>
          {networkMock.Polygon.name}
        </Typography>
      </StyledButton>
    </Grid>
  );
};
