import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Checkbox, FormControlLabel, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector } from 'hooks';
import { QuantityCounter } from 'modules/nft/pages/Nft/components';
import { useWalletConnectorContext } from 'services';
import { reset } from 'store/api/actions';
import nftSelector from 'store/nft/selectors';
import { claimNft, getAutofill, redeemNft } from 'store/orders/actions';
import actionTypes from 'store/orders/actionTypes';
import ordersSelector from 'store/orders/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_LIGHT_GRAY } from 'theme/variables';
import { RequestStatus } from 'types';
import { Claim, Network, Ownership, Redeem } from 'types/api';

import { inputFields, RedeemClaimFormFields, setInitialValues, validationSchema } from './RedeemClaimForm.helpers';

type RedeemClaimFormProps = {
  orderType: 'redeem' | 'claim';
  onIsValidChange: (isValid: boolean) => void;
};

const CallBack = ({ value, onChange }: { value: boolean; onChange: (value: boolean) => void }) => {
  useEffect(() => onChange(value), [value]);
  return null;
};

export const RedeemClaimForm: FC<RedeemClaimFormProps> = ({ orderType, onIsValidChange }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [quantity, setQuantity] = useState('1');
  const [isChecked, setIsChecked] = useState(false);
  const { redeem, claim } = useShallowSelector(ordersSelector.getProp('autofills'));
  const { id, network, owners, standart } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { address, network: userNetwork, email, displayName } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.REDEEM]: redeemRequestStatus, [actionTypes.CLAIM]: claimRequestStatus } = useShallowSelector(
    uiSelector.getUI,
  );
  const { walletService } = useWalletConnectorContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isRedeemSuccess = useMemo(() => redeemRequestStatus === RequestStatus.SUCCESS, [redeemRequestStatus]);
  const isClaimSuccess = useMemo(() => claimRequestStatus === RequestStatus.SUCCESS, [claimRequestStatus]);

  const userOwner = useMemo(
    () => (owners && owners.find((owner) => owner.address === address)) || ({} as Ownership),
    [address, owners],
  );

  const handleCheckboxClick = (
    values: RedeemClaimFormFields,
    setFieldValue: (item: string, value: string | number | Network | undefined) => void,
    setFieldTouched: (field: string, isTouched?: boolean | undefined) => void,
  ) => {
    if (!isChecked) {
      Object.keys(values).forEach(async (item) => {
        await setFieldValue(item, orderType === 'redeem' ? redeem[item as keyof Redeem] : claim[item as keyof Claim]);
        setFieldTouched(item, true);
      });
    } else {
      inputFields.forEach(({ name }) => {
        setFieldValue(name, '');
        setFieldTouched(name, true);
      });
    }
    setIsChecked((prev) => !prev);
  };

  const handleSubmit = (
    values: RedeemClaimFormFields,
    resetForm: () => void,
    setSubmitting: (value: boolean) => void,
  ) => {
    if (network?.name && network.name === userNetwork) {
      if (id) {
        const formData = new FormData();
        if (orderType === 'redeem') {
          formData.append('amount', quantity);
        }
        formData.append('token_id', id.toString());
        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName);
        formData.append('address', values.address);
        formData.append('zip_code', values.zipCode);
        formData.append('mobile_number', values.mobileNumber);
        formData.append('email', values.email);
        if (orderType === 'redeem') {
          dispatch(redeemNft({ formData, setSubmitting, web3Provider: walletService.Web3() }));
        } else {
          dispatch(claimNft({ formData, setSubmitting }));
        }
      }
    } else if (network?.name) {
      toast.error(`Wrong network, please change network to ${network.name} and try again`);
    }
  };

  useEffect(() => {
    if (isClaimSuccess || isRedeemSuccess) {
      navigate(-1);
      dispatch(reset(actionTypes[orderType === 'redeem' ? 'REDEEM' : 'CLAIM']));
    }
  }, [dispatch, isClaimSuccess, isRedeemSuccess, navigate, orderType]);

  useEffect(() => {
    dispatch(getAutofill({ type: orderType }));
  }, [dispatch, orderType]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={setInitialValues({ email: email || '' })}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => handleSubmit(values, resetForm, setSubmitting)}
    >
      {({ touched, errors, handleChange, handleBlur, values, isValid, setFieldValue, setFieldTouched }) => (
        <Form id="redeemForm">
          <CallBack value={isValid} onChange={onIsValidChange} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
            <Typography
              sx={{ typography: { xs: 'h4', md: 'h2' }, fontWeight: FontWeights.fontWeightSemiBold }}
              className="lg"
            >
              NFT Details
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="autofill"
                  checked={isChecked}
                  disabled={Object.keys(orderType === 'redeem' ? redeem : claim).length === 0}
                  onClick={() => handleCheckboxClick(values, setFieldValue, setFieldTouched)}
                />
              }
              label="Autofill"
            />
          </Box>
          <Stack spacing={2} width={{ xs: '100%', sm: '433px' }}>
            <Stack spacing={1}>
              <Typography sx={{ typography: 'h4', marginBottom: 2 }} className={isMediumScreen ? 'lg' : ''}>
                Profile Name
              </Typography>
              <TextField
                variant="filled"
                disabled
                name="profileName"
                value={displayName}
                placeholder=""
                InputProps={{
                  sx: { background: `${COLOR_LIGHT_GRAY} !important` },
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography sx={{ typography: 'h4', marginBottom: 2 }} className={isMediumScreen ? 'lg' : ''}>
                Wallet Address
              </Typography>
              <TextField
                variant="filled"
                disabled
                name="address"
                value={address}
                placeholder=""
                InputProps={{
                  sx: { background: `${COLOR_LIGHT_GRAY} !important` },
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography sx={{ typography: 'h4', marginBottom: 2 }} className={isMediumScreen ? 'lg' : ''}>
                Token ID
              </Typography>
              <TextField
                variant="filled"
                disabled
                name="tokenId"
                value={id}
                placeholder=""
                InputProps={{
                  sx: { background: `${COLOR_LIGHT_GRAY} !important` },
                }}
              />
            </Stack>
            {orderType === 'redeem' && standart === 'ERC1155' && (
              <QuantityCounter
                quantity={quantity}
                setQuantity={setQuantity}
                maxQuantity={+(userOwner?.quantity || 1) - +(userOwner?.sellingQuantity || 0)}
              />
            )}
          </Stack>
          <Typography
            sx={{
              typography: { xs: 'h2', md: 'h1' },
              fontWeight: FontWeights.fontWeightSemiBold,
              marginBottom: 4,
              marginTop: 6,
            }}
          >
            Address to receive
          </Typography>
          <Stack spacing={2} width={{ xs: '100%', sm: '433px' }}>
            {inputFields.map((item) => (
              <Field
                key={item.id}
                id={item.id}
                name={item.name}
                render={({ form: { isSubmitting } }: FieldProps) => (
                  <Stack spacing={1}>
                    <Typography sx={{ typography: 'h4', marginBottom: 2 }} className={isMediumScreen ? 'lg' : ''}>
                      {item.label}
                    </Typography>
                    <TextField
                      variant="filled"
                      disabled={isSubmitting}
                      multiline={item.isMultiline}
                      name={item.name}
                      value={values[item.name]}
                      placeholder={item.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched[item.name] && !!errors[item.name]}
                      helperText={
                        (touched[item.name] && errors[item.name]) || item.helperText?.(values[item.name]?.length || 0)
                      }
                      InputProps={{
                        sx: { background: `${COLOR_LIGHT_GRAY} !important` },
                      }}
                    />
                  </Stack>
                )}
              />
            ))}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
