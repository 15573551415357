import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains } from 'types';
import { Activity } from 'types/api';
import { camelize, logger } from 'utils';

import { getFollowingActivities } from '../actions';
import actionTypes from '../actionTypes';
import { setActivities, setTotalActivities } from '../reducer';

export function* getFollowingActivitiesSaga({ type, payload }: ReturnType<typeof getFollowingActivities>) {
  yield* put(request(type));
  const network: Chains = yield select(userSelector.getProp('network'));
  try {
    const { data } = yield* call(baseApi.getFollowingActivities, { ...payload, network });
    const result = camelize(data.results) as Activity[];

    yield* put(setActivities(result));

    yield put(setTotalActivities(data.total));
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getFollowingActivities', err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_FOLLOWING_ACTIVITIES, getFollowingActivitiesSaga);
}
