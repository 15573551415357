import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { Close, SuccessBadge } from 'components';
import { useShallowSelector } from 'hooks';
import { closeModal } from 'store/modals/reducer';
import modalSelector from 'store/modals/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_GREEN } from 'theme/variables';
import { Modals } from 'types';

export type SuccessCreateCollectibleModalProps = {
  clearAllForm: () => void;
};

export const SuccessCreateCollectibleModal: FC<SuccessCreateCollectibleModalProps> = ({ clearAllForm }) => {
  const { modalState } = useShallowSelector(modalSelector.getModals);
  const dispatch = useDispatch();

  const isOpen = useMemo(
    () => modalState.activeModal === Modals.SuccessCreateCollectible && modalState.open,
    [modalState.activeModal, modalState.open],
  );

  const handleClose = () => dispatch(closeModal());

  const handleMintAnotherOne = () => {
    clearAllForm();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          padding: 2,
          width: '300px',
          borderRadius: '16px',
        },
      }}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" gap={2}>
        <Box position="absolute" top="16px" right="16px">
          <Button variant="text" onClick={handleClose}>
            <Close sx={{ color: COLOR_GREEN }} />
          </Button>
        </Box>
        <SuccessBadge sx={{ color: COLOR_GREEN }} />
        <Typography textAlign="center">
          Congratulations!
          <br />
          You minted your NFT
        </Typography>
        <Button
          variant="contained"
          onClick={handleMintAnotherOne}
          sx={{ width: '163px', height: '48px', fontWeight: FontWeights.fontWeightMedium, textTransform: 'none' }}
        >
          Mint another one
        </Button>
      </Grid>
    </Dialog>
  );
};
