import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { ContractsNames } from 'services/WalletService/config';
import { buyNft } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';
import { Currencies, RequestStatus } from 'types';
import { Ownership } from 'types/api';

import { shortenPhrase } from '../../../../../../../../utils';

type SellerCardProps = {
  seller: Ownership;
};

const MAX_QUANTITY = '1'; // limited to 1 [MZ-243]

export const SellerCard: FC<SellerCardProps> = ({ seller }) => {
  const [quantity, setQuantity] = useState(MAX_QUANTITY);
  const { id } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { balance, network } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.BUY]: buyRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { walletService } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const isBuyLoading = useMemo(() => buyRequestStatus === RequestStatus.REQUEST, [buyRequestStatus]);

  const currentBalance = useMemo(
    () => balance[network][seller?.currency?.symbol?.toUpperCase() as Currencies] || 0,
    [balance, network, seller?.currency?.symbol],
  );

  const handleBuy = () => {
    if (id && seller?.url && seller?.price && seller?.currency?.symbol) {
      dispatch(
        buyNft({
          tokenAmount: quantity,
          web3Provider: walletService.Web3(),
          id,
          currencySymbol:
            seller.currency.symbol !== 'eth' && seller.currency.symbol !== 'matic'
              ? (seller.currency.symbol.toUpperCase() as ContractsNames)
              : undefined,
          sellerId: seller.url,
          price: +seller.price * +quantity,
        }),
      );
    }
  };

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      key={seller.address}
      mx="auto"
      p={2}
      width="100%"
      bgcolor={COLOR_LIGHT_GRAY}
      borderRadius="8px"
    >
      <Stack mb={2} direction="row" spacing={2} width="100%" alignItems="center">
        <Avatar srcSet={seller.avatar} sx={{ width: '40px', height: '40px' }} />
        <Box>
          <Typography>
            {seller.name && seller.name.length > 20 ? shortenPhrase(seller.name, 15, 5) : seller.name}
          </Typography>
          <Typography mt={0.25} variant="subtitle1" color={COLOR_GRAY_150}>
            {seller?.sellingQuantity || 1}/{seller?.quantity || 1} on sale for &nbsp;
            <Box component="span" color={COLOR_BLACK} fontWeight={FontWeights.fontWeightMedium}>
              {`${parseFloat(seller?.price || '0')} ${seller.currency?.symbol?.toUpperCase() || ''}`}
            </Box>
            &nbsp;each
          </Typography>
        </Box>
      </Stack>
      {/* <QuantityCounter */}
      {/*  quantity={quantity} */}
      {/*  setQuantity={setQuantity} */}
      {/*  maxQuantity={MAX_QUANTITY} */}
      {/*  alignItems="center" */}
      {/*  spacing={1} */}
      {/* /> */}
      <Button
        variant="contained"
        disabled={+currentBalance < +quantity * +(seller?.price || 0) || isBuyLoading}
        onClick={handleBuy}
        sx={{ width: '174px', height: '44px' }}
      >
        {isBuyLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'buy'}
      </Button>
    </Stack>
  );
};
