import { chains } from 'services/WalletService/config';
import { Chains, IChainType } from 'types';

export const constructExplorerUrl = (
  addressOrTxHash: string,
  network: Chains,
  chainType: IChainType,
  linkType: 'address' | 'token' | 'tx',
) => {
  return `${chains[network][chainType].blockExplorerUrl}/${linkType}/${addressOrTxHash}`;
};
