import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { setOnSale } from '../actions';
import actionTypes from '../actionTypes';
import { setDetailedNft } from '../reducer';

import { approveNftSaga } from './approveNft';

export function* setOnSaleSaga({
  type,
  payload: { isSingle, collectionAddress, web3Provider, amount, id, currency, price, endSale },
}: ReturnType<typeof setOnSale>) {
  yield put(request(type));

  try {
    try {
      yield call(approveNftSaga, {
        type: actionTypes.APPROVE_NFT,
        payload: { isSingle, collectionAddress, web3Provider },
      });
    } catch {
      return;
    }

    const { data } = yield call(
      baseApi.setOnSale,
      id,
      amount ? { price, currency, amount, endSale } : { price, currency, endSale },
    );
    yield put(setDetailedNft(camelize(data, true)));
    toast.success('You have successfully set NFT on sale');
    yield put(success(type));
  } catch (err) {
    logger('Set on sale', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SET_ON_SALE, setOnSaleSaga);
}
