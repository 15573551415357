import { FC } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY_100 } from 'theme/variables';

export const Terms: FC = () => {
  return (
    <>
      <Typography sx={{ pb: 2, typography: { xs: 'h2', sm: 'h1' }, color: `${COLOR_BLACK} !important` }}>
        Terms of Service
      </Typography>
      <Typography pb={2} sx={{ color: COLOR_GRAY_100 }}>
        Last Updated: August 26, 2022
      </Typography>

      <List sx={{ listStyleType: 'disc' }}>
        <ListItem sx={{ display: 'list-item' }}>Introduction</ListItem>
        <ListItem>
          These Terms of Service (“Terms”) govern your access to and use of the meta[z] website(s), our APIs, mobile app
          (the “App”), and any other software, tools, features, or functionalities provided on or in connection with our
          services; including without limitation using our services to view, explore, and create NFTs and use our tools,
          at your own discretion, to connect directly with others to purchase, sell, or transfer NFTs on public
          blockchains (collectively, the “Service”). “NFT” in these Terms means a non-fungible token or similar digital
          item implemented on a blockchain (such as the Ethereum blockchain), which uses smart contracts to link to or
          otherwise be associated with certain content or data.
        </ListItem>
        <ListItem>
          For purposes of these Terms, “user”, “you”, and “your” means you as the user of the Service. If you use the
          Service on behalf of a company or other entity then “you” includes you and that entity, and you represent and
          warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to
          these Terms, and (b) you agree to these Terms on the entity’s behalf.
        </ListItem>
        <ListItem>
          Meta[Z] is not a wallet provider, exchange, broker, financial institution, money services business, or
          creditor.
        </ListItem>
        <ListItem>
          Meta[Z] is not party to any agreement between any users. You bear full responsibility for verifying the
          identity, legitimacy, and authenticity of NFTs that you purchase from third-party sellers using the Service.
        </ListItem>

        <ListItem>
          Meta[Z] reserves the right to change or modify these Terms at any time and in our sole discretion. If we make
          material changes to these Terms, we will use reasonable efforts to provide notice of such changes, such as by
          providing notice through the Service or updating the “Last Updated” date at the beginning of these Terms. By
          continuing to access or use the Service, you confirm your acceptance of the revised Terms and all of the terms
          incorporated therein by reference effective as of the date these Terms are updated. It is your sole
          responsibility to review the Terms from time to time to view such changes and to ensure that you understand
          the terms and conditions that apply when you access or use the Service.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Accessing the Service</ListItem>
        <ListItem>
          By using your wallet in connection with the Service, you agree that you are using that wallet under the terms
          and conditions of the applicable provider of the wallet. Wallets are not operated by, maintained by, or
          affiliated with Meta[Z], and Meta[Z] does not have custody or control over the contents of your wallet and has
          no ability to retrieve or transfer its contents. Meta[Z] accepts no responsibility for, or liability to you,
          in connection with your use of a wallet and makes no representations or warranties regarding how the Service
          will operate with any specific wallet. You are solely responsible for keeping your wallet secure and you
          should never share your wallet credentials or seed phrase with anyone. If you discover an issue related to
          your wallet, please contact your wallet provider. Likewise, you are solely responsible for your Account and
          any associated wallet and we are not liable for any acts or omissions by you in connection with your Account
          or as a result of your Account or wallet being compromised. You agree to immediately notify us if you discover
          or otherwise suspect any security issues related to the Service or your Account.
        </ListItem>
        <ListItem>
          Meta[Z] may require you to provide additional information and documents in certain circumstances, such as at
          the request of any government authority, as any applicable law or regulation dictates, or to investigate a
          potential violation of these Terms. In such cases, Meta[Z], in its sole discretion, may disable your Account
          and block your ability to access the Service until such additional information and documents are processed by
          Meta[Z]. If you do not provide complete and accurate information in response to such a request, Meta[Z] may
          refuse to restore your access to the Service.
        </ListItem>
        <ListItem>
          Your access and use of the Service may be interrupted from time to time for any of several reasons, including,
          without limitation, the malfunction of equipment, periodic updating, maintenance, or repair of the Service or
          other actions that Meta[Z], in its sole discretion, may elect to take. If your Account becomes inactive for
          six months or longer, as determined by Meta[Z] in its sole discretion, you understand that Meta[Z] may disable
          your Account or reassign your username or associated url.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Ownership</ListItem>
        <ListItem>
          The Service, including its “look and feel” (e.g., text, graphics, images, logos, page headers, button icons,
          and scripts), proprietary content, information and other materials, and all content and other materials
          contained therein, including, without limitation, the Meta[Z] logo and all designs, text, graphics, pictures,
          data, software, sound files, other files, and the selection and arrangement thereof are the proprietary
          property of Meta[Z] or our affiliates, licensors, or users, as applicable, and you agree not to take any
          action(s) inconsistent with such ownership interests. We and our affiliates, licensors, and users, as
          applicable, reserve all rights in connection with the Service and its content, including, without limitation,
          the exclusive right to create derivative works.
        </ListItem>
        <ListItem>
          Meta[Z]’s name, logo, trademarks, and any Meta[Z] product or service names, designs, logos, and slogans are
          the intellectual property of Meta[Z] or our affiliates or licensors and may not be copied, imitated or used,
          in whole or in part, without our prior written permission in each instance. You may not use any metatags or
          other “hidden text” utilizing “Meta[Z]” or any other name, trademark or product or service name of Meta[Z] or
          our affiliates or licensors without our prior written permission. In addition, the “look and feel” of the
          Service constitutes the service mark, trademark or trade dress of Meta[Z] and may not be copied, imitated or
          used, in whole or in part, without our prior written permission.
        </ListItem>
        <ListItem>
          All other third-party trademarks, registered trademarks, and product names mentioned on the Service or
          contained in the content linked to or associated with any NFTs displayed on the Service are the property of
          their respective owners and may not be copied, imitated or used, in whole or in part, without the permission
          of the applicable intellectual property rights holder.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}> User Conduct</ListItem>

        <ListItem>
          To protect our community and comply with our legal obligations, we reserve the right to take action, with or
          without advance notice, if we believe you have violated these Terms. This may include: removing the ability to
          view certain NFTs on the Service or use our Service to interact with the NFTs; disabling the ability to use
          the Service in conjunction with buying/selling/transferring NFTs available on blockchains; disabling your
          ability to access our Service; and/or other actions.
        </ListItem>
        <ListItem
          sx={{
            display: 'list-item',
            listStyleType: 'none',
            '& .MuiListItem-root': { display: 'list-item', listStyleType: 'disc', pl: 3 },
          }}
        >
          You agree that you will not violate any law, contract, intellectual property or other third-party right, and
          that you are solely responsible for your conduct and content, while accessing or using the Service. You also
          agree that you will not:
          <ListItem>Use or attempt to use another user’s Account without authorization from such user;</ListItem>
          <ListItem>
            Pose as another person or entity, or use a wallet to engage in a transaction on Meta[Z] that is owned or
            controlled, in whole or in part, by any other person;
          </ListItem>
          <ListItem>
            Claim a Meta[Z] username for the purpose of reselling it, confusing others, deriving others’ goodwill, or
            otherwise engage in name squatting;
          </ListItem>
          <ListItem>Distribute spam, including through sending unwanted NFTs to other users;</ListItem>
          <ListItem>
            Use the Service – including through disseminating any software or interacting with any API – that could
            damage, disable, overburden, or impair the functioning of the Service in any manner;
          </ListItem>
          <ListItem>
            Bypass or ignore instructions that control access to the Service, including attempting to circumvent any
            rate limiting systems by using multiple API keys, directing traffic through multiple IP addresses, or
            otherwise obfuscating the source of traffic you send to Meta[Z];
          </ListItem>
          <ListItem>
            Use our Service for commercial purposes inconsistent with these Terms or any other instructions;
          </ListItem>
          <ListItem>
            Use any data mining, robot, spider, crawler, scraper, script, browser extension, offline reader, or other
            automated means or interface not authorized by us to access the Service, extract data, or otherwise
            interfere with or modify the rendering of Service pages or functionality;
          </ListItem>
          <ListItem>
            Reverse engineer, duplicate, decompile, disassemble, or decode any aspect of the Service, or do anything
            that might discover source code or bypass or circumvent measures employed to prevent or limit access to any
            service, area, or code of the Service;
          </ListItem>
          <ListItem>Sell or resell the Service or attempt to circumvent any Meta[Z] fee systems;</ListItem>
          <ListItem>
            Engage in behaviors that have the intention or the effect of artificially causing an item or collection to
            appear at the top of search results, or artificially increasing view counts, favorites, or other metrics
            that Meta[Z] might use to sort search results;
          </ListItem>
          <ListItem>
            Use the Service for or in connection with money laundering, terrorist financing, or other illicit financial
            activity, or in any way in connection with the violation of any law or regulation that applies to you or to
            Meta[Z];
          </ListItem>
          <ListItem>
            Use the Service, directly or indirectly, for, on behalf of, or for the benefit of, (a) any natural or legal
            person that is the subject of Sanctions; (b) any natural or legal person located in, ordinarily resident in,
            or organized under the laws of, any Embargoed Jurisdiction; or (c) any legal person owned or controlled,
            directly or indirectly, by any natural or legal person located in, ordinarily resident in, or organized
            under the laws of, any Embargoed Jurisdiction.
          </ListItem>
          <ListItem>
            Use the Service to carry out any financial activities subject to registration or licensing, including but
            not limited to creating, offering, selling, or buying securities, commodities, options, or debt instruments;
          </ListItem>
          <ListItem>
            Use the Service to engage in price manipulation, fraud, or other deceptive, misleading, or manipulative
            activity;
          </ListItem>
          <ListItem>
            Use the Service to buy, sell, or transfer stolen items, fraudulently obtained items, items taken without
            authorization, and/or any other illegally obtained items;
          </ListItem>
        </ListItem>
        <ListItem>
          Finally, by using the Service, you understand the importance of DYOR – doing your own research. You bear full
          responsibility for verifying the authenticity, legitimacy, identity, and other details about any NFT,
          collection, or account that you view or otherwise interact with in conjunction with our Service. We make no
          guarantees or promises about the identity, legitimacy, or authenticity of any NFT, collection, or account on
          the Service.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Communication Preferences</ListItem>
        <ListItem>
          By creating an Account, you consent to receive electronic communications from Meta[Z] (e.g., via email, push
          notification, text messages, or other types of messages). These communications may include notices about your
          Account (e.g., transactional information) and are part of your relationship with us. We may also send you
          promotional communications via email we think will be of interest to you. You understand that you are not
          required to provide this consent as a condition of using the Service and you may opt out of these
          communications through the Service or through your mobile device’s operating system (with the possible
          exception of important service announcements and administrative messages) by following the unsubscribe
          instructions provided.
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>Indemnification</ListItem>
        <ListItem>
          By agreeing to these Terms and accessing the Service, you agree, to the fullest extent permitted by applicable
          law, to indemnify, defend, and hold harmless Meta[Z], and our respective past, present, and future employees,
          officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent
          companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns
          (individually and collectively, the “Meta[Z] Parties”), from and against all actual or alleged claims,
          damages, awards, judgments, losses, liabilities, obligations, taxes, penalties, interest, fees, expenses
          (including, without limitation, attorneys’ fees and expenses), and costs (including, without limitation, court
          costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature
          whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or
          unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”), including,
          but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to
          (a) your use or misuse of the Service, content, NFTs, or content linked to or associated with any NFTs (b) any
          Feedback you provide, (c) your violation or breach of any term of these Terms or applicable law, and (d) your
          violation of the rights of or obligations to a third party, including another user or third-party, and (e)
          your negligence or wilful misconduct. You agree to promptly notify Meta[Z] of any Claims and cooperate with
          the Meta[Z] Parties in defending such Claims. You further agree that the Meta[Z] Parties shall have control of
          the defense or settlement of any Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER
          INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND META[Z].
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Disclaimers</ListItem>
        <ListItem>
          YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICE IS
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND META[Z] EXPRESSLY DISCLAIMS WARRANTIES OR CONDITIONS OF
          ANY KIND, EITHER EXPRESS OR IMPLIED. META[Z] (AND ITS SUPPLIERS) MAKE NO WARRANTY OR REPRESENTATION AND
          DISCLAIM ALL RESPONSIBILITY FOR WHETHER THE SERVICE: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON
          AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR
          SAFE. META[Z] DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
          IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NON-INFRINGEMENT. META[Z] WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN
          RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SERVICE. WHILE META[Z] ATTEMPTS TO MAKE YOUR ACCESS TO
          AND USE OF THE SERVICE SAFE, META[Z] CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT,
          CONTENT LINKED TO OR ASSOCIATED WITH ANY NFTS, OR ANY NFTS YOU INTERACT WITH USING OUR SERVICE OR OUR SERVICE
          PROVIDERS’ SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY
          DATA THAT YOU DISCLOSE ONLINE. NO ADVICE OR INFORMATION, WHETHER ORAL OR OBTAINED FROM THE META[Z] PARTIES OR
          THROUGH THE SERVICE, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN. YOU ACCEPT THE
          INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD
          META[Z] RESPONSIBLE FOR ANY BREACH OF SECURITY.
        </ListItem>
        <ListItem>
          WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE
          LIABLE TO YOU FOR, ANY USE OF NFTS, CONTENT, AND/OR CONTENT LINKED TO OR ASSOCIATED WITH NFTS, INCLUDING BUT
          NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (A) USER ERROR, INCORRECTLY CONSTRUCTED
          TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) UNAUTHORIZED ACCESS OR USE; (D) ANY
          UNAUTHORIZED THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING
          OR OTHER MEANS OF ATTACK AGAINST THE SERVICE OR NFTS.
        </ListItem>
        <ListItem>
          NFTS EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM
          NETWORK). ANY TRANSFERS OR SALES OCCUR ON THE ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM). META[Z] AND/OR ANY OTHER
          META[Z] PARTY CANNOT EFFECT OR OTHERWISE CONTROL THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS OR UNDERLYING OR
          ASSOCIATED CONTENT OR ITEMS.
        </ListItem>
        <ListItem>
          NO META[Z] PARTY IS RESPONSIBLE OR LIABLE FOR ANY SUSTAINED LOSSES OR INJURY DUE TO VULNERABILITY OR ANY KIND
          OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF
          THE NFTS. NO META[Z] PARTY IS RESPONSIBLE FOR LOSSES OR INJURY DUE TO LATE REPORTS BY DEVELOPERS OR
          REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE NFTS, INCLUDING FORKS,
          TECHNICAL NODE ISSUES OR ANY OTHER ISSUES HAVING LOSSES OR INJURY AS A RESULT.
        </ListItem>
        <ListItem>
          Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so the above
          exclusion may not apply to you.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Assumption of Risk</ListItem>
        <ListItem
          sx={{
            display: 'list-item',
            listStyleType: 'none',
            '& .MuiListItem-root': { display: 'list-item', listStyleType: 'disc', pl: 3 },
          }}
        >
          You accept and acknowledge:
          <ListItem>
            The value of an NFTs is subjective. Prices of NFTs are subject to volatility and fluctuations in the price
            of cryptocurrency can also materially and adversely affect NFT prices. You acknowledge that you fully
            understand this subjectivity and volatility and that you may lose money.
          </ListItem>
          <ListItem>
            A lack of use or public interest in the creation and development of distributed ecosystems could negatively
            impact the development of those ecosystems and related applications and could therefore also negatively
            impact the potential utility of NFTs.
          </ListItem>
          <ListItem>
            The regulatory regime governing blockchain technologies, non-fungible tokens, cryptocurrency, and other
            crypto-based items is uncertain, and new regulations or policies may materially adversely affect the
            development of the Service and the utility of NFTs.
          </ListItem>
          <ListItem>
            You are solely responsible for determining what, if any, taxes apply to your transactions and to withhold,
            collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. Meta[Z] is not
            responsible for determining, withholding, collecting, reporting, or remitting the taxes that apply to your
            NFTs.
          </ListItem>
          <ListItem>
            There are risks associated with using Internet and blockchain based products, including, but not limited to,
            the risk associated with hardware, software, and Internet connections, the risk of malicious software
            introduction, and the risk that third parties may obtain unauthorized access to your third-party wallet or
            Account. You accept and acknowledge that Meta[Z] will not be responsible for any communication failures,
            disruptions, errors, distortions or delays you may experience when using the Service or any Blockchain
            network, however caused.
          </ListItem>
          <ListItem>
            If you have a dispute with one or more users, YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND
            AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN ENTERING INTO
            THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT
            THE COVERAGE OF THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOR AT
            THE TIME OF AGREEING TO THIS RELEASE.
          </ListItem>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Limitation of Liability</ListItem>
        <ListItem>
          TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL META[Z] OR ITS SERVICE PROVIDERS BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
          SPECIAL, OR PUNITIVE DAMAGES ARISING FROM THESE TERMS OR THE SERVICE, PRODUCTS OR THIRD-PARTY SITES AND
          PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
          SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY STRICT LIABILITY OR TORT
          (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF META[Z] OR ITS
          SERVICE PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; OR (B) FOR ANY OTHER CLAIM, DEMAND, OR
          DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF THE DELIVERY, USE, OR
          PERFORMANCE OF THE SERVICE. ACCESS TO, AND USE OF, THE SERVICE, PRODUCTS OR THIRD-PARTY SITES, AND PRODUCTS
          ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
          OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.
        </ListItem>
        <ListItem>
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the
          above limitation or exclusion may not apply to you. Some jurisdictions also limit disclaimers or limitations
          of liability for personal injury from consumer products, so this limitation may not apply to personal injury
          claims.
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>Modifications to the Service</ListItem>
        <ListItem>
          We reserve the right in our sole discretion to modify, suspend, or discontinue, temporarily or permanently,
          the Service (or any features or parts thereof) at any time and without liability as a result.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Termination</ListItem>
        <ListItem>
          If you breach any of the provisions of these Terms, all licenses granted by Meta[Z] will terminate
          automatically. Additionally, notwithstanding anything contained in these Terms, we reserve the right, with or
          without notice and in our sole discretion, to suspend, disable, terminate, or delete your Account and/or your
          ability to access or use the Service (or any part of the foregoing) at any time and for any or no reason, and
          you acknowledge and agree that we shall have no liability or obligation to you in such event and that you will
          not be entitled to a refund of any amounts that you have already paid to us.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>Miscellaneous</ListItem>
        <ListItem>
          These Terms constitute the entire agreement between you and Meta[Z] relating to your access to and use of the
          Service. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you
          without the prior written consent of Meta[Z], and Meta[Z]’s failure to assert any right or provision under
          these Terms shall not constitute a waiver of such right or provision. No waiver by either party of any breach
          or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The
          section headings used herein are for reference only and shall not be read to have any legal effect.
        </ListItem>
        <ListItem>
          You and Meta[Z] agree that the United Nations Convention on Contracts for the International Sale of Goods will
          not apply to the interpretation or construction of these Terms.
        </ListItem>
        <ListItem>
          Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are
          not intended to confer third-party beneficiary rights upon any other person or entity.
        </ListItem>
      </List>
    </>
  );
};
