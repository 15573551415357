import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { SocialLinks } from 'components/SocialLinks';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_LIGHT } from 'theme/variables';

import { aboutItems, termsItems } from './Footer.helpers';

const handleScrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const Footer: FC = () => {
  return (
    <Box>
      <Stack
        px={{ xs: 1, sm: 4 }}
        py={{ xs: 3, sm: 6 }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        borderBottom={`1px solid ${COLOR_LIGHT}`}
      >
        <SocialLinks />
        <Stack direction="row" spacing={5}>
          {aboutItems.map(({ id, title, link, isExternal = false }) =>
            isExternal ? (
              <Link
                key={id}
                href={link}
                target="_blank"
                rel="noreferrer"
                zIndex={1}
                sx={{ height: 28, textDecoration: 'none' }}
              >
                <Typography color={COLOR_BLACK} fontWeight={FontWeights.fontWeightSemiBold}>
                  {title}
                </Typography>
              </Link>
            ) : (
              <NavLink key={id} to={link} onClick={handleScrollToTop}>
                <Typography color={COLOR_BLACK} fontWeight={FontWeights.fontWeightSemiBold}>
                  {title}
                </Typography>
              </NavLink>
            ),
          )}
        </Stack>
      </Stack>
      <Stack
        px={{ xs: 1, sm: 4 }}
        py={{ xs: 4, sm: 5 }}
        direction={{ xs: 'column-reverse', sm: 'row' }}
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1" fontWeight={FontWeights.fontWeightMedium} sx={{ color: COLOR_BLACK }}>
          © 2022 meta[Z] copyright | All Rights Reserved
        </Typography>
        <Stack direction="row" spacing={5} alignItems="center">
          {termsItems.map(({ id, title, link }) => (
            <NavLink key={id} to={link} onClick={handleScrollToTop}>
              <Typography variant="subtitle1" sx={{ color: COLOR_BLACK }}>
                {title}
              </Typography>
            </NavLink>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
