import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Avatar, Box, Button, IconButton, Menu, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import BigNumber from 'bignumber.js';
import { CopyText } from 'components/CopyText';
import { useModal, useShallowSelector } from 'hooks';
import { NavSelect } from 'modules/profile/pages/Profile/components/NavSelect';
import { currenciesImagesMock } from 'modules/profile/pages/Profile/Profile.mock';
import { getSelfCollections } from 'store/nft/actions';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { theme } from 'theme';
import { FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_150,
  COLOR_LIGHT,
  COLOR_LIGHT_GRAY,
  COLOR_RED_100,
  COLOR_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';
import { Currencies, Modals } from 'types';
import { shortenPhrase } from 'utils';

import { navButtonsMock, networkMock } from './Profile.helper';

type ProfileProps = {
  onOpenModal: (type: Modals) => void;
  handleIsOpenMobileMenu?: () => void;
};

const NavButton = styled(Button)({
  padding: theme.spacing(1.375),
  paddingLeft: theme.spacing(2),
  justifyContent: 'start',
  background: `${COLOR_LIGHT_GRAY} !important`,
  color: COLOR_BLACK,
  border: '1px solid',
  borderColor: 'transparent',
  '&:hover': {
    background: `${COLOR_LIGHT_GRAY} !important`,
    border: `1px solid ${COLOR_BLACK}`,
    color: COLOR_BLACK,
  },
});

const NetworkButton = styled(Button)({
  justifyContent: 'start',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(1.75),
  width: '144px',
  background: `${COLOR_WHITE} !important`,
  border: `1px solid ${COLOR_LIGHT} !important`,
  '&:hover': {
    background: `${COLOR_WHITE} !important`,
    border: `1px solid ${COLOR_GRAY_150} !important`,
  },
});

export const Profile: FC<ProfileProps> = ({ onOpenModal, handleIsOpenMobileMenu, ...menuProps }) => {
  const dispatch = useDispatch();
  const [isMenuVisible, onOpenMenu, onCloseMenu] = useModal(false);
  const popoverRef = useRef(null);
  const {
    address,
    id: userId,
    customUrl,
    avatar,
    name,
    displayName,
    network,
    balance,
    isMultipleFabricOwner,
    isSingleFabricOwner,
  } = useShallowSelector(userSelector.getUser);
  const collections = useShallowSelector(nftSelector.getProp('collections'));
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const profileId = customUrl || userId;
  const navigate = useNavigate();

  const handleNavigateToProfile = () => {
    navigate(routes.profile.root.getPath(profileId));
  };

  useEffect(() => {
    dispatch(getSelfCollections({ network }));
  }, [dispatch, network]);

  const handleClose = () => {
    if (handleIsOpenMobileMenu) handleIsOpenMobileMenu();
    onCloseMenu();
  };

  return (
    <>
      <Avatar srcSet={avatar} sx={{ width: 45, height: 45, cursor: 'pointer' }} onClick={onOpenMenu} ref={popoverRef} />

      {isMenuVisible && (
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            onMouseLeave: onCloseMenu,
          }}
          onClose={onCloseMenu}
          sx={{
            '& .MuiPopover-paper': {
              mt: 1,
              background: `${COLOR_WHITE} !important`,
              border: `1px solid ${COLOR_LIGHT}`,
              borderRadius: BORDER_RADIUS_DEFAULT,
              width: '342px',
              padding: theme.spacing(2, 2.5),
            },
            '& .MuiList-root': {
              padding: 0,
            },
          }}
          anchorEl={popoverRef.current}
          open={isMenuVisible}
          disableRestoreFocus
          {...menuProps}
        >
          <Stack spacing={2}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                onClick={handleNavigateToProfile}
                width="100%"
                sx={{ cursor: 'pointer' }}
              >
                <Avatar srcSet={avatar} sx={{ width: 53, height: 53 }} />
                <Typography
                  variant="h4"
                  fontWeight={FontWeights.fontWeightRegular}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}
                >
                  {(displayName || name || address).length > 15
                    ? shortenPhrase(displayName || name || address, 14, 0)
                    : displayName || name}
                </Typography>
              </Stack>
              <IconButton onClick={onCloseMenu}>
                <CloseRoundedIcon fontSize="small" sx={{ fill: COLOR_BLACK }} />
              </IconButton>
            </Stack>
            <CopyText text={address} />
            <Stack direction="row" spacing={1}>
              <NetworkButton disabled variant="text">
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={1}>
                    {networkMock[network].icon}
                    <Typography variant="body1" className="capitalize">
                      {network}
                    </Typography>
                  </Stack>
                </Stack>
              </NetworkButton>
              <NetworkButton disabled variant="text">
                <Stack spacing={0.75}>
                  {Object.entries(balance[network]).map(([key, balanceValue]) => (
                    <Stack key={key} direction="row" spacing={1} sx={{ img: { width: '26px', height: '26px' } }}>
                      {currenciesImagesMock[key as Currencies]}
                      <Typography variant="body1">
                        {+balanceValue > 10000 ? '>10000' : parseFloat(new BigNumber(balanceValue).toFixed(4, 1))}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </NetworkButton>
            </Stack>
            <Stack direction="column" spacing={1}>
              {navButtonsMock
                .filter((item) =>
                  collections.length || isSingleFabricOwner || isMultipleFabricOwner
                    ? true
                    : item.title !== 'Create NFT',
                )
                .filter((item) =>
                  !isSingleFabricOwner && !isMultipleFabricOwner ? item.title !== 'Create collection' : true,
                )
                .map(({ componentType, getComponentProps, title, icon, id, getPath = () => '' }) =>
                  componentType === 'accordion' ? (
                    <Box
                      key={id}
                      sx={{
                        padding: '0 11px 0 16px',
                        background: COLOR_LIGHT_GRAY,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'transparent',
                        '&:hover': {
                          borderColor: COLOR_BLACK,
                          transition: TRANSITION_DEFAULT_TIME,
                        },
                        '.MuiAccordionSummary-root': {
                          height: 48,
                        },
                        '.MuiAccordionDetails-root': {
                          mt: 1,
                        },
                        '& > .MuiPaper-root': {
                          marginTop: '0px !important', // due to hardcoded marginTop: '32px !important' in NavSelect
                        },
                        '& > .MuiPaper-root.Mui-expanded': {
                          paddingBottom: 1,
                        },
                      }}
                    >
                      {/* reason: getComponentProps will always present (in needed property inside of Profile.helper.tsx)
                    if developer it wants */}
                      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                      <NavSelect {...getComponentProps!(type, profileId)} onCloseParentMenu={handleClose} />
                    </Box>
                  ) : (
                    <NavButton
                      key={id}
                      variant="contained"
                      onClick={() => {
                        handleClose();
                        navigate(getPath(profileId));
                      }}
                    >
                      {icon}
                      <Typography variant="body1" className="capitalize" sx={{ paddingLeft: theme.spacing(1.25) }}>
                        {title}
                      </Typography>
                    </NavButton>
                  ),
                )}
              <NavButton
                variant="contained"
                onClick={() => {
                  onOpenModal(Modals.Disconnect);
                  onCloseMenu();
                }}
              >
                <LogoutRoundedIcon sx={{ color: COLOR_RED_100, width: '22px', height: '22px' }} />
                <Typography variant="body1" className="capitalize" sx={{ paddingLeft: theme.spacing(1.25) }}>
                  Disconnect
                </Typography>
              </NavButton>
            </Stack>
          </Stack>
        </Menu>
      )}
    </>
  );
};
