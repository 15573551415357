import HotImg1 from './assets/hot-1.png';
import HotImg2 from './assets/hot-2.png';
import HotImg3 from './assets/hot-3.png';
import HotImg4 from './assets/hot-4.png';
import HotImg5 from './assets/hot-5.png';
import HotImg6 from './assets/hot-6.png';
import HotImg7 from './assets/hot-7.png';
import HotImg9 from './assets/hot-9.png';
import HotImg10 from './assets/hot-10.png';
import HotImg11 from './assets/hot-11.png';
import HotImg12 from './assets/hot-12.png';
import HotImg13 from './assets/hot-13.png';
import HotImg14 from './assets/hot-14.png';
import HotImg15 from './assets/hot-15.png';
import HotImg16 from './assets/hot-16.png';
import HotImg17 from './assets/hot-17.png';
import HotImg18 from './assets/hot-18.png';
import { HotStylesBlockProps } from './components';

export const hotStylesItems: HotStylesBlockProps[] = [
  {
    img: HotImg1,
    title: 'Super Rare',
    hashtags: ['SuperRare', 'therarest'],
    description: 'Introducing the rarest sneakers ever released.',
  },
  {
    img: HotImg2,
    title: 'Jordan 1',
    hashtags: ['AirJordan', 'Jordan1'],
    description: 'The one that started it all, which continues to live on as a modern day sneakers staple.',
  },
  {
    img: HotImg3,
    title: 'Travis Scott',
    hashtags: ['TravisScott', 'CactusJack'],
    description: 'Collaborations with NIKE and Jordan embrace his persona, creating unique designs.',
  },
  {
    img: HotImg4,
    title: 'Off-White',
    hashtags: ['NIKExOFF-White'],
    description: '“it’s larger than design culture.” by Virgil Abloh on Nike News',
  },
  {
    img: HotImg5,
    title: 'Jordan',
    hashtags: ['AirJordan'],
    description: 'Jordan is more than a brand, but a culture now.',
  },
  {
    img: HotImg6,
    title: 'Dunk Low',
    hashtags: ['SB_Dunk_Low', 'Dunk_Low'],
    description: 'NIKE Dunk Low sneakers are firmly recognised for street styles for today.',
  },
  {
    img: HotImg13,
    title: 'Waffle',
    hashtags: ['Waffle'],
    description: 'Inspired by waffle baking machine. Enjoy the unique outsole.',
  },
  {
    img: HotImg7,
    title: 'Air Force',
    hashtags: ['AirForce'],
    description: 'The perfect sneaker must be timeless and must never go out of style. And there is Nike AF-1',
  },
  {
    img: HotImg10,
    title: 'Nike',
    hashtags: ['NikeCollaboration'],
    description: 'Nike created a culture of sneakers and street fashion by collaborating with multiple brands.',
  },
  {
    img: HotImg9,
    title: 'Air Max',
    hashtags: ['Air MAx'],
    description: 'Air technology is used in a sneaker for the first time. And the innovation goes on.',
  },
  {
    img: HotImg11,
    title: 'Yeezy',
    hashtags: ['adidas', 'yeezy'],
    description: 'adidas’s controversial collaboration with Ye.',
  },
  {
    img: HotImg12,
    title: 'Other Brands',
    hashtags: ['NewBalance', 'asics', 'BathingApe'],
    description: 'Come and find many more collectible worth sneakers here.',
  },
  {
    img: HotImg17,
    title: 'adidas',
    hashtags: ['adidas', 'sneakers'],
    description: 'Various creative collaboration put them back to the leading position in the sneaker scene',
  },
  {
    img: HotImg18,
    title: 'New Balance',
    hashtags: ['New Balance', 'sneakers'],
    description: 'New Balance’s rise to the sneaker scene is definitely commendable.',
  },
  {
    img: HotImg16,
    title: 'Golf',
    hashtags: ['Jordan', 'Golf'],
    description: 'Jordan golf',
  },
  {
    img: HotImg14,
    title: 'Cortez',
    hashtags: ['Nike', 'Cortez'],
    description: 'Cortez, the origin of Nike brand.',
  },
  {
    img: HotImg15,
    title: 'Blazer',
    hashtags: ['Blazer', 'Nike'],
    description: 'Originally created as a basketball shoe. Meet the latest blazer collection.',
  },
];
