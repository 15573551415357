import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getNftData } from '../actions';
import actionTypes from '../actionTypes';
import { setDetailedNft } from '../reducer';

export function* getNftDataSaga({ type, payload: { id } }: ReturnType<typeof getNftData>) {
  yield* put(request(type));

  try {
    const { data } = yield call(baseApi.getNftData, id);
    yield put(setDetailedNft(camelize(data, true)));
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getNftData', err);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_NFT_DATA, getNftDataSaga);
}
