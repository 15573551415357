import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { TokenFastSearch } from 'types/api';
import { logger } from 'utils';
import { camelize } from 'utils/camelize';

import { presearchNfts } from '../actions';
import actionTypes from '../actionTypes';
import { setPresearchedNfts } from '../reducer';

export function* presearchNftsSaga({ type, payload }: ReturnType<typeof presearchNfts>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.presearchNfts, payload);
    const result = camelize(data) as TokenFastSearch[];
    yield put(setPresearchedNfts(result));
    yield put(success(type));
  } catch (e) {
    logger('Presearch nfts', e);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.PRESEARCH_NFTS, presearchNftsSaga);
}
