import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { useShallowSelector } from 'hooks';
import { Subscription } from 'rxjs';
import { notifyText } from 'services/WalletService/config/constants';
import { login, updateUserInfo } from 'store/user/actions';
import { disconnectWalletState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Chains, State, UserState, WalletProviders } from 'types';
import { getToastMessage, shortenPhrase } from 'utils';
import { logger } from 'utils/logger';

import { WalletService } from '../WalletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
}

interface WalletConnectProps {
  children: ReactNode;
}

type IAccountInfo = IConnect | IError | { address: string };

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<WalletConnectProps> = ({ children }) => {
  const [currentSubscriber, setCurrentSubscriber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    provider: WalletProvider,
    chainType,
    network,
    key,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    currentSubscriber?.unsubscribe();
    setCurrentSubscriber(undefined);
    getToastMessage('info', notifyText.disconnet.info);
  }, [WalletConnect, currentSubscriber, dispatch]);

  const subscriberSuccess = useCallback(
    (data: { name: string; address: string }) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
        return;
      }
      if (data.name === 'accountsChanged' || data.name === 'chainChanged') {
        logger('login params:', [data.address, WalletProvider]);
        dispatch(
          login({
            address: data.address,
            providerName: WalletProvider || WalletProviders.metamask,
            web3Provider: WalletConnect.Web3(),
            network,
          }),
        );
        getToastMessage('info', 'Please sign login message at MetaMask');
      }
    },
    [disconnect, WalletProvider, dispatch, WalletConnect, network],
  );

  const subscriberError = useCallback(
    (error: { code: number }) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.code !== 4) {
        WalletConnect.resetConnect();
        getToastMessage('error', 'You changed to wrong network.');
        dispatch(disconnectWalletState());
      }
    },
    [WalletConnect, dispatch],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);

      try {
        if (connected) {
          if (!currentSubscriber) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const sub: any = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
            setCurrentSubscriber(sub);
          }

          const accountInfo: IAccountInfo = await WalletConnect.getAccount();
          const accountAddress = (accountInfo as IConnect).address;
          if (key?.length && address === accountAddress) {
            dispatch(updateUserInfo({ web3Provider: WalletConnect.Web3(), network: chain }));
            return;
          }
          if (accountAddress) {
            dispatch(
              login({
                address: accountAddress,
                providerName: provider,
                web3Provider: WalletConnect.Web3(),
                network: chain,
              }),
            );
            getToastMessage('success', `Wallet connected: ${shortenPhrase(accountAddress, 3, 3)}`);
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else {
          throw Error();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (!window.ethereum) {
          if (provider === WalletProviders.metamask) {
            window.open(`https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}`);
          }
          return;
        }

        if (error.code === 4) {
          getToastMessage('error', typeof error.message === 'string' ? error.message : error.message.text);
        }
      }
    },
    [WalletConnect, chainType, currentSubscriber, key, address, subscriberSuccess, subscriberError, dispatch],
  );

  useEffect(() => {
    // connect user if he connected previously
    //! !
    if (WalletProvider) {
      connect(WalletProvider, network);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
