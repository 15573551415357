import { toast } from 'react-toastify';
import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import BigNumber from 'bignumber.js';
import { contractsConfig } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains, IChainType } from 'types';
import { getMaxGas, logger } from 'utils';

import { approve } from '../actions';
import actionTypes from '../actionTypes';

export function* approveSaga({
  type,
  payload: { amount, spender, web3Provider, isBid, currencySymbol },
}: ReturnType<typeof approve>) {
  yield put(request(type));

  const address: string = yield select(userSelector.getProp('address'));
  const chainType: IChainType = yield select(userSelector.getProp('chainType'));
  const network: Chains = yield select(userSelector.getProp('network'));

  const { address: tokenAddress, abi } = contractsConfig.contracts[currencySymbol][chainType];

  try {
    const tokenContract: ContractWeb3 = yield new web3Provider.eth.Contract(abi[network], tokenAddress[network]);

    const allowance: string = yield call(tokenContract.methods.allowance(address, spender).call);

    if ((!isBid && new BigNumber(allowance).isLessThan(new BigNumber(amount))) || isBid) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield call(getMaxGas);
        yield call(tokenContract.methods.approve(spender, new BigNumber(amount).plus(allowance).toFixed(0, 1)).send, {
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });
        yield put(success(type));
      } catch (e) {
        yield put(error(type, e));
        logger('Approve token', e);
        toast.error('Something went wrong');
      }
    }
  } catch (err) {
    yield put(error(type, err));
    logger('Allowance token', err);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE, approveSaga);
}
