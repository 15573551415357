import { FC } from 'react';
import { Box, Container } from '@mui/material';

import { HeaderControls } from './components';

export const Header: FC = () => (
  <Box
    sx={{
      mb: 2,
      top: '0',
      position: 'relative',
      zIndex: '2',
      background: 'transparent',
    }}
  >
    <Container>
      <HeaderControls />
    </Container>
  </Box>
);
