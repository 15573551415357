import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getNetwork } from '../actions';
import actionTypes from '../actionTypes';
import { updateNetwork } from '../reducer';

export function* getNetworkSaga({ type, payload: { network } }: ReturnType<typeof getNetwork>) {
  yield* put(request(type));

  try {
    const { data } = yield call(baseApi.getNetwork, network);
    yield put(updateNetwork(camelize(data)));
    yield* put(success(type));
  } catch (err) {
    logger('Get network', err, 'error');
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_NETWORK, getNetworkSaga);
}
