import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { logger } from 'utils/logger';

import { login, updateUserInfo } from '../actions';
import actionTypes from '../actionTypes';
import { disconnectWalletState, updateUserState } from '../reducer';

export function* loginSaga({
  type,
  payload: { address, providerName, web3Provider, network },
}: ReturnType<typeof login>) {
  yield put(request(type));

  try {
    const { data: metamaskMessage } = yield call(baseApi.getMetamaskMessage, address);
    const signedMessage: string = yield call(web3Provider.eth.personal.sign, metamaskMessage, address, '');

    const {
      data: { key },
    } = yield call(baseApi.metamaskLogin, {
      address,
      signed_msg: signedMessage,
    });

    yield put(
      updateUserState({
        address,
        key,
        provider: providerName,
        network,
      }),
    );

    yield put(updateUserInfo({ web3Provider, network }));

    yield put(success(type));
  } catch (err) {
    logger('loginSaga', err);
    yield put(error(type, err));
    yield put(disconnectWalletState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
}
