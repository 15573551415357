import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { COLOR_GREEN } from 'theme/variables';

interface CityProps {
  title: string;
  isWorkInProgress?: boolean;
}

export const City: FC<CityProps> = ({ title, isWorkInProgress = false }) => {
  return (
    <Stack spacing={4.5} alignItems="center">
      <Stack spacing={2} width="100%">
        <Typography
          textTransform="uppercase"
          className="lg"
          sx={{ typography: { xs: 'h2', sm: 'h1' }, color: `${COLOR_GREEN} !important` }}
        >
          {title}
        </Typography>
        {!isWorkInProgress && (
          <Typography fontSize={{ md: '18px' }} lineHeight={{ md: '30px' }} sx={{ maxWidth: '644px' }}>
            It will provide a space around the world where customers can actually experience products, and these spaces
            are called METAZ spaces.
          </Typography>
        )}
      </Stack>
      {isWorkInProgress ? (
        <Box>
          <Button variant="outlined" size="large" sx={{ width: 288, mt: 2.5 }}>
            To be added
          </Button>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              img: {
                width: '100%',
                height: { xs: '459px', sm: 'auto' },
                objectFit: { xs: 'cover', sm: 'unset' },
              },
              height: { xs: '459px', sm: 'auto' },
              width: '100%',
              '& > iframe': {
                height: { xs: '459px', sm: '100vw' },
                maxHeight: 1026,
              },
            }}
          >
            <iframe
              title="Matterport Showroom"
              width="100%"
              height="459"
              src="https://my.matterport.com/show/?m=TGQEJCfe8Wb"
              frameBorder="0"
              allowFullScreen
            />
          </Box>
          <Box>
            <Button variant="outlined" size="large" sx={{ width: 288, mt: 2.5 }}>
              To be added
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
