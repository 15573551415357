import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';

import MobileTitleImage from './assets/mobileTitle.svg';
import TitleImage from './assets/title.svg';
import { previewText } from './TitleBlock.helpers';

export const TitleBlock: FC = () => (
  <Box>
    <Box component="img" src={TitleImage} alt="main title" width="100%" display={{ xs: 'none', sm: 'unset' }} />
    <Box component="img" src={MobileTitleImage} alt="main title" width="100%" display={{ xs: 'unset', sm: 'none' }} />
    <Box mt={2.5} display="flex" alignItems="center" overflow="hidden" width="100%">
      <Typography
        sx={{
          fontSize: { xs: 24, sm: 48 },
          lineHeight: { xs: '30px', sm: '60px' },
          fontWeight: FontWeights.fontWeightSemiBold,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          animation: 'ticker 10s infinite linear',
          '&::after': {
            content: `"${previewText}"`,
          },
          '@keyframes ticker': {
            '0%': {
              transform: 'translateX(0)',
            },
            '100%': {
              transform: 'translateX(-50%)',
            },
          },
        }}
      >
        {previewText}
      </Typography>
    </Box>
  </Box>
);
