import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { NftCard } from 'components';
import NftCardSkeleton from 'components/NftCardSkeleton';
import { useShallowSelector } from 'hooks';
import { times } from 'lodash';
import { searchNfts } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import { clearNfts } from 'store/nft/reducer';
import nftsSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { theme } from 'theme';
import { COLOR_BLACK } from 'theme/variables';
import { RequestStatus } from 'types';

export const ProfileNftCards: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const { nfts, totalNfts } = useShallowSelector(nftsSelector.getNft);
  const withOwnerReq = type === 'on-sale' || type === 'owned';
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);

  const fetchNfts = useCallback(() => {
    if (id !== '0') {
      dispatch(
        searchNfts({
          props: {
            type: 'items',
            owner: withOwnerReq ? id : '',
            onAnySale: type === 'on-sale',
            soldBy: type === 'sold' ? id : '',
            bidsBy: type === 'bidded' ? id : '',
            likedBy: type === 'favorited' ? id : '',
            itemsPerPage: visibleCount,
            page: 1,
          },
        }),
      );
    }
  }, [dispatch, withOwnerReq, id, type, visibleCount]);

  useEffect(() => {
    fetchNfts();
  }, [fetchNfts]);

  useEffect(
    () => () => {
      dispatch(clearNfts());
    },
    [dispatch, type],
  );
  const { [actionTypes.SEARCH_NFTS]: searchNftsRequestStatus } = useShallowSelector(uiSelector.getUI);

  const isSearchNftsLoading = useMemo(
    () => searchNftsRequestStatus === RequestStatus.REQUEST,
    [searchNftsRequestStatus],
  );

  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
  };
  const totalPages = Math.ceil(totalNfts / itemsPerPage);

  const getNoNftsSubtitle = () => (
    <Typography
      sx={{
        pt: 3,
        px: { xs: 1, md: 0 },
        fontSize: '24px',
        textAlign: { xs: 'center', md: 'unset' },
        lineHeight: '34px',
        a: {
          textDecoration: 'underline',
          color: `${COLOR_BLACK} !important`,
        },
      }}
    >
      {type === 'owned' && (
        <>
          Oh! It seems that you don&apos;t have any NFT yet, click <Link to={routes.explore.root.path}>here</Link> to
          choose the first one!
        </>
      )}
      {type === 'sold' && 'There is no NFT sold yet.'}
      {type === 'on-sale' && 'There is no NFT for sale. Go to your NFT list, select one to sell.'}
      {type === 'bidded' && 'There is no NFT bidded yet.'}
      {type === 'favorited' && 'Go to meta[Z] Marketplace! Explore awesome looking sneakers NFTs and many more.'}
    </Typography>
  );

  useEffect(() => {
    setVisibleCount(page * itemsPerPage);
  }, [page]);

  return (
    <Grid
      item
      container
      xs={12}
      sm={8.5}
      md={9}
      lg={10}
      columnSpacing={1.8}
      rowSpacing={3.25}
      justifyContent={!nfts.length && !isSearchNftsLoading ? 'center' : { xs: 'center', sm: 'flex-start' }}
    >
      {!!nfts.length &&
        nfts.map((item, index) => (
          <Grid
            item
            key={`${index + 1}`}
            alignItems="center"
            sx={{
              [theme.breakpoints.only('xs')]: {
                '& > div': { mx: 'auto' },
              },
            }}
          >
            <NftCard
              id={item.id || 1}
              cardSize="medium"
              nftSize={
                item.properties.find((prop: Record<string, string>) => prop.traitType === 'Size')?.traitValue || '0'
              }
              image={item.image || ''}
              animation={item.animationFile || ''}
              format={item.format || 'image'}
              title={item.name}
              price={item.price || 0}
              isLiked={item.isLiked || false}
              isClaimed={item.isClaimed}
              collectionType={
                item?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Location')?.traitValue ||
                ''
              }
              styleHash={
                item?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Style #')?.traitValue || ''
              }
            />
          </Grid>
        ))}
      {!nfts.length && !isSearchNftsLoading && getNoNftsSubtitle()}

      {isSearchNftsLoading && (
        <>
          {times(4, () => (
            <Grid
              item
              alignItems="center"
              display="flex"
              sx={{
                [theme.breakpoints.only('xs')]: {
                  '& > div': { mx: 'auto' },
                },
              }}
            >
              <NftCardSkeleton size="medium" />
            </Grid>
          ))}
        </>
      )}
      {page < totalPages && (
        <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
          Load more
        </Button>
      )}
    </Grid>
  );
};
