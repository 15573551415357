import { Modals } from 'types/store/modals';

export type ModalData = {
  [key in Modals]: {
    title: string;
    subtitle?: string;
  };
};

export const modalData: ModalData = {
  [Modals.ConnectWallet]: {
    title: 'Connect a Wallet',
    subtitle: 'Please select a wallet to connect to this dapp:',
  },
  [Modals.Disconnect]: {
    title: 'Disable your wallet?',
  },
  [Modals.Network]: {
    title: 'Current network',
  },
  [Modals.Activity]: {
    title: 'Notification',
  },
  [Modals.Custody]: {
    title: ' ',
  },
  [Modals.ApproveCustody]: {
    title: ' ',
  },
  [Modals.SuccessCreateCollectible]: {
    title: '',
  },
  [Modals.BuyMultiple]: {
    title: '',
  },
  [Modals.Convert]: {
    title: 'Convert',
  },
  [Modals.init]: {
    title: '',
  },
};
