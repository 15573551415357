import {
  ActivitiesReq,
  Chains,
  ISearchProps,
  LikedReq,
  MaxPriceReq,
  PresearchNftsReq,
  RequestWithPagination,
  SelfCollectionsReq,
  UserActivitiesReq,
} from 'types';

const accountURL = {
  metamaskLogin: 'account/metamask_login/',
  getMetamaskMessage: (address: string) => `/account/get_metamask_message/${address}/`,
  selfInfo: '/account/self/',
  getCollections: ({ network, standart, page, itemsPerPage }: SelfCollectionsReq) =>
    `/account/self/collections/?network=${network}&standart=${standart}&items_per_page=${itemsPerPage}&page=${page}`,
  like: '/account/self/like/',
  follow: '/account/self/follow/',
  unfollow: '/account/self/unfollow/',
  getProfileInfo: (id: number | string) => `/account/${id}/`,
  getFollowers: (id: number | string, page?: number, itemsPerPage?: number) =>
    `/account/${id}/followers/?${itemsPerPage ? `?items_per_page=${itemsPerPage}` : 9}&${page ? `?page=${page}` : 0}`,
  getFollowing: (id: number | string, page?: number, itemsPerPage?: number) =>
    `/account/${id}/following/?${itemsPerPage ? `?items_per_page=${itemsPerPage}` : 9}&${page ? `?page=${page}` : 0}`,

  getLiked: ({ id, network, page, itemsPerPage }: LikedReq) =>
    `/account/${id}/liked/?network=${network}&items_per_page=${itemsPerPage}&page=${page}`,
};

const networkURL = {
  getNetworks: '/network/',
  getNetwork: (name: string) => `/networks/${name}`,
};

const orderURL = {
  getAutofillClaim: '/orders/autofill/claim/',
  getAutofillCustody: '/orders/autofill/custody/',
  getAutofillRedeem: '/orders/autofill/redeem/',
  claim: '/orders/claim/',
  confirmClaim: (id: number) => `/orders/claim/${id}/send/`,
  custodyListed: '/orders/custody_listed/',
  custodyUnlisted: '/orders/custody_unlisted/',
  sendDeliveryInfo: (id: number) => `/orders/custody_unlisted/?order_id=${id}`,
  getHistoryClaim: (page: number, itemsPerPage?: number) =>
    `/orders/history/claim/?items_per_page=${itemsPerPage}&page=${page}`,
  getHistoryCustody: (page: number, itemsPerPage?: number) =>
    `/orders/history/custody/?items_per_page=${itemsPerPage}&page=${page}`,
  getHistoryRedeem: (page: number, itemsPerPage?: number) =>
    `/orders/history/redeem/?items_per_page=${itemsPerPage}&page=${page}`,
  redeem: '/orders/redeem/',
  redeemReject: '/orders/redeem/reject/',
  payForDeliveryCustody: (orderId: string | number) => `/orders/custody/${orderId}/usdc/`,
  payForDeliveryRedeem: (orderId: string | number) => `/orders/redeem/${orderId}/usdc/`,
  redeemPaymentReject: '/orders/redeem/payment_reject/',
  custodyPaymentReject: '/orders/cusotdy/payment_reject/',
};

const ratesURL = {
  getRates: (network?: string) => `/rates/${network ? `?network=${network}` : ''}`,
};

const storeURL = {
  bid: '/store/bids/make_bid/',
  buyReject: '/store/buy-reject/',
  buy: '/store/buy/',
  getCategories: '/store/categories/',
  getEnabledOnCarousel: (network?: Chains) => `/store/carousel/${network ? `?network=${network}` : ''}`,
  getFavorites: (network?: Chains) => `/store/favorites/${network ? `?network=${network}` : ''}`,
  createCollection: (network?: string) => `/store/create_collection/${network ? `?network=${network}` : ''}`,
  createToken: '/store/create_token/',
  maxPrice: ({ currency, network }: MaxPriceReq) =>
    `store/max_price/?${currency ? `currency=${currency}` : ''}${network ? `&network=${network}` : ''}`,
  mintReject: '/store/mint-reject/',
  presearchNfts: ({ presearch }: PresearchNftsReq) => `/store/presearch/?presearch=${presearch}`,
  searchNfts: (props: ISearchProps) =>
    `store/search/?${props.type ? `type=${props.type}` : ''}${
      props.categories ? `&categories=${props.categories}` : ''
    }${props.tags ? `&tags=${props.tags}` : ''}${props.collections ? `&collections=${props.collections}` : ''}${
      props.minPrice ? `&min_price=${props.minPrice}` : ''
    }${props.maxPrice ? `&max_price=${props.maxPrice}` : ''}${props.orderBy ? `&order_by=${props.orderBy}` : ''}${
      props.properties ? `&properties=${JSON.stringify(props.properties)}` : ''
    }${typeof props.onSale !== 'undefined' ? `&on_sale=${props.onSale}` : ''}${
      typeof props.onAucSale !== 'undefined' ? `&on_auc_sale=${props.onAucSale}` : ''
    }${typeof props.onAnySale !== 'undefined' ? `&on_any_sale=${props.onAnySale}` : ''}${
      props.standart ? `&standart=${props.standart}` : ''
    }${props.currency ? `&currency=${props.currency}` : ''}${props.page ? `&page=${props.page}` : ''}${
      props.itemsPerPage ? `&items_per_page=${props.itemsPerPage}` : ''
    }${props.network ? `&network=${props.network}` : ''}${props.text ? `&text=${props.text}` : ''}${
      props.owner ? `&owner=${props.owner}` : ''
    }${props.soldBy ? `&sold_by=${props.soldBy}` : ''}${props.bidsBy ? `&bids_by=${props.bidsBy}` : ''}${
      props.likedBy ? `&liked_by=${props.likedBy}` : ''
    }${props.collectionType ? `&collection_type=${props.collectionType}` : ''}${
      props.redeemStatus ? `&redeem_status=${props.redeemStatus}` : ''
    }`,

  getTokenInfo: (id: number | string) => `/store/token/${id}/`,
  mintToken: (id: number | string) => `/store/token/${id}/mint/`,
  burnToken: (id: number | string) => `/store/token/${id}/burn/`,
  endAuction: (id: number | string) => `/store/token/${id}/end_auction/`,
  getBids: (id: number | string) => `/store/token/${id}/get_bids/`,
  removeFromSale: (id: number | string) => `/store/token/${id}/remove_from_sale/`,
  setOnAuction: (id: number | string) => `/store/token/${id}/set_on_auction/`,
  setOnProfile: (id: number | string) => `/store/token/${id}/set_on_profile/`,
  setOnSale: (id: number | string) => `/store/token/${id}/set_on_sale/`,
  transferToken: (id: number | string) => `/store/token/${id}/transfer/`,
  trackTransaction: '/store/track_transaction/',
  support: '/store/support/',
  getCustodyLists: '/store/categories/full/',
};

const activityURL = {
  notifications: `/activity/notification/`,
  activities: (props: ActivitiesReq) =>
    `/activity/?${props.type ? `type=${props.type}` : ''}${
      props.itemsPerPage ? `&items_per_page=${props.itemsPerPage}` : ''
    }`,
  userActivities: (props: UserActivitiesReq) =>
    `/activity/${props.address}/?${props.type ? `type=${props.type}` : ''}${
      props.itemsPerPage ? `&items_per_page=${props.itemsPerPage}` : ''
    }`,
  followingActivities: (props: UserActivitiesReq) =>
    `/activity/${props.address}/following/?${props.type ? `type=${props.type}` : ''}${
      props.itemsPerPage ? `&items_per_page=${props.itemsPerPage}` : ''
    }`,
  topCollectors: (props: RequestWithPagination) =>
    `/activity/top-collectors/?page=${props.page || 1}&items_per_page=${props.itemsPerPage || 10}`,
};

export default {
  ...accountURL,
  ...networkURL,
  ...orderURL,
  ...ratesURL,
  ...storeURL,
  ...activityURL,
};
