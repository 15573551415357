import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton, Stack } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { SneakerCard } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import { times } from 'lodash';
import { getNewestNfts } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { RequestStatus } from 'types';
import { getPropertyValue } from 'utils';

import { TitleText } from '../../Home';
import { HomeSlider } from '..';

export const WhatsNew: FC = () => {
  const [perPage, setPerPage] = useState(3);
  const nfts = useShallowSelector(nftSelector.getProp('newest'));
  const { [actionTypes.GET_NEWEST]: getNewestNftsRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { width } = useWindowState();
  const dispatch = useDispatch();

  const isGetNewestLoading = useMemo(
    () => getNewestNftsRequestStatus === RequestStatus.REQUEST,
    [getNewestNftsRequestStatus],
  );

  useEffect(() => {
    if (width < 800) setPerPage(1);
    else if (width < 1200) setPerPage(2);
    else setPerPage(3);
  }, [width]);

  useEffect(() => {
    dispatch(getNewestNfts());
  }, [dispatch]);

  return (
    <Stack spacing={3}>
      <TitleText>What&apos;s NEW</TitleText>
      <HomeSlider
        perPage={perPage}
        gap={width < 700 ? 8 : 19}
        padding={{ left: width < 700 ? 0 : 15, right: width < 700 ? 0 : 15 }}
        fixedWidth={width < 700 ? 340 : undefined}
      >
        {isGetNewestLoading
          ? times(3).map((index) => (
              <SplideSlide key={index}>
                <Skeleton
                  className="square"
                  sx={{
                    maxWidth: 436,
                    width: '100%',
                    borderRadius: { xs: '10px', sm: '30px' },
                  }}
                />
              </SplideSlide>
            ))
          : nfts.map(({ id = 0, image = '', name, properties }, index) => (
              <SplideSlide key={`${index + 1}`}>
                <SneakerCard
                  id={id}
                  img={image}
                  name={name}
                  size={getPropertyValue(properties, 'Size')}
                  fontSize={18}
                  lineHeight="24px"
                />
              </SplideSlide>
            ))}
      </HomeSlider>
    </Stack>
  );
};
