import { FC } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';

import { navItems } from './Nav.helpers';

type NavProps = {
  closeMenu?: () => void;
};

export const Nav: FC<NavProps> = ({ closeMenu = () => '' }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    closeMenu();
    setTimeout(() => navigate(path), 0);
  };

  return (
    <Stack
      mt={{ xs: 2.5, sm: 0 }}
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      spacing={3}
      height="100%"
    >
      {navItems.map(({ path, title, unsetCase }) => (
        <Button variant="text" key={title} onClick={() => handleNavigate(path)}>
          <Typography
            variant="body2"
            color={path === pathname ? COLOR_BLACK : COLOR_GRAY_150}
            fontSize="14px"
            sx={{
              textDecoration: path === pathname ? 'underline' : 'none',
              textTransform: unsetCase ? 'none' : 'uppercase',
              '&:hover': { color: COLOR_BLACK },
            }}
          >
            {title}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};
