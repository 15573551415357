import { FC } from 'react';
import { Skeleton } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_300 } from 'theme/variables';

export const ActivitySkeleton: FC = () => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={108}
    animation="wave"
    sx={{ borderRadius: BORDER_RADIUS_DEFAULT, bgcolor: COLOR_GRAY_300 }}
  />
);
