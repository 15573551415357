import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterOutlined: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 13"
    sx={{
      fill: 'none',
      width: '14px',
      height: '13px',
      ...sx,
    }}
  >
    <path
      d="M0.999971 11C0.999971 11 3.49997 10.5 3.99997 9C3.99997 9 -2.87247e-05 7.5 0.999971 2C0.999971 2 2.99997 4.5 5.99997 5V4.00018C6.00003 3.42578 6.19784 2.86891 6.56016 2.4232C6.92249 1.97749 7.42722 1.67012 7.9895 1.55275C8.55178 1.43539 9.13733 1.5152 9.64769 1.77876C10.1581 2.04232 10.5621 2.47356 10.7919 2.99998L13 3L11 5C11 8.5 8.49997 12 3.99997 12C1.99997 12 0.999971 11 0.999971 11Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
