import { createAction } from '@reduxjs/toolkit';
import { ActivitiesReq, UserActivitiesReq, ViewActivityReq } from 'types';

import activityActionTypes from './actionTypes';

export const getNotifications = createAction(activityActionTypes.GET_NOTIFICATIONS);
export const viewActivity = createAction<ViewActivityReq>(activityActionTypes.VIEW_ACTIVITY);
export const getActivities = createAction<ActivitiesReq>(activityActionTypes.GET_ACTIVITIES);
export const getUserActivities = createAction<UserActivitiesReq>(activityActionTypes.GET_USER_ACTIVITIES);
export const getFollowingActivities = createAction<UserActivitiesReq>(activityActionTypes.GET_FOLLOWING_ACTIVITIES);
