import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { routes } from 'appConstants';
import { NftCard } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import nftSelector from 'store/nft/selectors';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';

type CollectionsScrollBarProps = {
  collectionId: number | string;
};

export const CollectionsScrollBar: FC<CollectionsScrollBarProps> = ({ collectionId }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowState();
  const nfts = useShallowSelector(nftSelector.getProp('nfts'));

  const filteredNfts = useMemo(() => nfts.filter((item) => item.id !== +(id || 0)), [id, nfts]);

  const handleNavigateToCollection = () => {
    navigate(routes.explore.root.getPath({ collection: collectionId.toString() }));
  };

  return nfts.length ? (
    <Stack
      spacing={3}
      mt={{
        xs: '100px',
        sm: '86px',
      }}
      mx={{ xs: 6, md: 5, lg: 7 }}
      sx={{
        '.splide__arrow': {
          background: COLOR_BLACK,
          width: 40,
          height: 40,
          svg: { fill: COLOR_WHITE, width: '0.6rem', height: '0.6rem' },
          '&--prev': {
            left: -50,
          },
          '&--next': {
            right: -50,
          },
        },
      }}
    >
      <Typography variant="h2" textTransform="uppercase" sx={{ color: COLOR_BLACK }}>
        More like this NFT
      </Typography>
      <Splide
        options={{
          type: 'slide',
          pagination: false,
          fixedWidth: width < 700 ? 180 : 296,
        }}
      >
        {filteredNfts.map((nft, index) => (
          <SplideSlide key={`${index + 1}`}>
            <NftCard
              id={nft?.id || 0}
              animation={nft?.animationFile || ''}
              nftSize={
                nft?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Size')?.traitValue || 0
              }
              image={nft?.image || ''}
              format={nft?.format || 'image'}
              title={nft?.name || ''}
              isLiked={nft?.isLiked || false}
              cardSize="medium"
              price={nft?.price || 0}
              isClaimed={nft?.isClaimed}
              collectionType={
                nft?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Location')?.traitValue || ''
              }
              styleHash={
                nft?.properties?.find((prop: Record<string, string>) => prop.traitType === 'Style #')?.traitValue || ''
              }
            />
          </SplideSlide>
        ))}
      </Splide>
      <Stack>
        <Button
          variant="outlined"
          onClick={handleNavigateToCollection}
          sx={{
            mx: 'auto',
            maxWidth: { sx: '163px', sm: '210px' },
            minWidth: { sx: '163', sm: '210px' },
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          view more
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Box />
  );
};
