import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_50, COLOR_GRAY_100, COLOR_GRAY_300, COLOR_GREEN } from 'theme/variables';

export const getMuiSelectOverrides = (theme: Theme): ComponentsOverrides['MuiSelect'] => ({
  filled: {
    background: COLOR_GRAY_300,
    color: COLOR_GRAY_100,
    borderRadius: BORDER_RADIUS_DEFAULT,
    padding: theme.spacing(1.375),
    '&:hover': { COLOR_GREEN },
  },
  iconFilled: {
    fill: COLOR_GREEN,
  },
  standard: {
    background: 'transparent',
    color: COLOR_GRAY_50,
  },
  iconStandard: {
    fill: COLOR_GRAY_50,
  },
});

export const getMuiSelectDefaultProps = (): ComponentsProps['MuiSelect'] => ({});
