import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Collapse, Grid, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { CopyText } from 'components';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { payForDelivery } from 'store/orders/actions';
import actionTypes from 'store/orders/actionTypes';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import {
  BORDER,
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_150,
  COLOR_GRAY_200,
  COLOR_LIGHT,
  COLOR_WHITE,
} from 'theme/variables';
import { RequestStatus } from 'types';
import { Custody } from 'types/api';
import { ellipsisText } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { CustodyButtonTextVariants, CustodyStatusColors } from '../CustodyRequests.helpers';

const Label = styled(Typography)(({ theme }) => ({
  color: COLOR_GRAY_150,
  width: '100%',
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(1),
}));

const ExpandButton = styled(Button)({
  color: COLOR_BLACK,
  textTransform: 'capitalize',
  fontSize: '14px',
  fontWeight: FontWeights.fontWeightMedium,
});

const StatusActionButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: FontWeights.fontWeightRegular,
  lineHeight: '18px',
  maxHeight: '50px',
  [theme.breakpoints.up('md')]: { minWidth: '192px' },
  [theme.breakpoints.only('md')]: { minWidth: '150px' },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  border: BORDER,
  textTransform: 'unset',
  '&:hover': {
    border: BORDER,
    borderColor: COLOR_GRAY_200,
  },
}));

export interface CustodyRequestBlockProps {
  custodyRequest: Custody;
  itemsPerPage: number;
  activeId: number;
  setActiveId: () => void;
}

export const CustodyRequestBlock: FC<CustodyRequestBlockProps> = ({
  activeId,
  setActiveId,
  custodyRequest,
  itemsPerPage,
}) => {
  const [open, setOpen] = useState(false);
  const { address: walletAddress, network: chainNetwork, balance } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.PAY_FOR_DELIVERY]: payForDeliveryRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { id: userId } = useParams();
  const {
    id,
    name,
    firstName,
    lastName,
    style,
    size,
    state,
    courierInvoiceNumber,
    zipCode,
    address,
    mobileNumber,
    email,
    paymentAmount,
  } = custodyRequest;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const currentBalance = useMemo(() => balance[chainNetwork].USDC || 0, [balance, chainNetwork]);

  const isPayForDeliveryLoading = useMemo(
    () => payForDeliveryRequestStatus === RequestStatus.REQUEST,
    [payForDeliveryRequestStatus],
  );
  const isPayActionNeeded = useMemo(
    () =>
      state &&
      (CustodyButtonTextVariants[state] === CustodyButtonTextVariants['Not tradable'] ||
        CustodyButtonTextVariants[state] === CustodyButtonTextVariants['Verification failed']),
    [state],
  );

  const handleCustodyActionClick = () => {
    if (state && userId && id && CustodyButtonTextVariants[state] === CustodyButtonTextVariants.Approved) {
      navigate(routes.profile.custody.delivery.root.getPath(userId, id));
    } else if (id && state && isPayActionNeeded) {
      setActiveId();
      dispatch(
        payForDelivery({
          type: 'custody',
          id,
          web3Provider: walletService.Web3(),
          itemsPerPage,
        }),
      );
    }
  };

  return (
    <Grid
      container
      key={id}
      flexDirection={{ xs: 'column', md: 'row' }}
      flexWrap="nowrap"
      gap={2}
      sx={{ backgroundColor: COLOR_LIGHT, p: 2, borderRadius: BORDER_RADIUS_DEFAULT }}
    >
      <Grid item container flexDirection="column" flexWrap="nowrap" xs={12} md={9} gap={2}>
        <Grid item container flexDirection={{ xs: 'column', md: 'row' }} flexWrap="nowrap" gap={2}>
          <Grid item flexDirection="column" xs={12} md={4.5}>
            <Stack spacing={0.25}>
              <Typography variant="h2" fontFamily={FontFamilies.primary}>
                №{id || '-'}
              </Typography>
              <Typography variant="h4" className="regular" {...ellipsisText()}>
                {name || '-'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5.8}>
            <Stack spacing={1} direction={{ xs: 'column-reverse', md: 'column' }}>
              <Typography {...ellipsisText()}>{style || '-'}</Typography>
              <Typography variant="h4" className="regular" {...ellipsisText()}>
                {firstName || '-'} {lastName || ''}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={1.7}>
            <Stack spacing={1}>
              <Typography>Size</Typography>
              <Typography>{size || '-'}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ mt: 2 }}>
          <Grid item container flexDirection={{ xs: 'column', md: 'row' }} spacing={2}>
            <Grid item container xs={12} md={4.5}>
              <Label>Wallet address</Label>
              <CopyText size="small" variant="contained" text={walletAddress || '-'} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Label>Courier invoice number</Label>
              <CopyText size="small" variant="contained" text={`№${courierInvoiceNumber || ' -'}`} />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <Label>Zip code</Label>
              <CopyText size="small" variant="contained" text={zipCode || '-'} />
            </Grid>
          </Grid>
          <Grid item container flexDirection={{ xs: 'unset', md: 'row' }} spacing={2} mt={2}>
            <Grid item container xs={12} md={4.5} width="100%">
              <Label>Address</Label>
              <Typography {...ellipsisText()}>{address || '-'}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Label>Mobile</Label>
              <Typography>{mobileNumber || '-'}</Typography>
            </Grid>
            <Grid item xs={6} md={3.5}>
              <Label>Email</Label>
              <Typography {...ellipsisText()}>{email || '-'}</Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack
          spacing={2}
          direction={{ xs: 'row', md: 'column' }}
          alignItems="flex-start"
          justifyContent="space-between"
          width="100%"
        >
          <Box px={2} py={1} {...flexHelper()} bgcolor={COLOR_BLACK} borderRadius="12px">
            <Typography
              sx={{
                color: state ? CustodyStatusColors[state] || COLOR_WHITE : COLOR_WHITE,
                textAlign: 'center',
              }}
            >
              {state}
            </Typography>
          </Box>
          {state && CustodyButtonTextVariants[state] && (
            <Stack display={{ xs: 'none', md: 'flex' }}>
              <StatusActionButton
                variant="outlined"
                size="small"
                disabled={
                  isPayForDeliveryLoading ||
                  (state && CustodyButtonTextVariants[state] === 'Postmint multiple token') ||
                  (isPayActionNeeded && (paymentAmount || 0) > currentBalance)
                }
                onClick={handleCustodyActionClick}
              >
                {isPayForDeliveryLoading && id === activeId ? (
                  <CircularProgress size={20} sx={{ color: COLOR_WHITE }} />
                ) : (
                  CustodyButtonTextVariants[state || 'Pending']
                )}
              </StatusActionButton>
              {isPayActionNeeded && (paymentAmount || 0) > currentBalance && (
                <Box mt={0.5}>
                  <Typography variant="subtitle1" lineHeight="20px">
                    Not enough balance
                  </Typography>
                  <Typography variant="subtitle1" lineHeight="20px">
                    You need {parseFloat((Math.round(+(paymentAmount || 0) * 100) / 100).toFixed(2))}
                    &nbsp;USDC
                  </Typography>
                </Box>
              )}
            </Stack>
          )}
          <ExpandButton variant="text" onClick={() => setOpen(!open)}>
            {open ? '- Less' : '+ More'}
          </ExpandButton>
        </Stack>
        {state && CustodyButtonTextVariants[state] && (
          <Stack display={{ xs: 'flex', md: 'none' }}>
            <StatusActionButton
              variant="outlined"
              size="small"
              disabled={
                isPayForDeliveryLoading ||
                (state && CustodyButtonTextVariants[state] === 'Postmint multiple token') ||
                (isPayActionNeeded && (paymentAmount || 0) > currentBalance)
              }
              onClick={handleCustodyActionClick}
            >
              {isPayForDeliveryLoading && id === activeId ? (
                <CircularProgress size={20} sx={{ color: COLOR_WHITE }} />
              ) : (
                CustodyButtonTextVariants[state || 'Pending']
              )}
            </StatusActionButton>
            {isPayActionNeeded && (paymentAmount || 0) > currentBalance && (
              <Typography variant="subtitle1" lineHeight="20px" mt={0.5}>
                Not enough balance: you need {parseFloat((Math.round(+(paymentAmount || 0) * 100) / 100).toFixed(2))}
                &nbsp;USDC
              </Typography>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
