import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Grid, styled, Typography } from '@mui/material';
import { Logo } from 'assets/img';
import { Link as LinkIcon } from 'components';
import { useShallowSelector } from 'hooks';
import moment from 'moment';
import nftSelector from 'store/nft/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150 } from 'theme/variables';
import { shortenPhrase } from 'utils';

const GreyText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: COLOR_GRAY_150,
});

export type NftInfoBlockProps = {
  tab: 'owner' | 'history' | 'bid';
  operation?: string;
  txLink?: string;
  userLink: string;
  isAdmin: boolean;
  avatar: string;
  name: string;
  address: string;
  price?: number | string;
  currency?: string;
  data?: string;
  quantity?: string | number;
  isMultiple?: boolean;
};

export const NftInfoBlock: FC<NftInfoBlockProps> = ({
  tab,
  avatar,
  name,
  data,
  userLink,
  txLink,
  address,
  isAdmin,
  operation,
  price,
  currency,
  quantity,
  isMultiple,
}) => {
  const { sellers, standart } = useShallowSelector(nftSelector.getProp('detailedNft'));

  const currentSeller = useMemo(
    () => (standart === 'ERC1155' && sellers ? sellers.find((seller) => seller?.address === address) : undefined),
    [address, sellers, standart],
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid container alignItems="center">
        {avatar ? (
          <Link to={userLink}>
            <Avatar srcSet={avatar} alt="avatar" sx={{ mr: tab === 'bid' ? '16px' : '13px', width: 40, height: 40 }} />
          </Link>
        ) : (
          <Avatar
            sx={{ mr: tab === 'bid' ? '16px' : '13px', width: '40px', height: '40px', border: '1px solid #FFFFFF' }}
          />
        )}
        {tab === 'bid' ? (
          <Typography fontWeight={FontWeights.fontWeightSemiBold}>
            {name.length < 15 ? name : shortenPhrase(name)}
          </Typography>
        ) : (
          <Box>
            {tab === 'owner' && (
              <Typography fontWeight={FontWeights.fontWeightSemiBold}>
                {name.length < 15 ? name : shortenPhrase(name)}
              </Typography>
            )}
            {tab === 'owner' && currentSeller && (
              <GreyText>
                {currentSeller.sellingQuantity}/{currentSeller.quantity} on sale for{' '}
                <Box component="span" sx={{ color: COLOR_BLACK, fontWeight: FontWeights.fontWeightMedium }}>
                  {parseFloat(currentSeller?.price || '0')}&nbsp;{currentSeller?.currency?.symbol?.toUpperCase() || ''}
                </Box>
                &nbsp;each
                {currentSeller.endSale && (
                  <>
                    <br />
                    {` until ${moment(+(currentSeller.endSale || 0) * 1000).format('MMMM Do, YYYY')}
                    ${moment(+(currentSeller.endSale || 0) * 1000).format('h:mm A')}`}
                  </>
                )}
              </GreyText>
            )}
            {tab === 'owner' && !currentSeller && (
              <GreyText>{isMultiple ? `Owner of ${quantity} Nft` : shortenPhrase(address)}</GreyText>
            )}
            {tab !== 'owner' && (
              <Grid container alignItems="center">
                <GreyText>
                  {`${operation === 'Transfer' ? 'Transferred' : ''}${operation === 'Auctionwin' ? 'Auction win' : ''}${
                    operation !== 'Transfer' && operation !== 'Auctionwin' ? operation : ''
                  }${operation === 'Mint' ? ' by' : ''}${
                    operation !== 'Mint' && operation !== 'Transfer' ? ' for' : ''
                  }`}
                  &nbsp;
                </GreyText>
                <Typography variant="subtitle1" fontWeight={FontWeights.fontWeightSemiBold} sx={{ color: COLOR_BLACK }}>
                  {operation === 'Mint' && 'MetaZ'}
                  {operation !== 'Transfer' && operation !== 'Mint' && `${price || 0} ${currency || ''}`}
                </Typography>
              </Grid>
            )}
            <Grid container mt={tab === 'history' ? '2px' : 0}>
              {operation !== 'Mint' && tab === 'history' && <Typography variant="subtitle1">by&nbsp;</Typography>}
              {operation !== 'Mint' && tab !== 'owner' && (
                <Typography variant="subtitle1" color={COLOR_GRAY_150}>
                  {shortenPhrase(address)}&nbsp;
                </Typography>
              )}
              {tab === 'history' && (
                <Typography variant="subtitle1">{moment(data, 'YYYY-MM-DDTHH:mm:ss.SSSZ').fromNow()}</Typography>
              )}
              {tab === 'history' && txLink && operation !== 'Listing' && (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <a href={txLink} target="_blank" rel="noreferrer">
                  <LinkIcon sx={{ color: COLOR_BLACK, ml: 1.5, mt: 0.75 }} />
                </a>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
      {tab === 'owner' && isAdmin && !currentSeller && (
        <Box sx={{ img: { width: '60px' } }}>
          <Logo />
        </Box>
      )}
      {tab === 'bid' && (
        <Grid container>
          <Typography variant="subtitle1" fontWeight={FontWeights.fontWeightSemiBold} sx={{ color: COLOR_BLACK }}>
            {`${parseFloat(String(price || 0))} ${currency}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
