const HELLO_WORLD = 'HELLO_WORLD' as const;
const GET_AUTOFILL = 'GET_AUTOFILL' as const;
const GET_HISTORY = 'GET_HISTORY' as const;
const REDEEM = 'REDEEM' as const;
const CLAIM = 'CLAIM' as const;
const CONFIRM_CLAIM = 'CONFIRM_CLAIM' as const;
const GET_CUSTODY_LISTS = 'GET_CUSTODY_LISTS' as const;
const CUSTODY_LISTED = 'CUSTODY_LISTED' as const;
const CUSTODY_UNLISTED = 'CUSTODY_UNLISTED' as const;
const SEND_DELIVERY_INFO = 'SEND_DELIVERY_INFO' as const;
const APPROVE_REDEEM_OR_CLAIM = 'APPROVE_REDEEM_OR_CLAIM' as const;
const PAY_FOR_DELIVERY = 'PAY_FOR_DELIVERY' as const;

export default {
  HELLO_WORLD,
  GET_AUTOFILL,
  GET_HISTORY,
  REDEEM,
  CLAIM,
  GET_CUSTODY_LISTS,
  CUSTODY_LISTED,
  CUSTODY_UNLISTED,
  SEND_DELIVERY_INFO,
  APPROVE_REDEEM_OR_CLAIM,
  CONFIRM_CLAIM,
  PAY_FOR_DELIVERY,
};
