import { COLOR_RED_100, COLOR_WHITE, COLOR_YELLOW } from 'theme/variables';
import { State } from 'types/api';

export const CustodyStatusColors: { [key in State]?: string } = {
  [State.WaitingForApproval]: COLOR_YELLOW,
  [State.WaitingForSneakers]: COLOR_YELLOW,
  [State.Enqueued]: COLOR_YELLOW,
  [State.Pending]: COLOR_YELLOW,
  [State.PendingRefundPayment]: COLOR_YELLOW,
  [State.Approved]: COLOR_WHITE,
  [State.ObjectReceived]: COLOR_WHITE,
  [State.NftMinted]: COLOR_WHITE,
  [State.Declined]: COLOR_RED_100,
  [State.VerificationFailed]: COLOR_RED_100,
  [State.NotTradable]: COLOR_RED_100,
  [State.SneakersWillSoonBeShippedOut]: COLOR_WHITE,
  [State.PostmintMultipleToken]: COLOR_WHITE,
  [State.SneakersSent]: COLOR_WHITE,
};
export const CustodyButtonTextVariants: { [key in State]?: string } = {
  [State.Approved]: 'Send the shipping information',
  [State.NotTradable]: 'Pay for return',
  [State.VerificationFailed]: 'Pay for return',
};
