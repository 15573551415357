import { FC } from 'react';
import { Button } from '@mui/material';
import { Filter } from 'components/Icon';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT_GRAY, COLOR_WHITE, TRANSITION_DEFAULT_TIME } from 'theme/variables';

export interface FilterButtonProps {
  isFilterChecked: boolean;
  setFilterChecked: (v: boolean) => void;
}
export const FilterButton: FC<FilterButtonProps> = ({ isFilterChecked, setFilterChecked }) => (
  <Button
    size="small"
    variant="contained"
    className={isFilterChecked ? 'checked' : 'not-checked'}
    onClick={() => setFilterChecked(!isFilterChecked)}
    sx={{
      px: { xs: 1.5, sm: 2.5 },
      py: { xs: 1, sm: 1.5 },
      gap: 1.25,
      color: COLOR_WHITE,
      fontSize: { xs: 12, sm: 16 },
      lineHeight: '24px',
      fontWeight: FontWeights.fontWeightSemiBold,
      transition: TRANSITION_DEFAULT_TIME,
      minWidth: '44px',
      height: { xs: 40, sm: 48 },
      '&.checked': {
        background: COLOR_BLACK,
        '&:hover': {
          background: COLOR_GRAY_150,
        },
      },
      '&.not-checked': {
        background: COLOR_LIGHT_GRAY,
        color: COLOR_BLACK,
        svg: { color: COLOR_BLACK },
        '&:hover': {
          background: COLOR_GRAY_150,
        },
      },
    }}
  >
    <Filter sx={{ color: COLOR_WHITE, transition: TRANSITION_DEFAULT_TIME }} />
    Filters
  </Button>
);
