import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckCircleOutline: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 44 44"
    sx={{
      ...sx,
      width: '44px',
      height: '44px',
      fill: 'none',
    }}
  >
    <path
      d="M31.4866 16.5217L18.8321 28.5942L12.5156 22.558"
      stroke="#7FFB50"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0003 42.3913C33.4302 42.3913 42.696 33.1255 42.696 21.6956C42.696 10.2658 33.4302 1 22.0003 1C10.5704 1 1.30469 10.2658 1.30469 21.6956C1.30469 33.1255 10.5704 42.3913 22.0003 42.3913Z"
      stroke="#7FFB50"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
