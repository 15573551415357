import nftActionTypes from 'store/nft/actionTypes';
import orderActionTypes from 'store/orders/actionTypes';
import userActionTypes from 'store/user/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';

import { getUIReducer } from '.';

const initialState: UIState = {
  [userActionTypes.UPDATE_USER_INFO]: RequestStatus.INIT,
  [userActionTypes.PATCH_USER_INFO]: RequestStatus.INIT,
  [userActionTypes.CONVERT]: RequestStatus.INIT,
  [userActionTypes.LOGIN]: RequestStatus.INIT,
  [nftActionTypes.CREATE_COLLECTION]: RequestStatus.INIT,
  [nftActionTypes.CREATE_NFT]: RequestStatus.INIT,
  [nftActionTypes.BUY]: RequestStatus.INIT,
  [nftActionTypes.BID]: RequestStatus.INIT,
  [nftActionTypes.TRANSFER]: RequestStatus.INIT,
  [nftActionTypes.SET_ON_AUCTION]: RequestStatus.INIT,
  [nftActionTypes.SET_ON_SALE]: RequestStatus.INIT,
  [nftActionTypes.BURN]: RequestStatus.INIT,
  [nftActionTypes.REMOVE_FROM_SALE]: RequestStatus.INIT,
  [orderActionTypes.REDEEM]: RequestStatus.INIT,
  [orderActionTypes.CLAIM]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
