import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Pagination, Skeleton, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector, useWindowState } from 'hooks';
import { getTopCollectors } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_LIGHT } from 'theme/variables';
import { RequestStatus } from 'types';

import { TitleText } from '../../Home';

import { TopCollectorCard, TopCollectorsRow } from './components';

const ColumnTitle = styled(Typography)({
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: FontWeights.fontWeightMedium,
  whiteSpace: 'nowrap',
});

export const TopCollectors: FC = () => {
  const [page, setPage] = useState(1);
  const { totalPages, results } = useShallowSelector(nftSelector.getProp('topCollectors'));
  const { [actionTypes.GET_TOP_COLLECTORS]: getTopCollectorsRequestStatus } = useShallowSelector(uiSelector.getUI);
  const startRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowState();

  const isGetCollectorsLoading = useMemo(
    () => getTopCollectorsRequestStatus === RequestStatus.REQUEST,
    [getTopCollectorsRequestStatus],
  );

  const navigateToUser = (id: string | number) => navigate(routes.profile.root.getPath(id));

  const navigateToToken = (id: string | number) => navigate(routes.nft.root.getPath(id));

  const handleChangePage = (e: ChangeEvent<unknown>, index: number) => {
    startRef.current.scrollIntoView();
    setPage(index);
  };

  useEffect(() => {
    dispatch(getTopCollectors({ page, itemsPerPage: 10 }));
  }, [dispatch, page]);

  return (
    <Stack spacing={3}>
      <TitleText ref={startRef}>Top Collectors</TitleText>
      <Stack>
        <Box
          pl={{ xs: 0, sm: 3 }}
          width="100%"
          display={{ xs: 'flex', sm: 'grid' }}
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          gridTemplateColumns={{ xs: 'unset', sm: '25% 15% 55%', md: '18% 12% 71%' }}
          columnGap={3}
        >
          <Box py={2}>
            <ColumnTitle>Profile</ColumnTitle>
          </Box>
          <Box py={2}>
            <ColumnTitle textAlign="center">Token amount</ColumnTitle>
          </Box>
          <Box display={{ xs: 'none', sm: 'block' }} />
        </Box>
        <Box
          display={{ xs: 'flex', sm: 'grid' }}
          flexDirection="column"
          width="100%"
          gridTemplateColumns={{ xs: 'unset', sm: '25% 15% 55%', md: '18% 8% 71%' }}
          columnGap={3}
          rowGap={4}
          p={{ xs: 0, sm: 3 }}
          border={{ xs: 'none', sm: `1px solid ${COLOR_LIGHT}` }}
          borderRadius="16px"
        >
          {!isGetCollectorsLoading ? (
            results?.map((collector) =>
              width < 700 ? (
                <TopCollectorCard
                  navigateToToken={navigateToToken}
                  navigateToUser={() => navigateToUser(collector.id || 0)}
                  key={collector.name}
                  avatar={collector.avatar}
                  name={collector.name}
                  tokenAmount={collector.tokensCount}
                  items={collector.tokens.map(({ id = 0, image = '' }) => ({ img: image, id }))}
                />
              ) : (
                <TopCollectorsRow
                  navigateToToken={navigateToToken}
                  navigateToUser={() => navigateToUser(collector.id || 0)}
                  key={collector.name}
                  avatar={collector.avatar}
                  name={collector.name}
                  tokenAmount={collector.tokensCount}
                  items={collector.tokens.map(({ id = 0, image = '' }) => ({ img: image, id }))}
                />
              ),
            ) || ''
          ) : (
            <Skeleton width="100%" height={128} sx={{ borderRadius: '12px' }} />
          )}
        </Box>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          sx={{ mt: 3 }}
        />
      </Stack>
    </Stack>
  );
};
