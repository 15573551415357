import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_BLACK, COLOR_GRAY_100, COLOR_GRAY_200, COLOR_WHITE } from '../variables';

export const getMuiRadioOverrides = (theme: Theme): ComponentsOverrides['MuiRadio'] => ({
  root: {
    margin: '0 16px 0 10px',
    width: '24px',
    minWidth: '24px',
    height: '24px',
    minHeight: '24px',
    background: COLOR_GRAY_200,
    color: 'transparent',
    border: `1px solid ${COLOR_GRAY_100}`,
    boxSizing: 'border-box',

    '&:hover': {
      borderColor: COLOR_BLACK,
      background: COLOR_GRAY_200,
    },

    '&.Mui-checked': {
      padding: 0,
      background: COLOR_WHITE,
      border: `6px solid ${COLOR_BLACK}`,
      svg: {
        display: 'none',
      },
    },
  },
});

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiRadio'] => ({});
