import { FC, MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { State, UserState } from 'types';

import { ClaimsHistory } from './ClaimsHistory';
import { RedeemsHistory } from './RedeemsHistory';

export const OrdersHistory: FC = () => {
  const navigate = useNavigate();

  const [alignment, setAlignment] = useState('claim');
  const { id: userId } = useParams();
  const { id: currentUserId, customUrl: userCustomUrl } = useShallowSelector<State, UserState>(userSelector.getUser);

  const isCurrentUser = userId === currentUserId?.toString() || userId === userCustomUrl;

  const handleChange = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    if (!isCurrentUser) {
      navigate(routes.profile.root.getPath(userId));
    }
  }, [isCurrentUser, navigate, userId]);

  return (
    <Grid item container xs={12} sm={8.5} md={9} lg={10} display="block">
      <Stack spacing={9}>
        <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange}>
          <ToggleButton value="claim">Claims</ToggleButton>
          <ToggleButton value="redeem">Redeems</ToggleButton>
        </ToggleButtonGroup>
        {alignment === 'claim' && <ClaimsHistory />}
        {alignment === 'redeem' && <RedeemsHistory />}
      </Stack>
    </Grid>
  );
};
