import { fork } from 'redux-saga/effects';
import activitySaga from 'store/activity/sagas';
import networkSaga from 'store/network/sagas';
import nftSaga from 'store/nft/sagas';
import ordersSaga from 'store/orders/sagas';
import profileSaga from 'store/profile/sagas';
import userSaga from 'store/user/sagas';

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(nftSaga);
  yield fork(profileSaga);
  yield fork(networkSaga);
  yield fork(activitySaga);
  yield fork(ordersSaga);
}
