const GET_ETHEREUM_BALANCE = 'GET_TOKEN_BALANCE' as const;
const GET_POLYGON_BALANCE = 'GET_NATIVE_BALANCE' as const;
const LOGIN = 'LOGIN' as const;
const UPDATE_USER_INFO = 'UPDATE_USER_INFO' as const;
const PATCH_USER_INFO = 'PATCH_USER_INFO' as const;
const CONVERT = 'CONVERT' as const;

export default {
  GET_ETHEREUM_BALANCE,
  GET_POLYGON_BALANCE,
  LOGIN,
  UPDATE_USER_INFO,
  PATCH_USER_INFO,
  CONVERT,
};
