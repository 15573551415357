import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import nftActionTypes from 'store/nft/actionTypes';
import { getNftDataSaga } from 'store/nft/sagas/getNftData';
import nftSelector from 'store/nft/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { logger } from 'utils';

import { claimNft } from '../actions';
import actionTypes from '../actionTypes';

export function* claimNftSaga({ type, payload: { formData, setSubmitting } }: ReturnType<typeof claimNft>) {
  yield put(request(type));
  const { id }: { id: number } = yield select(nftSelector.getProp('detailedNft'));

  try {
    yield call(baseApi.claim, formData);

    yield call(getNftDataSaga, { type: nftActionTypes.GET_NFT_DATA, payload: { id } });

    toast.success('You have successfully send claim NFT request. Wait approve from admin');
    yield put(success(type));
  } catch (err) {
    logger('Claim NFT', err);
    yield put(error(type, err));
  } finally {
    setSubmitting(false);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM, claimNftSaga);
}
