import { toast } from 'react-toastify';
import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import { contractsConfig, ContractsNames } from 'services/WalletService/config/index';
import { error, request, success } from 'store/api/actions';
import networkSelector from 'store/network/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains, IChainType } from 'types';
import { getMaxGas, logger } from 'utils';

import { approveNft } from '../actions';
import actionTypes from '../actionTypes';

export function* approveNftSaga({
  type,
  payload: { isSingle, collectionAddress, web3Provider },
}: ReturnType<typeof approveNft>) {
  const { contracts } = contractsConfig;

  yield put(request(type));

  const { address, chainType, network }: { address: string; chainType: IChainType; network: Chains } = yield select(
    userSelector.getUser,
  );
  const { exchangeAddress }: { exchangeAddress: string } = yield select(networkSelector.getNetwork);

  try {
    const nftAbi = contracts[isSingle ? ContractsNames.erc721 : ContractsNames.erc1155][chainType].abi[network];
    const nftContract: ContractWeb3 = yield new web3Provider.eth.Contract(nftAbi, collectionAddress);

    const isApproved: boolean = yield call(nftContract.methods.isApprovedForAll(address, exchangeAddress).call);

    if (isApproved) {
      yield put(success(type));
      return;
    }

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);

    yield call(nftContract.methods.setApprovalForAll(exchangeAddress, true).send, {
      from: address,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    yield put(success(type));
  } catch (err) {
    logger('Approve NFT', err);
    yield put(error(type, err));
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE_NFT, approveNftSaga);
}
