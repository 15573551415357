import { FC, useState } from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { LikeButton } from 'components';
import { useShallowSelector } from 'hooks';
import nftSelector from 'store/nft/selectors';
import { COLOR_GRAY_150, COLOR_LIGHT } from 'theme/variables';
import { getPropertyValue } from 'utils';

export type NftHeaderProps = {
  isMobile: boolean;
};

export const NftHeader: FC<NftHeaderProps> = ({ isMobile }) => {
  const [shareHelperText, setShareHelpertext] = useState('');

  const { isLiked, likeCount, name, id, properties } = useShallowSelector(nftSelector.getProp('detailedNft'));

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setShareHelpertext('Coppied!');
    } catch (err) {
      setShareHelpertext('Not coppied!');
    }
    setTimeout(() => setShareHelpertext(''), 5000);
  };

  return (
    <Box
      width="100%"
      display={isMobile ? { xs: 'block', sm: 'none' } : { xs: 'none', sm: 'block' }}
      mt={{ xs: 0, sm: 4.5, lg: 6.5, xl: 11 }}
    >
      <Typography textTransform="uppercase" lineHeight="20px">
        {getPropertyValue(properties, 'Brand')}&nbsp;
      </Typography>
      <Typography variant="h1" mt="10px" fontSize={{ xs: '24px', lg: '36px' }} lineHeight="40px" className="bold">
        {name || 'Noname'}
      </Typography>
      <Stack spacing={0.5} alignItems="flex-end">
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" mt={{ xs: 1, sm: 4 }}>
          {id ? (
            <LikeButton id={id} isLiked={!!isLiked} likesCount={likeCount} />
          ) : (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="72px"
              height="30px"
              sx={{ borderRadius: '8px', backgroundColor: COLOR_LIGHT }}
            />
          )}
          {id ? (
            <Button variant="text" onClick={handleCopyUrl}>
              <ShareOutlinedIcon />
              <Typography textTransform="capitalize" ml={0.5} color={COLOR_GRAY_150}>
                Share
              </Typography>
            </Button>
          ) : (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="72px"
              height="30px"
              sx={{ borderRadius: '8px', backgroundColor: COLOR_LIGHT }}
            />
          )}
        </Stack>
        <Box height={2}>
          <Typography variant="subtitle2" color={COLOR_GRAY_150}>
            {shareHelperText}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
