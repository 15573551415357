import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { SxProps, TextField } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GRAY_200,
  COLOR_LIGHT,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
} from 'theme/variables';

export interface DateTimePickerProps {
  value: Moment | null;
  setValue: Dispatch<SetStateAction<Moment | null>>;
  disabled: boolean;
  disablePast?: boolean;
  error?: boolean;
  helperText?: string;
}
export const DateTimePicker: FC<DateTimePickerProps> = ({
  value,
  setValue,
  disabled,
  disablePast = false,
  error,
  helperText,
}) => {
  const disablePastError = useMemo(() => disablePast && !!value && value < moment(), [disablePast, value]);

  const popperSx: SxProps = {
    '& .MuiPaper-root': {
      backgroundColor: COLOR_LIGHT_GRAY,
      border: `1px solid ${COLOR_LIGHT}`,
      borderRadius: BORDER_RADIUS_DEFAULT,
      minHeight: '344px',
      svg: {
        color: COLOR_BLACK,
      },
    },

    '& .MuiPickersArrowSwitcher-root': {
      '& .MuiPickersArrowSwitcher-button': {
        color: COLOR_BLACK,

        '&.Mui-disabled': {
          color: COLOR_GRAY_150,
        },
      },
    },

    '& .MuiCalendarPicker-root': {
      borderRadius: BORDER_RADIUS_DEFAULT,

      '& .MuiDayPicker-weekDayLabel': {
        color: COLOR_GRAY_100,
        fontWeight: FontWeights.fontWeightSemiBold,
        fontSize: '11px',
      },

      '& .MuiPickersDay-dayWithMargin': {
        color: COLOR_BLACK,
        backgroundColor: 'transparent',
        fontWeight: FontWeights.fontWeightSemiBold,
        fontSize: '14px',

        '&:focus': {
          backgroundColor: COLOR_BLACK,
          color: COLOR_WHITE,
        },

        '&:hover': {
          border: `1px solid ${COLOR_BLACK}`,
        },

        '&.MuiPickersDay-today': {
          backgroundColor: COLOR_GRAY_150,
          color: COLOR_WHITE,
          border: 'none',

          '&:hover': {
            background: COLOR_BLACK,
          },
        },

        '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover ': {
          backgroundColor: COLOR_BLACK,
          color: COLOR_WHITE,
        },

        '&.Mui-disabled': {
          color: COLOR_GRAY_100,
        },
      },

      '& .MuiYearPicker-root': {
        '& .PrivatePickersYear-yearButton ': {
          width: 'unset',
          height: '28px',
          fontWeight: FontWeights.fontWeightSemiBold,

          '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover ': {
            backgroundColor: COLOR_BLACK,
            color: COLOR_WHITE,
          },

          '&:hover': {
            border: `1px solid ${COLOR_BLACK}`,
          },

          '&.Mui-disabled': {
            color: COLOR_GRAY_100,

            '&:hover': {
              border: 'none',
            },
          },
        },
      },
    },

    '& .MuiClock-root': {
      paddingTop: '50px',

      '& .MuiClock-clock': {
        backgroundColor: COLOR_GRAY_200,

        '& .MuiClock-pin, .MuiClockPointer-root': {
          backgroundColor: COLOR_WHITE,
        },

        '& .MuiClockNumber-root': {
          color: COLOR_GRAY_100,

          '&.Mui-selected': {
            backgroundColor: COLOR_WHITE,
            color: COLOR_BLACK,
          },
        },
      },

      '& .MuiClock': {
        '&-amButton, &-pmButton': {
          borderRadius: BORDER_RADIUS_DEFAULT,
          height: '36px',
          width: '36px',
          fontWeight: FontWeights.fontWeightRegular,
          span: { color: COLOR_WHITE },
          backgroundColor: COLOR_GRAY_100,

          '&:hover': {
            backgroundColor: COLOR_BLACK,

            span: {
              color: COLOR_WHITE,
            },
          },
        },
        '&-pmButton span': {
          color: COLOR_BLACK,
        },
      },
    },
  };

  return (
    <DesktopDateTimePicker
      value={value}
      onChange={(newValue: Moment | null) => {
        setValue(newValue);
      }}
      disablePast={disablePast}
      renderInput={(params) => (
        <TextField
          variant="filled"
          disabled={disabled}
          {...params}
          sx={{
            '.MuiInputBase-root': {
              width: { xs: '100%', sm: 247, xl: 350 },
              height: 56,
              maxWidth: '350px',
              '.MuiInputBase-input': { padding: 0 },
            },

            button: {
              marginRight: 'unset',
              color: COLOR_GRAY_150,
              '&:hover': {
                color: COLOR_BLACK,
              },
            },
          }}
          error={disablePastError || error}
          helperText={disablePastError ? 'Past dates not available' : helperText}
        />
      )}
      PopperProps={{
        sx: popperSx,
      }}
      components={{
        OpenPickerIcon: DateRangeOutlinedIcon,
      }}
      OpenPickerButtonProps={{ style: { color: COLOR_BLACK } }}
    />
  );
};
