export { default as image1 } from './1.jpg';
export { default as image2 } from './2.jpg';
export { default as image3 } from './3.jpg';
export { default as image4 } from './4.jpg';
export { default as image5 } from './5.jpg';
export { default as image6 } from './6.jpg';
export { default as image7 } from './7.jpg';
export { default as image8 } from './8.jpg';
export { default as image9 } from './9.jpg';
export { default as image10 } from './10.jpg';
export { default as image11 } from './11.jpg';
export { default as image12 } from './12.jpg';
export { default as image13 } from './13.jpg';
export { default as image14 } from './14.jpg';
export { default as image15 } from './15.jpg';
export { default as image16 } from './16.jpg';
export { default as image17 } from './17.jpg';
export { default as image18 } from './18.jpg';
export { default as image19 } from './19.jpg';
export { default as image20 } from './20.jpg';
export { default as image21 } from './21.jpg';
export { default as image22 } from './22.jpg';
export { default as image23 } from './23.jpg';
export { default as image24 } from './24.jpg';
export { default as image25 } from './25.jpg';
export { default as image26 } from './26.jpg';
export { default as image27 } from './27.jpg';
export { default as image28 } from './28.jpg';
export { default as image29 } from './29.jpg';
export { default as image30 } from './30.jpg';
export { default as image31 } from './31.jpg';
export { default as image32 } from './32.jpg';
export { default as image33 } from './33.jpg';
export { default as image34 } from './34.jpg';
export { default as image35 } from './35.jpg';
export { default as image36 } from './36.jpg';
export { default as image37 } from './37.jpg';
export { default as image38 } from './38.jpg';
export { default as image39 } from './39.jpg';
