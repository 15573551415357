import { FC } from 'react';
import { Link, Stack } from '@mui/material';
import { COLOR_BLACK } from 'theme/variables';

import { socialsItems } from './SocialLinks.helpers';

export const SocialLinks: FC = () => {
  return (
    <Stack spacing={1.75} direction="row" alignItems="center">
      {socialsItems.map(({ id, Icon, link }) => (
        <Link key={id} href={link} target="_blank" rel="noreferrer" zIndex={1} sx={{ height: 28 }}>
          <Icon sx={{ fill: COLOR_BLACK }} width="28px" height="28px" />
        </Link>
      ))}
    </Stack>
  );
};
