import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Telegram: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: 28, height: 28, fill: 'none', ...sx }} viewBox="0 0 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM14.5017 10.3356C13.14 10.9019 10.4185 12.0742 6.33721 13.8524C5.67447 14.1159 5.3273 14.3737 5.2957 14.6258C5.24228 15.0519 5.77583 15.2197 6.50238 15.4481C6.60121 15.4792 6.70361 15.5114 6.80859 15.5455C7.52341 15.7779 8.48496 16.0497 8.98484 16.0605C9.43827 16.0703 9.94435 15.8834 10.5031 15.4997C14.3164 12.9256 16.2848 11.6246 16.4084 11.5965C16.4955 11.5768 16.6164 11.5519 16.6982 11.6246C16.7801 11.6974 16.772 11.8352 16.7634 11.8721C16.7105 12.0975 14.6162 14.0446 13.5323 15.0522C13.1944 15.3663 12.9548 15.5892 12.9058 15.6401C12.796 15.754 12.6841 15.8619 12.5766 15.9655C11.9126 16.6057 11.4146 17.0858 12.6042 17.8697C13.1759 18.2465 13.6334 18.558 14.0897 18.8688C14.5882 19.2082 15.0853 19.5468 15.7285 19.9684C15.8924 20.0758 16.0489 20.1874 16.2014 20.2961C16.7814 20.7096 17.3025 21.0811 17.9464 21.0219C18.3205 20.9875 18.7069 20.6357 18.9031 19.5866C19.3669 17.1071 20.2786 11.735 20.4893 9.52128C20.5077 9.32733 20.4845 9.07911 20.4658 8.97015C20.4472 8.86118 20.4082 8.70594 20.2666 8.59101C20.0989 8.4549 19.8399 8.4262 19.7241 8.42824C19.1975 8.43752 18.3896 8.71843 14.5017 10.3356Z"
      fill="currentColor"
    />
  </SvgIcon>
);
