import { FC, Ref } from 'react';
import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { COLOR_GRAY_50, COLOR_GRAY_150 } from 'theme/variables';

export interface SearchFieldProps {
  placeholder?: string;
  popoverRef?: Ref<HTMLDivElement>;
}
export const SearchField: FC<SearchFieldProps & TextFieldProps> = ({
  placeholder,
  popoverRef,
  fullWidth,
  ...fieldProps
}) => {
  return (
    <TextField
      placeholder={placeholder}
      InputProps={{
        endAdornment: <Typography color={COLOR_GRAY_150}>Search</Typography>,
        startAdornment: fieldProps.value ? <Box mr={0.5}>|</Box> : '',
      }}
      variant="outlined"
      autoComplete="off"
      ref={popoverRef}
      sx={{
        fontSize: '16px',
        lineHeight: '24px',
        '&::-webkit-input-placeholder': {
          fontWeight: FontWeights.fontWeightSemiBold,
        },
        '& .MuiOutlinedInput-root': {
          height: fullWidth ? 48 : 44,
          width: fullWidth ? 'auto' : { xs: 300, lg: 400 },
        },
        '& .MuiOutlinedInput-input': {
          px: !fieldProps.value ? '16px !important' : 0,
          py: '8px !important',
          '&::placeholder': { fontWeight: FontWeights.fontWeightSemiBold },
        },
        '& .Mui-focused': {
          border: `1px solid ${COLOR_GRAY_50} !important`,
        },
        width: '100%',
      }}
      {...fieldProps}
    />
  );
};
