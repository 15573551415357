import { Box, BoxProps, Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';

interface NoticeProps {
  containerProps?: BoxProps;
}

export const Notice = ({ containerProps, ...props }: NoticeProps & CheckboxProps) => {
  return (
    <>
      <Typography sx={{ typography: 'body1', marginBottom: 4 }}>
        Please note.
        <br /> 1. This service is limited to collectible sneakers. <br /> 2. You are fully aware that this service is
        for meta[Z] to take full custody of your sneakers and NFT is handed out in return. <br /> 3. Once your sneakers
        are accepted for custody, redeem will only be available after 90 days from your custody starting date. <br /> 4.
        If your sneakers are not authentic or to fail the inspection, the sneakers will be returned cash on delivery.
        <br /> 5. This custody service fee is subject to change upon prior notice <br />
        6. We take no responsibility from any wrong transaction and/ or cost incurred due to mistakenly entered
        misinformation.
      </Typography>

      <Box {...containerProps}>
        <FormControlLabel
          control={
            <Checkbox
              name="autofill"
              // checked={isChecked}
              // onClick={() => handleCheckboxClick(values, setFieldValue)}
              // disabled={!Object.keys(custodyAutofill).length}
              {...props}
            />
          }
          label="I agree"
          sx={{ fontWeight: FontWeights.fontWeightSemiBold, margin: 0 }}
        />
      </Box>
    </>
  );
};
