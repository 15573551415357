import { createAction } from '@reduxjs/toolkit';
import {
  ConvertReq,
  LoginReq,
  RequestWithFormData,
  RequestWithSubmit,
  RequestWithWeb3Provider,
  UpdateUserInfoReq,
} from 'types';

import actionTypes from './actionTypes';

export const getEthereumBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_ETHEREUM_BALANCE);
export const getPolygonBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_POLYGON_BALANCE);
export const login = createAction<LoginReq>(actionTypes.LOGIN);
export const updateUserInfo = createAction<UpdateUserInfoReq>(actionTypes.UPDATE_USER_INFO);
export const patchUserInfo = createAction<RequestWithFormData & RequestWithSubmit>(actionTypes.PATCH_USER_INFO);
export const convert = createAction<ConvertReq & RequestWithWeb3Provider>(actionTypes.CONVERT);
