import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Like: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      width: '25px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5096 6C6.6456 6 5.8356 6.334 5.2296 6.941C3.9716 8.201 3.9716 10.252 5.2306 11.514L12.2896 18.585L19.3496 11.514C20.6086 10.252 20.6086 8.201 19.3496 6.941C18.1376 5.726 16.0016 5.728 14.7896 6.941L12.9976 8.736C12.6216 9.113 11.9576 9.113 11.5816 8.736L9.7896 6.94C9.1836 6.334 8.3746 6 7.5096 6ZM12.2896 21C12.0246 21 11.7696 20.895 11.5826 20.706L3.8146 12.926C1.7786 10.886 1.7786 7.567 3.8146 5.527C4.7986 4.543 6.1106 4 7.5096 4C8.9086 4 10.2216 4.543 11.2046 5.527L12.2896 6.614L13.3746 5.528C14.3586 4.543 15.6706 4 17.0706 4C18.4686 4 19.7816 4.543 20.7646 5.527C22.8016 7.567 22.8016 10.886 20.7656 12.926L12.9976 20.707C12.8096 20.895 12.5556 21 12.2896 21Z"
      fill="currentColor"
    />
  </SvgIcon>
);
