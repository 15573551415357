const HELLO_WORLD = 'HELLO_WORLD' as const;
const GET_CATEGORIES = 'GET_CATEGORIES' as const;
const GET_ENABLED_ON_CAROUSEL = 'GET_ENABLED_ON_CAROUSEL' as const;
const GET_FAVORITES = 'GET_FAVORITES' as const;
const GET_NEWEST = 'GET_NEWEST' as const;
const GET_POPULAR = 'GET_POPULAR' as const;
const GET_TOP_COLLECTORS = 'GET_TOP_COLLECTORS' as const;
const GET_SELF_COLLECTIONS = 'GET_SELF_COLLECTIONS' as const;
const CREATE_COLLECTION = 'CREATE_COLLECTION' as const;
const APPROVE_NFT = 'APPROVE_NFT' as const;
const APPROVE = 'APPROVE' as const;
const MINT_NFT = 'MINT_NFT' as const;
const CREATE_NFT = 'CREATE_NFT' as const;
const GET_NFT_DATA = 'GET_NFT_DATA' as const;
const SEARCH_NFTS = 'SEARCH_NFTS' as const;
const PRESEARCH_NFTS = 'PRESEARCH_NFTS' as const;
const GET_MAX_PRICE = 'GET_MAX_PRICE' as const;
const LIKE_NFT = 'LIKE_NFT' as const;
const BUY = 'BUY' as const;
const BID = 'BID' as const;
const SET_ON_SALE = 'SET_ON_SALE' as const;
const TRANSFER = 'TRANSFER' as const;
const SET_ON_AUCTION = 'SET_ON_AUCTION' as const;
const END_AUCTION = 'END_AUCTION' as const;
const REMOVE_FROM_SALE = 'REMOVE_FROM_SALE' as const;
const BURN = 'BURN' as const;

export default {
  HELLO_WORLD,
  GET_CATEGORIES,
  GET_ENABLED_ON_CAROUSEL,
  GET_FAVORITES,
  GET_NEWEST,
  GET_POPULAR,
  GET_TOP_COLLECTORS,
  GET_SELF_COLLECTIONS,
  CREATE_COLLECTION,
  APPROVE_NFT,
  APPROVE,
  MINT_NFT,
  CREATE_NFT,
  GET_NFT_DATA,
  SEARCH_NFTS,
  PRESEARCH_NFTS,
  GET_MAX_PRICE,
  LIKE_NFT,
  BUY,
  BID,
  SET_ON_SALE,
  SET_ON_AUCTION,
  TRANSFER,
  END_AUCTION,
  REMOVE_FROM_SALE,
  BURN,
};
