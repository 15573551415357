import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { removeFromSale } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';

export const RemoveFromSaleButton: FC = () => {
  const { id } = useShallowSelector(nftSelector.getProp('detailedNft'));
  const { [actionTypes.REMOVE_FROM_SALE]: removeFromSaleRequestStatus } = useShallowSelector(uiSelector.getUI);
  const dispatch = useDispatch();

  const isRemoveFromSaleLoading = useMemo(
    () => removeFromSaleRequestStatus === RequestStatus.REQUEST,
    [removeFromSaleRequestStatus],
  );

  const handleRemoveFromSale = () => {
    if (id) {
      dispatch(removeFromSale({ id }));
    }
  };

  return (
    <Button
      variant="contained"
      disabled={!id && isRemoveFromSaleLoading}
      onClick={handleRemoveFromSale}
      sx={{ width: '210px', height: '44px' }}
    >
      {isRemoveFromSaleLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'remove from sale'}
    </Button>
  );
};
