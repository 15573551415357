import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains } from 'types';
import { camelize, getMaxGas, logger } from 'utils';

import { createCollection } from '../actions';
import actionTypes from '../actionTypes';

export function* createCollectionSaga({
  type,
  payload: { network, formData, web3Provider, setSubmitting },
}: ReturnType<typeof createCollection>) {
  yield* put(request(type));

  try {
    const { data } = yield call(baseApi.createCollection, { network: network || Chains.Polygon, formData });

    if (data.unique_name === 'this collection name is occupied') {
      toast.error('This collection name is already used');
      yield put(error(type));
      return;
    }

    if (data.unique_symbol === 'this collection symbol is occupied') {
      toast.error('This collection symbol is already used');
      yield put(error(type));
      return;
    }

    const address: string = yield* select(userSelector.getProp('address'));
    const { initialTx, collection } = camelize(data);

    if (initialTx) {
      try {
        const gasPrice = yield* call(web3Provider.eth.getGasPrice);
        const estimatedGas = yield* call(web3Provider.eth.estimateGas, {
          data: initialTx?.data,
          value: initialTx?.value,
          from: initialTx?.from,
          gasPrice,
          to: initialTx?.to,
        });

        yield call(web3Provider.eth.sendTransaction, {
          ...initialTx,
          from: address,
          gasPrice: Number(gasPrice),
          gas: estimatedGas,
        });

        toast.success('You have successfully created collection');
        yield put(success(type));
      } catch (e) {
        logger('sendTransaction', e, 'error');
        yield call(baseApi.mintReject, { ids: [collection.id], type: 'collection' });
        yield put(error(type, e));
        toast.error('Something went wrong');
      }
    }
  } catch (err) {
    logger('createCollection', err, 'error');
    toast.error('Something went wrong');
    yield put(error(type, err));
  } finally {
    setSubmitting(false);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CREATE_COLLECTION, createCollectionSaga);
}
