import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { removeFromSale } from '../actions';
import actionTypes from '../actionTypes';
import { setDetailedNft } from '../reducer';

export function* removeFromSaleSaga({ type, payload: { id } }: ReturnType<typeof removeFromSale>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.removeFromSale, id);
    yield put(setDetailedNft(camelize(data, true)));
    toast.success('You have successfully removed NFT from sale');
    yield put(success(type));
  } catch (err) {
    logger('Remove from sale', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REMOVE_FROM_SALE, removeFromSaleSaga);
}
