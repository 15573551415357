import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IS_PRODUCTION } from 'appConstants/env';
import { Chains, UserState } from 'types';

const initialState: UserState = {
  avatar: '',
  address: '',
  isSingleFabricOwner: false,
  isMultipleFabricOwner: false,
  id: 0,
  provider: null,
  chainType: IS_PRODUCTION ? 'mainnet' : 'testnet', // NOTE: both development and production uses polygon mainnet
  network: Chains.Polygon,
  balance: {
    [Chains.Polygon]: {
      USDC: 0,
    },
  },
  rates: [],
  key: '',
  displayName: '',
  name: '',
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state: UserState, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
      };
    },
  },
});

export const { disconnectWalletState, updateUserState } = userReducer.actions;

export default userReducer.reducer;
