export { default as image1 } from './1.jpg';
export { default as image2 } from './2.jpg';
export { default as image3 } from './3.jpg';
export { default as image4 } from './4.jpg';
export { default as image5 } from './5.jpg';
export { default as image6 } from './6.jpg';
export { default as image7 } from './7.jpg';
export { default as image8 } from './8.jpg';
export { default as image9 } from './9.jpg';
export { default as image10 } from './10.jpg';
export { default as image11 } from './11.jpg';
export { default as image12 } from './12.jpg';
export { default as image13 } from './13.jpg';
