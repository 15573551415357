import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckedRounded: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: 14, height: 11, fill: 'none', ...sx }} viewBox="0 0 14 11">
    <g filter="url(#filter0_d_1_2131)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4882 0.57982C11.95 0.0666829 12.7404 0.0250849 13.2535 0.486909C13.7667 0.948732 13.8083 1.73909 13.3464 2.25223L5.84644 10.5856C5.36734 11.1179 4.53984 11.1396 4.03343 10.6332L0.700101 7.29991C0.211946 6.81175 0.211946 6.0203 0.700101 5.53214C1.18826 5.04399 1.97971 5.04399 2.46787 5.53214L4.86957 7.93385L11.4882 0.57982Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_2131"
        x="0.333984"
        y="0.166016"
        width="13.334"
        height="11.4585"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.625" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2131" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2131" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);
