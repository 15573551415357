import New1 from './assets/new-1.png';
import New2 from './assets/new-2.png';
import New3 from './assets/new-3.png';
import New4 from './assets/new-4.png';
import New5 from './assets/new-5.png';
import New6 from './assets/new-6.png';
import New7 from './assets/new-7.png';
import New8 from './assets/new-8.png';
import New9 from './assets/new-9.png';
import New10 from './assets/new-10.png';
import New11 from './assets/new-11.png';
import New12 from './assets/new-12.png';
import New13 from './assets/new-13.png';
import New14 from './assets/new-14.png';
import New15 from './assets/new-15.png';
import { NotableNewsCardProps } from './components';

export const newsItems: NotableNewsCardProps[] = [
  {
    img: New15,
    title: `METAZ Selected for Google Cloud's Startup Program, Unlocking Liquidity in Tokenized Sneakers`,
    date: '2024 Oct. 31',
    link: 'https://blog.metaz.io/metaz-google-cloud-startup-program/',
  },
  {
    img: New14,
    title: `METAZ at Korea Blockchain Week`,
    date: '2024 Sep. 05',
    link: 'https://blog.metaz.io/metaz-korea-blockchain-week/',
  },
  {
    img: New13,
    title: `How Meta[Z] Can Supercharge Your Sneaker Collection`,
    date: '2024 Mar. 19',
    link: 'https://blog.metaz.io/how-meta-z-can-supercharge-your-sneaker-collection/',
  },
  {
    img: New12,
    title: `MetaZ Holdings attracts $1million Pre-Series A round.`,
    date: '2023 May. 08',
    link: 'https://blog.metaz.io/metaz-holdings-attracted-1-million-in-a-pre-series-a-round/',
  },
  {
    img: New11,
    title: `meta[Z] at NFT NYC 2023`,
    date: '2023 Apr. 20',
    link: 'https://blog.metaz.io/meta-z-attended-nft-nyc-2023/',
  },
  {
    img: New9,
    title: `World's first metaverse department store 'THE 75007' launching exhibition`,
    date: '2023 Feb. 22',
    link: 'https://blog.metaz.io/75007/',
  },
  {
    img: New10,
    title: `Brand new meta[Z] Vault`,
    date: '2023 Feb. 22',
    link: 'https://blog.metaz.io/songdo-canggo/',
  },
  {
    img: New6,
    title: `WEB3 Creative Studio GALLERIEX x Jewelry Brand L'Dezen NFT Launching Show`,
    date: '2023 Jan. 27',
    link: 'https://blog.metaz.io/jyueolri-beuraendeu-nft-reoncingsyo/',
  },
  {
    img: New7,
    title: 'Happy Christmas Party with NFT Communities',
    date: '2023 Jan. 9',
    link: 'https://blog.metaz.io/kaesbotika-keuriseumaseu-haengsa/',
  },
  {
    img: New8,
    title: 'Hardware Wallet Brand [LEDGER] New Product Promotion Event',
    date: '2022 Dec. 14',
    link: 'https://blog.metaz.io/resjeohaengsa/',
  },
  {
    img: New1,
    title: 'Hype Your Everything, meta[Z] launched a new complete  NFT platform',
    date: '2022 Dec. 14',
    link: 'https://blog.metaz.io/hype-your-everything-meta-z-launched-a-new-complete-nft-platform/',
  },
  {
    img: New2,
    title: 'CloneX Korea x meta[Z] Event ',
    date: '2022 Oct. 31',
    link: 'https://blog.metaz.io/welcome-1st-clone-x-korea-jeonsi-opeuning-in-metaz-space/',
  },
  {
    img: New3,
    title: "Virgil Abloh's posthumous work 'LV x Nike Air Force 1 Monogram'",
    date: '2022 Oct. 31',
    link: 'https://blog.metaz.io/beojil-abeulro-yujag-lv-x-nike-air-force-1-monogram-metazeneun-issji/',
  },
  {
    img: New4,
    title: 'meta[Z] ArtZ: Deconstructionism by Rudy x TBOS',
    date: '2022 Oct. 31',
    link: 'https://blog.metaz.io/meta-art-z-xin-haecejuyizhan-rudy-yungyeongdeog-2/',
  },
  {
    img: New5,
    title: 'CoinOne Commercial shoot in meta[Z] Space',
    date: '2022 Oct. 31',
    link: 'https://blog.metaz.io/metaz-space-visited-by-bibi-x-code-kunst/',
  },
];
