import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'appConstants';

export const useActivity = () => {
  const navigate = useNavigate();
  const handleActivityClick = useCallback(
    (tokenId: string | undefined, profileId: string | undefined) => {
      if (tokenId) {
        navigate(routes.nft.root.getPath(tokenId));
      } else {
        navigate(routes.profile.root.getPath(profileId || ''));
      }
    },
    [navigate],
  );

  return {
    handleActivityClick,
  };
};
