import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton, Stack } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { SneakerCard } from 'components';
import { useShallowSelector, useWindowState } from 'hooks';
import { times } from 'lodash';
import { getPopularNfts } from 'store/nft/actions';
import actionTypes from 'store/nft/actionTypes';
import nftSelector from 'store/nft/selectors';
import uiSelector from 'store/ui/selectors';
import { RequestStatus } from 'types';
import { getPropertyValue } from 'utils';

import { TitleText } from '../../Home';
import { HomeSlider } from '..';

export const MostPopular: FC = () => {
  const [perPage, setPerPage] = useState(4);
  const nfts = useShallowSelector(nftSelector.getProp('popular'));
  const { [actionTypes.GET_POPULAR]: getPopularNftsRequestStatus } = useShallowSelector(uiSelector.getUI);
  const dispatch = useDispatch();
  const { width } = useWindowState();

  const isGetPopularLoading = useMemo(
    () => getPopularNftsRequestStatus === RequestStatus.REQUEST,
    [getPopularNftsRequestStatus],
  );

  useEffect(() => {
    if (width < 800) setPerPage(1);
    else if (width < 1000) setPerPage(2);
    else if (width < 1200) setPerPage(3);
    else setPerPage(4);
  }, [width]);

  useEffect(() => {
    dispatch(getPopularNfts());
  }, [dispatch]);

  return (
    <Stack spacing={3}>
      <TitleText>Most Popular</TitleText>
      <HomeSlider perPage={perPage} gap={16} padding={{ left: width < 700 ? 0 : 15, right: width < 700 ? 0 : 15 }}>
        {isGetPopularLoading
          ? times(4).map((index) => (
              <SplideSlide key={index}>
                <Skeleton
                  className="square"
                  sx={{ maxWidth: 324.5, width: '100%', borderRadius: { xs: '10px', sm: '30px' } }}
                />
              </SplideSlide>
            ))
          : nfts.map(({ id = 0, image = '', name, properties }, index) => (
              <SplideSlide key={`${index + 1}`}>
                <SneakerCard
                  id={id}
                  img={image}
                  name={name}
                  size={getPropertyValue(properties, 'Size')}
                  fontSize={14}
                  lineHeight="18px"
                />
              </SplideSlide>
            ))}
      </HomeSlider>
    </Stack>
  );
};
