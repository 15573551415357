import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { logger } from 'utils';
import { camelize } from 'utils/camelize';

import { getMaxPrice } from '../actions';
import actionTypes from '../actionTypes';
import { setMaxPrice } from '../reducer';

export function* getMaxPriceSaga({ type, payload }: ReturnType<typeof getMaxPrice>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.getMaxPrice, payload);
    const result = camelize(data) as { maxPrice: number };
    yield put(setMaxPrice(result.maxPrice));

    yield put(success(type));
  } catch (e) {
    logger('Get max price', e);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MAX_PRICE, getMaxPriceSaga);
}
