import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, IconButton, Stack, styled, TextField, Typography } from '@mui/material';
import BigNumber from 'bignumber.js/bignumber';
import { CompareArrows } from 'components';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { reset } from 'store/api/actions';
import uiSelector from 'store/ui/selectors';
import { convert } from 'store/user/actions';
import actionTypes from 'store/user/actionTypes';
import userSelector from 'store/user/selectors';
import { COLOR_GRAY_100, COLOR_GREEN, COLOR_WHITE, COLOR_WHITE_OPACITY_20 } from 'theme/variables';
import { Chains, CurrenciesForConvert, RequestStatus } from 'types';
import { flexHelper } from 'utils/flexHelper';

const currencyList: { [key in Chains]: CurrenciesForConvert[] } = {
  Polygon: ['matic', 'wmatic'],
};

const AmountTextField = styled(TextField)({
  '.MuiOutlinedInput-root': { padding: '10px 12px', height: '44px' },
});

const CurrencyBadge = styled(Box)({
  padding: '4px 8px',
  width: 'auto',
  height: '24px',
  ...flexHelper(),
  columnGap: '6px',
  background: COLOR_WHITE_OPACITY_20,
  borderRadius: '4px',
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: COLOR_WHITE,
  img: {
    width: '16px',
    height: '16px',
  },
});

type ConvertModalProps = {
  closeModal: () => void;
};

export const ConvertModal: FC<ConvertModalProps> = ({ closeModal }) => {
  const [currencies, setCurrencies] = useState<CurrenciesForConvert[]>([]);
  const [error, setError] = useState('');
  const [amountInput, handleChangeAmountInput] = useValidateInputField({
    type: ValidationTypes.number,
    decimals: 4,
  });
  const { balance, network, rates } = useShallowSelector(userSelector.getUser);
  const { [actionTypes.CONVERT]: convertRequestStatus } = useShallowSelector(uiSelector.getUI);
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const isConvertLoading = useMemo(() => convertRequestStatus === RequestStatus.REQUEST, [convertRequestStatus]);
  const isConvertSuccess = useMemo(() => convertRequestStatus === RequestStatus.SUCCESS, [convertRequestStatus]);

  const handleSwapCurrencies = () => {
    setCurrencies((prev) => [prev[1], prev[0]]);
  };

  const handleConvert = () => {
    dispatch(convert({ currency: currencies[0], amount: amountInput, web3Provider: walletService.Web3() }));
  };

  useEffect(() => {
    if (network) {
      setCurrencies(currencyList[network]);
    }
  }, [network]);

  useEffect(() => {
    if (amountInput !== '' && +amountInput < 0.001) setError('Amount should be equal or greater then 0.001');
    else if (+amountInput > +(balance[network][currencies[0]] || 0))
      setError('Amount should be equal or lower then your balance');
    else setError('');
  }, [amountInput, balance, currencies, network]);

  useEffect(() => {
    if (isConvertSuccess) {
      closeModal();
      dispatch(reset(actionTypes.CONVERT));
    }
  }, [closeModal, dispatch, isConvertSuccess]);

  return (
    <Stack spacing={2} alignItems="center" maxWidth="342px">
      <Stack spacing={0.5} alignItems="flex-end" width="100%">
        <AmountTextField
          variant="outlined"
          fullWidth
          placeholder="You pay"
          value={amountInput}
          onChange={handleChangeAmountInput}
          error={!!error}
          helperText={error as ReactNode}
          InputProps={{
            endAdornment: (
              <CurrencyBadge>
                <img
                  src={
                    rates.find((rate) => rate.symbol === (currencies[0] !== 'wmatic' ? currencies[0] : 'matic'))?.image
                  }
                  alt="first currency logo"
                />
                {currencies[0]}
              </CurrencyBadge>
            ),
          }}
        />
        <Typography fontSize="12px" lineHeight="16px" color={COLOR_GRAY_100}>
          Balance: {parseFloat(new BigNumber(balance[network][currencies[0]] || 0).toFixed(4, 1))}
        </Typography>
      </Stack>
      <IconButton onClick={handleSwapCurrencies}>
        <CompareArrows />
      </IconButton>
      <AmountTextField
        variant="outlined"
        fullWidth
        placeholder="You receive"
        value={amountInput}
        onChange={handleChangeAmountInput}
        InputProps={{
          endAdornment: (
            <CurrencyBadge>
              <img
                src={
                  rates.find((rate) => rate.symbol === (currencies[1] !== 'wmatic' ? currencies[1] : 'matic'))?.image
                }
                alt="first currency logo"
              />
              {currencies[1]}
            </CurrencyBadge>
          ),
        }}
      />
      <Button
        variant="contained"
        disabled={!!error || isConvertLoading || amountInput === ''}
        onClick={handleConvert}
        sx={{ width: '100%', height: '44px' }}
      >
        {isConvertLoading ? <CircularProgress size={30} sx={{ color: COLOR_GREEN }} /> : 'convert'}
      </Button>
    </Stack>
  );
};
