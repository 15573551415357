import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import { useShallowSelector } from 'hooks';
import profileSelector from 'store/profile/selectors';
import { FontFamilies } from 'theme/Typography';
import {
  BORDER,
  BORDER_BUTTON_GREEN,
  BORDER_RADIUS_DEFAULT,
  COLOR_LIGHT,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';
import { ProfileState, State } from 'types';
import { shortenPhrase } from 'utils';

export const Followers: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lastPathName = useMemo(() => pathname.split('/').pop(), [pathname]);
  const { followers, follows, followersCount, followsCount } = useShallowSelector<State, ProfileState>(
    profileSelector.getProfile,
  );
  const userFollowData = lastPathName === 'following' ? follows : followers;
  const userFollowCount = lastPathName === 'followers' ? followsCount || 0 : followersCount || 0;

  const itemsPerPage = 9;
  const totalPages = Math.ceil(userFollowCount / itemsPerPage);

  const [page, setPage] = useState(1);
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);

  const showMoreItems = () => {
    setPage((prevValue) => prevValue + 1);
  };
  useEffect(() => {
    setVisibleCount(page * itemsPerPage);
  }, [page]);
  const handleNavigateToProfile = (id: number | string) => {
    navigate(routes.profile.root.getPath(id));
  };
  return (
    <Grid item container xs={12} sm={8.5} md={9} lg={10} mt={0} display="block">
      <Typography
        variant="h3"
        className="capitalize"
        ml={1}
        sx={{ fontFamily: FontFamilies.primary, pb: 2, height: 'min-content' }}
      >
        {lastPathName}
      </Typography>

      <Stack direction="row" flexWrap="wrap" width="100%">
        {userFollowData?.length ? (
          userFollowData.slice(0, visibleCount).map((follower, index) => (
            <Stack
              direction="row"
              m={1}
              justifyContent="flex-start"
              alignItems="center"
              spacing={1.5}
              key={`${index + 1}`}
              onClick={() => handleNavigateToProfile(follower.customUrl || follower.id || '/')}
              sx={{
                cursor: 'pointer',
                width: { xs: '100%', sm: '320px' },
                px: 3,
                py: 1.75,
                borderRadius: BORDER_RADIUS_DEFAULT,
                border: BORDER,
                borderColor: COLOR_LIGHT,
                transition: TRANSITION_DEFAULT_TIME,

                '&:hover': {
                  background: COLOR_LIGHT,
                },
              }}
            >
              <Avatar srcSet={follower.avatar} sx={{ width: '56px', height: '56px', border: BORDER_BUTTON_GREEN }} />
              <Typography
                variant="h4"
                className="regular"
                sx={{ fontFamily: FontFamilies.primary, overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
              >
                {follower.displayName || shortenPhrase(follower.name || '')}
              </Typography>
            </Stack>
          ))
        ) : (
          <Typography ml={1}>There is no {lastPathName} yet.</Typography>
        )}
      </Stack>
      {page < totalPages && (
        <Button size="large" variant="outlined" sx={{ width: '100%', mt: 4 }} onClick={showMoreItems}>
          Load more
        </Button>
      )}
    </Grid>
  );
};
