import { FC, useRef } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Box, Button, IconButton, Menu, styled, Typography } from '@mui/material';
import { useModal } from 'hooks';
import { theme } from 'theme';
import { FontWeights } from 'theme/Typography';
import { BORDER, BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_400, COLOR_LIGHT_GRAY } from 'theme/variables';

import { SortAccordion } from './SortAccordion';
import { sortItemsMock } from './SortSelect.helpers';

type SortSelectProps = {
  activeSortItem: string;
  onChange: (value: string) => void;
  isMobile?: boolean;
};

const SortButton = styled(Button)({
  width: 130,
  height: 48,
  textTransform: 'none',
  background: COLOR_LIGHT_GRAY,
  color: COLOR_BLACK,
  fontSize: 16,
  lineHeight: '24px',
  '&:hover': { background: COLOR_LIGHT_GRAY, border: BORDER, borderColor: COLOR_BLACK, svg: { color: COLOR_BLACK } },
  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 40,
  },
});

export const SortSelect: FC<SortSelectProps> = ({ onChange, activeSortItem, isMobile = false }) => {
  const [isMenuVisible, onOpenMenu, onCloseMenu] = useModal(false);
  const popoverRef = useRef(null);
  return (
    <Box display={isMobile ? { xs: 'block', sm: 'none' } : { xs: 'none', sm: 'block' }} position="relative">
      <SortButton
        variant="contained"
        size="small"
        onClick={onOpenMenu}
        ref={popoverRef}
        endIcon={<KeyboardArrowDownRoundedIcon sx={{ width: '24px', height: '24px' }} />}
        sx={{
          '.MuiButton-endIcon': {
            transform: isMenuVisible ? 'rotate(180deg)' : 'auto',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, sm: 16 },
            fontWeight: FontWeights.fontWeightSemiBold,
            position: 'relative',
            right: theme.spacing(1),
          }}
        >
          Sort by
        </Typography>
      </SortButton>

      {isMenuVisible && (
        <Menu
          marginThreshold={0}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            onMouseLeave: onCloseMenu,
          }}
          onClose={onCloseMenu}
          sx={{
            '& .MuiPopover-paper': {
              mt: 1,
              background: COLOR_GRAY_400,
              borderRadius: BORDER_RADIUS_DEFAULT,
              width: '168px',
              padding: theme.spacing(1, 2),
              position: 'relative',
            },
            '& .MuiList-root': {
              padding: 0,
            },
          }}
          anchorEl={popoverRef.current}
          open={isMenuVisible}
          disableRestoreFocus
        >
          <Box position="relative">
            <IconButton onClick={onCloseMenu} sx={{ position: 'absolute', right: -2, top: 8, zIndex: 2 }}>
              <CloseRoundedIcon fontSize="small" sx={{ color: COLOR_BLACK }} />
            </IconButton>
            {sortItemsMock.map((sortItem) => (
              <Box key={sortItem.label}>
                <SortAccordion {...sortItem} onClick={onChange} activeItem={activeSortItem} />
              </Box>
            ))}
          </Box>
        </Menu>
      )}
    </Box>
  );
};
