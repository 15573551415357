import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger } from 'utils';

import { endAuction } from '../actions';
import actionTypes from '../actionTypes';

import { getNftDataSaga } from './getNftData';

export function* endAuctionSaga({ type, payload: { id, web3Provider } }: ReturnType<typeof endAuction>) {
  yield put(request(type));

  const {
    address,
    customUrl,
    id: userId,
  }: { address: string; customUrl: string; id: string } = yield select(userSelector.getUser);

  try {
    const { data } = yield call(baseApi.endAuction, id);

    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        const { transactionHash } = yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        yield call(baseApi.trackTransaction, {
          tx_hash: String(transactionHash),
          token: id,
          ownership: customUrl || userId,
          amount: 1,
        });

        yield call(getNftDataSaga, {
          type: actionTypes.GET_NFT_DATA,
          payload: { id },
        });

        toast.success('You have successfully ended auction');
        yield put(success(type));
      } catch (e) {
        logger('Send transaction for end auction', e);
        yield put(error(type));
        toast.error('Something went wrong');
      }
    } else {
      yield put(error(type));
      toast.error('No initial tx');
    }
  } catch (err) {
    logger('End auction', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.END_AUCTION, endAuctionSaga);
}
