import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { BORDER, COLOR_BLACK, COLOR_WHITE } from 'theme/variables';

export interface StatusActionButtonProps {
  actionLabel: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}
export const StatusActionButton: FC<StatusActionButtonProps> = ({ actionLabel, isLoading, disabled, onClick }) => {
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={onClick}
      disabled={isLoading || disabled}
      sx={{
        whiteSpace: 'nowrap',
        border: BORDER,
        fontSize: 14,
        minWidth: 100,
        py: 0.5,
        px: 1,
        mt: { xs: 1, lg: 0 },
        '&:hover': { backgroundColor: COLOR_BLACK, border: BORDER, borderColor: COLOR_BLACK },
      }}
    >
      {isLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : actionLabel}
    </Button>
  );
};
