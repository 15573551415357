/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_BUTTON, COLOR_BLACK, COLOR_GRAY_150, COLOR_LIGHT } from 'theme/variables';

export const getMuiSlider = (theme: Theme): ComponentsOverrides['MuiSlider'] => {
  return {
    root: {
      color: COLOR_BLACK,
      height: 4,
    },
    rail: {
      color: COLOR_LIGHT,
      opacity: 0.7,
      height: 4,
    },
    thumb: {
      height: 20,
      width: 20,
      background: COLOR_BLACK,
      '&$focused, &$focusVisible, &$activated, &$jumped, &:hover': {
        borderColor: COLOR_BLACK,
      },
      marginLeft: -theme.spacing(1.5),
      border: BORDER_BUTTON,
      borderColor: COLOR_BLACK,
    },
    active: {},
    valueLabel: {
      display: 'none',
    },
    track: {
      borderRadius: 4,
      border: 'none',
      color: COLOR_GRAY_150,
    },
  };
};
