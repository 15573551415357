import { FC } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY_100 } from 'theme/variables';

export const Privacy: FC = () => {
  return (
    <>
      <Typography sx={{ pb: 2, typography: { xs: 'h2', sm: 'h1' }, color: `${COLOR_BLACK} !important` }}>
        Privacy Policy
      </Typography>
      <Typography pb={2} sx={{ color: COLOR_GRAY_100 }}>
        Last Updated: August 26, 2022
      </Typography>
      <Typography>
        Meta[Z] (“Meta[Z],” “we”, “us”, or “our”) is committed to protecting your privacy. We have prepared this Privacy
        Policy to describe to you our practices regarding the Personal Data (as defined below) we collect, use, and
        share in connection with the Meta[Z] website and other software provided on or in connection with our services,
        as described in our Terms of Service (collectively, the “Service”). “NFT” in this Privacy Policy means a
        non-fungible token or similar digital item implemented on a blockchain (such as the Ethereum blockchain), which
        uses smart contracts to link to or otherwise be associated with certain content or data.
      </Typography>
      <List sx={{ listStyleType: 'disc', '& .MuiListItem-root': { display: 'list-item', pl: 3 } }}>
        <ListItem>
          Types of Data We Collect. “Personal Data” means data that allows someone to identify you individually,
          including, for example, your name, email address, as well as any other non-public information about you that
          is associated with or linked to any of the foregoing. “Anonymous Data” means data, including aggregated and
          de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not, by
          itself, permit the identification of individual persons. We collect Personal Data and Anonymous Data as
          described below.
          <ListItem>
            Information You Provide Us
            <ListItem>
              When you use our Service, update your account profile, or contact us, we may collect Personal Data from
              you, such as email address, first and last name, user name, and other information you provide. We also
              collect your blockchain address, which may become associated with Personal Data when you use our Service.
            </ListItem>
            <ListItem>
              Our Service lets you store preferences like how your content is displayed, notification settings, and
              favorites. We may associate these choices with your ID, browser, or mobile device.
            </ListItem>
            <ListItem>
              If you provide us with feedback or contact us, we will collect your name and contact information, as well
              as any other content included in the message.
            </ListItem>
            <ListItem>
              We may also collect Personal Data at other points in our Service where you voluntarily provide it or where
              we state that Personal Data is being collected.
            </ListItem>
          </ListItem>
        </ListItem>
        <ListItem>
          Use of Your Personal Data.
          <ListItem>
            We process your Personal Data to run our business, provide the Service, personalize your experience on the
            Service, and improve the Service. Specifically, we use your Personal Data to:
            <ListItem> facilitate the creation of and secure your account; </ListItem>
            <ListItem> identify you as a user in our system; </ListItem>
            <ListItem>
              provide you with our Service, including, but not limited to, helping you view, explore, and create NFTs
              using our tools and, at your own discretion, connect directly with others to purchase, sell, or transfer
              NFTs on public blockchains;
            </ListItem>
            <ListItem>
              improve the administration of our Service and quality of experience when you interact with our Service,
              including, but not limited to, by analyzing how you and other users find and interact with the Service;
            </ListItem>
            <ListItem>provide customer support and respond to your requests and inquiries; </ListItem>
            <ListItem> investigate and address conduct that may violate our Terms of Service; </ListItem>
            <ListItem>
              detect, prevent, and address fraud, violations of our terms or policies, and/or other harmful or unlawful
              activity;
            </ListItem>
            <ListItem>
              display your username next to the NFTs currently or previously accessible in your third-party wallet, and
              next to NFTs on which you have interacted;
            </ListItem>
            <ListItem>
              send you a welcome email to verify ownership of the email address provided when your account was created;
            </ListItem>
            <ListItem>
              send you administrative notifications, such as security, support, and maintenance advisories;
            </ListItem>
            <ListItem>
              send you notifications related to actions on the Service, including notifications of offers on your NFTs;
            </ListItem>
            <ListItem>
              send you newsletters, promotional materials, and other notices related to our Services or third
              parties&apos; goods and services;
            </ListItem>
            <ListItem> respond to your inquiries related to employment opportunities or other requests; </ListItem>
            <ListItem>
              comply with applicable laws, cooperate with investigations by law enforcement or other authorities of
              suspected violations of law, and/or to pursue or defend against legal threats and/or claims; and
            </ListItem>
            <ListItem> act in any other way we may describe when you provide the Personal Data.</ListItem>
          </ListItem>
          <ListItem>
            We may create Anonymous Data records from Personal Data. We use this Anonymous Data to analyze request and
            usage patterns so that we may improve our Services and enhance Service navigation. We reserve the right to
            use Anonymous Data for any purpose and to disclose Anonymous Data to third parties without restriction.
          </ListItem>
        </ListItem>
        <ListItem>
          Disclosure of Your Personal Data. We disclose your Personal Data as described below and as described elsewhere
          in this Privacy Policy.
          <ListItem>
            Third Party Service Providers. We may share your Personal Data with third party service providers to:
            provide technical infrastructure services; conduct quality assurance testing; analyze how our Service is
            used; prevent, detect, and respond to unauthorized activities; provide technical and customer support;
            and/or to provide other support to us and to the Service.
          </ListItem>
          <ListItem>
            Affiliates. We may share some or all of your Personal Data with any subsidiaries, joint ventures, or other
            companies under our common control (“Affiliates”), in which case we will require our Affiliates to honor
            this Privacy Policy.
          </ListItem>
          <ListItem>
            Corporate Restructuring. We may share some or all of your Personal Data in connection with or during
            negotiation of any merger, financing, acquisition, or dissolution transaction or proceeding involving sale,
            transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an
            insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If
            another company acquires our company, business, or assets, that company will possess the Personal Data
            collected by us and will assume the rights and obligations regarding your Personal Data as described in this
            Privacy Policy.
          </ListItem>
          <ListItem>
            Legal Rights. Regardless of any choices you make regarding your Personal Data (as described below), Meta[Z]
            may disclose Personal Data if it believes in good faith that such disclosure is necessary: (a) in connection
            with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas, warrants, or
            other legal process served on Meta[Z]; (c) to protect or defend the rights or property of Meta[Z] or users
            of the Service; and/or (d) to investigate or assist in preventing any violation or potential violation of
            the law, this Privacy Policy, or our Terms of Service.
          </ListItem>
          <ListItem>
            Other Disclosures. We may also disclose your Personal Data: to fulfill the purpose for which you provide it;
            for any other purpose disclosed by us when you provide it; or with your consent.
          </ListItem>
        </ListItem>

        <ListItem>
          Third-Party Wallets. To use our Service, you must use a third-party wallet which allows you to engage in
          transactions on public blockchains. Your interactions with any third-party wallet provider are governed by the
          applicable terms of service and privacy policy of that third party.
        </ListItem>
        <ListItem>
          Data Retention. We may retain your Personal Data as long as you continue to use the Service, have an account
          with us, or for as long as is necessary to fulfill the purposes outlined in this Privacy Policy. We may
          continue to retain your Personal Data even after you deactivate your account and/or cease to use the Service
          if such retention is reasonably necessary to comply with our legal obligations, to resolve disputes, prevent
          fraud and abuse, enforce our Terms or other agreements, and/or protect our legitimate interests. Where your
          Personal Data is no longer required for these purposes, we will delete it.
        </ListItem>
        <ListItem>
          Data Protection. We care about the security of your information and use physical, administrative, and
          technological safeguards to preserve the integrity and security of information collected through our Service.
          However, no security system is impenetrable and we cannot guarantee the security of our systems. In the event
          that any information under our custody and control is compromised as a result of a breach of security, we will
          take steps to investigate and remediate the situation and, in accordance with applicable laws and regulations,
          notify those individuals whose information may have been compromised.
          <ListItem>
            You are responsible for the security of your digital wallet, and we urge you to take steps to ensure it is
            and remains secure. If you discover an issue related to your wallet, please contact your wallet provider.
          </ListItem>
        </ListItem>
        <ListItem>
          Changes to This Privacy Policy. This Privacy Policy may be updated from time to time for any reason. We will
          notify you of any changes to our Privacy Policy by posting the new Privacy Policy. The date the Privacy Policy
          was last revised is identified at the beginning of this Privacy Policy. You are responsible for periodically
          visiting our Service and this Privacy Policy to check for any changes.
        </ListItem>
      </List>
    </>
  );
};
