import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Slider,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useShallowSelector } from 'hooks';
import { getMaxPrice } from 'store/nft/actions';
import nftSelector from 'store/nft/selectors';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_LIGHT, COLOR_WHITE_OPACITY_20 } from 'theme/variables';
import { Currencies } from 'types';
import { useDebounce } from 'use-debounce/lib';

import { initialFilterState, PriceRangeProps } from '../FilterMenu.helpers';

const RangeTextField = styled(TextField)(({ theme }) => ({
  width: '120px',
  '.MuiInputBase-root': {
    borderColor: COLOR_LIGHT,
  },
  input: { p: theme.spacing(1.25), textAlign: 'center' },
}));

type RangeAccordionProps = {
  label: string;
  activeItem: PriceRangeProps;
  onChange: (value: PriceRangeProps) => void;
};

export const RangeAccordion: FC<RangeAccordionProps> = ({ label, activeItem, onChange }) => {
  const dispatch = useDispatch();
  const maxValue = useShallowSelector(nftSelector.getProp('maxPrice'));
  const minValue = 0;

  const [priceItem, setPriceItem] = useState(initialFilterState.priceRange);

  const [debouncedValue] = useDebounce(priceItem, 1000);

  useEffect(() => {
    if (debouncedValue.maxValue) onChange(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (activeItem.currency) dispatch(getMaxPrice({ currency: activeItem.currency as Currencies }));
  }, [activeItem.currency, dispatch]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setPriceItem({ ...priceItem, minValue: newValue[0], maxValue: newValue[1] });
  };
  const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceItem(
      event.target.value === ''
        ? { ...priceItem, minValue: 0 }
        : { ...priceItem, minValue: Number(event.target.value) },
    );
  };
  const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceItem(
      event.target.value === ''
        ? { ...priceItem, maxValue: 0 }
        : { ...priceItem, maxValue: Number(event.target.value) },
    );
  };
  const handleBlurMin = () => {
    if (priceItem.minValue < minValue) {
      setPriceItem({ ...priceItem, minValue });
    } else if (priceItem.minValue > maxValue) {
      setPriceItem({ ...priceItem, minValue: maxValue, maxValue });
    } else if (priceItem.minValue > priceItem.maxValue) {
      setPriceItem({ ...priceItem, maxValue: priceItem.minValue });
    }
  };
  const handleBlurMax = () => {
    if (priceItem.maxValue < minValue) {
      setPriceItem({ ...priceItem, minValue, maxValue: minValue });
    } else if (priceItem.maxValue > maxValue) {
      setPriceItem({ ...priceItem, maxValue });
    } else if (priceItem.maxValue < priceItem.minValue) {
      setPriceItem({ ...priceItem, minValue: priceItem.maxValue });
    }
  };
  return (
    <Accordion disableGutters key={label} defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        expandIcon={<KeyboardArrowDownIcon fontSize="medium" sx={{ color: COLOR_BLACK }} />}
      >
        <Typography className="semiBold">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ background: COLOR_WHITE_OPACITY_20, mb: 2 }} />
        {priceItem.currency && !maxValue && (
          <Typography variant="subtitle1" sx={{ mt: 1, color: COLOR_GRAY_100 }}>
            There is no nfts with this currency
          </Typography>
        )}
        {priceItem.currency && !!maxValue && (
          <>
            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Typography sx={{ color: COLOR_GRAY_100, fontSize: '12px' }}>Min</Typography>
              <Typography sx={{ color: COLOR_GRAY_100, fontSize: '12px' }}>Max</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <RangeTextField
                value={priceItem.minValue || minValue}
                size="small"
                onChange={handleMinInputChange}
                onBlur={handleBlurMin}
                inputProps={{
                  min: minValue,
                  max: maxValue,
                  type: 'number',
                }}
              />
              <Slider
                value={[priceItem.minValue || minValue, priceItem.maxValue || maxValue]}
                onChange={handleSliderChange}
                min={minValue}
                max={maxValue}
                step={maxValue > 10 ? 1 : minValue}
                disableSwap
              />
              <RangeTextField
                value={priceItem.maxValue || maxValue}
                size="small"
                onChange={handleMaxInputChange}
                onBlur={handleBlurMax}
                inputProps={{
                  min: minValue,
                  max: maxValue,
                  type: 'number',
                }}
              />
            </Stack>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
