import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { FontWeights } from '../Typography';
import { COLOR_BLACK, COLOR_LIGHT_GRAY, COLOR_WHITE } from '../variables';

export const getMuiPaginationOverrides = (theme: Theme): ComponentsOverrides['MuiPagination'] => ({
  root: {
    ul: {
      justifyContent: 'center',
    },
    button: {
      minWidth: 28,
      height: 28,
      background: COLOR_LIGHT_GRAY,
      border: 'none',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: FontWeights.fontWeightRegular,
      '&.Mui-selected': {
        background: COLOR_BLACK,
        color: COLOR_WHITE,
        fontWeight: FontWeights.fontWeightSemiBold,
      },
    },
  },
});

export const getMuiPaginationDefaultProps = (): ComponentsProps['MuiPagination'] => ({});
