import { FC } from 'react';
import { Skeleton } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_300 } from 'theme/variables';

interface BlockSkeletonProps {
  height: { xs: number; md: number; lg: number };
}
export const BlockSkeleton: FC<BlockSkeletonProps> = ({ height }) => (
  <Skeleton
    variant="rectangular"
    width="100%"
    animation="wave"
    sx={{
      borderRadius: BORDER_RADIUS_DEFAULT,
      bgcolor: COLOR_GRAY_300,
      height: { xs: `${height.xs}px`, md: `${height.md}px`, lg: `${height.lg}px` },
    }}
  />
);
