import { FC, useRef } from 'react';
import { IconButton, Menu } from '@mui/material';
import { Burger, MenuClose } from 'components';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_LIGHT, COLOR_WHITE } from 'theme/variables';

import { Nav } from '../Nav';

type NavMenuProps = {
  isMenuOpen: boolean;
  handleIsOpenMenu: () => void;
};

export const NavMenu: FC<NavMenuProps> = ({ isMenuOpen, handleIsOpenMenu }) => {
  const popoverRef = useRef(null);

  return (
    <>
      <IconButton ref={popoverRef} onClick={handleIsOpenMenu}>
        {isMenuOpen ? <MenuClose sx={{ color: COLOR_BLACK }} /> : <Burger sx={{ color: COLOR_BLACK }} />}
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={popoverRef.current}
        disableRestoreFocus
        onClose={handleIsOpenMenu}
        PaperProps={{
          onMouseLeave: handleIsOpenMenu,
        }}
        sx={{
          '.MuiPaper-root': {
            px: 3,
            mt: 1,
            background: `${COLOR_WHITE} !important`,
            boxShadow: 'none',
            border: `1px solid ${COLOR_LIGHT}`,
            borderRadius: BORDER_RADIUS_DEFAULT,

            '.MuiList-root': { height: '100%' },
          },
        }}
      >
        <Nav closeMenu={handleIsOpenMenu} />
      </Menu>
    </>
  );
};
