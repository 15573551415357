export enum Modals {
  ConnectWallet = 'ConnectWallet',
  Disconnect = 'Disconnect',
  Network = 'Network',
  Activity = 'Activity',
  Custody = 'Custody',
  ApproveCustody = 'ApproveCustody',
  SuccessCreateCollectible = 'SuccessCreateCollectible',
  BuyMultiple = 'BuyMultiple',
  Convert = 'Convert',
  init = '',
}

export interface ModalState {
  activeModal: Modals;
  txHash: string;
  open: boolean;
}

export type ModalsInitialState = {
  modalState: ModalState;
};
