import { fork } from 'redux-saga/effects';

import approveSaga from './approve';
import approveNftSaga from './approveNft';
import bidSaga from './bid';
import burnSaga from './burn';
import buySaga from './buy';
import createCollectionSaga from './createCollection';
import createNftSaga from './createNft';
import endAuctionSaga from './endAuction';
import getCategoriesSaga from './getCategories';
import getSelfCollectionsSaga from './getCollections';
import getEnabledOnCarouselSaga from './getEnabledOnCarousel';
import getFavoritesSaga from './getFavorites';
import getMaxPriceSaga from './getMaxPrice';
import getNewestNftsSaga from './getNewestNfts';
import getNftDataSaga from './getNftData';
import getPopularNftsSaga from './getPopularNfts';
import getTopCollectorsSaga from './getTopCollectors';
import likeNftSaga from './likeNft';
import mintNftSaga from './mintNft';
import presearchNftsSaga from './presearchNfts';
import removeFromSaleSaga from './removeFromSale';
import searchNftsSaga from './searchNfts';
import setOnAuctionSaga from './setOnAuction';
import setOnSaleSaga from './setOnSale';
import transferSaga from './transfer';

export default function* nftSagas() {
  yield fork(approveNftSaga);
  yield fork(approveSaga);
  yield fork(bidSaga);
  yield fork(burnSaga);
  yield fork(buySaga);
  yield fork(createCollectionSaga);
  yield fork(createNftSaga);
  yield fork(endAuctionSaga);
  yield fork(getCategoriesSaga);
  yield fork(getEnabledOnCarouselSaga);
  yield fork(getFavoritesSaga);
  yield fork(getMaxPriceSaga);
  yield fork(getNftDataSaga);
  yield fork(getSelfCollectionsSaga);
  yield fork(likeNftSaga);
  yield fork(removeFromSaleSaga);
  yield fork(presearchNftsSaga);
  yield fork(searchNftsSaga);
  yield fork(setOnSaleSaga);
  yield fork(setOnAuctionSaga);
  yield fork(transferSaga);
  yield fork(mintNftSaga);
  yield fork(getNewestNftsSaga);
  yield fork(getPopularNftsSaga);
  yield fork(getTopCollectorsSaga);
}
