import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { logger } from 'utils';

import { unfollow } from '../actions';
import profileActionTypes from '../actionTypes';

import { getProfileInfoSaga } from './getProfileInfo';

export function* unfollowSaga({ type, payload: { id } }: ReturnType<typeof unfollow>) {
  yield put(request(type));

  try {
    yield call(baseApi.unfollow, { id });

    yield call(getProfileInfoSaga, {
      type: profileActionTypes.GET_PROFILE,
      payload: { id },
    });

    yield put(success(type));
  } catch (e) {
    logger('Unfollow user', e);
    put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(profileActionTypes.UNFOLLOW, unfollowSaga);
}
