import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLACK,
  COLOR_GRAY_50,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GRAY_200,
  COLOR_GRAY_400,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    boxSizing: 'border-box',
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_DEFAULT,
    transition: TRANSITION_DEFAULT_TIME,
    fontWeight: FontWeights.fontWeightSemiBold,
    padding: theme.spacing(2, 2),
    fontSize: '16px',
    lineHeight: '24px',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },

    [theme.breakpoints.down('md')]: {
      transition: 'none',
    },
  },

  startIcon: {
    position: 'absolute',
    left: theme.spacing(2),
  },

  endIcon: {
    position: 'absolute',
    right: theme.spacing(2),
  },

  sizeSmall: {
    fontWeight: FontWeights.fontWeightMedium,
    fontSize: '14px',
    padding: theme.spacing(1, 2),
    textTransform: 'capitalize',
    minHeight: 0,
    minWidth: 0,
  },

  sizeLarge: {
    padding: theme.spacing(0, 4.5),
    height: '44px',
    minWidth: '168px',
  },

  outlined: {
    border: `1px solid ${COLOR_BLACK}`,
    background: 'transparent',
    color: COLOR_BLACK,

    '&:active': {
      background: `${COLOR_GRAY_200} !important`,
      borderColor: COLOR_GRAY_200,
      color: `${COLOR_WHITE} !important`,
      svg: { path: { stroke: COLOR_WHITE } },
    },

    '&:hover': {
      borderColor: COLOR_GRAY_200,
      background: COLOR_GRAY_200,
      color: COLOR_WHITE,
      p: {
        transition: TRANSITION_DEFAULT_TIME,
      },
    },
    [`&.${buttonClasses.disabled}`]: {
      background: COLOR_GRAY_400,
      borderColor: COLOR_GRAY_100,
      color: COLOR_GRAY_100,
    },
  },

  contained: {
    backgroundColor: COLOR_BLACK,
    color: COLOR_WHITE,
    '&:hover': {
      backgroundColor: COLOR_GRAY_200,
      color: COLOR_WHITE,
    },
    '&:active': {
      border: 'none',
      background: `${COLOR_GRAY_400} !important`,
      color: COLOR_WHITE,
    },
    [`&.${buttonClasses.disabled}`]: {
      background: COLOR_GRAY_150,
      color: COLOR_WHITE,
    },
    '&.gray': {
      backgroundColor: COLOR_GRAY_150,
      textTransform: 'none',
      color: COLOR_WHITE,
      '&:hover': {
        backgroundColor: COLOR_GRAY_50,
      },
    },
    '&.light-gray': {
      background: COLOR_LIGHT_GRAY,
      border: 'none',
      borderRadius: '8px',
      color: COLOR_GRAY_150,
      textTransform: 'none',
      fontWeight: FontWeights.fontWeightRegular,
      '&:hover': {
        color: COLOR_GRAY_150,
        background: COLOR_LIGHT_GRAY,
        border: 'none',
      },
      '&:active': {
        background: `${COLOR_LIGHT_GRAY} !important`,
      },
    },
    '&.unset-case': {
      textTransform: 'none',
    },
  },

  text: {
    background: 'none',
    backgroundColor: 'transparent',
    transition: '0',
    boxSizing: 'border-box',
    borderColor: 'transparent',
    padding: 0,
    margin: 0,
    minWidth: 0,
    '.MuiButton-endIcon': {
      position: 'relative',
      right: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_GRAY_200,
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'outlined',
});
