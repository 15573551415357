import * as Yup from 'yup';

export type CollectionProps = {
  media: string;
  preview: string;
  isSingle: boolean;
  name: string;
  category: number;
  type: 'Redeemable' | 'Claimable' | 'Digital only';
  fee: number;
  payoutWalletAddress: string;
  symbol: string;
  description: string;
};

export const defaultCollectionProps: CollectionProps = {
  media: '',
  preview: '',
  isSingle: true,
  name: '',
  category: 0,
  type: 'Redeemable',
  fee: 0,
  payoutWalletAddress: '',
  symbol: '',
  description: '',
};

export const collectionValidationSchema = Yup.object().shape({
  media: Yup.mixed().required('Media is required'),
  name: Yup.string().min(2, 'Too short').max(25, 'Too long').required('Name is required'),
  category: Yup.number().required('Category is required'),
  fee: Yup.number().min(0, 'Minimal amount equals to 0').max(10, 'Too much').required('Fee is required'),
  symbol: Yup.string().min(2, 'Too short').max(50, 'Too long').required('Symbol is required'),
  description: Yup.string().min(5, 'Too short').max(500, 'Too long').required('Description is required'),
  payoutWalletAddress: Yup.string()
    .min(10, 'Too short')
    .max(80, 'Too long')
    .required('Payout wallet address is required'),
});

type CollectionType = {
  id: number;
  label: string;
};

export const collectionTypes: CollectionType[] = [
  {
    id: 0,
    label: 'Redeemable',
  },
  {
    id: 1,
    label: 'Claimable',
  },
  {
    id: 2,
    label: 'Digital only',
  },
];
