import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals } from 'types';
import { camelize, getMaxGas, logger } from 'utils';

import { createNft } from '../actions';
import actionTypes from '../actionTypes';

import { approveNftSaga } from './approveNft';

export function* createNftSaga({
  type,
  payload: { isSingle, formData, collectionAddress, web3Provider },
}: ReturnType<typeof createNft>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.createNft, formData);

    if (data.unique_name === 'this token name is occupied') {
      toast.error('This name is already used');
      yield put(error(type));
      return;
    }

    const { address } = yield select(userSelector.getUser);
    const { initialTx, tokenIds } = camelize(data);

    if (initialTx) {
      try {
        try {
          yield call(approveNftSaga, {
            type: actionTypes.APPROVE_NFT,
            payload: { isSingle, web3Provider, collectionAddress },
          });
        } catch {
          return;
        }

        const gasPrice = yield* call(web3Provider.eth.getGasPrice);
        const estimatedGas = yield* call(web3Provider.eth.estimateGas, {
          data: initialTx?.data,
          value: initialTx?.value,
          from: initialTx?.from,
          gasPrice,
          to: initialTx?.to,
        });

        yield call(web3Provider.eth.sendTransaction, {
          ...initialTx,
          from: address,
          gasPrice: Number(gasPrice),
          gas: estimatedGas,
        });

        yield put(setActiveModal({ activeModal: Modals.SuccessCreateCollectible, open: true }));
        yield put(success(type));
      } catch (e: any) {
        const errCode = e === 'number' ? e : e.code;

        if (errCode === 4001) {
          yield call(baseApi.mintReject, { ids: tokenIds || [], type: 'token' });
        }

        toast.error(errCode === 4001 ? 'You have rejected confirmation' : 'Something went wrong');
        logger('Send tx', e);

        yield put(error(type, e));
      }
    } else {
      yield put(error(type));
      yield call(baseApi.mintReject, { ids: tokenIds || [], type: 'token' });
      toast.error('No initial tx for sending');
    }
  } catch (err) {
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CREATE_NFT, createNftSaga);
}
