import { TypographyOptions } from '@mui/material/styles/createTypography';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_PURPLE, COLOR_WHITE } from 'theme/variables';

export enum FontWeights {
  fontWeightLight = 300,
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightSemiBold = 600,
  fontWeightBold = 700,
  fontWeightBlack = 900,
}

export enum FontFamilies {
  primary = '"Inter", sans-serif',
  secondary = '"Druk-Wide", sans-serif',
  tertiary = '"MonumentExtended", sans-serif',
  quaternary = '"NotoSans", sans-serif',
}

const colorVariants = {
  '&.white': {
    color: COLOR_WHITE,
  },
  '&.purple': {
    color: COLOR_PURPLE,
  },
  '&.gray': {
    color: COLOR_GRAY_100,
  },
};

const styleVariants = {
  '&.capitalize': {
    textTransform: 'capitalize',
  },
  '&.uppercase': {
    textTransform: 'uppercase',
  },
  '&.unset-transform': {
    textTransform: 'unset',
  },
};

const weightVariants = {
  '&.light': {
    fontWeight: FontWeights.fontWeightLight,
  },
  '&.regular': {
    fontWeight: FontWeights.fontWeightRegular,
  },
  '&.medium': {
    fontWeight: FontWeights.fontWeightMedium,
  },
  '&.semiBold': {
    fontWeight: FontWeights.fontWeightSemiBold,
  },
  '&.bold': {
    fontWeight: FontWeights.fontWeightBold,
  },
};

export const getTypographyOptions = (): TypographyOptions => ({
  allVariants: {
    fontFamily: FontFamilies.primary,
    color: COLOR_BLACK,
    fontWeight: FontWeights.fontWeightRegular,
    textAlign: 'left',
    fontStyle: 'normal',
    ...colorVariants,
    ...styleVariants,
    ...weightVariants,
  },

  h1: {
    fontSize: '36px',
    lineHeight: '52px',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.primary,
    color: COLOR_BLACK,

    '&.lg': {
      fontSize: '48px',
      lineHeight: '60px',
      fontWeight: FontWeights.fontWeightSemiBold,
    },
  },

  h2: {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: FontWeights.fontWeightMedium,

    '&.lg': {
      fontSize: '32px',
      lineHeight: '34px',
      fontWeight: FontWeights.fontWeightSemiBold,
    },
  },

  h3: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.secondary,
  },

  h4: {
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: FontWeights.fontWeightSemiBold,
    fontFamily: FontFamilies.primary,

    '&.md': {
      fontSize: '24px',
    },
    '&.lg': {
      fontSize: '28px',
    },
  },

  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,

    '&.s': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: FontWeights.fontWeightRegular,
    },
  },

  body2: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightSemiBold,
    fontFamily: FontFamilies.primary,
  },

  subtitle1: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
    '&.lg': {
      fontSize: '28px',
    },
  },

  subtitle2: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
  },
});
