import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_200, COLOR_GREEN, COLOR_WHITE } from 'theme/variables';

export const getMuiChip = (theme: Theme): ComponentsOverrides['MuiChip'] => {
  const deleteIconStyles = {
    outline: 'none',
    border: 'none',
    transform: 'scale(0.6)',
    '& > path': {
      fill: COLOR_GREEN,
    },
    '&$disabled': {
      opacity: 0.5,
      border: 'none',
    },
  };

  return {
    root: {
      backgroundColor: COLOR_GRAY_200,
      color: COLOR_WHITE,
      borderRadius: BORDER_RADIUS_DEFAULT,
      border: '1px solid',
      borderColor: COLOR_GRAY_200,
      '&:hover, &:focus': {
        border: '1px solid',
        borderColor: COLOR_GREEN,
        '& .MuiChip-label': { color: COLOR_GREEN },
      },
    },

    deleteIcon: deleteIconStyles,
    icon: {
      marginLeft: -theme.spacing(0.5),
      marginRight: theme.spacing(0.75),
    },
    label: {
      overflow: 'visible',
      color: COLOR_WHITE,
      fontWeight: FontWeights.fontWeightMedium,
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: 'capitalize',
    },
  };
};

export const getMuiChipDefaultProps = (): ComponentsProps['MuiChip'] => ({
  deleteIcon: <CloseRoundedIcon />,
});
