import { Box, BoxProps, Typography } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { COLOR_WHITE } from 'theme/variables';

interface HowItWorksCardProps extends BoxProps {
  title: string;
  description: string;
  image: string;
}

export const HowItWorksCard = ({ title, description, image, ...boxProps }: HowItWorksCardProps) => (
  <Box
    {...boxProps}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: COLOR_WHITE,
      paddingY: { xs: 3, md: 4, xl: 8 },
      paddingX: { xs: 3, md: 5, xl: 10 },
      ...boxProps.sx,
    }}
  >
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 28, md: 48 },
          lineHeight: { xs: '34px', md: '60px' },
          fontWeight: FontWeights.fontWeightSemiBold,
        }}
      >
        {title}
      </Typography>
      <Box sx={{ paddingBottom: { xs: 2, md: 3 } }} />
      <Typography
        sx={{
          fontSize: { xs: 17, md: 20 },
          lineHeight: { xs: '25px', md: '32px' },
          fontWeight: { xs: FontWeights.fontWeightRegular, lg: FontWeights.fontWeightMedium },
        }}
      >
        {description}
      </Typography>
    </Box>

    <Box sx={{ paddingBottom: { xs: 2, md: 4, xl: 4 } }} />

    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'start', md: 'center' },
        minHeight: 0,
        flex: 1,
        maxHeight: 336,
      }}
    >
      <Box component="img" src={image} alt={title} sx={{ maxWidth: { xs: '75%', md: 'min(100%, 336px)' } }} />
    </Box>
  </Box>
);
