import { fork } from 'redux-saga/effects';

import convertSaga from './convert';
import getPolygonBalance from './getPolygonBalance';
import login from './login';
import patchUserInfo from './patchUserInfo';
import updateUserInfo from './updateUserInfo';

export default function* userSagas() {
  yield fork(login);
  yield fork(updateUserInfo);
  yield fork(patchUserInfo);
  yield fork(getPolygonBalance);
  yield fork(convertSaga);
}
