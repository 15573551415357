/* eslint-disable @typescript-eslint/no-unused-vars */

import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_200 } from 'theme/variables';

export const getMuiAccordion = (theme: Theme): ComponentsOverrides['MuiAccordion'] => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },

    '&.contained': {
      background: COLOR_GRAY_200,
      borderRadius: BORDER_RADIUS_DEFAULT,
    },
  },
});

export const getMuiAccordionDetails = (theme: Theme): ComponentsOverrides['MuiAccordionDetails'] => ({
  root: {
    padding: 0,
  },
});
export const getMuiAccordionSummary = (theme: Theme): ComponentsOverrides['MuiAccordionSummary'] => ({
  root: {
    minHeight: '24px',
  },
  content: {
    margin: theme.spacing(1, 0),
  },
});

export const getMuiAccordionPropsDefaultProps = (): ComponentsProps['MuiAccordion'] => ({
  disableGutters: true,
  elevation: 0,
  square: true,
});
