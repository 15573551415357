import { ActivityState, State } from 'types';

const activitySelector = {
  getActivity: (state: State): ActivityState => state.activity,
  getProp:
    <T extends keyof ActivityState>(key: T) =>
    (state: State): ActivityState[T] =>
      state.activity[key],
};

export default activitySelector;
