import { call, put, takeLatest } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { TokenFull } from 'types/api';
import { logger } from 'utils';
import { camelize } from 'utils/camelize';

import { searchNfts } from '../actions';
import actionTypes from '../actionTypes';
import { setNfts, setTotalNfts } from '../reducer';

export function* searchNftsSaga({ type, payload }: ReturnType<typeof searchNfts>) {
  yield put(request(type));

  try {
    const { data } = yield call(baseApi.searchNfts, payload);
    if (payload.props.type === 'items') {
      const result = camelize(data.results) as TokenFull[];
      yield put(setNfts(result));
      yield put(setTotalNfts(data.total));
    }

    yield put(success(type));
  } catch (e) {
    logger('Search nfts', e);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SEARCH_NFTS, searchNftsSaga);
}
