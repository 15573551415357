import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { CollectionTypeBadge, Copy } from 'components';
import { useShallowSelector } from 'hooks';
import { getNftData } from 'store/nft/actions';
import nftSelector from 'store/nft/selectors';
import actionTypes from 'store/orders/actionTypes';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';
import { RequestStatus } from 'types';
import { getPropertyValue, shortenPhrase } from 'utils';

import { RedeemClaimForm } from './components';

type RedeemClaimNftProps = {
  type: 'redeem' | 'claim';
};

export const RedeemClaimNft: FC<RedeemClaimNftProps> = ({ type }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const { animationFile, name, properties, format, owners, isClaimed } = useShallowSelector(
    nftSelector.getProp('detailedNft'),
  );
  const address = useShallowSelector(userSelector.getProp('address'));

  const { [actionTypes.REDEEM]: redeemRequestStatus, [actionTypes.CLAIM]: claimRequestStatus } = useShallowSelector(
    uiSelector.getUI,
  );
  const isRedeemLoading = useMemo(() => redeemRequestStatus === RequestStatus.REQUEST, [redeemRequestStatus]);
  const isClaimLoading = useMemo(() => claimRequestStatus === RequestStatus.REQUEST, [claimRequestStatus]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(address);
      toast.success('Wallet address was copied');
    } catch (err) {
      toast.error('Something went wrong, try again lately');
    }
  };

  const handleIsValidChange = useCallback(
    (newIsValid: boolean) => {
      setIsValid(newIsValid);
    },
    [setIsValid],
  );

  useEffect(() => {
    if (id) dispatch(getNftData({ id }));
  }, [dispatch, id]);

  const isOwner = useMemo(
    () => (!owners?.length ? true : owners?.find((owner) => owner.address === address)),
    [address, owners],
  );

  useEffect(() => {
    if (!isOwner) navigate('/404');
  }, [isOwner, navigate]);

  return (
    <Box sx={{ width: { xs: '100%', md: '70%' }, marginX: 'auto' }}>
      <Box px={{ xs: 0, lg: '112px' }}>
        <Typography
          textTransform="capitalize"
          fontWeight={FontWeights.fontWeightBold}
          sx={{ typography: { xs: 'h2', md: 'h1' } }}
          className="lg"
        >
          {type === 'redeem' ? 'redeem' : 'claim'} request form
        </Typography>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        mt={8}
        px={{ xs: 0, lg: '112px' }}
        spacing={{ xs: 3, md: 16 }}
        marginBottom={6}
      >
        <Stack width={{ xs: '100%', sm: '432px' }} spacing={3}>
          <Box position="relative" overflow="hidden" borderRadius="8px" sx={{ 'img, audio, video': { width: '100%' } }}>
            <CollectionTypeBadge
              isClaimed={isClaimed}
              collectionType={type === 'redeem' ? 'Redeemable' : 'Claimable'}
            />
            {format === 'image' && <img src={animationFile} alt="NFT animation file" style={{ borderRadius: '8px' }} />}
            {format === 'video' && (
              <video controls autoPlay>
                <source src={animationFile} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                <track kind="captions" />
              </video>
            )}
            {format === 'audio' && (
              <audio controls autoPlay>
                <source src={animationFile} />
                <track kind="captions" />
              </audio>
            )}
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography>NFT ID:</Typography>
            <Typography sx={{ color: COLOR_BLACK }}>{id}</Typography>
          </Stack>
          <Typography variant="h4" className="lg">
            {name}
          </Typography>
          <Stack direction="row" spacing={10}>
            <Stack direction="row" spacing={2}>
              <Typography>Size type:</Typography>

              <Typography sx={{ color: COLOR_BLACK }}>{getPropertyValue(properties, 'Size Type') || 'None'}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography>Size:</Typography>
              <Typography sx={{ color: COLOR_BLACK }}>{getPropertyValue(properties, 'Size') || 'None'}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography>Wallet address:</Typography>
            <Button onClick={handleCopy} variant="text" sx={{ gap: 1 }}>
              <Typography sx={{ color: COLOR_BLACK }}>{shortenPhrase(address)}</Typography>
              <Copy sx={{ path: { stroke: COLOR_BLACK } }} />
            </Button>
          </Stack>
        </Stack>
        <RedeemClaimForm orderType={type} onIsValidChange={handleIsValidChange} />
      </Stack>
      <Box px={{ xs: 0, lg: '112px' }}>
        <Typography sx={{ marginBottom: 2 }}>
          Please note.
          <br /> 1. This service is limited to meta[Z] collectible sneakers NFTs.
          <br /> 2. Delivery charge (policy to be mentioned)
          <br /> 3. We take no responsibility from any wrong transaction and/ or cost incurred due to mistakenly entered
          misinformation.
        </Typography>
        <Box sx={{ marginBottom: 6 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="autofill"
                checked={isAgree}
                onClick={() => setIsAgree((previousIsAgree) => !previousIsAgree)}
              />
            }
            label="I agree"
            sx={{
              marginLeft: 0,
              '.MuiTypography-root': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: FontWeights.fontWeightSemiBold,
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            form="redeemForm"
            type="submit"
            variant="contained"
            disabled={!isAgree || !isValid || isRedeemLoading || isClaimLoading}
            sx={{ width: { xs: '100%', md: 400 }, textTransform: 'capitalize' }}
          >
            {isClaimLoading || isRedeemLoading ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
              <>Request {type}</>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
