import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { FontWeights } from 'theme/Typography';
import { COLOR_WHITE } from 'theme/variables';

export type SneakerCardProps = {
  id: string | number;
  img: string;
  name: string;
  size: string;
  fontSize: number;
  lineHeight: string;
};

export const SneakerCard: FC<SneakerCardProps> = ({ id, img, name, size, fontSize, lineHeight }) => {
  const navigate = useNavigate();

  const CardText = styled(Typography)({
    fontSize,
    lineHeight,
    fontWeight: FontWeights.fontWeightSemiBold,
    color: COLOR_WHITE,
    textTransform: 'uppercase',
  });

  return (
    <Box
      position="relative"
      mx="auto"
      my="auto"
      overflow="hidden"
      width="100%"
      borderRadius={{ xs: '10px', sm: fontSize === 18 ? '16px' : '30px' }}
      onClick={() => navigate(routes.nft.root.getPath(id))}
      sx={{ cursor: 'pointer', '&:after': { content: '""', display: 'block', paddingBottom: '100%' } }}
    >
      <Box
        position="absolute"
        zIndex={2}
        width="100%"
        height="100%"
        sx={{
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 44.79%, rgba(0, 0, 0, 0.8) 100%)',
        }}
      />
      <Box
        position="absolute"
        component="img"
        src={img}
        alt={`${name}`}
        sx={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }}
      />
      <Stack
        position="absolute"
        bottom={20}
        left={0}
        zIndex={3}
        px={2}
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-end"
        width="100%"
      >
        <Box>
          <CardText>{name}</CardText>
        </Box>
        <CardText whiteSpace="nowrap">size: US{size}</CardText>
      </Stack>
    </Box>
  );
};
