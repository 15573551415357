import { toast } from 'react-toastify';
import { ContractWeb3 } from '@amfi/connect-wallet/dist/interface';
import BigNumber from 'bignumber.js/bignumber';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Chains, IChainType } from 'types';
import { logger } from 'utils';

import { convert, updateUserInfo } from '../actions';
import actionTypes from '../actionTypes';

export function* convertSaga({ type, payload: { amount, web3Provider, currency } }: ReturnType<typeof convert>) {
  yield put(request(type));
  const { network, address, chainType }: { network: Chains; address: string; chainType: IChainType } = yield select(
    userSelector.getUser,
  );

  try {
    const { address: tokenAddress, abi } =
      contractsConfig.contracts[network === 'Polygon' ? ContractsNames.WMATIC : ContractsNames.WETH][chainType];
    const tokenContract: ContractWeb3 = yield new web3Provider.eth.Contract(abi[network], tokenAddress[network]);
    const tokenDecimals: string = yield call(tokenContract.methods.decimals().call);

    if (currency === 'eth' || currency === 'matic') {
      yield call(tokenContract.methods.deposit().send, {
        from: address,
        value: new BigNumber(amount).times(10 ** 18).toFixed(0, 1),
      });
    } else {
      yield call(tokenContract.methods.withdraw(new BigNumber(amount).times(10 ** +tokenDecimals).toFixed(0, 1)).send, {
        from: address,
      });
    }

    yield put(updateUserInfo({ web3Provider, network }));
    toast.success(`You have successfully converted ${currency.toUpperCase()}`);
    yield put(success(type));
  } catch (err) {
    logger('Covert', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CONVERT, convertSaga);
}
