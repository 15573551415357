import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import {
  BORDER_GREEN,
  BORDER_RADIUS_DEFAULT,
  BORDER_RED,
  COLOR_BLACK,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GRAY_400,
  COLOR_GRAY_700,
  COLOR_GREEN,
  COLOR_LIGHT,
  COLOR_LIGHT_GRAY,
  COLOR_RED_100,
  COLOR_WHITE,
} from 'theme/variables';

export const getMuiInputBaseOverrides = (): ComponentsOverrides['MuiInputBase'] => ({
  input: {
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 1000px transparent inset !important',
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  multiline: {
    height: '100%',
  },
});

export const getMuiTextFieldOverrides = (theme: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
    '& .MuiFilledInput-root': {
      boxShadow: 'none',
      color: COLOR_BLACK,
      background: COLOR_LIGHT_GRAY,
      border: '1px solid',
      borderColor: COLOR_LIGHT,
      borderRadius: BORDER_RADIUS_DEFAULT,
      minHeight: 44,
      '&:before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&:hover': {
        background: COLOR_LIGHT_GRAY,
        borderColor: COLOR_GRAY_150,
      },
      '&.Mui-focused': {
        background: COLOR_LIGHT_GRAY,
        borderColor: COLOR_GRAY_150,
      },
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: COLOR_GRAY_100,
      },
      '&.Mui-disabled': {
        backgroundColor: COLOR_LIGHT_GRAY,
        '&:hover': {
          borderColor: COLOR_GRAY_700,
        },
      },
      '&.MuiInputBase-colorSuccess': {
        border: BORDER_GREEN,
      },
      '&.Mui-error': {
        border: BORDER_RED,
        '&:after': {
          border: 'none',
        },
      },
      '&.MuiInputBase-formControl': {
        padding: theme.spacing(1.25),
        paddingLeft: theme.spacing(2),
        textarea: {
          resize: 'vertical',
          '&::-webkit-resizer': {
            borderWidth: '8px',
            borderStyle: 'solid',
            borderColor: `transparent ${COLOR_GRAY_100} ${COLOR_GRAY_100} transparent`,
            borderRadius: '2px',
          },
        },
      },
      'input:-webkit-autofill': {
        backgroundColor: `${COLOR_GRAY_400} !important`,
        border: 'none',
      },
    },

    '& .MuiOutlinedInput-root': {
      color: COLOR_BLACK,
      borderRadius: BORDER_RADIUS_DEFAULT,
      background: COLOR_WHITE,
      border: `1px solid ${COLOR_GRAY_150}`,
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        borderColor: COLOR_GRAY_150,
        border: 'none',
      },
    },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    // },
    // '& .MuiFilledInput-underline:after': {
    //   borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    // },
    '& .MuiInputLabel-filled': {
      color: COLOR_GREEN,
      paddingLeft: theme.spacing(0.5),
      top: '-7px',
      '&.Mui-focused': {
        color: COLOR_GREEN,
      },
    },
    '& .MuiFormHelperText-root': {
      color: COLOR_GRAY_100,
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: 0,
      marginTop: theme.spacing(1),

      '& .Mui-error': {
        color: COLOR_RED_100,
      },
    },
    'input[type="number"]': {
      MozAppearance: 'textfield',
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  inputProps: {
    style: {
      padding: 0,
    },
  },
});
