import { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { COLOR_GREEN, COLOR_WHITE_OPACITY_20 } from 'theme/variables';

import { CreateFormProps } from '../../CreateCollectibleForm.helpers';

export type RadioAccordionProps = {
  disabled: boolean;
  label: string;
  name: string;
  items: string[];
  setFieldValue: (item: string, value: Record<string, string>) => void;
  value: string;
  values: CreateFormProps;
};

export const RadioAccordion: FC<RadioAccordionProps> = ({
  disabled,
  label,
  name,
  items,
  value,
  setFieldValue,
  values,
}) => (
  <Accordion disableGutters key={label} defaultExpanded>
    <AccordionSummary
      sx={{ px: 0 }}
      expandIcon={<KeyboardArrowDownIcon fontSize="medium" sx={{ color: COLOR_GREEN }} />}
    >
      <Typography className="medium">{label}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Divider sx={{ background: COLOR_WHITE_OPACITY_20, mb: 1 }} />
      <RadioGroup
        name={name}
        onChange={(e) => setFieldValue('details', { ...values.details, [name]: e.target.value })}
        defaultValue={value}
      >
        <Grid container spacing={1} justifyContent="space-between">
          {items.map((item) => (
            <Grid key={item} item xs={name === 'sizeType' ? 0 : 6}>
              <FormControlLabel disabled={disabled} value={item} control={<Radio sx={{ mr: 1 }} />} label={item} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </AccordionDetails>
  </Accordion>
);
