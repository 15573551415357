import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getMaxGas, logger, shortenPhrase } from 'utils';

import { transferNft } from '../actions';
import actionTypes from '../actionTypes';

import { getNftDataSaga } from './getNftData';

export function* transferSaga({
  type,
  payload: { id, amount, address, web3Provider },
}: ReturnType<typeof transferNft>) {
  yield put(request(type));

  const userAddress: string = yield select(userSelector.getProp('address'));

  try {
    const { data } = yield call(baseApi.transfer, id, { address, amount });

    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: userAddress,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        yield call(getNftDataSaga, {
          type: actionTypes.GET_NFT_DATA,
          payload: { id },
        });

        toast.success(`You have successfully transferred NFT to ${shortenPhrase(address)}`);
        yield put(success(type));
      } catch (e) {
        logger('Send transaction to transfer token', e);
        toast.error('Something went wrong');
        yield put(error(type, e));
      }
    } else {
      toast.error('No initial tx');
      yield put(error(type));
    }
  } catch (err) {
    logger('Transfer token', err);
    toast.error('Something went wrong');
    yield put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.TRANSFER, transferSaga);
}
