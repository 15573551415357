import { FC } from 'react';
import { Box } from '@mui/material';
import { FontWeights } from 'theme/Typography';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/variables';

type CollectionTypeBadgeProps = {
  collectionType: string | number;
  isClaimed: boolean;
};

export const CollectionTypeBadge: FC<CollectionTypeBadgeProps> = ({ collectionType, isClaimed }) => (
  <Box
    display={{ xs: 'none', sm: 'block' }}
    px={0.75}
    py={0.5}
    position="absolute"
    left={11}
    top={11}
    height={24}
    bgcolor={COLOR_BLACK}
    borderRadius={1}
    fontSize={14}
    lineHeight="16px"
    textAlign="center"
    fontWeight={FontWeights.fontWeightSemiBold}
    color={COLOR_WHITE}
  >
    {isClaimed && 'Claimed'}
    {!isClaimed && (collectionType === 'Digital' ? 'Digital only' : collectionType)}
  </Box>
);
