import { FC } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Accordion, AccordionDetails, AccordionSummary, Divider, MenuItem, Typography } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY_100, COLOR_WHITE_OPACITY_20 } from 'theme/variables';

import { SortItem } from './SortSelect.helpers';

type SortAccordion = {
  label: string;
  items: SortItem[];
  activeItem: string;
  onClick: (value: string) => void;
};
export const SortAccordion: FC<SortAccordion> = ({ label, items, activeItem, onClick }) => {
  const handleItemClick = (item: string) => {
    onClick(item === activeItem ? '' : item);
  };
  return (
    <Accordion disableGutters key={label} defaultExpanded>
      <AccordionSummary sx={{ pl: 0 }}>
        <Typography className="medium">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ background: COLOR_WHITE_OPACITY_20, mb: 1 }} />
        {items.map(({ title, value }) => (
          <MenuItem
            key={value}
            onClick={() => handleItemClick(value)}
            sx={{ px: 0, justifyContent: 'space-between', width: '100%' }}
          >
            <Typography variant="subtitle1" color={activeItem === value ? COLOR_BLACK : COLOR_GRAY_100}>
              {title}
            </Typography>
            {activeItem === value && <CheckIcon className="checkIcon" sx={{ width: '15px', color: COLOR_BLACK }} />}
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
