import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Activity } from 'types/api';
import { ActivityState } from 'types/store/activity';

const initialState: ActivityState = {
  notifications: [],
  isNewNotifications: false,
  activities: [],
  totalActivities: 0,
};

export const activityReducer = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Activity[]>) => ({
      ...state,
      notifications: action.payload,
    }),
    clearNotifications: (state) => ({
      ...state,
      notifications: [],
    }),
    setIsNewNotifications: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isNewNotifications: action.payload,
    }),
    setActivities: (state, action: PayloadAction<Activity[]>) => ({
      ...state,
      activities: action.payload,
    }),
    clearActivities: (state) => ({
      ...state,
      activities: [],
    }),
    setTotalActivities: (state, action: PayloadAction<number>) => ({
      ...state,
      totalActivities: action.payload,
    }),
    clearTotalActivities: (state) => ({
      ...state,
      totalActivities: 0,
    }),
  },
});

export const {
  setNotifications,
  clearNotifications,
  setIsNewNotifications,
  setActivities,
  clearActivities,
  setTotalActivities,
  clearTotalActivities,
} = activityReducer.actions;
export default activityReducer.reducer;
