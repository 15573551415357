import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Avatar, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { routes } from 'appConstants/routes';
import BigNumber from 'bignumber.js';
import { CopyText } from 'components';
import { useShallowSelector } from 'hooks';
import { follow, getProfileById, unfollow } from 'store/profile/actions';
import { clearProfile } from 'store/profile/reducer';
import profileSelector from 'store/profile/selectors';
import userSelector from 'store/user/selectors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { BORDER_RADIUS_SMALL, COLOR_BLACK, COLOR_GRAY_10, COLOR_GRAY_150, COLOR_LIGHT_GRAY } from 'theme/variables';
import { Currencies, ProfileState, State, UserState } from 'types';
import { shortenPhrase } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { ProfileInfo } from '../ProfileInfo';

import { NavMenu } from './components';
import { currenciesImagesMock } from './Profile.mock';

const FollowersButton = styled(Button)({
  backgroundColor: COLOR_LIGHT_GRAY,
  border: `1px solid ${COLOR_GRAY_150}`,
  borderRadius: BORDER_RADIUS_SMALL,
  color: COLOR_BLACK,
  height: '32px',
  width: '120px',
  whiteSpace: 'nowrap',
  '&.active': {
    backgroundColor: COLOR_GRAY_150,
  },
});

interface ProfileProps {
  children?: ReactNode;
}
export const Profile: FC<ProfileProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    displayName,
    name,
    avatar,
    followersCount,
    followsCount,
    customUrl,
    address,
    bio,
    email,
    site,
    twitter,
    instagram,
    facebook,
    isFollowing,
  } = useShallowSelector<State, ProfileState>(profileSelector.getProfile);
  const {
    id: userId,
    customUrl: userCustomUrl,
    balance,
    network,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const { id } = useParams();
  const { pathname } = useLocation();
  const isCurrentUser = id === userId?.toString() || id === userCustomUrl;
  const lastPathName = useMemo(() => pathname.split('/').pop(), [pathname]);

  const handleNavigateToEdit = () => {
    navigate(routes.profile.edit.root.getPath(customUrl || id));
  };
  const handleNavigateToCustody = () => {
    navigate(routes.profile.custody.root.getPath(customUrl || id));
  };
  const handleNavigateToFollowers = () => {
    navigate(routes.profile.followers.root.getPath(customUrl || id));
  };
  const handleNavigateToFollowing = () => {
    navigate(routes.profile.following.root.getPath(customUrl || id));
  };

  const handleFollow = () => {
    if (id) {
      dispatch(follow({ id }));
    }
  };

  const handleUnfollow = () => {
    if (id) {
      dispatch(unfollow({ id }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getProfileById({ id }));
    }
  }, [dispatch, id]);

  useEffect(
    () => () => {
      dispatch(clearProfile());
    },
    [dispatch],
  );

  return (
    <>
      <Grid
        container
        flexDirection={{ xs: 'column', sm: 'row' }}
        rowGap={4}
        columnGap={2}
        xs={12}
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      >
        <Grid
          item
          xs={12}
          sm={3.5}
          md={3}
          lg={2}
          mx={{ xs: 'auto', sm: 0 }}
          pr={{ xs: 0, sm: 2 }}
          {...flexHelper('space-between', 'center')}
          flexDirection="column"
        >
          <Grid item mx="auto">
            <Avatar
              srcSet={avatar}
              sx={{
                width: '112px',
                height: '112px',
              }}
            />
          </Grid>
          {isCurrentUser && (
            <Button
              variant="outlined"
              size="large"
              sx={{
                display: { xs: 'none', sm: 'block' },
                mt: 2,
                width: '100%',
                minWidth: 80,
                p: 0,
                whiteSpace: 'nowrap',
                fontWeight: FontWeights.fontWeightBold,
              }}
              onClick={handleNavigateToEdit}
            >
              Edit profile
            </Button>
          )}
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          flexDirection="row"
          xs={12}
          sm={8.5}
          md={9}
          lg={10}
          columnGap={2}
          justifyContent="space-between"
        >
          <Grid
            item
            container
            rowGap={2.5}
            xs={12}
            flexDirection="column"
            justifyContent="space-between"
            alignItems={{ xs: 'center', sm: 'start' }}
            mx={{ xs: 'auto', sm: 0 }}
          >
            <Typography
              sx={{
                typography: { xs: 'h3', md: 'h1' },
                color: `${COLOR_BLACK} !important`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: { xs: 'center', sm: 'start' },
                width: '100%',
              }}
            >
              {displayName || shortenPhrase(name || '')}
            </Typography>
            <Stack spacing={2}>
              <Grid container columnGap={1.25} justifyContent={{ xs: 'center', sm: 'start' }}>
                <FollowersButton
                  size="small"
                  onClick={handleNavigateToFollowers}
                  className={lastPathName === 'followers' ? 'active' : ''}
                >
                  {followersCount} Followers
                </FollowersButton>
                <FollowersButton
                  onClick={handleNavigateToFollowing}
                  size="small"
                  className={lastPathName === 'following' ? 'active' : ''}
                >
                  {followsCount} Following
                </FollowersButton>
              </Grid>
              {isCurrentUser && (
                <Grid container columnGap={2.5} justifyContent={{ xs: 'center', sm: 'start' }}>
                  {Object.entries(balance[network]).map(([key, balanceValue]) => (
                    <Stack
                      key={key}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ img: { width: '24px', height: '24px' } }}
                    >
                      {currenciesImagesMock[key as Currencies]}
                      <Typography variant="h3" fontFamily={FontFamilies.primary}>
                        {+balanceValue > 10000 ? '>10000' : parseFloat(new BigNumber(balanceValue).toFixed(4, 1))}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
              )}
            </Stack>
            <Grid
              item
              container
              columnGap={2}
              rowGap={2}
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'center', sm: 'start' }}
            >
              <Stack
                maxWidth={{ xs: '320px', sm: '360px' }}
                spacing={2}
                alignItems={{ xs: 'center', sm: 'flex-start' }}
              >
                <CopyText variant="contained" text={address || '-'} />
                {!isCurrentUser && !!userId && (
                  <Stack direction="row" spacing={1.25}>
                    <Button
                      variant={isFollowing ? 'outlined' : 'contained'}
                      size="large"
                      sx={{
                        width: '175px',
                        fontWeight: FontWeights.fontWeightBold,
                      }}
                      onClick={isFollowing ? handleUnfollow : handleFollow}
                    >
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </Button>
                  </Stack>
                )}
              </Stack>
              {isCurrentUser && (
                <Stack direction="row" spacing={{ xs: 2, sm: 0 }}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      minWidth: '120px',
                      width: '140px',
                      p: 0,
                      display: { xs: 'block', sm: 'none' },
                      whiteSpace: 'nowrap',
                      fontWeight: FontWeights.fontWeightBold,
                    }}
                    onClick={handleNavigateToEdit}
                  >
                    Edit profile
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      minWidth: '120px',
                      width: { xs: '140px', sm: '136px' },
                      whiteSpace: 'nowrap',
                      fontWeight: FontWeights.fontWeightBold,
                    }}
                    onClick={handleNavigateToCustody}
                  >
                    <ShoppingBagOutlinedIcon sx={{ mr: 0.5 }} />
                    Custody
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={9}>
        <Grid
          item
          xs={12}
          sm={3.5}
          md={3}
          lg={2}
          mb={{ xs: 4, sm: 0 }}
          sx={{
            overflowX: { xs: 'scroll', sm: 'auto' },
            scrollbarWidth: 'thin',
            scrollbarColor: `${COLOR_GRAY_10} ${COLOR_LIGHT_GRAY}`,
            '&::-webkit-scrollbar': {
              backgroundColor: COLOR_LIGHT_GRAY,
              height: 4,
            },
            '&::-webkit-scrollbar-thumb': {
              height: 1,
              background: COLOR_GRAY_10,
            },
          }}
        >
          {id && <NavMenu userId={userCustomUrl || userId.toString()} profileId={id} />}
        </Grid>
        {children || (
          <ProfileInfo
            bio={bio || 'There is no information yet'}
            socialLinks={{ email, site, twitter, instagram, facebook }}
          />
        )}
      </Grid>
    </>
  );
};
