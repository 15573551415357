import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowLeftGreen: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 6 11"
    sx={{
      width: '6px',
      height: '11px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 1.07121C6.09763 1.46174 6.09763 2.0949 5.70711 2.48543L2.41421 5.77832L5.70711 9.07121C6.09763 9.46174 6.09763 10.0949 5.70711 10.4854C5.31658 10.876 4.68342 10.876 4.29289 10.4854L0.292893 6.48543C-0.0976311 6.0949 -0.0976311 5.46174 0.292893 5.07121L4.29289 1.07121C4.68342 0.680689 5.31658 0.680689 5.70711 1.07121Z"
      fill="currentColor"
    />
  </SvgIcon>
);
