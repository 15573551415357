import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from 'store/nft/actionTypes';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { getEnabledOnCarousel } from '../actions';
import { setEnabledOnCarousel } from '../reducer';

export function* getEnabledOnCarouselSaga({ type }: ReturnType<typeof getEnabledOnCarousel>) {
  yield* put(request(type));

  try {
    const { data } = yield* call(baseApi.getEnabledOnCarousel);

    yield* put(setEnabledOnCarousel(camelize(data)));

    yield* put(success(type));
  } catch (e) {
    yield* put(error(type, e));
    logger('getEnabledOnCarouselSaga', e);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_ENABLED_ON_CAROUSEL, getEnabledOnCarouselSaga);
}
