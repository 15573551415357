import { routes } from 'appConstants';

export const aboutItems = [
  {
    id: 1,
    title: 'meta[Z] Blog',
    link: 'https://metaz.ghost.io/',
    isExternal: true,
  },
  {
    id: 2,
    title: 'How it works',
    link: 'https://metaz.gitbook.io/how-it-works/',
    isExternal: true,
  },
];

export const termsItems = [
  {
    id: 1,
    title: 'Privacy Policy',
    link: routes.privacy.root.path,
  },
  {
    id: 2,
    title: 'Terms of Use',
    link: routes.terms.root.path,
  },
];
