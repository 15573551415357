import { FC, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { times } from 'lodash';
import { COLOR_BLACK, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'theme/variables';

import { TitleText } from '../../Home';

import { HotStylesBlock } from './components';
import { hotStylesItems } from './HotStyles.mocked';

export const HotStyles: FC = () => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Stack spacing={3}>
      <TitleText>Hot Styles</TitleText>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: '100%', sm: 'repeat(2, 50%)' }}
        gridTemplateRows="repeat(auto, 380px)"
        gap={{ xs: 5, sm: 0 }}
      >
        {times(isExpand ? hotStylesItems.length : 8).map((index) => (
          <HotStylesBlock
            key={index}
            {...hotStylesItems[index]}
            isLastRow={isExpand ? index === 12 || index === 13 : index === 6 || index === 7}
          />
        ))}
      </Box>
      <Button
        onClick={() => setIsExpand((prev) => !prev)}
        sx={{
          width: '100%',
          height: 56,
          background: COLOR_LIGHT_GRAY,
          border: 'none',
          borderRadius: '8px',
          color: COLOR_BLACK,
          textTransform: 'none',
          '&:hover': {
            color: COLOR_WHITE,
          },
        }}
      >
        {isExpand ? 'Show less' : 'View more'}
      </Button>
    </Stack>
  );
};
