import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Warning: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={{ width: '27px', height: '24px', fill: 'none', ...sx }} viewBox="0 0 27 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1838 13.773C14.1838 14.4254 13.6543 14.9549 13.0019 14.9549C12.3494 14.9549 11.8199 14.4254 11.8199 13.773V9.04515C11.8199 8.39271 12.3494 7.8632 13.0019 7.8632C13.6543 7.8632 14.1838 8.39271 14.1838 9.04515V13.773ZM13.0019 18.5008C12.3494 18.5008 11.8199 17.9712 11.8199 17.3188C11.8199 16.6664 12.3494 16.1369 13.0019 16.1369C13.6543 16.1369 14.1838 16.6664 14.1838 17.3188C14.1838 17.9712 13.6543 18.5008 13.0019 18.5008ZM25.4842 17.6769L16.4163 2.6437C15.7083 1.4712 14.4318 0.771484 13.0017 0.771484C11.5715 0.771484 10.295 1.4712 9.58699 2.6437L0.519056 17.6769C-0.152293 18.7915 -0.172386 20.1319 0.465868 21.2618C1.15022 22.4757 2.47991 23.2286 3.93372 23.2286H22.0696C23.5234 23.2286 24.8531 22.4757 25.5374 21.2618C26.1757 20.1319 26.1556 18.7915 25.4842 17.6769Z"
      fill="currentColor"
    />
  </SvgIcon>
);
