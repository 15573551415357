import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack, useMediaQuery } from '@mui/material';
import { routes } from 'appConstants/routes';
import { LogoSmall } from 'assets/img';
import { NotifyBell } from 'components';
import { useShallowSelector } from 'hooks';
import { getNotifications } from 'store/activity/actions';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { theme } from 'theme';
import { COLOR_BLACK } from 'theme/variables';
import { Modals, State, UserState } from 'types';

import { ConnectWalletButton } from './ConnectWalletButton';
import { MobileMenu } from './MobileMenu';
import { Nav } from './Nav';
import { NavMenu } from './NavMenu';
import { Profile } from './Profile';
import { SearchNfts } from './SearchNfts';

export const HeaderControls: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMenuOpen, setIsOpenMenu] = useState(false);

  const { address } = useShallowSelector<State, UserState>(userSelector.getUser);

  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  const closeMenu = () => setIsOpenMenu(false);
  const handleIsOpenNavMenu = () => setIsOpenMenu((prev) => !prev);
  const handleNavigateToHome = () => {
    navigate(routes.home.root.path);
    if (isMenuOpen) setTimeout(closeMenu, 0);
  };

  useEffect(() => {
    setIsOpenMenu(false);
  }, [isXsScreen, isSmScreen]);

  useEffect(() => {
    if (address) dispatch(getNotifications());
    const intervalId = setInterval(() => {
      if (address) dispatch(getNotifications());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [address, dispatch]);

  return (
    <Stack
      position="relative"
      direction="row"
      py={{ xs: 2, md: 2.5 }}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      {isXsScreen && (
        <MobileMenu
          isMenuOpen={isMenuOpen}
          handleIsOpenMenu={handleIsOpenNavMenu}
          address={address}
          handleOpenModal={handleOpenModal}
          handleNavigateToHome={handleNavigateToHome}
        />
      )}

      <Stack direction="row" spacing={3}>
        {isSmScreen && <NavMenu isMenuOpen={isMenuOpen} handleIsOpenMenu={handleIsOpenNavMenu} />}

        <IconButton onClick={handleNavigateToHome} sx={{ cursor: 'pointer' }}>
          <LogoSmall />
        </IconButton>

        {!isXsScreen && <SearchNfts />}
      </Stack>

      <Stack direction="row" spacing={7.5} sx={{ display: 'flex' }}>
        {isDesktopScreen && <Nav />}

        {address.length ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton onClick={() => handleOpenModal(Modals.Activity)}>
              <NotifyBell sx={{ color: COLOR_BLACK }} />
            </IconButton>
            {!isXsScreen && <Profile onOpenModal={handleOpenModal} />}
          </Stack>
        ) : (
          !isXsScreen && <ConnectWalletButton onClick={() => handleOpenModal(Modals.ConnectWallet)} />
        )}
      </Stack>
    </Stack>
  );
};
