const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS' as const;
const VIEW_ACTIVITY = 'VIEW_ACTIVITY' as const;
const GET_ACTIVITIES = 'GET_ACTIVITIES' as const;
const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES' as const;
const GET_FOLLOWING_ACTIVITIES = 'GET_FOLLOWING_ACTIVITIES' as const;

export default {
  GET_NOTIFICATIONS,
  VIEW_ACTIVITY,
  GET_ACTIVITIES,
  GET_USER_ACTIVITIES,
  GET_FOLLOWING_ACTIVITIES,
};
