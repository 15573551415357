import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { ArrowDownWhite, Photo, TextArea, Uploader, ValidatedTextField } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector, ValidationTypes } from 'hooks';
import { SizeAccordion } from 'modules/explore/pages/Explore/components/FilterMenu/components';
import networkSelector from 'store/network/selectors';
import { getSelfCollections } from 'store/nft/actions';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { TertiaryButton } from 'theme/Button';
import { FontWeights } from 'theme/Typography';
import {
  COLOR_BLACK,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GREEN,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
} from 'theme/variables';
import { Currency } from 'types/api';

import { SavedCollectibleForms } from '../../CreateCollectible';

import { RadioAccordion } from './components';
import {
  auctionTimes,
  CreateFormProps,
  details,
  props,
  saleTypes,
  validationSchema,
} from './CreateCollectibleForm.helpers';

const DashedContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  backgroundColor: COLOR_LIGHT_GRAY,
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%237F777777' stroke-width='2' stroke-dasharray='5%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: '8px',
});

const TextButton = styled(Button)({
  color: COLOR_GRAY_100,
  fontWeight: FontWeights.fontWeightRegular,
  textTransform: 'none',
});

const FinalButton = styled(Button)({
  width: '175px',
  height: '44px',
  fontWeight: FontWeights.fontWeightBold,
});

export type CreateCollectibleFormProps = {
  isSingle: boolean;
  savedCollectibleForms: CreateFormProps[];
  setSavedCollectibleForms: Dispatch<SetStateAction<SavedCollectibleForms>>;
  currentFormIndex: number;
  closeForm: () => void;
};

export const CreateCollectibleForm: FC<CreateCollectibleFormProps> = ({
  isSingle,
  savedCollectibleForms,
  setSavedCollectibleForms,
  currentFormIndex,
  closeForm,
}) => {
  const [activeSize, setActiveSize] = useState<Array<string | number>>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { network, isMultipleFabricOwner, isSingleFabricOwner } = useShallowSelector(userSelector.getUser);
  const collections = useShallowSelector(nftSelector.getProp('collections'));
  const { currencies, platformFeePercentage } = useShallowSelector(networkSelector.getNetwork);

  const handleChangeSaleType = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string | Currency) => void,
    currency: Currency,
  ) => {
    setFieldValue('saleType', e.target.value);
    if (e.target.value !== 'instant') {
      const currencyArray =
        (currencies && currencies.filter((item) => item.symbol !== 'eth' && item.symbol !== 'matic')) || [];
      if (!currencyArray.find((item) => item.symbol === currency.symbol)) {
        setFieldValue('currency', currencyArray[0]);
      }
    } else if (currencies) {
      setFieldValue('currency', currencies[0]);
    }
  };

  const handleChangeSize = (items: Array<string | number>) => setActiveSize(items);

  const handleSubmit = (values: CreateFormProps) => {
    setSavedCollectibleForms((prev) => {
      if (prev[isSingle ? 'single' : 'multiple'].length > currentFormIndex) {
        return {
          ...prev,
          [isSingle ? 'single' : 'multiple']: prev[isSingle ? 'single' : 'multiple'].map((form, index) =>
            index === currentFormIndex ? values : form,
          ),
        };
      }
      return { ...prev, [isSingle ? 'single' : 'multiple']: [...prev[isSingle ? 'single' : 'multiple'], values] };
    });
    closeForm();
  };

  useEffect(() => {
    dispatch(getSelfCollections({ standart: isSingle ? 'ERC721' : 'ERC1155', network, page: 1 }));
  }, [dispatch, isSingle, network]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        ...(savedCollectibleForms[currentFormIndex] || props),
        currency: currencies ? currencies[0] : {},
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ touched, errors, handleChange, handleBlur, values, setFieldValue, isValid }) => (
        <Form>
          <Grid
            px={{ xs: 0, md: '110px' }}
            mt="51px"
            container
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            gap={{ xs: '36px', md: '128px' }}
          >
            <Box>
              <Uploader formikValue="media" setFormat={(value) => setFieldValue('format', value)}>
                {!values.preview ? (
                  <DashedContainer width={{ xs: '100%', sm: '432px' }} maxWidth="432px" height="432px">
                    <Photo sx={{ width: '46px', height: '46px', color: COLOR_BLACK }} />
                    <Typography mt="8px" variant="h4" fontWeight={FontWeights.fontWeightRegular}>
                      Upload file
                    </Typography>
                    <Typography sx={{ color: COLOR_GRAY_100 }}>Drag or choose your file to upload</Typography>
                    <Typography variant="subtitle1" sx={{ color: COLOR_GRAY_100 }}>
                      (Max 30 Mb)
                    </Typography>
                  </DashedContainer>
                ) : (
                  <Box mt={3} sx={{ 'img, video, audio': { width: { xs: '100%', sm: '432px' } } }}>
                    {values.format === 'image' && <img src={values.preview} alt="preview" />}
                    {values.format === 'video' && (
                      <video controls>
                        <source src={values.preview} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                        <track kind="captions" />
                      </video>
                    )}
                    {values.format === 'audio' && (
                      <audio controls>
                        <source src={values.preview} />
                        <track kind="captions" />
                      </audio>
                    )}
                  </Box>
                )}
              </Uploader>
              {values.preview && (
                <Grid mt="40px" container gap="17px" alignItems="center">
                  <Uploader formikValue="cover" isImgOnly>
                    {!values.coverPreview ? (
                      <DashedContainer width={{ xs: '162px', sm: '206px' }} height={{ xs: '162px', sm: '206px' }}>
                        <Photo sx={{ width: '46px', height: '46px', color: COLOR_BLACK }} />
                      </DashedContainer>
                    ) : (
                      <Box mt={3} sx={{ img: { width: { xs: '162px', sm: '206px' } } }}>
                        <img src={values.coverPreview} alt="cover preview" />
                      </Box>
                    )}
                  </Uploader>
                  <Stack direction="column" spacing={1} width="210px">
                    <Typography mt="16px" variant="h4" fontWeight={FontWeights.fontWeightRegular}>
                      Upload preview
                    </Typography>
                    <Typography sx={{ color: COLOR_GRAY_100 }}>Drag or choose your file to upload</Typography>
                    <Typography variant="subtitle1" sx={{ color: COLOR_GRAY_100 }}>
                      (PNG, GIF, WEBP, MP4, JPEG, WEBM, MP3. Max 1 Mb)
                    </Typography>
                  </Stack>
                </Grid>
              )}
            </Box>
            <Stack direction="column" spacing={2} width={{ xs: '100%', sm: '360px' }}>
              <Typography mb="8px" variant="h4" fontWeight={FontWeights.fontWeightRegular}>
                Unlockable content
              </Typography>
              <ToggleButtonGroup
                color="secondary"
                value={values.isUnlockable}
                exclusive
                onChange={() => setFieldValue('isUnlockable', !values.isUnlockable)}
              >
                <ToggleButton value={Boolean('true')}>Enable</ToggleButton>
                <ToggleButton value={false}>Disable</ToggleButton>
              </ToggleButtonGroup>
              {values.isUnlockable && (
                <Field
                  id="text"
                  name="text"
                  render={({ form: { isSubmitting } }: FieldProps) => (
                    <TextArea
                      name="text"
                      disabled={isSubmitting}
                      label="Text"
                      placeholder="Enter content (access key, link a file, etc.)"
                      value={values.text}
                      handleChange={(e) => setFieldValue('text', e.target.value)}
                      handleBlur={handleBlur}
                      error={(touched.text && errors.text) || ''}
                    />
                  )}
                />
              )}
              <Stack
                p="24px"
                direction="column"
                spacing={3}
                sx={{ backgroundColor: COLOR_LIGHT_GRAY, borderRadius: '16px' }}
              >
                <Stack direction="row" spacing="13px" alignItems="center">
                  <Checkbox checked={values.onSale} onChange={() => setFieldValue('onSale', !values.onSale)} />
                  <Typography variant="h4" fontWeight={FontWeights.fontWeightRegular}>
                    Put on sale
                  </Typography>
                </Stack>
                {values.onSale && (
                  <>
                    {isSingle ? (
                      <Field
                        id="saleType"
                        name="saleType"
                        render={({ form: { isSubmitting } }: FieldProps) => (
                          <RadioGroup
                            name="saleType"
                            onChange={(e) => handleChangeSaleType(e, setFieldValue, values.currency)}
                            defaultValue={values.saleType}
                            sx={{ gap: 2 }}
                          >
                            {saleTypes.map(
                              (item) =>
                                (isSingle || item.index === 0) && (
                                  <FormControlLabel
                                    disabled={isSubmitting}
                                    key={item.index}
                                    value={item.value}
                                    control={<Radio />}
                                    label={item.label}
                                  />
                                ),
                            )}
                          </RadioGroup>
                        )}
                      />
                    ) : (
                      ''
                    )}
                    {values.saleType === 'auctionTime' ? (
                      <Stack direction="row" spacing={2}>
                        {auctionTimes.map((item) => (
                          <TertiaryButton
                            key={item.index}
                            className={clsx(values.endAuction === item.index ? 'active' : 'not-active')}
                            onClick={() => setFieldValue('endAuction', item.index)}
                            sx={{
                              textTransform: 'none',
                              backgroundColor: COLOR_GRAY_150,
                              color: COLOR_WHITE,
                              '& .active': { backgroundColor: `${COLOR_BLACK} !important` },
                            }}
                          >
                            {item.label}
                          </TertiaryButton>
                        ))}
                      </Stack>
                    ) : (
                      ''
                    )}
                    <Grid container rowGap={1} columnGap={1} justifyContent="space-between">
                      {(currencies &&
                        currencies.map((item) =>
                          values.saleType === 'instant' || (item.symbol !== 'eth' && item.symbol !== 'matic') ? (
                            <TertiaryButton
                              key={item.name}
                              size="small"
                              className={clsx(values.currency.symbol === item?.symbol ? 'active' : 'not-active')}
                              onClick={() => setFieldValue('currency', item)}
                              sx={{ gap: 1, img: { width: '20px' }, p: { color: COLOR_WHITE } }}
                            >
                              <img src={item.image} alt={item.name} />
                              {item?.symbol?.toUpperCase()}
                            </TertiaryButton>
                          ) : (
                            ''
                          ),
                        )) ||
                        []}
                    </Grid>
                    <Stack spacing={1}>
                      <Typography>{values.saleType === 'instant' ? 'Enter price' : 'Minimum bid'}</Typography>
                      <ValidatedTextField
                        item="priceOrBid"
                        validationType={ValidationTypes.number}
                        decimals={4}
                        name="priceOrBid"
                        variant="filled"
                        fullWidth
                        autoComplete="off"
                        placeholder="You will receive"
                        value={values.priceOrBid}
                        onBlur={handleBlur}
                        error={touched.priceOrBid && !!errors.priceOrBid}
                        helperText={(touched.priceOrBid && errors.priceOrBid) || ''}
                        InputProps={{
                          endAdornment: currencies &&
                            currencies.find((item) => item?.symbol === values.currency?.symbol)?.image && (
                              <img
                                src={currencies.find((item) => item?.symbol === values.currency?.symbol)?.image}
                                alt="currency logo"
                              />
                            ),
                        }}
                        sx={{ '.MuiFilledInput-root': { background: COLOR_LIGHT_GRAY }, img: { width: '20px' } }}
                      />
                      <Typography variant="subtitle1" sx={{ color: COLOR_GRAY_150 }}>
                        Service fee {parseFloat(platformFeePercentage)}%
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Accordion defaultExpanded sx={{ background: COLOR_LIGHT_GRAY, borderRadius: '8px' }}>
                <AccordionSummary
                  expandIcon={<ArrowDownWhite sx={{ color: COLOR_GREEN }} />}
                  aria-controls="collections-content"
                  id="collection-accordion"
                >
                  <Typography fontWeight={FontWeights.fontWeightMedium}>Collections</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack pl={5} pb={2} spacing={1} alignItems="flex-start">
                    {collections && collections.length ? (
                      collections.map((item, index) => (
                        <TextButton
                          variant="text"
                          key={item.id}
                          onClick={() => setFieldValue('collection', index)}
                          sx={{ color: values.collection === index ? COLOR_GREEN : COLOR_GRAY_100 }}
                        >
                          {item.name}
                        </TextButton>
                      ))
                    ) : (
                      <Typography>No available collections</Typography>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              {(isSingleFabricOwner || isMultipleFabricOwner) && (
                <Button
                  variant="contained"
                  sx={{
                    width: 250,
                    padding: '8px 13px',
                    fontWeight: FontWeights.fontWeightRegular,
                    textTransform: 'none',
                  }}
                  onClick={() => navigate('/create/collection')}
                >
                  Create new collection +
                </Button>
              )}
              {Object.keys(collections[values.collection]?.propertyData || {}).map((item) =>
                item === 'size' ? (
                  <SizeAccordion
                    key="size"
                    type="create"
                    label="Size"
                    items={collections[values.collection]?.propertyData?.size || []}
                    activeItems={activeSize}
                    onChange={handleChangeSize}
                    setFieldValue={setFieldValue}
                    values={values}
                    value={values.details.size}
                  />
                ) : (
                  <Field
                    key={item}
                    id={item}
                    name={item}
                    render={({ form: { isSubmitting } }: FieldProps) => (
                      <RadioAccordion
                        disabled={isSubmitting}
                        label={item}
                        name={item}
                        items={collections[values.collection]?.propertyData?.[item] || ['Others']}
                        setFieldValue={setFieldValue}
                        value={values.details[item]}
                        values={values}
                      />
                    )}
                  />
                ),
              )}
              <Stack pt={1} spacing={3}>
                <Typography variant="h4" fontWeight={FontWeights.fontWeightRegular}>
                  Items Details
                </Typography>
                <Stack spacing={2}>
                  {details.map((item) => {
                    if (item.name !== 'totalSupply' || !isSingle)
                      return item.name === 'totalSupply' ? (
                        <Stack key={item.id} spacing={1}>
                          <Typography>{item.label}</Typography>
                          <ValidatedTextField
                            item={item.name}
                            validationType={ValidationTypes.int}
                            variant="filled"
                            name={item.name}
                            autoComplete="off"
                            value={values[item.name]}
                            onBlur={handleBlur}
                            error={touched[item.name] && !!errors[item.name]}
                            helperText={String((touched[item.name] && errors[item.name]) || '') || item.textHelper}
                          />
                        </Stack>
                      ) : (
                        <Field
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          render={({ form: { isSubmitting } }: FieldProps) => (
                            <Stack spacing={1}>
                              <Typography>{item.label}</Typography>
                              <TextField
                                variant="filled"
                                name={item.name}
                                autoComplete="off"
                                disabled={item.name === 'creatorRoyalty' ? true : isSubmitting}
                                value={
                                  item.name !== 'creatorRoyalty'
                                    ? values[item.name]
                                    : parseFloat(String(collections[values.collection]?.royaltyPercentage || 0))
                                }
                                onChange={item.name === 'creatorRoyalty' ? undefined : handleChange}
                                onBlur={handleBlur}
                                error={touched[item.name] && !!errors[item.name]}
                                helperText={String((touched[item.name] && errors[item.name]) || '') || item.textHelper}
                              />
                            </Stack>
                          )}
                        />
                      );
                    return '';
                  })}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <FinalButton
                    variant="contained"
                    type="submit"
                    disabled={!isValid || (values.onSale && (+values.priceOrBid < 0.001 || !values.currency.symbol))}
                  >
                    save
                  </FinalButton>
                  <FinalButton variant="outlined" onClick={closeForm}>
                    Clear all
                  </FinalButton>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
