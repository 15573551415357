import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlusGreen: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    sx={{
      width: '56px',
      height: '56px',
      fill: 'none',
      ...sx,
    }}
    viewBox="0 0 56 56"
  >
    <path
      d="M28.0007 9.33301V46.6663M46.6673 27.9997L9.33398 27.9997"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
