import { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useShallowSelector, useTimeLeft } from 'hooks';
import moment from 'moment';
import nftSelector from 'store/nft/selectors';
import { COLOR_BLACK, COLOR_GRAY_100 } from 'theme/variables';

export const NftPriceOrBid: FC = () => {
  const { isSelling, isAucSelling, isTimedAucSelling, highestBid, minimalBid, price, currency, owners } =
    useShallowSelector(nftSelector.getProp('detailedNft'));
  const endSaleDates = useMemo(() => owners?.map((owner) => +owner.endSale), [owners]);
  const furthestDate = useMemo(() => (endSaleDates?.length ? Math.max(...endSaleDates) : 0), [endSaleDates]);
  const timeLeft = useTimeLeft(+furthestDate * 1000);

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <Stack direction="column" alignItems="flex-start" spacing={1} flex="1 1 0px">
        {(isAucSelling || isTimedAucSelling) && highestBid ? (
          <>
            <Typography color={COLOR_GRAY_100}>Highest bid</Typography>
            <Typography variant="h2" sx={{ color: COLOR_BLACK }}>{`${parseFloat(highestBid?.amount || '0')} ${
              currency?.symbol?.toUpperCase() || 'ETH'
            }`}</Typography>
            <Typography variant="subtitle2">{`$ ${parseFloat(
              new BigNumber(highestBid?.amount || 0).times(currency?.rate || 0).toFixed(4, 1),
            )}`}</Typography>
          </>
        ) : (
          ''
        )}
        {(isAucSelling || isTimedAucSelling) && !highestBid ? (
          <>
            <Typography color={COLOR_GRAY_100}>minimal bid</Typography>
            <Typography variant="h2" sx={{ color: COLOR_BLACK }}>{`${parseFloat(String(minimalBid || 0))} ${
              currency?.symbol?.toUpperCase() || 'ETH'
            }`}</Typography>
            <Typography variant="subtitle2">{`$ ${parseFloat(
              new BigNumber(minimalBid || 0).times(currency?.rate || 0).toFixed(4, 1),
            )}`}</Typography>
          </>
        ) : (
          ''
        )}
        {!isAucSelling && !isTimedAucSelling && isSelling ? (
          <>
            <Typography color={COLOR_GRAY_100}>Price</Typography>
            <Typography variant="h4" className="bold" fontSize={{ xs: '20px', lg: '28px' }}>
              {price
                ? `${parseFloat(String(price || 0)).toLocaleString('en-US')} ${
                    currency?.symbol?.toUpperCase() || 'ETH'
                  }`
                : '-'}
            </Typography>
            <Typography variant="subtitle1" color={COLOR_GRAY_100}>{`$ ${parseFloat(
              new BigNumber(price || 0).times(currency?.rate || 0).toFixed(4, 1),
            )}`}</Typography>
          </>
        ) : (
          ''
        )}
      </Stack>
      <Stack direction="column" alignItems="flex-start" spacing={1} flex="1 1 0px">
        <Typography className="gray">Sale ends in</Typography>
        {!timeLeft ||
        (+timeLeft.days === 0 && +timeLeft.hours === 0 && +timeLeft.minutes === 0 && +timeLeft.seconds === 0) ? (
          <Typography>-</Typography>
        ) : (
          <>
            <Typography variant="h4" className="bold" fontSize={{ xs: '20px', lg: '28px' }}>
              {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
            </Typography>
            <Typography variant="subtitle2" className="gray" fontSize={{ xs: '12px', lg: '14px' }}>
              {`${moment(+(furthestDate || 0) * 1000).format('MMMM Do, YYYY')}
                    ${moment(+(furthestDate || 0) * 1000).format('h:mm A')} GMT${moment(
                +(furthestDate || 0) * 1000,
              ).format('Z')}`}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
