import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_GRAY_100, COLOR_LIGHT_GRAY } from 'theme/variables';

export const getMuiMenu = (theme: Theme): ComponentsOverrides['MuiMenu'] => ({
  paper: {
    backgroundColor: `${COLOR_LIGHT_GRAY} !important`,
    borderRadius: BORDER_RADIUS_DEFAULT,
  },
});

export const getMuiMenuItem = (): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    transition: '200ms',
    color: COLOR_GRAY_100,
    '&:hover': {
      color: COLOR_BLACK,
    },
    '&:selected': { color: COLOR_BLACK },
    '&.Mui-selected': { color: COLOR_BLACK },
  },
});

export const getMuiMenuDefaultProps = (): ComponentsProps['MuiMenu'] => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
});
