import { Dispatch, FC, SetStateAction } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { CreateFormProps } from 'modules/create/pages/CreateCollectible/components/CreateCollectibleForm/CreateCollectibleForm.helpers';
import { TertiaryButton } from 'theme/Button';
import { COLOR_GREEN, COLOR_WHITE_OPACITY_20 } from 'theme/variables';

import { ChipData } from '../FilterMenu.helpers';

type SizeAccordionProps = {
  type: 'explore' | 'create';
  label: string;
  items: string[];
  activeItems: Array<string | number>;
  onChange: (value: Array<string | number>) => void;
  setChipData?: Dispatch<SetStateAction<ChipData[]>>;
  setFieldValue?: (item: string, value: Record<string, string>) => void;
  value?: string;
  values?: CreateFormProps;
};
export const SizeAccordion: FC<SizeAccordionProps> = ({
  type,
  label,
  items,
  activeItems,
  onChange,
  setChipData,
  setFieldValue,
  value,
  values,
}) => {
  const handleClick = (newItem: string) => {
    if (activeItems.includes(newItem)) {
      onChange(activeItems.filter((item) => item !== newItem));
      if (setChipData) {
        setChipData((prevChipData) => prevChipData.filter((item) => item !== newItem));
      }
    } else {
      onChange([...activeItems, newItem]);
      if (setChipData) {
        setChipData((prevChipData) => [...prevChipData, newItem]);
      }
    }
  };
  return (
    <Accordion disableGutters key={label} defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        expandIcon={<KeyboardArrowDownIcon fontSize="medium" sx={{ color: COLOR_GREEN }} />}
      >
        <Typography className="medium">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ background: COLOR_WHITE_OPACITY_20, mb: 2 }} />
        <Grid container rowGap={1} columnGap={1}>
          {items.map((item) => (
            <TertiaryButton
              key={item}
              size="small"
              className={activeItems.includes(item) || value === item ? 'active' : 'not-active'}
              onClick={() =>
                type === 'create' && setFieldValue && values
                  ? setFieldValue('details', { ...values.details, size: item })
                  : handleClick(item)
              }
              sx={{ maxWidth: '84px', width: '68px', height: '36px' }}
            >
              {item}
            </TertiaryButton>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
