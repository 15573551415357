import { COLOR_RED_100, COLOR_WHITE, COLOR_YELLOW } from 'theme/variables';
import { State } from 'types/api';

export const OrderStatusColors: { [key in State]?: string } = {
  [State.WaitingForApproval]: COLOR_YELLOW,
  [State.WaitingForAcceptance]: COLOR_YELLOW,
  [State.Enqueued]: COLOR_YELLOW,
  [State.Pending]: COLOR_YELLOW,
  [State.WaitingForDispatch]: COLOR_YELLOW,
  [State.Approved]: COLOR_WHITE,
  [State.Created]: COLOR_WHITE,
  [State.Declined]: COLOR_RED_100,
  [State.Denied]: COLOR_RED_100,
  [State.ClaimTxCommitted]: COLOR_WHITE,
  [State.SneakersSent]: COLOR_WHITE,
  [State.PayForShipping]: COLOR_YELLOW,
  [State.PaymentDeliveryPending]: COLOR_YELLOW,
};

export const OrderButtonTextVariants: { [key in State]?: string } = {
  [State.Approved]: 'Confirm claim',
  [State.PayForShipping]: 'Pay for shipping',
};
