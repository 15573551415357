import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import nftActionTypes from 'store/nft/actionTypes';
import { getNftDataSaga } from 'store/nft/sagas/getNftData';
import nftSelector from 'store/nft/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Collection } from 'types/api';
import { getMaxGas, logger } from 'utils';

import { redeemNft } from '../actions';
import actionTypes from '../actionTypes';

import { approveRedeemOrClaimSaga } from './approveRedeemOrClaim';

export function* redeemNftSaga({
  type,
  payload: { formData, setSubmitting, web3Provider },
}: ReturnType<typeof redeemNft>) {
  yield put(request(type));
  const address: string = yield select(userSelector.getProp('address'));
  const { collection, standart, id }: { collection: Collection; standart: string; id: number } = yield select(
    nftSelector.getProp('detailedNft'),
  );

  try {
    try {
      yield call(approveRedeemOrClaimSaga, {
        type: actionTypes.APPROVE_REDEEM_OR_CLAIM,
        payload: { web3Provider, collectionAddress: collection.address || '', isSingle: standart === 'ERC721' },
      });
    } catch {
      return;
    }

    const { data } = yield call(baseApi.redeem, formData);

    if (data.initial_tx) {
      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
        yield call(web3Provider.eth.sendTransaction, {
          ...data.initial_tx,
          from: address,
          maxFeePerGas,
          maxPriorityFeePerGas,
        });

        yield call(getNftDataSaga, { type: nftActionTypes.GET_NFT_DATA, payload: { id } });

        toast.success('You have successfully send redeem NFT request. Wait approve from admin');
        yield put(success(type));
      } catch (err) {
        yield call(baseApi.redeemReject, { tokenId: id, amount: 1 });

        logger('Send redeem transaction', err);
        toast.error('Something went wrong');
        yield put(error(type));
      }
    } else {
      yield put(error(type));
      toast.error('No initial tx');
    }
  } catch (err) {
    logger('', err);
    yield put(error(type, err));
  } finally {
    setSubmitting(false);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REDEEM, redeemNftSaga);
}
