import { FC } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { CoinbaseLogo, MetaMaskLogo, WalletConnectLabel, WalletConnectLogo } from 'assets/img';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { FontFamilies } from 'theme/Typography';
import { BORDER_RADIUS_DEFAULT, COLOR_GRAY_100, COLOR_GRAY_200 } from 'theme/variables';
import { Chains, INotifyModalProps, WalletProviders } from 'types';

interface IConnectWalletModalProps extends INotifyModalProps {
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
}

const WalletButton = styled(Button)({
  width: '100%',
  height: '54px',
  background: COLOR_GRAY_200,
  fontFamily: FontFamilies.primary,
  fontSize: '22px',
  borderRadius: BORDER_RADIUS_DEFAULT,
  border: 'none',
  '&:hover': {
    background: COLOR_GRAY_200,
    border: 'none',
  },
});

export const ConnectWalletModal: FC<IConnectWalletModalProps> = ({ onConnectWallet, closeModal, currData }) => {
  const network = useShallowSelector(userSelector.getProp('network'));

  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    onConnectWallet(provider, chain);
    closeModal();
  };

  return (
    <Stack spacing={1} minWidth={{ xs: 'auto' }}>
      <Typography variant="subtitle1" color={COLOR_GRAY_100} className="regular">
        {currData?.subtitle}
      </Typography>
      <Stack spacing={1}>
        <WalletButton onClick={() => handleConnect(WalletProviders.metamask, network)}>
          <MetaMaskLogo />
        </WalletButton>

        <WalletButton onClick={() => handleConnect(WalletProviders.coinbaseWallet, network)}>
          <CoinbaseLogo />
        </WalletButton>
        <WalletButton onClick={() => handleConnect(WalletProviders.walletConnect, network)}>
          <Box mr={0.75}>
            <WalletConnectLogo />
          </Box>
          <WalletConnectLabel />
        </WalletButton>
      </Stack>
    </Stack>
  );
};
