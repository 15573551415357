/* eslint-disable */
/* @ts-ignore */
/**
 * DO NOT MODIFY IT BY HAND.
 * This file was automatically generated.
 */

export enum CollectionType {
  Digital = 'Digital',
  Claimable = 'Claimable',
  Redeemable = 'Redeemable',
}

export enum Standart {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export enum State {
  WaitingForApproval = 'Waiting for approval',
  Approved = 'Approved',
  Declined = 'Declined',
  ClaimTxCommitted = 'Claim tx committed',
  SneakersSent = 'Sneakers sent',
  WaitingForSneakers = 'Waiting for sneakers',
  PayForShipping = 'Pay for shipping',
  PaymentDeliveryPending = 'Payment delivery pending',
  ObjectReceived = 'Object received',
  Enqueued = 'Enqueued',
  Pending = 'Pending',
  NftMinted = 'Nft minted',
  VerificationFailed = 'Verification failed',
  PendingRefundPayment = 'Pending refund payment',
  SneakersWillSoonBeShippedOut = 'Sneakers will soon be shipped out',
  Created = 'Created',
  WaitingForAcceptance = 'Waiting for acceptance',
  Denied = 'Denied',
  WaitingForDispatch = 'Waiting for dispatch',
  PostmintMultipleToken = 'Postmint multiple token',
  NotTradable = 'Not tradable',
}
