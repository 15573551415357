import { toast } from 'react-toastify';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, logger } from 'utils';

import { setOnAuction } from '../actions';
import actionTypes from '../actionTypes';
import { setDetailedNft } from '../reducer';

import { approveNftSaga } from './approveNft';

export function* setOnAuctionSaga({
  type,
  payload: { isSingle, collectionAddress, web3Provider, minimal_bid, end_auction, start_auction, id, amount, currency },
}: ReturnType<typeof setOnAuction>) {
  yield* put(request(type));

  try {
    try {
      yield call(approveNftSaga, {
        type: actionTypes.APPROVE_NFT,
        payload: { isSingle, collectionAddress, web3Provider },
      });
    } catch {
      return;
    }

    const { data } = yield call(baseApi.setOnAuction, id, {
      minimal_bid,
      currency,
      amount,
      end_auction,
      start_auction,
    });
    yield put(setDetailedNft(camelize(data, true)));
    toast.success('You have successfully set NFT on auction');
    yield* put(success(type));
  } catch (err) {
    logger('Set on auction', err);
    toast.error('Something went wrong');
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SET_ON_AUCTION, setOnAuctionSaga);
}
